import { COACH_Actions, SemanticICONS, SemanticICONS_color, SESSION_STATUS } from '../../../models/enum';
import { ICoachAction, IMediaResource, ISession } from '../../../models/type';
import { groupBy } from 'lodash';
import moment from 'moment';
import { maxUploadSize } from '../../../config/helperConstant';
import { switchSessionType } from '../../../functions/coach-reducer';

interface InitialMediaResource {
  pdf: Array<IMediaResource>;
  image: Array<IMediaResource>;
  web_link: Array<IMediaResource>;
  video_link: Array<IMediaResource>;
}

export interface CoachReducerState {
  mediaResources: InitialMediaResource;
  myCourses: {
    comingSessions: Array<any>;
    finishedCourses: Array<any>;
    selectedSession: ISession;
  };
  upload: {
    files: any;
    iconName: SemanticICONS;
    iconColor: SemanticICONS_color;
  };
}

const initialEmptyMediaResource: InitialMediaResource = {
  pdf: [],
  web_link: [],
  video_link: [],
  image: [],
};

const initialState: CoachReducerState = {
  mediaResources: initialEmptyMediaResource,
  myCourses: {
    comingSessions: [],
    finishedCourses: [],
    selectedSession: null,
  },
  upload: {
    files: null,
    iconName: SemanticICONS['cloud upload'],
    iconColor: SemanticICONS_color.grey,
  },
};

export const coachReducer = (state = initialState, action: ICoachAction) => {
  switch (action.type) {
    case COACH_Actions.set_media_resources:
      const copiedMediaRersources: any = { ...initialEmptyMediaResource };
      if (!action.payload.length) {
        return { ...state, mediaResources: initialEmptyMediaResource };
      }
      action.payload.forEach((item: IMediaResource) => {
        copiedMediaRersources[item.format] = [...copiedMediaRersources[item.format], item];
      });
      return { ...state, mediaResources: copiedMediaRersources };

    case COACH_Actions.clear_media_resources:
      return { ...state, mediaResources: initialEmptyMediaResource };
    case COACH_Actions.set_upload_photo:
      return {
        ...state,
        upload: {
          ...state.upload,
          files: action.payload.files,
          iconName:
            action.payload.files[0].size <= maxUploadSize
              ? action.payload.iconName
              : SemanticICONS['exclamation triangle'],
          iconColor: action.payload.files[0].size <= maxUploadSize ? action.payload.iconColor : SemanticICONS_color.red,
        },
      };

    case COACH_Actions.clear_upload:
      return {
        ...state,
        upload: {
          ...state.upload,
          files: null,
          iconName: SemanticICONS['cloud upload'],
          iconColor: SemanticICONS_color.grey,
        },
      };

    case COACH_Actions.select_session_to_join:
      return {
        ...state,
        myCourses: {
          ...state.myCourses,
          selectedSession: {
            ...action.payload,
          },
        },
      };

    case COACH_Actions.write_note:
      return {
        ...state,
        myCourses: {
          ...state.myCourses,
          selectedSession: {
            ...state.myCourses.selectedSession,
            note: action.payload,
          },
        },
      };

    case COACH_Actions.update_ongoing_session:
      return {
        ...state,
        myCourses: {
          ...state.myCourses,
          selectedSession: {
            ...state.myCourses.selectedSession,
            status: action.payload,
          },
        },
      };

    case COACH_Actions.clear_select_session:
      return {
        ...state,
        myCourses: { ...state.myCourses, selectedSession: null },
      };

    case COACH_Actions.set_my_sessions:
      //Filter status planned for comming sessions
      const plannedSessions = action.payload.filter(
        (item: ISession) =>
          item.status === SESSION_STATUS.planned ||
          item.status === SESSION_STATUS.started_waiting ||
          item.status === SESSION_STATUS.started,
      );

      // const startedSession = action.payload.find((item: ISession) => {
      //   return item.status === SESSION_STATUS.started;
      // });

      plannedSessions.sort((a: ISession, b: ISession) => {
        return new Date(a.planned_at).valueOf() - new Date(b.planned_at).valueOf();
      });

      const groupPlannedSessionsByDateMonthYear = groupBy(plannedSessions, function (session: ISession) {
        return moment(session.planned_at).format('DD/MM/YYYY');
      });
      let groupPlannedSessionsByMonthYear: any = {};
      for (let key in groupPlannedSessionsByDateMonthYear) {
        const getMonthYear = moment(key, 'DD/MM/YYYY').format('MM/YYYY');
        if (groupPlannedSessionsByMonthYear[getMonthYear]) {
          groupPlannedSessionsByMonthYear[getMonthYear] = {
            ...groupPlannedSessionsByMonthYear[getMonthYear],
            [key]: groupPlannedSessionsByDateMonthYear[key],
          };
        } else {
          groupPlannedSessionsByMonthYear = {
            ...groupPlannedSessionsByMonthYear,
            [getMonthYear]: { [key]: groupPlannedSessionsByDateMonthYear[key] },
          };
        }
      }
      return {
        ...state,
        myCourses: {
          ...state.myCourses,
          comingSessions: groupPlannedSessionsByMonthYear,
          // selectedSession: !!startedSession ? startedSession : null,
        },
      };

    case COACH_Actions.set_my_finished_sessions:
      //Filler sessions has finished at
      const finishedSessions = action.payload.filter((item: ISession) => {
        return !!item.finished_at;
      });

      finishedSessions.sort((a: ISession, b: ISession) => {
        return new Date(a.planned_at).valueOf() - new Date(b.planned_at).valueOf();
      });

      const groupByMonthYear = groupBy(finishedSessions, (session: ISession) => {
        return moment(session.finished_at).format('MM/YYYY');
      });

      return {
        ...state,
        myCourses: { ...state.myCourses, finishedCourses: groupByMonthYear },
      };

    case COACH_Actions.update_video_conference_link:
      return {
        ...state,
        myCourses: {
          ...state.myCourses,
          selectedSession: {
            ...state.myCourses.selectedSession,
            videoconference_link: action.payload,
          },
        },
      };

    case COACH_Actions.switch_session_type:
      const { id, newIsOfflineValue, sessionDate } = action.payload;
      return switchSessionType(state, id, newIsOfflineValue, sessionDate);
    default:
      return state;
  }
};
