import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import PrimaryLayout from '../../layouts/PrimaryLayout/PrimaryLayout';
import { MaterialIconName, Role_ID } from '../../models/enum';
import ProjectDirectorDashboard from '../ProjectDirectorDashboard/ProjectDirectorDashboard';
import styles from './projectDirectorMainPage.module.scss';

const ProjectDirectorMainPage: React.FC = () => {
  /*METHODS*/

  const navigate = useNavigate();

  const { t: translate } = useTranslation();

  const gotToProfile = () => navigate(`/${Role_ID.PROJECT_DIRECTOR}/profile`);

  return (
    <PrimaryLayout
      primaryTitle={translate('Dashboard')}
      primaryTitleIcon={MaterialIconName.assignment}
      role_name={translate('Project director')}
      toProfile={gotToProfile}
      hideUserIcon={true}
      hideMessageIcon={true}
      onClickPrimaryTitle={() => navigate(`/${Role_ID.PROJECT_DIRECTOR}`)}>
      <div className={styles.wrapper}>
        <ProjectDirectorDashboard />
      </div>
    </PrimaryLayout>
  );
};

export default ProjectDirectorMainPage;
