import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Segment } from 'semantic-ui-react';
import { ISession } from '../../models/type';
import styles from './coachCardFinishedCourse.module.scss';
import moment from 'moment';
import SwitchSessionTypeButton from '../../components/SwitchSessionTypeButton/SwitchSessionTypeButton';

interface Props {
  finishedSessions: ISession;
}

const CoachCardFinishedCourse: React.FC<Props> = (props: Props) => {
  /*METHODS*/
  const { t: translate } = useTranslation();

  return (
    <Segment className={`${styles.customSegment} ${props.finishedSessions.is_offline && styles.violetBorder}`}>
      <div className={styles.topBlock}>
        <Grid>
          <Grid.Row>
            <Grid.Column mobile='16' tablet='6' computer='6'>
              <div className={styles.topLeft}>
                <span className={`${styles.text1} topLevelText`}>
                  {translate('Session') +
                    ' ' +
                    props.finishedSessions.current_index +
                    '/' +
                    props.finishedSessions.course_context.number_of_sessions}
                </span>
                <span className={`${styles.text1}`}>{props.finishedSessions.course_context.offer_name}</span>
              </div>
            </Grid.Column>
            <Grid.Column mobile='16' tablet='10' computer='10'>
              <div className={styles.topRight}>
                <span className={`${styles.text3}`}>
                  {translate('Last update')}{' '}
                  <span className={`${styles.text4}`}>
                    {moment(props.finishedSessions.finished_at).format('DD/MM/YYYY')}
                  </span>
                </span>

                <span className={`${styles.text3}`}>
                  <span className={styles.textDanger}>{translate(props.finishedSessions.status)}</span>
                </span>
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
      <div className={styles.bottomBlock}>
        <Grid>
          <Grid.Row>
            <Grid.Column mobile='16' tablet='6' computer='6'>
              <div className={styles.bottomLeft}>
                <span className={styles.text5}>
                  {translate('Coachee')}
                  {' : '}
                  {props.finishedSessions.coachee.first_name + ' ' + props.finishedSessions.coachee.last_name}
                </span>
                <SwitchSessionTypeButton
                  sessionID={props.finishedSessions.id}
                  sessionStatus={props.finishedSessions.status}
                  isOffline={props.finishedSessions.is_offline}
                  triggerBy='readonly'
                />
              </div>
            </Grid.Column>

            <Grid.Column mobile='16' tablet='10' computer='10'>
              <div className={styles.bottomRight}>
                <span className={styles.text6}>
                  <span className={styles.bold700}>{translate('Client')}</span>
                  {' : '}
                  {props.finishedSessions.course_context.client_name}
                </span>
                <span className={styles.text6}>
                  <span className={styles.bold700}>{translate('Program')}</span>
                  {' : '}
                  {props.finishedSessions.course_context.program_name}
                </span>
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    </Segment>
  );
};

export default React.memo(CoachCardFinishedCourse);
