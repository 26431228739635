import React from 'react';
import { useDispatch } from 'react-redux';
import { enableSwitchSessionType } from '../../functions/session-card';
import { COACHEE_ACTIONS, COACH_Actions, STATUS_CODE } from '../../models/enum';
import ToggleButton from '../ToggleButton/ToggleButton';
import { useMutation } from 'urql';
import { SESSION_to_offline, SESSION_to_online } from '../../services/apis/sessionApis';

interface Props {
  sessionID: string;
  sessionStatus: string;
  isOffline: boolean;
  triggerBy: 'coach' | 'coachee' | 'readonly';
  sessionDate?: string;
}

const SwitchSessionTypeButton: React.FC<Props> = (props: Props) => {
  const [, changeSessionToOffline] = useMutation(SESSION_to_offline);
  const [, changeSessionToOnline] = useMutation(SESSION_to_online);

  const dispatch = useDispatch();

  const updateCoacheeReduxStore = (value: boolean) => {
    dispatch({
      type: COACHEE_ACTIONS.switch_session_type,
      payload: { id: props.sessionID, newIsOfflineValue: value },
    });
  };

  const updateCoachReduxStore = (value: boolean) => {
    dispatch({
      type: COACH_Actions.switch_session_type,
      payload: { id: props.sessionID, newIsOfflineValue: value, sessionDate: props.sessionDate },
    });
  };

  const switchSessionType = () => {
    if (props.triggerBy === 'readonly') return;

    const newIsOfflineValue = !props.isOffline;

    if (newIsOfflineValue === true) {
      changeSessionToOffline({ session_id: props.sessionID }).then((res) => {
        if (+res.data.sessionIsOffline.status_code !== +STATUS_CODE.SUCCESS) return;

        if (props.triggerBy === 'coachee') updateCoacheeReduxStore(newIsOfflineValue);

        if (props.triggerBy === 'coach') updateCoachReduxStore(newIsOfflineValue);
      });
    } else {
      changeSessionToOnline({ session_id: props.sessionID }).then((res) => {
        if (+res.data.sessionIsOnline.status_code !== +STATUS_CODE.SUCCESS) return;

        if (props.triggerBy === 'coachee') updateCoacheeReduxStore(newIsOfflineValue);

        if (props.triggerBy === 'coach') updateCoachReduxStore(newIsOfflineValue);
      });
    }
  };

  return (
    <ToggleButton
      checked={props.isOffline}
      handleChange={switchSessionType}
      disable={!enableSwitchSessionType(props.sessionStatus) || props.triggerBy === 'readonly'}
      label='Face to face'
    />
  );
};

export default SwitchSessionTypeButton;
