import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from 'urql';
import { PROGRAMMANGER_ACTIONS } from '../../models/enum';
import { PROGRAMMANAGER_program_info } from '../../services/apis/programMangerApis';
import styles from './programManagerMainPage.module.scss';

const NameOfProgram: React.FC = () => {
  const [programQueryResult] = useQuery({
    query: PROGRAMMANAGER_program_info,
    variables: { program_id: window.location.pathname.split('/').pop() },
    requestPolicy: 'cache-and-network',
  });

  const { name } = useSelector((state: any) => state.programManagerReducer.programInfo);

  const dispatch = useDispatch();

  /**
   * Effect dispatch program info: id, name, status to ProgramManagerReducer
   * Used it since this components always run whenever go to program manager page dashboard or coachee list
   */
  useEffect(() => {
    if (programQueryResult.data) {
      dispatch({
        type: PROGRAMMANGER_ACTIONS.set_program_info,
        payload: programQueryResult.data?.getProgram?.program,
      });
    }
  }, [dispatch, programQueryResult]);

  return <h1 className={styles.title}>{`Programme : ${!!name ? name : ''}`}</h1>;
};

export default NameOfProgram;
