import { PROGRAMMANGER_ACTIONS, SemanticICONS, SemanticICONS_color } from '../../../models/enum';
import { ICoachee_extended, IProgramMangerAction, IOfferConfiguration } from '../../../models/type';
import { mapAllCoursesProgressionPoint } from '../../ProgramManager/progression';

const initialState: any = {
  program: null,
  uploadComponent: {
    title: 'Importer la liste des coachés',
    titleDesc: 'Un fichier excel avec 3 colonnes dans cet ordre : ',
    titleDesc_bold: 'EMAIL, NOM, PRENOM',
    titleDescContinuous: 'et ne dépassant pas 5Mo',
    iconName: SemanticICONS['cloud upload'],
    iconColor: SemanticICONS_color['grey'],
    files: [],
  },
  listOfCoacheePage: {
    listCoachee: [],
    selectedCoacheeList: [],
    pagination: null,
  },
  coursesProgression: {
    programID: '',
    progressionCollection: [],
  },
  programInfo: {
    id: '',
    name: '',
    status: '',
  },
};

const programManagerReducer = (state = initialState, action: IProgramMangerAction) => {
  switch (action.type) {
    case PROGRAMMANGER_ACTIONS.set_program_info_viewing:
      return { ...state, program: action.payload };

    case PROGRAMMANGER_ACTIONS.set_list_coachee:
      return {
        ...state,
        listOfCoacheePage: {
          ...state.listOfCoacheePage,
          listCoachee: action.payload.coachees,
          pagination: action.payload.paginatorInfo,
        },
      };

    case PROGRAMMANGER_ACTIONS.select_coachee:
      const tempArr = state.listOfCoacheePage.selectedCoacheeList;
      //find index if id existed
      const existedId = tempArr.findIndex((item: ICoachee_extended) => item.id === action.payload.id);
      if (existedId === -1) tempArr.push(action.payload);
      else tempArr.splice(existedId, 1);
      return {
        ...state,
        listOfCoacheePage: {
          ...state.listOfCoacheePage,
          selectedCoacheeList: tempArr,
        },
      };

    case PROGRAMMANGER_ACTIONS.set_upload_file:
      return {
        ...state,
        uploadComponent: {
          ...state.uploadComponent,
          iconName: action.payload.iconName,
          iconColor: action.payload.iconColor,
          files: action.payload.files,
          title: action.payload.title,
          titleDesc: action.payload.titleDesc,
          titleDesc_bold: action.payload.titleDesc_bold,
        },
      };

    case PROGRAMMANGER_ACTIONS.remove_upload_file:
      return {
        ...state,
        uploadComponent: {
          ...state.uploadComponent,
          iconName: SemanticICONS['cloud upload'],
          iconColor: SemanticICONS_color['grey'],
          files: [],
        },
      };

    case PROGRAMMANGER_ACTIONS.clear_selected_coachee:
      return {
        ...state,
        listOfCoacheePage: {
          ...state.listOfCoacheePage,
          selectedCoacheeList: [],
        },
      };

    case PROGRAMMANGER_ACTIONS.assign_coachee_to_course:
      const copiedProgram = { ...state.program };
      const indexOfOfferConfig = copiedProgram.offerConfigurations.findIndex((item: IOfferConfiguration) => {
        return action.payload.selectedOfferID === item.id;
      });
      copiedProgram.offerConfigurations[indexOfOfferConfig].number_of_unassigned =
        copiedProgram.offerConfigurations[indexOfOfferConfig].number_of_unassigned - action.payload.coacheeIDs.length;
      return {
        ...state,
        program: copiedProgram,
        listOfCoacheePage: {
          ...state.listOfCoacheePage,
          selectedCoacheeList: [],
        },
      };

    case PROGRAMMANGER_ACTIONS.clear_store:
      return {
        ...state,
        program: null,
        listOfCoacheePage: {
          listCoachee: [],
          selectedCoacheeList: [],
          pagination: null,
        },
        coursesProgression: {
          programID: '',
          progressionCollection: [],
        },
        programInfo: {
          id: '',
          name: '',
          status: '',
        },
      };

    case PROGRAMMANGER_ACTIONS.set_course_progress:
      const newCoursesProgression = {
        programID: action.payload.programID,
        progressionCollection: mapAllCoursesProgressionPoint(action.payload.courses),
      };

      return { ...state, coursesProgression: newCoursesProgression };

    case PROGRAMMANGER_ACTIONS.set_program_info:
      const { id, name, status } = action.payload;

      return {
        ...state,
        programInfo: {
          id,
          name,
          status,
        },
      };
    default:
      return state;
  }
};

export default programManagerReducer;
