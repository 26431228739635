import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Grid, Segment } from 'semantic-ui-react';
import { useQuery } from 'urql';
import { PROGRAMMANAGER_get_program } from '../../services/apis/programMangerApis';
import { filerCourseType, generate_staked_bar_data } from '../../utilities/ChartHelper';
import ChartContainer from '../ChartContainer/ChartContainer';
import AwaitingComp from '../Common/AwaitingComp/AwaitingComp';
import DashboardStatisticalComp from '../DashboardStatisticalComp/DashboardStatisticalComp';
import TopListingComp from '../TopListingComp/TopListingComp';
import { OFFER_NAME, OFFER_NAME_Capitalize, PROGRAMMANGER_ACTIONS, ProgramStatus, Role_ID } from '../../models/enum';
import styles from './PMDashboard.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { calcAvgProgrammProgressionPoint, renderPercentage } from '../../services/ProgramManager/progression';

const NewPMDashboard: React.FC = () => {
  const [programDashboardQuery] = useQuery({
    query: PROGRAMMANAGER_get_program,
    variables: { program_id: window.location.pathname.split('/').pop() },
    requestPolicy: 'cache-and-network',
  });

  /*METHODS*/
  const { t: translate } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const allCoursesProgressions = useSelector((state: any) => state.programManagerReducer.coursesProgression);

  /*EFFECT*/
  useEffect(() => {
    if (programDashboardQuery.data) {
      if (programDashboardQuery.data.getProgram.program.status === ProgramStatus.NOT_READY)
        navigate(`/${Role_ID.PROGRAM_MANAGER}`);
      return;
    }

    // Dispatch len store course progress
    if (programDashboardQuery.data) {
      dispatch({
        type: PROGRAMMANGER_ACTIONS.set_course_progress,
        payload: {
          programID: programDashboardQuery.data.getProgram.program.id,
          courses: programDashboardQuery.data.getProgram.program.courses,
        },
      });
    }
  }, [dispatch, navigate, programDashboardQuery]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.contentBlock}>
        {programDashboardQuery.data?.getProgram && (
          <Grid>
            <Grid.Row>
              <Grid.Column mobile='16' tablet='16' computer='8'>
                <div className={`${styles.heightForBlock} ${styles.blockMarginBottom}`}>
                  <Segment className={`${styles.customedSegment}`}>
                    <ChartContainer
                      chartTitle={translate('Program progress')}
                      chartTitleHighlight={true}
                      chartType='horizontal_stacked_bar'
                      data={generate_staked_bar_data(programDashboardQuery.data.getProgram.program.courses_summary)}
                    />
                  </Segment>
                </div>
                <div className={styles.leftBottomWrapper}>
                  <div className={`${styles.leftBottomBlock} ${styles.heightForBlock} ${styles.blockMarginBottom}`}>
                    <Segment className={`${styles.customedSegment} ${styles.h100}`}>
                      <ChartContainer
                        chartTitle={OFFER_NAME_Capitalize.flash + ' ' + translate('Course summary')}
                        chartType='horizontal_stacked_bar'
                        data={generate_staked_bar_data(
                          filerCourseType(
                            programDashboardQuery.data.getProgram.program.offerConfigurations,
                            OFFER_NAME.flash,
                          ),
                        )}
                      />
                    </Segment>
                  </div>
                  <div className={`${styles.leftBottomBlock} ${styles.heightForBlock} ${styles.blockMarginBottom}`}>
                    <Segment className={`${styles.customedSegment} ${styles.h100}`}>
                      <ChartContainer
                        chartTitle={OFFER_NAME_Capitalize.focus + ' ' + translate('Course summary')}
                        chartType='horizontal_stacked_bar'
                        data={generate_staked_bar_data(
                          filerCourseType(
                            programDashboardQuery.data.getProgram.program.offerConfigurations,
                            OFFER_NAME.focus,
                          ),
                        )}
                      />
                    </Segment>
                  </div>
                  <div className={`${styles.leftBottomBlock} ${styles.heightForBlock} `}>
                    <Segment className={`${styles.customedSegment} ${styles.h100}`}>
                      <ChartContainer
                        chartTitle={OFFER_NAME_Capitalize.development + ' ' + translate('Course summary')}
                        chartType='horizontal_stacked_bar'
                        data={generate_staked_bar_data(
                          filerCourseType(
                            programDashboardQuery.data.getProgram.program.offerConfigurations,
                            OFFER_NAME.development,
                          ),
                        )}
                      />
                    </Segment>
                  </div>
                </div>
              </Grid.Column>
              <Grid.Column mobile='16' tablet='16' computer='8'>
                <Grid>
                  <Grid.Row className={styles.firstRowOfRightCol}>
                    <Grid.Column mobile='16' computer='8'>
                      <div className={`${styles.heightForBlock} ${styles.blockMarginBottom}`}>
                        <Segment className={`${styles.customedSegment} ${styles.h100}`}>
                          <DashboardStatisticalComp
                            label={translate('Session completed over sessions planned')}
                            category_sessions={programDashboardQuery.data.getProgram.program.sessions_summary}
                          />
                        </Segment>
                      </div>
                    </Grid.Column>
                    <Grid.Column mobile='16' computer='8'>
                      <div className={`${styles.heightForBlock} ${styles.blockMarginBottom}`}>
                        <Segment className={`${styles.customedSegment} ${styles.h100}`}>
                          <DashboardStatisticalComp
                            label={translate('Self diagnostic done')}
                            category_auto_diagnostics={programDashboardQuery.data.getProgram.program.self_diagnosis}
                          />
                        </Segment>
                      </div>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row className={styles.secondRowOfRightCol}>
                    <Grid.Column mobile='16' computer='8'>
                      <div className={`${styles.heightForBlock} ${styles.blockMarginBottom}`}>
                        <Segment className={`${styles.customedSegment} ${styles.h100}`}>
                          <DashboardStatisticalComp
                            label={translate('Total coachees')}
                            simpleStatistic={{
                              number: programDashboardQuery.data.getProgram.program.number_of_coachees,
                            }}
                          />
                        </Segment>
                      </div>
                    </Grid.Column>
                    <Grid.Column mobile='16' computer='8'>
                      <div className={`${styles.heightForBlock} ${styles.blockMarginBottom}`}>
                        <Segment className={`${styles.customedSegment} ${styles.h100}`}>
                          <DashboardStatisticalComp
                            label={translate('Overall satisfaction')}
                            simpleStatistic={{
                              number: programDashboardQuery.data.getProgram.program.courses_summary.evaluation + '/5',
                            }}
                          />
                        </Segment>
                      </div>
                    </Grid.Column>
                    <Grid.Column mobile='16' computer='8'>
                      <div className={`${styles.heightForBlock} ${styles.blockMarginBottom}`}>
                        <Segment className={`${styles.customedSegment} ${styles.h100}`}>
                          <DashboardStatisticalComp
                            label={translate('Progression')}
                            simpleStatistic={{
                              number: renderPercentage(
                                calcAvgProgrammProgressionPoint(allCoursesProgressions.progressionCollection),
                              ),
                            }}
                          />
                        </Segment>
                      </div>
                    </Grid.Column>
                    <Grid.Column computer='8'></Grid.Column>
                    <Grid.Column computer='16'>
                      <div className={styles.topNeedBlock}>
                        <Segment className={`${styles.customedSegment} ${styles.topNeedBlock}`}>
                          <TopListingComp top_of_besoins={programDashboardQuery.data.getProgram.program.top_of_needs} />
                        </Segment>
                      </div>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        )}
      </div>
      {programDashboardQuery.fetching && <AwaitingComp fluid={true} />}
    </div>
  );
};

export default NewPMDashboard;
