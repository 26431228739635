import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'urql';
import PrimaryLayout from '../../layouts/PrimaryLayout/PrimaryLayout';
import { Role_ID, SortDirection, ProgramOrderableColumn, MaterialIconName } from '../../models/enum';
import { IProgram_extended } from '../../models/type';
import { PROGRAM_fetch_programs_by_user_role_ID } from '../../services/apis/programApis';
import ProgramCardForManager from '../../components/ProgramCardForManager/ProgramCardForManager';
import styles from './programManagerIntermediatePage.module.scss';

const ProgramManagerIntermediatePage: React.FC = () => {
  /*PROPS*/
  const [programQueryVariables] = useState({
    page: 1,
    order_by: ProgramOrderableColumn.NUMBER_OF_COACHEES,
    direction: SortDirection.ASC,
  });

  /*METHODS*/
  const { t: translate } = useTranslation();
  const navigate = useNavigate();

  const [programsQueryResult] = useQuery({
    query: PROGRAM_fetch_programs_by_user_role_ID,
    variables: programQueryVariables,
    requestPolicy: 'cache-and-network',
  });

  const gotToProfile = () => navigate(`/${Role_ID.PROGRAM_MANAGER}/profile`);

  const renderProgramsCard = useCallback(() => {
    return programsQueryResult.data.listMyPrograms.programs.map((item: IProgram_extended) => {
      return <ProgramCardForManager key={item.id} program={item} />;
    });
  }, [programsQueryResult.data]);

  return (
    <PrimaryLayout
      primaryTitle={translate('Manage programs')}
      primaryTitleIcon={MaterialIconName.assignment}
      role_name={translate('Program manager')}
      toProfile={gotToProfile}
      hideMessageIcon={true}
      onClickPrimaryTitle={() => {}}
      hideUserIcon={true}>
      <div className={styles.wrapper}>
        <div className={styles.titleAndNavBlock}>
          <h1 className={styles.title}>{translate('Programs list')}</h1>
          <p className={styles.titleDesc}>{translate('Click a program')}</p>
        </div>
        <div className={styles.programsBlock}>{programsQueryResult.data?.listMyPrograms && renderProgramsCard()}</div>
      </div>
    </PrimaryLayout>
  );
};

export default ProgramManagerIntermediatePage;
