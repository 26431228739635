import { IDiagnosisAnswer, IDiscColorObject } from '../../models/type';
import { parseJson } from '../../utilities/helper';
import styles from './diagnosisFinishedResult.module.scss';

interface Props {
  answer: IDiagnosisAnswer;
}

const ResultCheckBox = ({ answer }: Props) => {
  const colorClass = () => {
    const valueObject: IDiscColorObject | null = parseJson<IDiscColorObject>(answer.value);
    let colorClass;
    if (valueObject == null) colorClass = styles['bg-default'];
    else if (valueObject.disc_colour === 'red') colorClass = styles['bg-red'];
    else if (valueObject.disc_colour === 'yellow') colorClass = styles['bg-yellow'];
    else if (valueObject.disc_colour === 'green') colorClass = styles['bg-green'];
    else if (valueObject.disc_colour === 'blue') colorClass = styles['bg-blue'];

    return colorClass;
  };

  return (
    <div className={styles.checkBox}>
      <div className={`${styles.checkBox_inner} ${colorClass()}`}></div>
    </div>
  );
};

export default ResultCheckBox;
