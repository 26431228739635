import React from 'react';
import ResourceItem from './ResourceItem';
import styles from './coachMediaResourcesPage.module.scss';
import { Grid } from 'semantic-ui-react';
import { CoachMediaResourcesTitle } from '../../config/helperConstant';
import { IMediaResource } from '../../models/type';

interface Props {
  categoryTitle: string;
  resourcesArray: Array<IMediaResource>;
}

const ResourcesCategoryBlock: React.FC<Props> = (props: Props) => {
  /*METHODS*/

  const renderResourcesItem = () => {
    return props.resourcesArray.map((item: IMediaResource) => {
      return (
        <Grid.Column stretched mobile='16' tablet='8' computer='4' key={item.id}>
          <ResourceItem mediaResourceItem={item} />
        </Grid.Column>
      );
    });
  };

  return (
    <div className={styles.categoryBlock}>
      <h6 className={styles.categoryTitle}>{CoachMediaResourcesTitle.get(props.categoryTitle)}</h6>
      <Grid stretched>
        <Grid.Row stretched>{renderResourcesItem()}</Grid.Row>
      </Grid>
    </div>
  );
};

export default ResourcesCategoryBlock;
