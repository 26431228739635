import { RouteObject } from 'react-router-dom';
import MessagingPage from '../../pages/MessagingPage/MessagingPage';
import CoacheeAutoDiagnosis from '../CoacheeAutoDiagnosis/CoacheeAutoDiagnosis';
import CoacheeClosedSession from '../CoacheeClosedSession/CoacheeClosedSession';
import CoacheeCurrentSession from '../CoacheeCurrentSession/CoacheeCurrentSession';
import CoacheeMediaResources from '../CoacheeMediaResources/CoacheeMediaResources';
import CoacheeObjective from '../CoacheeObjective/CoacheeObjective';
import CoacheeUpcomingSession from '../CoacheeUpcomingSession/CoacheeUpcomingSession';

const coacheeSessionManagementRouter: RouteObject[] = [
  { path: 'current', element: <CoacheeCurrentSession /> },
  { path: 'upcoming', element: <CoacheeUpcomingSession /> },
  { path: 'closed', element: <CoacheeClosedSession /> },
  { path: 'objective', element: <CoacheeObjective /> },
  { path: 'media', element: <CoacheeMediaResources /> },
  { path: 'diagnostic', element: <CoacheeAutoDiagnosis /> },
  { path: 'message', element: <MessagingPage /> },
];

export default coacheeSessionManagementRouter;
