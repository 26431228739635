import React, { useState } from 'react';
import { Navigate } from 'react-router-dom';
import { Button, Icon } from 'semantic-ui-react';
import styles from './Annoucement.module.scss';

interface IProps {
  title: string;
  description: string;
  btnTitle: string;
  style2?: boolean;
  style2Data?: any;
  style2ButtonFunction?: () => void;
}

const Annoucement: React.FC<IProps> = (props) => {
  const [directToLogin, setDirectToLogin] = useState(false);

  if (directToLogin) return <Navigate to='/login' />;

  /*MAIN RETURN*/

  /*2nd STYLE*/
  if (props.style2) {
    return (
      <div className={styles.formStyle2}>
        <Icon name='checkmark' color='green' size='big' />
        <p>{props.title}</p>
        <ul>
          {props.style2Data.map((item: string, index: number) => {
            return <li key={index}>{item}</li>;
          })}
        </ul>
        <Button type='button' className={styles.submitButtonWidth2} onClick={props.style2ButtonFunction}>
          <span>{props.btnTitle}</span>
        </Button>
      </div>
    );
  }

  /*1st STYLE*/
  return (
    <div className={styles.wrapper}>
      <Icon name='checkmark' size='huge' color='green' />
      <p className={`${styles.title} topLevelText`}>{props.title}</p>
      <p className={styles.description}>{props.description}</p>
      <Button
        type='button'
        className={styles.submitButton}
        style={props.btnTitle ? { visibility: 'visible' } : { visibility: 'hidden' }}
        onClick={() => setDirectToLogin(true)}>
        {props.btnTitle}
      </Button>
    </div>
  );
};

export default React.memo(Annoucement);
