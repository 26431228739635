import { useEffect, useState } from 'react';
import { Icon } from 'semantic-ui-react';
import { useQuery } from 'urql';
import { STATUS_CODE } from '../../models/enum';
import { MESSAGE_get_notify } from '../../services/apis/messageApis';
import styles from './primaryLayout.module.scss';

interface Props {
  onClick: () => void;
}

const MessageNotifyIcon = ({ onClick }: Props) => {
  /*STATE*/
  const [numNotification, setNumNotification] = useState(0);

  /*METHODS*/

  const [notifyResult] = useQuery({
    query: MESSAGE_get_notify,
    requestPolicy: 'cache-and-network',
  });

  const handleIconClicked = () => {
    setNumNotification(0);
    onClick();
  };

  /*EFFECT*/

  useEffect(() => {
    try {
      if (+notifyResult.data?.getMessagesNotification?.status_code === +STATUS_CODE.SUCCESS) {
        setNumNotification(notifyResult.data.getMessagesNotification.number_of_unread_messages);
      }
    } catch (e) {
      setNumNotification(0);
      console.log(e);
    }
  }, [notifyResult]);

  return (
    <div onClick={handleIconClicked}>
      <Icon name='envelope outline' className={styles.notifiedMail} />
      {numNotification ? (
        <p className={`${styles.notifiedNumber} ${styles.notifiedNumberPosition2}`}>{numNotification}</p>
      ) : (
        ''
      )}
    </div>
  );
};

export default MessageNotifyIcon;
