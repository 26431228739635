import { useTranslation } from 'react-i18next';
import { useQuery } from 'urql';
import { useNavigate } from 'react-router-dom';

import EditableCoacheComp from '../../components/EditableCoacheComp/EditableCoacheComp';
import PrimaryLayout from '../../layouts/PrimaryLayout/PrimaryLayout';
import { MaterialIconName, Role_ID, UserRoles } from '../../models/enum';
import { COACHEE_fetch_info } from '../../services/apis/coacheeApis';
import { getUserRoleID } from '../../utilities/helper';
import styles from './coacheProfilePage.module.scss';

const CoacheProfilePage = () => {
  /*METHODS*/
  const { t: translate } = useTranslation();
  const navigate = useNavigate();

  const gotToProfile = () => navigate(`/${Role_ID.COACHEE}/profile`);

  const [coacheInfo] = useQuery({
    query: COACHEE_fetch_info,
    variables: {
      id: getUserRoleID(UserRoles.COACHEE),
    },
  });

  /*MAIN RETURN*/
  return (
    <PrimaryLayout
      primaryTitle={'Mes parcours'}
      primaryTitleIcon={MaterialIconName.assignment}
      role_name='Coaché'
      hideUserIcon={true}
      toProfile={gotToProfile}
      onClickPrimaryTitle={() => navigate(`/${Role_ID.COACHEE}`)}>
      <div className={styles.wrapper}>
        <div className={styles.titleBlock}>
          <h1 className={styles.title}>{translate('My profile')}</h1>
        </div>
        {coacheInfo.data && <EditableCoacheComp user_info={coacheInfo.data.detailCoachee.user_infos} />}
      </div>
    </PrimaryLayout>
  );
};

export default CoacheProfilePage;
