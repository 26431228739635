import { useState, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon, Segment, Transition } from 'semantic-ui-react';
import { ISession } from '../../models/type';
import styles from './noteReviewComp.module.scss';

interface Props {
  session?: ISession;
}

const NoteReviewComp = ({ session }: Props) => {
  const [showNoteContent, setShowNoteContent] = useState(false);
  const { t: translate } = useTranslation();

  return (
    <Segment className={styles.noteBlock}>
      <div className={`${styles.topNoteBlock} ${setShowNoteContent && styles.borderBottom}`}>
        <span>
          <Icon name='file alternate outline' className={styles.icon1} />
          {translate('Note of session') + ' ' + session.current_index}
        </span>
        <Icon
          name={`sort ${showNoteContent ? 'up' : 'down'}`}
          className={styles.icon2}
          onClick={() => setShowNoteContent(!showNoteContent)}
        />
      </div>
      <Transition.Group animation='slide down' duration={450}>
        {showNoteContent && (
          <div className={`${styles.bottomNoteBlock}`}>
            <p className={styles.noteText}>{session.note}</p>
          </div>
        )}
      </Transition.Group>
    </Segment>
  );
};

export default memo(NoteReviewComp);
