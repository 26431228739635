import React from 'react';
import { Icon, Segment } from 'semantic-ui-react';
import styles from './coachMediaResourcesPage.module.scss';
import { SemanticICONS, MediaResourcesFormats } from '../../models/enum';
import { palette } from '../../theme/palette';
import { IMediaResource } from '../../models/type';
import { domainName } from '../../config/helperConstant';

interface Props {
  mediaResourceItem: IMediaResource;
}

const ResourceItem: React.FC<Props> = (props: Props) => {
  let iconName = SemanticICONS['file pdf outline'];
  let prefixDomain = '';

  if (props.mediaResourceItem.format === MediaResourcesFormats.web_link) {
    iconName = SemanticICONS.linkify;
  } else if (props.mediaResourceItem.format === MediaResourcesFormats.video_link) {
    iconName = SemanticICONS.video;
  } else if (props.mediaResourceItem.format === MediaResourcesFormats.image) {
    iconName = SemanticICONS['image outline'];
    prefixDomain = domainName + '/';
  } else if (props.mediaResourceItem.format === MediaResourcesFormats.pdf) {
    prefixDomain = domainName + '/';
  }

  return (
    <a href={prefixDomain + props.mediaResourceItem.link} target='_blank' rel="noreferrer">
      <Segment className={`${styles.customSegment} ${styles.resourceItem}`}>
        <div className={styles.mediaItemContent}>
          <Icon name={iconName} className={styles.resourceIcon} style={{ color: palette.secondary_blue2 }} />
          <p className={styles.resourceTitle}>{props.mediaResourceItem.title}</p>
        </div>
      </Segment>
    </a>
  );
};

export default ResourceItem;
