import { token, token_user } from '../config/tokenConstant';

export const getMilisecondExpiresDate = (minute: number): number => {
  const minuteToMilisecond: number = 60 * 1000;
  return Date.now() + minute * minuteToMilisecond;
};

export const deleteAllLocalToken = () => {
  localStorage.removeItem(token);
  localStorage.removeItem(token_user);
};
