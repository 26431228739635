import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'urql';
import EditableProgramManagerComp from '../../components/EditableProgramManagerComp/EditableProgramManagerComp';
import PrimaryLayout from '../../layouts/PrimaryLayout/PrimaryLayout';
import { MaterialIconName, Role_ID, UserRoles } from '../../models/enum';
import { PROGRAMMANAGER_info } from '../../services/apis/programMangerApis';
import { getUserRoleID } from '../../utilities/helper';
import styles from './programMangerProfile.module.scss';

const ProgramMangerProfilePage: React.FC = (props: any) => {
  /*METHODS*/
  const { t: translate } = useTranslation();
  const navigate = useNavigate();
  const gotToProfile = () => navigate(`/${Role_ID.PROGRAM_MANAGER}/profile`);

  const [programManagerInfo] = useQuery({
    query: PROGRAMMANAGER_info,
    variables: {
      id: getUserRoleID(UserRoles.PROGRAM_MANAGER),
    },
  });

  /*MAIN RETURN*/
  return (
    <PrimaryLayout
      primaryTitle='Gérer les programmes'
      primaryTitleIcon={MaterialIconName.assignment}
      role_name='Gestionnaire de programme'
      toProfile={gotToProfile}
      hideUserIcon={true}
      hideMessageIcon={true}
      onClickPrimaryTitle={() => navigate(`/${Role_ID.PROGRAM_MANAGER}`)}>
      <div className={styles.wrapper}>
        <div className={styles.titleBlock}>
          <h1 className={styles.title}>{translate('My profile')}</h1>
        </div>
        {programManagerInfo.data && (
          <EditableProgramManagerComp user_info={programManagerInfo.data.detailProgramManager.user_infos} />
        )}
      </div>
    </PrimaryLayout>
  );
};

export default ProgramMangerProfilePage;
