import { createRoot } from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider as ReduxProvider } from 'react-redux';

import '../src/utilities/i18n';
import 'semantic-ui-css/semantic.min.css';
import { store } from './services/redux/rootReducer';
import UrqlProvider from './core/UrqlProvider';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <ReduxProvider store={store}>
    <UrqlProvider>
      <App />
    </UrqlProvider>
  </ReduxProvider>,
);

reportWebVitals();
