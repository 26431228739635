import moment from 'moment';

import { token_user } from '../config/tokenConstant';
import { SESSION_CONSTANT, SESSION_STATUS, UserRoles } from '../models/enum';
import {
  IChatUser,
  ICoacheeAnswerInput,
  IDiagnosisAnswer,
  ISemanticDropDownOptions,
  IUserRole,
  MediaResourceType,
  NavLinkProps,
  Nullable,
} from '../models/type';

export const sumTotal = (arr: Array<number>) => {
  return arr.reduce((result, item) => {
    return (result += item);
  }, 0);
};

export const getUserRoleID = (role_name: UserRoles) => {
  const userRolesArray: Array<IUserRole> = JSON.parse(localStorage.getItem(token_user)).user_roles;
  const filteredArray: Array<IUserRole> = userRolesArray.filter((item: IUserRole) => {
    return item.role_name === role_name;
  });
  return filteredArray[0].id;
};

/**
 *
 * @param minutes
 * @returns 1H30
 */
export const convertMinuteToHour = (minutes: number, format: string): string => {
  if (!minutes) return;
  const rhours = Math.floor(minutes / 60);
  const rminutes = minutes - rhours * 60;

  if (format === 'hh:mm') return `${rhours < 10 && '0'}${rhours}H${rminutes < 10 ? '0' + rminutes : rminutes}`;
  if (format === 'h:mm') return `${rhours}h${rminutes < 10 ? '0' + rminutes : rminutes}`;
  return `${rhours}h${rminutes < 10 ? '0' + rminutes : rminutes}`;
};

/**
 *
 * @param beforeEventAcception in mintues
 * @param startTime
 * @return
 */
export const checkOnTimeInMinute = (beforeEventAcception: number, startTime: string): boolean => {
  const convertTimeType = new Date(startTime);
  const now = new Date();
  const diff = Math.abs(now.valueOf() - convertTimeType.valueOf());
  const diffMinutes = Math.floor(diff / 60000);
  if (diffMinutes <= beforeEventAcception) return true;
  return false;
};

/**
 *
 * @param startTime
 * @param givenMinutes
 * @return has it passed by a given minute after event started
 */
export const checkExcessStartTimeByAGivenMinute = (startTime: string, givenMinutes: number): boolean => {
  const now = new Date();
  const convertedStartTime = new Date(startTime);
  const diffMiliSeconds = now.valueOf() - convertedStartTime.valueOf();
  if (diffMiliSeconds <= 0) return false;
  if (diffMiliSeconds > givenMinutes * SESSION_CONSTANT.one_minute_in_miliseconds) return true;
  return false;
};

/**
 *
 * @param startTime
 * @return : yes if 15' before session start or now < session started time
 */
export const checkCanJoinSession = (startTime: string, duration: number, sessionStatus: string): boolean => {
  if ([`${SESSION_STATUS.started_waiting}`, `${SESSION_STATUS.started}`].includes(sessionStatus)) return true;
  const now = new Date();
  const convertedStartTime = new Date(startTime);
  //IS 15 mins before session start:
  const diff = Math.abs(now.valueOf() - convertedStartTime.valueOf());
  const diffMinutes = Math.floor(diff / 60000);
  if (diffMinutes <= SESSION_CONSTANT.buffer_time) return true;
  //IS now > startTime and not over duration/2:
  if (now.valueOf() - convertedStartTime.valueOf() > 0 && diffMinutes < duration / 2) return true;
  return false;
};

export const checkAnswerIDHasInAnswerArray = (
  questionID: string,
  answerID: string,
  coacheeAnswersArray: Array<ICoacheeAnswerInput>,
) => {
  //Find index of that questionID:
  const indexOFQuestion = coacheeAnswersArray.findIndex((item: ICoacheeAnswerInput) => item.question_id === questionID);
  if (indexOFQuestion === -1) return false;
  const answerArray: Array<string> = coacheeAnswersArray[indexOFQuestion].answers;
  return answerArray.includes(answerID);
};

/**
 *
 * @param answersArray
 * @param min
 * @param max
 * @return is qualified min and max answers
 */
export const isAnswerBetweenMinMax = (answersArray: Array<any>, min: number, max: number): boolean => {
  const numberOfAnswer: number = answersArray.length;
  return min <= numberOfAnswer && numberOfAnswer <= max;
};

/**
 *
 * @param conversersArray
 * @param userID
 * @return sender
 */
export const findReceiver = (conversersArray: Array<IChatUser>, userID: string): IChatUser => {
  let receiver: IChatUser = null;
  conversersArray.forEach((converser: IChatUser) => {
    if (converser.id !== userID) receiver = converser;
  });
  return receiver;
};

/**
 *
 * @param rawOptions
 * @param translateFuction
 */
export const translateDropDownOption = (
  rawOptions: Array<ISemanticDropDownOptions>,
  translateFuction: (text: string) => string,
) => {
  return rawOptions.map((item: ISemanticDropDownOptions) => {
    return {
      key: item.key,
      text: translateFuction(item.text),
      value: item.value,
    };
  });
};

export const validURL = (str: string): boolean => {
  const pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i',
  ); // fragment locator
  return !!pattern.test(str);
};

export const calendlyScheduledEventURL = (eventID: string): string => {
  return `https://api.calendly.com/scheduled_events/${eventID}/invitees`;
};

export const countUserRoleWithoutStaff = (): number => {
  const userFromLocal = JSON.parse(localStorage.getItem(token_user));
  const hasStaffRole = userFromLocal.user_roles.some((item: IUserRole) => item.role_name === UserRoles.STAFF);
  const numberOfRole = hasStaffRole ? userFromLocal.user_roles.length - 1 : userFromLocal.user_roles.length;

  return numberOfRole;
};

export const calcHeight = (value: string) => {
  const numberOfLineBreaks = (value.match(/\n/g) || []).length;
  // min-height + lines x line-height + padding + border
  const newHeight = 20 + numberOfLineBreaks * 20 + 10 + 1;
  if (newHeight > 100) return 100;
  return newHeight;
};

/**
 *
 * @param rawOptions
 * @param translateFuction
 */
export const createMonthDropDownSelfDiagnostisOptions = (
  rawOptions: Array<IDiagnosisAnswer>,
  translateFuction: (text: string) => string,
  selectedYearByUserValue: string,
) => {
  const currentMonth = moment().format('M');
  const currentYear = moment().format('YYYY');
  return rawOptions.map((item: IDiagnosisAnswer) => {
    const monthInEnglish: string = moment(item.value, 'MM').format('MMMM');
    const monthInNumber: string = moment(item.value, 'MM').format('M');
    return {
      key: item.id,
      text: moment(item.value, 'MM').format('MM') + ' - ' + translateFuction(monthInEnglish),
      value: item.id,
      disabled: +selectedYearByUserValue === +currentYear && +monthInNumber > +currentMonth,
    };
  });
};

export const createDropDownMenu = (rawOptions: Array<IDiagnosisAnswer>, selectedMonthByUserValue: string) => {
  const currentMonth = moment().format('M');
  const currentYear = moment().format('YYYY');
  return rawOptions
    .map((item: IDiagnosisAnswer) => {
      const yearInNumber: string = moment(item.value).format('YYYY');
      return {
        key: item.id,
        text: item.text,
        value: item.id,
        disabled: +selectedMonthByUserValue > +currentMonth && +yearInNumber === +currentYear,
      };
    })
    .reverse();
};

export const capitalizeFirstLetter = (text: string) => {
  return text.charAt(0).toUpperCase() + text.slice(1);
};

export const formatFranceTel = (phoneNumber: string) => {
  const removedLeading = phoneNumber.substring(3);
  return `+33 ${removedLeading.substring(0, 1)} ${removedLeading.substring(1, 3)} ${removedLeading.substring(
    3,
    5,
  )} ${removedLeading.substring(5, 7)} ${removedLeading.substring(7, 9)}`;
};

/**
 * @param arg string
 * @returns json object or null
 */
export const parseJson = <T>(arg: string): Nullable<T> => {
  try {
    return JSON.parse(arg);
  } catch (error) {
    return null;
  }
};

/**
 * React router dom NavLink class
 * @param navLinkProps
 * @param defaultClass
 * @param activeClass
 * @returns
 */
export const navLinkClasses = (navLinkProps: NavLinkProps, defaultClass: string, activeClass: string): string => {
  if (navLinkProps.isActive) return `${defaultClass} ${activeClass}`;
  return defaultClass;
};

export const filterMediaResourceByTheme = (mediaResources: MediaResourceType, theme: string[]) => {
  const pdf = mediaResources.pdf.filter((i) => {
    return i.thematics.some((t) => theme.includes(t.keyword));
  });
  const image = mediaResources.image.filter((i) => {
    return i.thematics.some((t) => theme.includes(t.keyword));
  });
  const web_link = mediaResources.web_link.filter((i) => {
    return i.thematics.some((t) => theme.includes(t.keyword));
  });
  const video_link = mediaResources.video_link.filter((i) => {
    return i.thematics.some((t) => theme.includes(t.keyword));
  });

  return {
    pdf,
    web_link,
    video_link,
    image,
  };
};
