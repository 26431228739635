import React from 'react';
import { Icon, Segment } from 'semantic-ui-react';
import moment from 'moment';

import { SemanticICONS } from '../../models/enum';
import { IMessage } from '../../models/type';
import { token_user } from '../../config/tokenConstant';
import styles from './messageItem.module.scss';

interface Props {
  message: IMessage;
}

const MessageItem: React.FC<Props> = ({ message }: Props) => {
  const isMyMessage = message.sender === JSON.parse(localStorage.getItem(token_user)).id;

  const hasSeen = !!message.read_at;

  return (
    <div className={`${styles.messageItemRow} ${isMyMessage && styles.myMessagePosition}`}>
      <div>
        <Segment className={`${styles.messageItem} ${isMyMessage && styles.myMessage}`}>
          {message?.title && <div className={styles.messageTitle}>{message.title}</div>}
          {message.content && <div className={styles.messageContentArea}>{message.content}</div>}
          {isMyMessage && (
            <div className={styles.bottomIconBlock}>
              <Icon name={hasSeen ? SemanticICONS.eye : SemanticICONS.check} className={styles.messageIcon} />
            </div>
          )}
        </Segment>
        <p className={`${styles.sentTimeText} ${isMyMessage && styles.myMessageTimePosition} `}>
          {moment(message.sent_at).format('HH:mm')}
        </p>
      </div>
    </div>
  );
};

export default MessageItem;
