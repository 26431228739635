import { Navigate, RouteProps } from 'react-router-dom';
import { token, token_user } from '../../config/tokenConstant';

const PrivateRoute = ({ element }: RouteProps) => {
  if (!localStorage.getItem(token) || !localStorage.getItem(token_user)) return <Navigate to='/login' />;

  return <>{element}</>;
};

export default PrivateRoute;
