import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Menu } from 'semantic-ui-react';
import { ICourse, IObjective, IObjectiveEvaluation } from '../../models/type';
import ChartContainer from '../ChartContainer/ChartContainer';
import styles from './coacheeObjective.module.scss';
import { palette } from '../../theme/palette';

interface Props {
  objectives?: Array<IObjective>;
}

const CoacheeObjective: React.FC<Props> = (props: Props) => {
  /*STATE*/

  const { t: translate } = useTranslation();

  const [state, setState] = useState({
    menu: {
      activeName: translate('Objective') + ' ' + 1,
      activeIndex: 0,
    },
  });

  const myCourseSelector: ICourse = useSelector((state: any) => {
    return state.coacheeReducer.myCourseManagement?.myCourse;
  });

  let objectives: Array<IObjective> = myCourseSelector?.objectives;

  if (props.objectives) objectives = props.objectives;

  /*METHODS*/

  const renderMenuItem = () => {
    if (!objectives) return;

    return objectives.map((item, index) => {
      return (
        <Menu.Item
          key={item.id}
          name={translate('Objective') + ' ' + (index + 1)}
          active={state.menu.activeName === translate('Objective') + ' ' + (index + 1)}
          onClick={(e, { name }) =>
            setState({
              ...state,
              menu: { ...state.menu, activeName: name, activeIndex: index },
            })
          }
        />
      );
    });
  };

  const generateLineDataFromEvaluation = (evaluationArray: Array<IObjectiveEvaluation>) => {
    const labelsArray: Array<number> = [];
    const numberDataArray: Array<number> = [];
    evaluationArray.forEach((item) => {
      labelsArray.push(item.session_current_index);
      numberDataArray.push(item.grade);
    });
    return {
      labels: labelsArray,
      datasets: [
        {
          data: numberDataArray,
          fill: false,
          lineTension: 0, // straigh line
          borderColor: palette.secondary_blue2,
          // pointRadius: 0,
        },
      ],
    };
  };

  /*MAIN RETURN*/

  if (!objectives?.length) return <div></div>;

  return (
    <div className={styles.wrapper}>
      <div id='menu' className={styles.menuWrapper}>
        <Menu pointing secondary>
          {renderMenuItem()}
        </Menu>
      </div>

      <div className={styles.contentWrapper}>
        <div className={styles.topContentBlock}>
          <p className={styles.text1}>{objectives[state.menu.activeIndex].description}</p>
        </div>

        <div className={styles.midContentBlock}>
          <h6 className={styles.contentTitle}>{translate('Indicator')}</h6>
          <p className={styles.text1}>{objectives[state.menu.activeIndex].key_indicators}</p>
        </div>

        <div className={styles.bottomContentBlock}>
          <h6 className={styles.contentTitle}>{translate('Evaluate progress')}</h6>
          <div className={styles.chartWrapper}>
            {objectives[state.menu.activeIndex]?.evaluations ? (
              <ChartContainer
                chartTitle={''}
                chartTitleHighlight={false}
                chartType='line-chart'
                data={{
                  chartType: 'line-chart',
                  data: generateLineDataFromEvaluation(objectives[state.menu.activeIndex].evaluations),
                  xAxisLabel: translate('Session'),
                  yAxisLabel: translate('Note'),
                }}
              />
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoacheeObjective;
