import { Fragment, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Grid, Segment } from 'semantic-ui-react';
import { ReactComponent as Logo } from '../../assets/images/logo_svg.svg';
import { UserRoles, Role_ID } from '../../models/enum';
import styles from './intermediatePage.module.scss';
import { IUserRole } from '../../models/type';
import { checkRGPD } from '../../services/Auth/Authentication';
import { token_user } from '../../config/tokenConstant';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Coach } from '../../assets/images/coach.svg';
import { ReactComponent as Coachee } from '../../assets/images/coache.svg';
import { ReactComponent as ProgramManger } from '../../assets/images/program_manager.svg';
import { ReactComponent as ProjectDirector } from '../../assets/images/project_director.svg';

const IntermediatePage = () => {
  /*STATE*/
  const [user, setUser] = useState(null);

  /*METHODS*/
  const { t: translate } = useTranslation();
  const navigate = useNavigate();

  const renderRoleItem = useCallback(() => {
    return user.user_roles.map((item: IUserRole, index: number) => {
      const temp: any = {
        title: null,
        role_id: null,
      };
      if (item.role_name === UserRoles.PROJECT_DIRECTOR) {
        temp.title = translate('Project director');
        temp.role_id = Role_ID.PROJECT_DIRECTOR;
      } else if (item.role_name === UserRoles.PROGRAM_MANAGER) {
        temp.title = translate('Program manager');
        temp.role_id = Role_ID.PROGRAM_MANAGER;
      } else if (item.role_name === UserRoles.COACH) {
        temp.title = translate('Coach');
        temp.role_id = Role_ID.COACH;
      } else if (item.role_name === UserRoles.COACHEE) {
        temp.title = translate('Coachee');
        temp.role_id = Role_ID.COACHEE;
      } else {
        return <Fragment key={index}></Fragment>;
      }

      return (
        <Grid.Column key={index} mobile={16} tablet={16} computer={4}>
          <div className={styles.roleItem}>
            <Segment className={styles.customSegment} onClick={() => navigate(`/${temp.role_id}`)}>
              {item.role_name === UserRoles.PROJECT_DIRECTOR && <ProjectDirector className={styles.imageSvg} />}
              {item.role_name === UserRoles.PROGRAM_MANAGER && <ProgramManger className={styles.imageSvg} />}
              {item.role_name === UserRoles.COACH && <Coach className={styles.imageSvg} />}
              {item.role_name === UserRoles.COACHEE && <Coachee className={styles.imageSvg} />}
              <div className={styles.titleBlock}>
                <p className={styles.itemTitle}>{temp.title}</p>
              </div>
            </Segment>
          </div>
        </Grid.Column>
      );
    });
  }, [navigate, translate, user?.user_roles]);

  useEffect(() => {
    if (!checkRGPD()) {
      navigate('/RGDP');
      return;
    }

    const userFromLocal = JSON.parse(localStorage.getItem(token_user));

    const indexOfStaffRole = userFromLocal.user_roles.findIndex(
      (item: IUserRole) => item.role_name === UserRoles.STAFF,
    );

    if (indexOfStaffRole !== -1) {
      userFromLocal.user_roles.splice(indexOfStaffRole, 1);
    }

    const numberOfRoleWithoutStaff = userFromLocal.user_roles.length;

    if (numberOfRoleWithoutStaff === 1) {
      switch (userFromLocal.user_roles[0].role_name) {
        case UserRoles.PROJECT_DIRECTOR:
          navigate(`/${Role_ID.PROJECT_DIRECTOR}`);
          return;
        case UserRoles.PROGRAM_MANAGER:
          navigate(`/${Role_ID.PROGRAM_MANAGER}`);
          return;
        case UserRoles.COACH:
          navigate(`/${Role_ID.COACH}`);
          return;
        case UserRoles.COACHEE:
          navigate(`/${Role_ID.COACHEE}`);
          return;
      }
    }
    setUser(userFromLocal);
  }, [navigate]);

  return (
    <Container fluid className={styles.wrapper}>
      <Logo className='logo logoPosition_normal' />
      {user && (
        <div className={styles.contentWrapper}>
          <div className={styles.rolesBlock}>
            <div className={styles.headerBlock}>
              <p className={styles.textNormal}>{translate('Select a role')}</p>
            </div>
            <Grid>
              <Grid.Row stretched centered textAlign='center' verticalAlign='middle'>
                {renderRoleItem()}
              </Grid.Row>
            </Grid>
          </div>
        </div>
      )}
    </Container>
  );
};

export default IntermediatePage;
