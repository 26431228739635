import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import MessageItem from './MessageItem';
import { IMessage } from '../../models/type';
import { IMessageReducer } from '../../services/redux/Reducers/MessageReducer';
import styles from './messagingPage.module.scss';

const Messages = () => {
  const { t: translate } = useTranslation();
  const { messages }: IMessageReducer = useSelector((state: any) => state.messageReducer);

  const renderMessageUpWard = () => {
    let hasTimeDivider = false;
    return messages.map((message: IMessage) => {
      if (moment().diff(message.sent_at, 'days') && !hasTimeDivider) {
        hasTimeDivider = true;
        return (
          <div key={message.id}>
            <div className={styles.dividerBlock}>
              <div className={styles.textBox}>{translate('Today')}</div>
            </div>
            <MessageItem message={message} />
          </div>
        );
      }
      return <MessageItem message={message} key={message.id} />;
    });
  };

  return <>{messages && renderMessageUpWard()}</>;
};

export default Messages;
