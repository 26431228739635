import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Segment } from 'semantic-ui-react';
import { useQuery } from 'urql';
import ChartContainer from '../../components/ChartContainer/ChartContainer';
import DashboardStatisticalComp from '../../components/DashboardStatisticalComp/DashboardStatisticalComp';
import { IProgramsSummary } from '../../models/type';
import { PROJECTDIRECTOR_dashboard } from '../../services/apis/projectDirectorApis';
import { generate_simple_staked_bar_data } from '../../utilities/ChartHelper';
import styles from './projectDirectorDashboard.module.scss';
import YearlySummary from './YearlySummary';

const ProjectDirectorDashboard: React.FC = (props) => {
  /*METHODS*/

  const { t: translate } = useTranslation();

  const [projectDirectorDashboard] = useQuery({
    query: PROJECTDIRECTOR_dashboard,
  });

  const renderYearlySummary = (programArray: Array<IProgramsSummary>) => {
    if (!programArray || !programArray.length) return <div></div>;
    return programArray.map((item: IProgramsSummary) => {
      return <YearlySummary programSummary={item} />;
    });
  };

  if (!projectDirectorDashboard.data) return <></>;

  return (
    <Grid>
      <Grid.Row divided={false}>
        <Grid.Column mobile={16} tablet={16} computer={16} widescreen={14} largeScreen={13}>
          <div>
            <p className={styles.title}>{translate('Ongoing program')}</p>
            <div>
              <Grid columns='equal'>
                <Grid.Row stretched className={styles.customedGridRow}>
                  <Grid.Column className={styles.customedGridColumn}>
                    <Segment className={styles.customedSegment}>
                      <DashboardStatisticalComp
                        label={translate('Program', {
                          count:
                            projectDirectorDashboard.data.getProjectDirectorDashboard.programs.in_progress
                              .number_of_programs,
                        })}
                        simpleStatistic={{
                          number:
                            projectDirectorDashboard.data.getProjectDirectorDashboard.programs.in_progress
                              .number_of_programs,
                        }}
                      />
                    </Segment>
                  </Grid.Column>
                  <Grid.Column className={styles.customedGridColumn}>
                    <Segment className={styles.customedSegment}>
                      <DashboardStatisticalComp
                        label={translate('Manager', {
                          count:
                            projectDirectorDashboard.data.getProjectDirectorDashboard.programs.in_progress
                              .number_of_program_managers,
                        })}
                        simpleStatistic={{
                          number:
                            projectDirectorDashboard.data.getProjectDirectorDashboard.programs.in_progress
                              .number_of_program_managers,
                        }}
                      />
                    </Segment>
                  </Grid.Column>
                  <Grid.Column className={styles.customedGridColumn}>
                    <Segment className={styles.customedSegment}>
                      <DashboardStatisticalComp
                        label={translate('Coachee', {
                          count:
                            projectDirectorDashboard.data.getProjectDirectorDashboard.programs.in_progress
                              .number_of_coachees,
                        })}
                        simpleStatistic={{
                          number:
                            projectDirectorDashboard.data.getProjectDirectorDashboard.programs.in_progress
                              .number_of_coachees,
                        }}
                      />
                    </Segment>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </div>
            <div className={styles.chartsBlock}>
              <Grid stretched>
                <Grid.Row>
                  <Grid.Column mobile='16' tablet='8' computer='8'>
                    <Segment className={`${styles.customedSegment} ${styles.firstChartItem}`}>
                      <ChartContainer
                        chartTitle={''}
                        chartTitleHighlight={false}
                        chartType='simple_horizontal_stack_bar'
                        data={generate_simple_staked_bar_data(
                          projectDirectorDashboard.data.getProjectDirectorDashboard.programs.in_progress.courses,
                          translate('PlannedCourse', { count: 2 }),
                        )}
                      />
                    </Segment>
                  </Grid.Column>
                  <Grid.Column mobile='16' tablet='8' computer='8'>
                    <Segment className={styles.customedSegment}>
                      <ChartContainer
                        chartTitle={''}
                        chartTitleHighlight={false}
                        chartType='simple_horizontal_stack_bar'
                        data={generate_simple_staked_bar_data(
                          projectDirectorDashboard.data.getProjectDirectorDashboard.programs.in_progress.sessions,
                          translate('PlannedSession', { count: 2 }),
                        )}
                      />
                    </Segment>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </div>
          </div>

          <div>
            <p className={styles.title}>{translate('Finished program')}</p>
            {renderYearlySummary(projectDirectorDashboard.data?.getProjectDirectorDashboard.programs.done)}
          </div>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default ProjectDirectorDashboard;
