import React from 'react';
import { IContactListItem } from '../../models/type';
import styles from './contactList.module.scss';

interface Props {
  contactList: IContactListItem[];
  selectingConversationID?: string;
  handleSelectConversation: (contact: IContactListItem) => void;
}

const ContactList = ({ contactList, handleSelectConversation, selectingConversationID }: Props) => {
  const renderContactListItem = () => {
    return contactList.map((contact: IContactListItem) => {
      return (
        <li
          key={contact.receiverID}
          className={`${contact.conversationID === selectingConversationID && styles.contactActive} ${
            contact.hasUnreadMessage && styles.contactHasUnread
          }`}
          onClick={() => handleSelectConversation(contact)}>
          {contact.name}
        </li>
      );
    });
  };

  return (
    <div className={styles.contactsBlock}>
      <ul>{renderContactListItem()}</ul>
    </div>
  );
};

export default ContactList;
