import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { NavLink, Outlet } from 'react-router-dom';
import { Grid } from 'semantic-ui-react';
import ClockTimer from '../../components/ClockTimer/ClockTimer';
import CoacheeObjectiveModal from '../../components/CoacheeObjectiveModal/CoacheeObjectiveModal';
import { ISession } from '../../models/type';
import styles from './coachMainPage.module.scss';
import { navLinkClasses } from '../../utilities/helper';

const CoachMainPageContent: React.FC = () => {
  /*STATE*/

  const [state, setState] = useState({
    showCoacheeObjectivesModal: false,
  });

  const onGoingSessionSelector: ISession = useSelector((state: any) => state.coachReducer.myCourses.selectedSession);

  /*PROPS*/

  /*METHODS*/

  const { t: translate } = useTranslation();

  /*EFFECT*/

  return (
    <>
      <Grid className={`${styles.wrapper} ${styles.m0} `}>
        <Grid.Row className={`${styles.p0}`} divided={false}>
          <Grid.Column
            mobile={16}
            tablet={3}
            computer={3}
            widescreen={2}
            largeScreen={2}
            className={` ${styles.leftColumn}`}>
            <nav className={styles.leftBlock}>
              <NavLink
                to='coming-sessions'
                className={(navLinkProps) => navLinkClasses(navLinkProps, styles.navLink, styles.activeLink)}>
                {translate('ComingSession', { count: 2 })}
              </NavLink>
              <NavLink
                to='finished-sessions'
                className={(navLinkProps) => navLinkClasses(navLinkProps, styles.navLink, styles.activeLink)}>
                {translate('Session doned')}
              </NavLink>
              <NavLink
                to='course-supervision'
                className={(navLinkProps) => navLinkClasses(navLinkProps, styles.navLink, styles.activeLink)}>
                {translate('Course supervision')}
              </NavLink>
              <NavLink
                to='media-resources'
                className={(navLinkProps) => navLinkClasses(navLinkProps, styles.navLink, styles.activeLink)}>
                {translate('Media resources')}
              </NavLink>
              {!!onGoingSessionSelector?.course_context.objectives.length && (
                <div
                  className={`${styles.navLink} ${styles.myNavLink}`}
                  onClick={() => {
                    setState({ ...state, showCoacheeObjectivesModal: true });
                  }}>
                  <span>{translate('Objective', { count: 2 })}</span>
                </div>
              )}
              {onGoingSessionSelector && <ClockTimer usingByCoach={true} />}
            </nav>
          </Grid.Column>
          <Grid.Column
            mobile={16}
            tablet={13}
            computer={13}
            widescreen={14}
            largeScreen={14}
            className={styles.rightColumnWrapper}>
            <Outlet />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      {state.showCoacheeObjectivesModal && (
        <CoacheeObjectiveModal
          cancel={() => {
            setState({ ...state, showCoacheeObjectivesModal: false });
          }}
        />
      )}
    </>
  );
};

export default CoachMainPageContent;
