import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Grid } from 'semantic-ui-react';
import { useQuery } from 'urql';
import EditableCoachDetailComp from '../../components/EditableCoachDetailComp/EditableCoachDetailComp';
import PrimaryLayout from '../../layouts/PrimaryLayout/PrimaryLayout';
import { MaterialIconName, Role_ID, STATUS_CODE, UserRoles } from '../../models/enum';
import { COACH_detail } from '../../services/apis/coachApis';
import { getUserRoleID } from '../../utilities/helper';
import styles from './coachProfilePage.module.scss';

const CoachProfilePage: React.FC = () => {
  /*PROPS*/

  const [coachDetail, executeFetchCoachDetail] = useQuery({
    query: COACH_detail,
    variables: {
      coach_id: getUserRoleID(UserRoles.COACH),
    },
    requestPolicy: 'cache-and-network',
  });
  /*METHODS*/
  const { t: translate } = useTranslation();

  const navigate = useNavigate();

  const gotToProfile = () => navigate(`/${Role_ID.COACH}/profile`);

  return (
    <PrimaryLayout
      primaryTitle={'Mes parcours'}
      primaryTitleIcon={MaterialIconName.assignment}
      role_name={translate('Coach')}
      toProfile={gotToProfile}
      hideUserIcon={true}
      goToMessage={() => navigate(`/${Role_ID.COACH}/message`)}
      onClickPrimaryTitle={() => navigate(`/${Role_ID.COACH}`)}>
      <Grid className={styles.gridWrapper}>
        <Grid.Row>
          <Grid.Column mobile='16' tablet='16' computer='16' widescreen='14' largeScreen='13'>
            <div className={styles.wrapper}>
              <div className={styles.titleBlock}>
                <h1 className={styles.title}>{translate('My profile')}</h1>
              </div>
              {+coachDetail.data?.detailCoach?.status_code === +STATUS_CODE.SUCCESS && (
                <EditableCoachDetailComp
                  editMode={true}
                  coachDetail={coachDetail.data.detailCoach.coach}
                  fetchCoachDetail={executeFetchCoachDetail}
                />
              )}
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </PrimaryLayout>
  );
};

export default CoachProfilePage;
