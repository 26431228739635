import { CoachReducerState } from '../services/redux/Reducers/CoachReducer';
import moment from 'moment';
import { ISession } from '../models/type';

export const switchSessionType = (
  state: CoachReducerState,
  sessionId: string,
  newIsOfflineValue: boolean,
  date: string,
): CoachReducerState => {
  const newState = { ...state };
  const newMyCourse = { ...newState.myCourses };
  const newComingSessions: any = { ...newMyCourse.comingSessions };

  const monthYear = moment(date).format('MM/YYYY');
  const dateMonthYear = moment(date).format('DD/MM/YYYY');

  const newMonthYearObj = { ...newComingSessions[monthYear] };
  const arrayContentObjToUpdate: ISession[] = [...newMonthYearObj[dateMonthYear]];

  const index = arrayContentObjToUpdate.findIndex((session: ISession) => session.id === sessionId);

  const newSession: ISession = { ...arrayContentObjToUpdate[index], is_offline: newIsOfflineValue };
  arrayContentObjToUpdate[index] = newSession;
  newMonthYearObj[dateMonthYear] = arrayContentObjToUpdate;
  newComingSessions[monthYear] = newMonthYearObj;
  newMyCourse.comingSessions = newComingSessions;
  newState.myCourses = newMyCourse;

  return newState;
};
