import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import resources from '../assets/locales/locales';
import HttpApi from 'i18next-http-backend';

i18n
  .use(HttpApi)
  .use(initReactI18next)
  .init({
    resources,
    lng: 'fr',
    fallbackLng: 'fr',
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
