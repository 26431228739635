import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Icon } from 'semantic-ui-react';
import styles from './button.module.scss';

interface Props {
  disabled: boolean;
  handleClick: () => void;
}

const AssignButton: React.FC<Props> = (props: Props) => {
  const { t: translate } = useTranslation();

  return (
    <Button className={`${styles.btn} ${styles.btnAssign} `} disabled={props.disabled} onClick={props.handleClick}>
      <Icon name='graduation' />
      <span>{translate('Assign course', { count: 2 })}</span>
    </Button>
  );
};

export default AssignButton;
