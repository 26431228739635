import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Button, Icon } from 'semantic-ui-react';
import { useMutation } from 'urql';
import { PROGRAMMANGER_ACTIONS, STATUS_CODE } from '../../models/enum';
import { PROGRAMMANAGER_assign_follow_up_course } from '../../services/apis/programMangerApis';
import AssignMultiFollowUpCourseContent from './AssignMultiFollowUpCourseContent';
import styles from './listCoacheeModalOptions.module.scss';

interface Props {
  onClose: () => void;
  handleRefetchCoacheeList: () => void;
  refetchProgramDetail: () => void;
}

const ModalOptionFollowUp: React.FC<Props> = (props: Props) => {
  const [state, setState] = useState({
    showSuccess: false,
    selectedCourses: [],
  });

  /* METHODS */
  const { t: translate } = useTranslation();
  const dispatch = useDispatch();

  const [res, assignFollouUp] = useMutation(PROGRAMMANAGER_assign_follow_up_course);

  const handleOnChangeCheckbox = (value: any) => {
    const tempSelectedCourse = [...state.selectedCourses];
    const indexFound = tempSelectedCourse.findIndex((item: string) => item === value);
    if (indexFound === -1) {
      tempSelectedCourse.push(value);
    } else {
      tempSelectedCourse.splice(indexFound, 1);
    }
    setState({ ...state, selectedCourses: tempSelectedCourse });
  };

  const handleSubmit = () => {
    assignFollouUp({ courses_ids: state.selectedCourses })
      .then((res) => {
        if (+res.data?.assignFollowUpCourse.status_code === +STATUS_CODE.SUCCESS) {
          props.handleRefetchCoacheeList();
          setState({ ...state, showSuccess: true });
          props.refetchProgramDetail();
          dispatch({ type: PROGRAMMANGER_ACTIONS.clear_selected_coachee });
        } else console.error(res.error);
      })
      .catch((err) => console.error(err));
  };

  if (state.showSuccess)
    return (
      <div>
        <div className={styles.successBlock}>
          <Icon name='check' size='huge' className={styles.iconCheck} />
          <span className={`${styles.successText} topLevelText`}>{translate('Congratulation')}</span>
          <p className={styles.successMessage}>{translate('Course assigned success')}</p>
        </div>
        <div className={styles.btnBlock}>
          <Button className={`${styles.agreeBtn} ${styles.styleInfo}`} onClick={props.onClose}>
            <span className='buttonText topLevelText'>{translate('Close')}</span>
          </Button>
        </div>
      </div>
    );

  return (
    <>
      <AssignMultiFollowUpCourseContent
        handleOnChangeCheckbox={(value) => handleOnChangeCheckbox(value)}
        selectedCourses={state.selectedCourses}
      />
      <div className={styles.btnBlock}>
        <Button className={styles.cancelBtn} onClick={props.onClose}>
          <span className='buttonText topLevelText'>{translate('Cancel')}</span>
        </Button>
        <Button
          className={`${styles.agreeBtn} ${state.selectedCourses.length && styles.styleInfo} `}
          disabled={!state.selectedCourses.length || res.fetching}
          onClick={handleSubmit}>
          <span className='buttonText topLevelText'>{translate('Assign')}</span>
        </Button>
      </div>
    </>
  );
};

export default ModalOptionFollowUp;
