import { switchSessionType } from '../../../functions/coachee-reducer';
import { COACHEE_ACTIONS, COURSE_ACTIONS, SESSION_ACTIONS, SESSION_STATUS } from '../../../models/enum';
import {
  ICoacheeAction,
  ICourse,
  IDiagnosisQuestion,
  IDiagnosisQuestionCollection,
  ISession,
  ICoacheeAnswerInput,
  IDiagnosisCoacheeAnswer,
  IDiagnosisAnswer,
} from '../../../models/type';
import { isAnswerBetweenMinMax } from '../../../utilities/helper';

export interface Store {
  selectedCourse: any;
  selfDiagnostic: {
    coacheeAnswerDiagnosisArr: Array<ICoacheeAnswerInput>;
    questionCollectionFromServer: Array<IDiagnosisQuestionCollection>;
    showSubmitBtn: boolean;
    diagnosisID: string;
    stepsQuestionArray: Array<IDiagnosisQuestion>;
    currentStepIndex: number;
    showFinishResult: boolean;
  };
  myCourseManagement: {
    myCourse: ICourse;
    myCurrentSession: ISession;
    myUpComingSession: Array<ISession>;
    myDoneSessions: Array<ISession>;
    canShowEndSessionButton: boolean;
  };
}

const initialState: Store = {
  selectedCourse: null,
  selfDiagnostic: {
    diagnosisID: '',
    questionCollectionFromServer: [],
    coacheeAnswerDiagnosisArr: [],
    showSubmitBtn: false,
    stepsQuestionArray: [],
    currentStepIndex: 0,
    showFinishResult: false,
  },
  myCourseManagement: {
    myCourse: null,
    myCurrentSession: null,
    myUpComingSession: [],
    myDoneSessions: [],
    canShowEndSessionButton: false,
  },
};

const createQuestionsArray = (
  questionsFromServer: Array<IDiagnosisQuestionCollection>,
  coacheeAnswers: Array<ICoacheeAnswerInput>,
): Array<IDiagnosisQuestion> => {
  let questionsStepArray: Array<IDiagnosisQuestion> = [];
  let allCoacheeAnswerID: Array<string> = [];

  coacheeAnswers.forEach((coacheeAnswer) => {
    allCoacheeAnswerID = [...allCoacheeAnswerID, ...coacheeAnswer.answers];
  });
  questionsFromServer.forEach((questionCollection) => {
    if (questionCollection.collection_type === 'public') {
      questionsStepArray = [...questionsStepArray, ...questionCollection.questions];
    }
    if (questionCollection.collection_type === 'logic') {
      const from_answers: Array<string> = questionCollection.from_answers;
      const yesHasAnswerID: boolean = from_answers.some((id) => allCoacheeAnswerID.includes(id));
      if (yesHasAnswerID) {
        questionsStepArray = [...questionsStepArray, ...questionCollection.questions];
      }
    }
  });
  return questionsStepArray;
};

export const coacheeReducer = (state = initialState, action: ICoacheeAction) => {
  switch (action.type) {
    case COACHEE_ACTIONS.select_course:
      return { ...state, selectedCourse: action.payload };
    case COACHEE_ACTIONS.update_course_status:
      return {
        ...state,
        selectedCourse: { ...state.selectedCourse, status: action.payload },
      };

    /*NEW SELF DIAGNOSTIC*/
    case COACHEE_ACTIONS.init_self_diagnosis:
      const sortedQuestionCollection: Array<IDiagnosisQuestionCollection> =
        action.payload.self_diagnosis_template.question_collections.sort(
          (a: IDiagnosisQuestionCollection, b: IDiagnosisQuestionCollection) => a.position - b.position,
        );
      let tempSteps: Array<IDiagnosisQuestion> = [];
      let tempCoacheeAnswers: Array<any> = [];
      let tempCurrentStepIndex: number = 0;
      let shouldDisplaySubmitBtn: boolean = false;

      // CASE : no coachee answer
      if (!action.payload.coachee_answers.length) {
        tempSteps = createQuestionsArray(sortedQuestionCollection, tempCoacheeAnswers);
      } else {
        tempCoacheeAnswers = action.payload.coachee_answers.map((item: IDiagnosisCoacheeAnswer) => {
          const tempAnswers = [...item.answers];
          tempAnswers.sort((a, b) => {
            return +a.value - +b.value;
          });
          return {
            question_id: item.question.id,
            answers: tempAnswers.map((answer: IDiagnosisAnswer) => {
              return answer.id;
            }),
          };
        });
        tempSteps = createQuestionsArray(sortedQuestionCollection, tempCoacheeAnswers);
        tempCurrentStepIndex = tempCoacheeAnswers.length - 1;
        shouldDisplaySubmitBtn = true;
      }

      return {
        ...state,
        selfDiagnostic: {
          ...state.selfDiagnostic,
          diagnosisID: action.payload.id,
          questionCollectionFromServer: sortedQuestionCollection,
          stepsQuestionArray: tempSteps,
          coacheeAnswerDiagnosisArr: tempCoacheeAnswers,
          currentStepIndex: tempCurrentStepIndex,
          showSubmitBtn: shouldDisplaySubmitBtn,
        },
      };
    case COACHEE_ACTIONS.choose_self_diagnosis_answer:
      const { answerID, max_answers, min_answers, questionID, question_type } = action.payload;
      let tempStoreAnswer: Array<ICoacheeAnswerInput> = [...state.selfDiagnostic.coacheeAnswerDiagnosisArr];
      let tempCopiedSteps: Array<IDiagnosisQuestion> = [];
      let canShowSubmitButton: boolean = false;

      //CHECK IF that is question 1: need to find and update steps

      //loop through collectionFromServer
      // IF collection_type === "logic"
      // if from_answers.includes(answerID);
      // yes includes : step.concat(collection.questions)
      // no includes : check next collection
      //IF collection_type === "public" -> steps.concat(collection.questions)
      const isTheFirstQuestion: boolean =
        questionID === state.selfDiagnostic.questionCollectionFromServer[0].questions[0].id;

      //Find index of that question in tempStoreAnswer
      const indexFound = tempStoreAnswer.findIndex((item: ICoacheeAnswerInput) => {
        return item.question_id === questionID;
      });

      /**CAUTIONS: WHERE TO CHECK FIRST ANSWER CHANGED */
      //IF indexFound AND isTheFirstQuestion true
      // This where to check if new answerID === old answerID is in tempStoreAnswer
      // IF YES ==> do nothing
      // IF NO  ==> tempStoreAnswer just keep answers for questions in collection[0]
      // const questionsIDOfCollectionZero = [id,id,id];
      // tempStoreAnswer =  coacheeAnswerDiagnosisArr.filter answer has questionID included in questionsIDOfCollectionZero;
      if (indexFound !== -1 && isTheFirstQuestion) {
        if (answerID !== tempStoreAnswer[0].answers[0]) {
          //WHEN 1st FIRST ANSWER differ from CURRENT ANSWER
          const questionsOfCollectionZero = state.selfDiagnostic.questionCollectionFromServer[0].questions.map(
            (item: IDiagnosisQuestion) => {
              return item.id;
            },
          );
          tempStoreAnswer = state.selfDiagnostic.coacheeAnswerDiagnosisArr.filter((item: ICoacheeAnswerInput) => {
            return questionsOfCollectionZero.includes(item.question_id);
          });
        }
        //WHEN 1st FIRST ANSWER IS SAME AS CURRENT ANSWER => DO NOTHING
      }

      //if indexFound === -1 , push answer to tempStoreAnswer
      if (indexFound === -1) {
        if (question_type === 'date') {
          tempStoreAnswer.push({
            question_id: questionID,
            answers: answerID,
          });
        } else {
          tempStoreAnswer.push({
            question_id: questionID,
            answers: [answerID],
          });
        }

        canShowSubmitButton = isAnswerBetweenMinMax(
          tempStoreAnswer[tempStoreAnswer.length - 1].answers,
          min_answers,
          max_answers,
        );
      } else {
        //if indexFound !== -1 , question has been answer
        // if questionType === "radio" = > replace answer of that question with new answer
        if (question_type === 'radio') {
          tempStoreAnswer[indexFound].answers = [answerID];
          canShowSubmitButton = true;
        }
        // if questionType === "checkbox":
        // Count how many answers
        // findIndex of answerID in answers Array
        // if found index => remove answer ID
        // not found index AND  if Count < max answer: ADD answerID
        else if (question_type === 'checkbox') {
          const countAnswered: number = tempStoreAnswer[indexFound].answers.length;

          const indexOfAnswerID: number = tempStoreAnswer[indexFound].answers.findIndex((item: string) => {
            return item === answerID;
          });

          if (indexOfAnswerID !== -1) {
            tempStoreAnswer[indexFound].answers.splice(indexOfAnswerID, 1);
          } else if (indexOfAnswerID === -1 && countAnswered < max_answers) {
            tempStoreAnswer[indexFound].answers = [...tempStoreAnswer[indexFound].answers, answerID];
          }

          //CHECK min_answers <= answer number <= max_answers
          canShowSubmitButton = isAnswerBetweenMinMax(tempStoreAnswer[indexFound].answers, min_answers, max_answers);
        } else if (question_type === 'date') {
          tempStoreAnswer[indexFound].answers = [...answerID];
          canShowSubmitButton = isAnswerBetweenMinMax(tempStoreAnswer[indexFound].answers, min_answers, max_answers);
        }
      }

      tempCopiedSteps = createQuestionsArray(state.selfDiagnostic.questionCollectionFromServer, tempStoreAnswer);

      return {
        ...state,
        selfDiagnostic: {
          ...state.selfDiagnostic,
          showSubmitBtn: canShowSubmitButton,
          coacheeAnswerDiagnosisArr: tempStoreAnswer,
          stepsQuestionArray: tempCopiedSteps,
        },
      };
    case COACHEE_ACTIONS.delete_all_answer:
      return {
        ...state,
        selfDiagnostic: {
          ...state.selfDiagnostic,
          coacheeAnswerDiagnosisArr: [],
        },
      };
    case COACHEE_ACTIONS.answer_diagnosis:
      //if next question has in coachee answer and its answers length > 0 => show submitbutton
      const nextIndex = ++state.selfDiagnostic.currentStepIndex;
      let shouldShowSubmitBtn = false;
      const findQuestionIndex = state.selfDiagnostic.coacheeAnswerDiagnosisArr.findIndex(
        (item: ICoacheeAnswerInput) => {
          return item.question_id === state.selfDiagnostic.stepsQuestionArray[nextIndex].id;
        },
      );
      if (
        findQuestionIndex !== -1 &&
        state.selfDiagnostic.coacheeAnswerDiagnosisArr[findQuestionIndex].answers.length
      ) {
        shouldShowSubmitBtn = true;
      }

      return {
        ...state,
        selfDiagnostic: {
          ...state.selfDiagnostic,
          currentStepIndex: nextIndex,
          showSubmitBtn: shouldShowSubmitBtn,
          coacheeAnswerDiagnosisArr: action.payload.filteredCoacheeAnswerArrays,
        },
      };
    case COACHEE_ACTIONS.back_to_previous_question:
      if (!state.selfDiagnostic.currentStepIndex) return state;
      return {
        ...state,
        selfDiagnostic: {
          ...state.selfDiagnostic,
          currentStepIndex: --state.selfDiagnostic.currentStepIndex,
          showSubmitBtn: true,
        },
      };
    case COACHEE_ACTIONS.finish_self_diagnostic:
      return {
        ...state,
        selfDiagnostic: {
          ...state.selfDiagnostic,
          showFinishResult: true,
          currentStepIndex: state.selfDiagnostic.currentStepIndex + 1,
        },
      };
    case COACHEE_ACTIONS.clear_self_diagnostic:
      return {
        ...state,
        selfDiagnostic: {
          ...state.selfDiagnostic,
          diagnosisID: '',
          questionCollectionFromServer: [],
          coacheeAnswerDiagnosisArr: [],
          showSubmitBtn: false,
          stepsQuestionArray: [],
          currentStepIndex: 0,
          showFinishResult: false,
        },
      };
    case COURSE_ACTIONS.update_objectives:
      return {
        ...state,
        myCourseManagement: {
          ...state.myCourseManagement,
          myCourse: {
            ...state.myCourseManagement.myCourse,
            objectives: action.payload.saveObjectiveResponse,
          },
        },
      };
    case SESSION_ACTIONS.set_sessions:
      let tempCurrentSession = null;
      //Upcoming sessions are sessions with status unplanned | planned | started_wating | started

      const tempUpComingSession = action.payload.sessions.filter((item: ISession) => {
        return [
          `${SESSION_STATUS.unplanned}`,
          `${SESSION_STATUS.planned}`,
          `${SESSION_STATUS.started_waiting}`,
          `${SESSION_STATUS.started}`,
        ].includes(item.status);
      });

      if (tempUpComingSession.length) {
        //Sort Upcoming sessions by planned_at time:
        tempUpComingSession.sort((a: ISession, b: ISession) => {
          if (!a.planned_at && !b.planned_at) return 0;
          else if (!a.planned_at && b.planned_at) return 1;
          else if (a.planned_at && !b.planned_at) return -1;
          else {
            return new Date(a.planned_at).valueOf() - new Date(b.planned_at).valueOf();
          }
        });
        //The first item with nearest planned at time is current session:
        tempCurrentSession = tempUpComingSession[0];
        //splice that first item will have array of UpcomingSessions
        tempUpComingSession.splice(0, 1);
      }
      return {
        ...state,
        myCourseManagement: {
          ...state.myCourseManagement,
          myCourse: action.payload,
          myCurrentSession: tempCurrentSession,
          myUpComingSession: tempUpComingSession,
        },
      };
    case SESSION_ACTIONS.coachee_set_done_sessions:
      const doneSessions: Array<ISession> = action.payload;
      doneSessions.sort((a: ISession, b: ISession) => a.current_index - b.current_index);
      return {
        ...state,
        myCourseManagement: {
          ...state.myCourseManagement,
          myDoneSessions: doneSessions,
        },
      };

    case SESSION_ACTIONS.coachee_add_new_done_session:
      const newSessionDone: ISession = {
        ...action.payload.newSessionDone,
        status: SESSION_STATUS.done,
      };
      const tempDoneSessionsArray = [...state.myCourseManagement.myDoneSessions, newSessionDone];
      tempDoneSessionsArray.sort((a: ISession, b: ISession) => a.current_index - b.current_index);
      return {
        ...state,
        myCourseManagement: {
          ...state.myCourseManagement,
          myDoneSessions: tempDoneSessionsArray,
        },
      };

    case SESSION_ACTIONS.update_status:
      return {
        ...state,
        myCourseManagement: {
          ...state.myCourseManagement,
          myCurrentSession: {
            ...state.myCourseManagement.myCurrentSession,
            status: action.payload.status,
            planned_at: action.payload.planned_at
              ? action.payload.planned_at
              : state.myCourseManagement.myCurrentSession.planned_at,
            attendees: action.payload.attendees,
          },
        },
      };
    case SESSION_ACTIONS.save_planned_session:
      return {
        ...state,
        myCourseManagement: {
          ...state.myCourseManagement,
          myCurrentSession: {
            ...state.myCourseManagement.myCurrentSession,
            status: action.payload.session.status,
            planned_at: action.payload.session.planned_at,
            calendly_event_uri: action.payload.calendlyEventID,
          },
        },
      };
    case SESSION_ACTIONS.update_show_end_button:
      return {
        ...state,
        myCourseManagement: {
          ...state.myCourseManagement,
          canShowEndSessionButton: action.payload,
        },
      };
    case COACHEE_ACTIONS.update_video_conference_link:
      const { sessionID, videoConfLink } = action.payload;
      if (state.myCourseManagement.myCurrentSession.id === sessionID) {
        return {
          ...state,
          myCourseManagement: {
            ...state.myCourseManagement,
            myCurrentSession: {
              ...state.myCourseManagement.myCurrentSession,
              videoconference_link: videoConfLink,
            },
          },
        };
      }
      const foundSessionIndex: number = state.myCourseManagement.myUpComingSession.findIndex(
        (comingSession: ISession) => {
          return comingSession.id === sessionID;
        },
      );
      if (foundSessionIndex !== -1) {
        const tempSession = {
          ...state.myCourseManagement.myUpComingSession[foundSessionIndex],
          videoconference_link: videoConfLink,
        };
        const clonedupComingSession = [...state.myCourseManagement.myUpComingSession];
        clonedupComingSession[foundSessionIndex] = tempSession;
        return {
          ...state,
          myCourseManagement: {
            ...state.myCourseManagement,
            myUpComingSession: clonedupComingSession,
          },
        };
      }
      return { ...state };
    case SESSION_ACTIONS.write_note:
      return {
        ...state,
        myCourseManagement: {
          ...state.myCourseManagement,
          myCurrentSession: {
            ...state.myCourseManagement.myCurrentSession,
            note: action.payload,
          },
        },
      };

    case COACHEE_ACTIONS.switch_session_type:
      const { id, newIsOfflineValue } = action.payload;
      return switchSessionType(state, id, newIsOfflineValue);
    default:
      return state;
  }
};
