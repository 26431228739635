import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { IDiagnosisQuestionCollection } from '../../models/type';
import styles from './coacheSelfDiagnosticPage.module.scss';

const SelfDiagnostisCollectionTitle: React.FC = () => {
  /*METHODS*/
  const { t: translate } = useTranslation();

  const selfDiagnostisSelectore = useSelector((state: any) => state.coacheeReducer.selfDiagnostic);

  const chooseTitle = (): string => {
    if (
      !selfDiagnostisSelectore.questionCollectionFromServer.length ||
      !selfDiagnostisSelectore.stepsQuestionArray.length
    )
      return;

    if (selfDiagnostisSelectore.currentStepIndex >= selfDiagnostisSelectore.stepsQuestionArray.length - 1) {
      return translate('Scoring disgnosis');
    }

    let title: string;
    const currentQuestionID = selfDiagnostisSelectore.stepsQuestionArray[selfDiagnostisSelectore.currentStepIndex].id;

    selfDiagnostisSelectore.questionCollectionFromServer.forEach((item: IDiagnosisQuestionCollection) => {
      const hasCurrentQuestionID: boolean = item.questions.some((question) => {
        return question.id === currentQuestionID;
      });
      if (hasCurrentQuestionID) title = item.title;
    });
    return title;
  };

  return <h3 className={`${styles.categoryTitle} topLevelText`}>{chooseTitle()}</h3>;
};

export default React.memo(SelfDiagnostisCollectionTitle);
