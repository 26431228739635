import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Grid, Icon } from 'semantic-ui-react';
import { useMutation } from 'urql';
import { COURSE_ACTIONS, STATUS_CODE } from '../../models/enum';
import { IObjective, IObjectiveEvaluationInput, IObjectiveInput, ISession } from '../../models/type';
import { COURSE_reevaluate_objectives, COURSE_save_objectives } from '../../services/apis/courseApis';
import EvaluationBar from '../EvaluationBar/EvaluationBar';
import styles from './coacheeSetObjectiveComp.module.scss';

interface Props {
  closeSetObjective: () => void;
  needToSetObjective: boolean;
}

const CoacheeSetObjectiveComp: React.FC<Props> = (props: Props) => {
  /*PROPS*/

  const currentSession: ISession = useSelector(
    (state: any) => state.coacheeReducer.myCourseManagement.myCurrentSession,
  );

  const objectivesOnStore = useSelector((state: any) => state.coacheeReducer.myCourseManagement.myCourse.objectives);

  const [state, setState] = useState({
    objectivesArray: [{ description: '', keyIndicators: '', grade: 0 }],
    reEvaluationArray: [],
    indexingContent: 0,
    edited: false,
  });

  /*METHODS*/

  const { t: translate } = useTranslation();

  const dispatch = useDispatch();

  const [, executeSaveObjectives] = useMutation(COURSE_save_objectives);

  const [, executeReevaluate] = useMutation(COURSE_reevaluate_objectives);

  const renderObjectiveButton = useCallback(
    (objectivesArray: Array<IObjectiveInput>) => {
      return objectivesArray.map((item: IObjectiveInput, index: number) => {
        return (
          <Button
            key={`objectiveButtonKey${index}`}
            className={`${styles.objectiveButton} ${state.indexingContent === index && styles.objectiveBtnActive}`}
            onClick={() => setState({ ...state, indexingContent: index })}>
            <span className='topLevelText'>
              {translate('Objective')} {index + 1}
            </span>
          </Button>
        );
      });
    },
    [state, translate],
  );

  const isAllObjectiveAndIndicatorFilled = (): boolean => {
    return state.objectivesArray.every((item) => {
      return !!item.keyIndicators && !!item.description;
    });
  };

  const handleAddMoreObjective = useCallback(() => {
    const tempObjectiveArray = [...state.objectivesArray];
    tempObjectiveArray.push({ description: '', keyIndicators: '', grade: 0 });
    setState({ ...state, objectivesArray: tempObjectiveArray });
  }, [state]);

  const handleDeleteObjective = useCallback(() => {
    const tempObjectiveArray = [...state.objectivesArray];
    tempObjectiveArray.splice(state.indexingContent, 1);
    setState({
      ...state,
      objectivesArray: tempObjectiveArray,
      indexingContent: state.indexingContent - 1,
    });
  }, [state]);

  const handleOnChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      const tempObjectiveArr: Array<IObjectiveInput> = [...state.objectivesArray];
      const tempObjective: IObjectiveInput = {
        ...tempObjectiveArr[state.indexingContent],
        [e.currentTarget.name]: e.currentTarget.value,
      };
      tempObjectiveArr[state.indexingContent] = tempObjective;
      setState({ ...state, objectivesArray: tempObjectiveArr, edited: true });
    },
    [state],
  );

  const handleSetGrade = useCallback(
    (grade: number) => {
      const tempObjectiveArr: Array<IObjectiveInput> = [...state.objectivesArray];
      const tempObjective: IObjectiveInput = {
        ...tempObjectiveArr[state.indexingContent],
        grade: grade,
      };
      tempObjectiveArr[state.indexingContent] = tempObjective;
      setState({ ...state, objectivesArray: tempObjectiveArr });
    },
    [state],
  );

  const handleSetGradeReevaluate = useCallback(
    (grade: number) => {
      const tempReevaluateArr: Array<IObjectiveEvaluationInput> = [...state.reEvaluationArray];
      const tempReevaluateObject: IObjectiveEvaluationInput = {
        ...tempReevaluateArr[state.indexingContent],
        grade: grade,
      };
      tempReevaluateArr[state.indexingContent] = tempReevaluateObject;
      setState({
        ...state,
        reEvaluationArray: tempReevaluateArr,
        edited: true,
      });
    },
    [state],
  );

  const handleSaveObjective = (sessionID: string, objectivesArray: Array<IObjectiveInput>) => {
    executeSaveObjectives({
      session_id: sessionID,
      objectives: objectivesArray,
    })
      .then((res) => {
        if (+res.data.saveObjectives.status_code === +STATUS_CODE.SUCCESS) {
          props.closeSetObjective();
          dispatch({
            type: COURSE_ACTIONS.update_objectives,
            payload: {
              session: currentSession,
              saveObjectiveResponse: res.data.saveObjectives.objectives,
            },
          });
        } else {
          props.closeSetObjective();
          console.error(res.error);
        }
      })
      .catch((err) => console.error(err));
  };

  const handleReevaluate = (sessionID: string, reEvaluateArray: Array<IObjectiveEvaluationInput>) => {
    executeReevaluate({ session_id: sessionID, evaluations: reEvaluateArray })
      .then((res) => {
        if (+res.data.saveObjectivesProgress.status_code === +STATUS_CODE.SUCCESS) {
          props.closeSetObjective();
          dispatch({
            type: COURSE_ACTIONS.update_objectives,
            payload: {
              session: currentSession,
              saveObjectiveResponse: res.data.saveObjectivesProgress.objectives,
            },
          });
        } else {
          props.closeSetObjective();
          console.error(res.error);
        }
      })
      .catch((err) => console.error(err));
  };

  /*EFFECT*/

  //EFFECT map to reEvaluationArray:
  useEffect(() => {
    const mappedArray: Array<IObjectiveEvaluationInput> = objectivesOnStore.map((item: IObjective) => {
      return {
        objective_id: item.id,
        grade: item.evaluations[item.evaluations.length - 1].grade,
      };
    });
    setState({ ...state, reEvaluationArray: mappedArray });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [objectivesOnStore]);

  /*MAIN RETURN*/
  return (
    <div className={styles.modal}>
      <div className={styles.wrapper}>
        <div className={`${styles.header} topLevelText`}>
          {translate('Session') + ' ' + currentSession.current_index} -
          {' ' + translate(props.needToSetObjective ? 'Set objectives' : 'Evaluate my progress')}
          <Icon name='close' className={styles.closeIcon} onClick={props.closeSetObjective} />
        </div>
        {props.needToSetObjective ? (
          //BLOCK - set objective
          <div className={styles.bottomBlock}>
            <Grid>
              <Grid.Row>
                <Grid.Column mobile='16' tablet='16' computer='4'>
                  <div className={styles.leftBlock}>
                    {renderObjectiveButton(state.objectivesArray)}
                    {props.needToSetObjective && state.objectivesArray.length < 3 && (
                      <Button className={styles.objectiveButton} onClick={handleAddMoreObjective}>
                        <Icon name='plus' className={styles.plusIcon} />
                      </Button>
                    )}
                  </div>
                </Grid.Column>
                <Grid.Column mobile='16' tablet='16' computer='12'>
                  <div className={styles.rightBlock}>
                    <div className={styles.textAreaTitle}>
                      <span>
                        {translate('Objective')}
                        <sup className={styles.sup}>*</sup>
                      </span>
                      {!!state.indexingContent && <span onClick={handleDeleteObjective}>{translate('Remove')}</span>}
                    </div>
                    <textarea
                      name='description'
                      className={`${styles.textArea} ${styles.objectiveArea} ${
                        props.needToSetObjective && styles.editEffect
                      }`}
                      value={state.objectivesArray[state.indexingContent].description}
                      onChange={handleOnChange}
                      readOnly={!props.needToSetObjective}></textarea>
                    <div className={styles.textAreaTitle}>
                      <span>
                        {translate('Indicator')}
                        <sup className={styles.sup}>*</sup>
                      </span>
                    </div>
                    <textarea
                      name='keyIndicators'
                      className={`${styles.textArea} ${styles.indicatorArea} ${
                        props.needToSetObjective && styles.editEffect
                      }`}
                      value={state.objectivesArray[state.indexingContent].keyIndicators}
                      readOnly={!props.needToSetObjective}
                      onChange={handleOnChange}></textarea>
                    <div className={styles.textAreaTitle}>
                      <span>{translate('Evaluate my progress')}</span>
                    </div>
                    <EvaluationBar
                      setGrade={(grade: number) => {
                        handleSetGrade(grade);
                      }}
                      grade={state.objectivesArray[state.indexingContent].grade}
                    />
                  </div>
                  <div className={styles.buttonBlock}>
                    <p className={styles.textWarningSetObjective}>
                      {'(' + translate('Warning you cannot change objectives after set') + ')'}
                    </p>
                    <Button
                      className={`${styles.saveBtn} ${
                        isAllObjectiveAndIndicatorFilled() && state.edited && styles.activeSaveBtn
                      }`}
                      disabled={!isAllObjectiveAndIndicatorFilled() || !state.edited}
                      onClick={() => handleSaveObjective(currentSession.id, state.objectivesArray)}>
                      <span className={'buttonText topLevelText'}>{translate('Save objectives')}</span>
                    </Button>
                  </div>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </div>
        ) : (
          // BLOCK re-evaluate
          <div className={styles.bottomBlock}>
            <Grid>
              <Grid.Row>
                <Grid.Column mobile='16' tablet='16' computer='4'>
                  <div className={styles.leftBlock}>{renderObjectiveButton(objectivesOnStore)}</div>
                </Grid.Column>
                <Grid.Column mobile='16' tablet='16' computer='12'>
                  <div className={styles.rightBlock}>
                    <div className={styles.textAreaTitle}>
                      <span>{translate('Objective')}</span>
                    </div>
                    <textarea
                      name='description'
                      className={`${styles.textArea} ${styles.objectiveArea}`}
                      value={objectivesOnStore[state.indexingContent].description}
                      readOnly={true}></textarea>
                    <div className={styles.textAreaTitle}>
                      <span>{translate('Indicator')}</span>
                    </div>
                    <textarea
                      name='keyIndicators'
                      className={`${styles.textArea} ${styles.indicatorArea}`}
                      value={objectivesOnStore[state.indexingContent].key_indicators}
                      readOnly={true}></textarea>
                    <div className={styles.textAreaTitle}>
                      <span>{translate('Evaluate my progress')}</span>
                    </div>
                    <EvaluationBar
                      setGrade={(grade: number) => {
                        handleSetGradeReevaluate(grade);
                      }}
                      grade={state.reEvaluationArray[state.indexingContent]?.grade}
                    />
                  </div>
                  {!props.needToSetObjective && (
                    <div className={styles.buttonBlock}>
                      <p className={styles.textWarningSetObjective}>
                        {'(' + translate('Warning you cannot change objectives after set') + ')'}
                      </p>
                      <Button
                        className={`${styles.saveBtn} ${state.edited && styles.activeSaveBtn}`}
                        disabled={!state.edited}
                        onClick={() => handleReevaluate(currentSession.id, state.reEvaluationArray)}>
                        <span className={'buttonText topLevelText'}>{translate('Save')}</span>
                      </Button>
                    </div>
                  )}
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </div>
        )}
      </div>
    </div>
  );
};

export default React.memo(CoacheeSetObjectiveComp);
