import { Icon } from 'semantic-ui-react';

import { ReactComponent as Logo } from '../../assets/images/logo_svg.svg';
import ContactCustomerPhoto from '../../assets/images/contact_customer_transparent.png';
import styles from './customerSupportInfo.module.scss';
import { formatFranceTel } from '../../utilities/helper';

const CustomerSupportInfo = () => {
  return (
    <div className={`${styles.contactBlock}`}>
      <div className={styles.flag}>
        <div className={styles.container}>
          <img src={ContactCustomerPhoto} alt='Contact Customer' />
          <div>
            <p>Une question ?</p>
            <p>Besoin d’aide ?</p>
            <p>Contactez-nous par</p>
            <p>téléphone ou SMS</p>
          </div>

          <div className={styles.phoneNumberBlock}>
            <span>
              <Icon className={styles.phoneIcon} name='phone' />
            </span>
            <a className={styles.phoneNumber} href={`tel:${process.env.REACT_APP_CUSTOMER_SERVICES_PHONE_NUMBER}`}>
              {formatFranceTel(process.env.REACT_APP_CUSTOMER_SERVICES_PHONE_NUMBER)}
            </a>
          </div>

          <div>
            <p>Nous sommes à votre disposition</p>
            <p>
              <span>L'équipe</span>
              <Logo className={styles.logo} />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerSupportInfo;
