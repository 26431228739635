import React, { useCallback, useState } from 'react';
import PrimaryLayout from '../../layouts/PrimaryLayout/PrimaryLayout';
import styles from './coacheCourseSelectionPage.module.scss';
import { MaterialIconName, Role_ID, UserRoles } from '../../models/enum';
import { useQuery } from 'urql';
import { getUserRoleID } from '../../utilities/helper';
import { ICourse, IProgram_of_coachee } from '../../models/type';
import AwaitingComp from '../../components/Common/AwaitingComp/AwaitingComp';
import { COACHEE_fetch_info } from '../../services/apis/coacheeApis';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import CoacheeCourseCard from './CoacheeCourseCard';
import CoacheeEvaluateFinishedCourse from '../../components/CoacheeEvaluateFinishedCourse/CoacheeEvaluateFinishedCourse';

const CoacheCourseSelectionPage: React.FC = () => {
  /*STATE*/
  const [helperState, setHelperState] = useState({
    showFinalEvaluation: false,
    courseIdToEvaluate: '',
  });

  const [coacheeQueryResult, requeryCoacheeDetail] = useQuery({
    query: COACHEE_fetch_info,
    variables: {
      id: getUserRoleID(UserRoles.COACHEE),
    },
    requestPolicy: 'cache-and-network',
  });

  /*METHODS*/

  const { t: translate } = useTranslation();

  const navigate = useNavigate();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleOpenCourseEvaluation = (courseID: string) => {
    setHelperState({
      ...helperState,
      showFinalEvaluation: true,
      courseIdToEvaluate: courseID,
    });
  };

  const handleCloseCourseEvaluation = () => {
    setHelperState({
      ...helperState,
      showFinalEvaluation: false,
      courseIdToEvaluate: '',
    });
  };

  const gotToProfile = () => navigate(`/${Role_ID.COACHEE}/profile`);

  const renderProgramBlock = useCallback(() => {
    return coacheeQueryResult.data.detailCoachee.programs.map((item: IProgram_of_coachee) => {
      return (
        <div className={styles.programWrapper} key={item.id}>
          <h1 className={styles.programName}>{item.name}</h1>
          <div className={styles.courseWrapper}>
            {item.courses.map((course: ICourse) => {
              return (
                <CoacheeCourseCard
                  course={course}
                  key={course.id}
                  openEvaluateCourse={() => handleOpenCourseEvaluation(course.id)}
                />
              );
            })}
          </div>
        </div>
      );
    });
  }, [coacheeQueryResult.data?.detailCoachee.programs, handleOpenCourseEvaluation]);

  return (
    <PrimaryLayout
      primaryTitle={translate('My courses')}
      primaryTitleIcon={MaterialIconName.assignment}
      role_name={translate('Coachee')}
      toProfile={gotToProfile}
      onClickPrimaryTitle={() => navigate(`/${Role_ID.COACHEE}`)}
      hideUserIcon={true}
      goToMessage={() => navigate(`/${Role_ID.COACHEE}/course-management/message`)}>
      {coacheeQueryResult.fetching && <AwaitingComp fluid={true} />}

      {coacheeQueryResult.data && <div className={styles.wrapper}>{renderProgramBlock()}</div>}

      {helperState.showFinalEvaluation && (
        <CoacheeEvaluateFinishedCourse
          courseID={helperState.courseIdToEvaluate}
          close={handleCloseCourseEvaluation}
          requeryCoacheeDetail={requeryCoacheeDetail}
        />
      )}
    </PrimaryLayout>
  );
};

export default CoacheCourseSelectionPage;
