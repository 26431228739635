import { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'urql';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Progress } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import styles from './coacheSelfDiagnosticPage.module.scss';
import PrimaryLayout from '../../layouts/PrimaryLayout/PrimaryLayout';
import { COACHEE_ACTIONS, Role_ID, STATUS_CODE, COURSE_STATUS, MaterialIconName } from '../../models/enum';
import { COURSE_course_on_boarded, COURSE_fetch_diagnosis } from '../../services/apis/courseApis';
import AwaitingComp from '../../components/Common/AwaitingComp/AwaitingComp';
import MultiFormWrapper from '../../components/MultiFormWrapper/MultiFormWrapper';
import DiagnosisFinishedResult from '../../components/DiagnosisFinishedResult/DiagnosisFinishedResult';
import SelfDiagnostisCollectionTitle from './SelfDiagnostisCollectionTitle';

const CoacheSelfDiagnosticPage = () => {
  const [showMainContent, setShowMainContent] = useState(false);
  const coacheeSelector = useSelector((state: any) => state.coacheeReducer.selfDiagnostic);
  const location = useLocation();

  const [questionsQueryResult] = useQuery({
    query: COURSE_fetch_diagnosis,
    variables: { course_id: location.state.selectedCourse.id },
    requestPolicy: 'cache-and-network',
  });

  const [courseOnboardedResult, executeCourOnboarded] = useMutation(COURSE_course_on_boarded);

  /*METHODS*/
  const { t: translate } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const gotToProfile = () => navigate(`/${Role_ID.COACHEE}/profile`);

  /**
   * EFFECT for first run, check for condition to allow stay on self-diagnostic
   */
  useEffect(() => {
    //if no selected course => go back to course selection
    if (!location.state?.selectedCourse || !location.state?.selectedCourse.offer_configuration.has_self_diagnosis)
      navigate(`/${Role_ID.COACHEE}`);
    //if onboarding => call api to change status to started_diagnosis
    else if (location.state.selectedCourse.status === COURSE_STATUS.started_onboarding) {
      executeCourOnboarded({ course_id: location.state.selectedCourse.id })
        .then((res) => {
          if (res.data?.courseOnboarded.status_code === +STATUS_CODE.SUCCESS) {
            dispatch({
              type: COACHEE_ACTIONS.update_course_status,
              payload: res.data.courseOnboarded.course.status,
            });
            setShowMainContent(true);
          } else if (res?.error.graphQLErrors[0].extensions.error_code === STATUS_CODE.BADREQUEST) {
            setShowMainContent(true);
          } else {
            console.error('Cannot onboard this course', res.error);
            navigate(`/${Role_ID.COACHEE}`);
          }
        })
        .catch((err) => console.error(err));
    } else if (location.state.selectedCourse.status === COURSE_STATUS.started_self_diagnosis) {
      setShowMainContent(true);
    } else {
      alert('Aucun auto-diagnostic pour ce parcours');
      navigate(`/${Role_ID.COACHEE}`);
      return;
    }
  }, [dispatch, executeCourOnboarded, location.state.selectedCourse, navigate]);

  useEffect(() => {
    return () => {
      dispatch({
        type: COACHEE_ACTIONS.clear_self_diagnostic,
      });
    };
  }, [dispatch]);

  useEffect(() => {
    if (!questionsQueryResult.data) return;
    //Dispatch question collections to redux and check condition to prepare self-diagnostic page
    dispatch({
      type: COACHEE_ACTIONS.init_self_diagnosis,
      payload: questionsQueryResult.data.getCourseSelfDiagnosis.self_diagnosis,
    });
  }, [dispatch, questionsQueryResult.data]);

  /*MAIN RETURN*/

  return (
    <PrimaryLayout
      primaryTitle={translate('My courses')}
      primaryTitleIcon={MaterialIconName.assignment}
      role_name={translate('Coachee')}
      whiteBackground={true}
      toProfile={gotToProfile}
      hideUserIcon={true}
      onClickPrimaryTitle={() => navigate(`/${Role_ID.COACHEE}`)}>
      {showMainContent && questionsQueryResult.data?.getCourseSelfDiagnosis && (
        <div className={!coacheeSelector.showFinishResult ? styles.wrapper : ''}>
          <div className={styles.titleWrapper}>
            <SelfDiagnostisCollectionTitle />
          </div>
          {!coacheeSelector.showFinishResult ? (
            <div>
              <div className={styles.progressWrapper}>
                <Container>
                  <Progress
                    indicating
                    value={
                      !coacheeSelector.currentStepIndex
                        ? coacheeSelector.currentStepIndex
                        : coacheeSelector.currentStepIndex + 1
                    }
                    total={coacheeSelector.stepsQuestionArray.length}
                    size='small'
                    className={styles.progress}
                  />
                </Container>
              </div>

              <div className={styles.formWrapper}>
                <Container>
                  <div className={styles.formWrapperInner}>
                    <MultiFormWrapper />
                  </div>
                </Container>
              </div>
            </div>
          ) : (
            <Container>
              <DiagnosisFinishedResult
                selectedCourse={location.state.selectedCourse}
                showCheckIcon={true}
                showNextButton={true}
              />
            </Container>
          )}
        </div>
      )}
      {(questionsQueryResult.fetching || courseOnboardedResult.fetching) && <AwaitingComp fluid={true} />}
    </PrimaryLayout>
  );
};

export default CoacheSelfDiagnosticPage;
