import { createBrowserRouter } from 'react-router-dom';
import ForgetPasswordPage from '../../pages/ForgetPasswordPage/ForgetPasswordPage';
import LoginPage from '../../pages/LoginPage/LoginPage';
import ActivateAndResetPasswordPage from '../../pages/ActivateAndResetPasswordPage/ActivateAndResetPasswordPage';
import PrivateRoute from './PrivateRoute';
import IntermediatePage from '../../pages/IntermediatePage/IntermediatePage';
import RGPDPage from '../../pages/RGPDPage/RGPDPage';
import { Role_ID } from '../../models/enum';
import ProgramManagerMainPage from '../../pages/ProgramManagerMainPage/ProgramManagerMainPage';
import ProgramManagerIntermediatePage from '../../pages/ProgramManagerIntermediatePage/ProgramManagerIntermediatePage';
import ProgramMangerProfilePage from '../../pages/ProgramMangerProfilePage/ProgramMangerProfile';
import CoacheCourseSelectionPage from '../../pages/CoacheCourseSelectionPage/CoacheCourseSelectionPage';
import CoacheProfilePage from '../../pages/CoacheProfilePage/CoacheProfilePage';
import CoacheSelfDiagnosticPage from '../../pages/CoacheSelfDiagnosticPage/CoacheSelfDiagnostic';
import OnboardingPage from '../../pages/OnboardingPage/OnboardingPage';
import CoacheeCoachSelectionPage from '../../pages/CoacheeCoachSelectionPage/CoacheeCoachSelectionPage';
import CoacheeCourseManagementPage from '../../pages/CoacheeCourseManagermentPage/CoacheeCourseManagementPage';
import CoachMainPage from '../../pages/CoachMainPage/CoachMainPage';
import CoachProfilePage from '../../pages/CoachProfilePage/CoachProfilePage';
import ProjectDirectorMainPage from '../../pages/ProjectDirectorMainPage/ProjectDirectorMainPage';
import ProjectDirectorProfilePage from '../../pages/ProjectDirectorProfilePage/ProjectDirectorProfilePage';
import coachMainPageRouter from './CoachMainPageRouter';
import coacheeSessionManagementRouter from './CoacheeSessionManagementRouter';
import prograManagerRouter from './PMRouter';

const router = createBrowserRouter([
  { path: '/', element: <LoginPage /> },
  { path: 'login', element: <LoginPage /> },
  { path: 'forgetpassword', element: <ForgetPasswordPage /> },
  { path: 'reset-password', element: <ActivateAndResetPasswordPage /> },
  { path: 'activate-account', element: <ActivateAndResetPasswordPage /> },
  { path: 'RGDP', element: <PrivateRoute element={<RGPDPage />} /> },
  { path: 'roles', element: <PrivateRoute element={<IntermediatePage />} /> },
  { path: `${Role_ID.PROGRAM_MANAGER}`, element: <PrivateRoute element={<ProgramManagerIntermediatePage />} /> },
  { path: `${Role_ID.PROGRAM_MANAGER}/profile`, element: <PrivateRoute element={<ProgramMangerProfilePage />} /> },
  {
    path: `${Role_ID.PROGRAM_MANAGER}/main`,
    element: <PrivateRoute element={<ProgramManagerMainPage />} />,
    children: prograManagerRouter,
  },
  { path: `${Role_ID.COACHEE}`, element: <PrivateRoute element={<CoacheCourseSelectionPage />} /> },
  { path: `${Role_ID.COACHEE}/profile`, element: <PrivateRoute element={<CoacheProfilePage />} /> },
  { path: `${Role_ID.COACHEE}/on-boarding`, element: <PrivateRoute element={<OnboardingPage />} /> },
  { path: `${Role_ID.COACHEE}/self-diagnostic`, element: <PrivateRoute element={<CoacheSelfDiagnosticPage />} /> },
  { path: `${Role_ID.COACHEE}/coach-selection`, element: <PrivateRoute element={<CoacheeCoachSelectionPage />} /> },
  {
    path: `${Role_ID.COACHEE}/course-management`,
    element: <PrivateRoute element={<CoacheeCourseManagementPage />} />,
    children: coacheeSessionManagementRouter,
  },
  { path: `${Role_ID.COACH}`, element: <PrivateRoute element={<CoachMainPage />} />, children: coachMainPageRouter },
  { path: `${Role_ID.COACH}/profile`, element: <PrivateRoute element={<CoachProfilePage />} /> },
  { path: `${Role_ID.PROJECT_DIRECTOR}`, element: <PrivateRoute element={<ProjectDirectorMainPage />} /> },
  { path: `${Role_ID.PROJECT_DIRECTOR}/profile`, element: <PrivateRoute element={<ProjectDirectorProfilePage />} /> },
  { path: '*', element: <>404 Not Found. Page you are looking for doesn not exist</> },
]);

export default router;
