import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from 'semantic-ui-react';
import { IProgramsSummary } from '../../models/type';
import styles from './yearlySummary.module.scss';

interface Props {
  programSummary: IProgramsSummary;
}

const YearlySummary: React.FC<Props> = (props: Props) => {
  const { t: translate } = useTranslation();

  const isPastYear = +props.programSummary.year !== +new Date().getFullYear().valueOf();

  if (!props.programSummary.year) return <></>;

  return (
    <div>
      <Grid className={styles.gridWrapper}>
        <Grid.Row divided={false} className={styles.yearlyRow}>
          <Grid.Column mobile={16} tablet={4} computer={4} className={styles.columnYear}>
            <div className={styles.blockYear}>
              <span className={isPastYear && styles.pastYeargray_MPB}>{props.programSummary.year}</span>
            </div>
          </Grid.Column>
          <Grid.Column mobile={16} tablet={12} computer={12}>
            <Grid>
              <Grid.Row>
                <Grid.Column mobile={16} tablet={4} computer={4} className={styles.columnResume}>
                  <div className={styles.blockResume}>
                    <span className={`${styles.numberResume} ${isPastYear && styles.pastYearGreyMPB}`}>
                      {props.programSummary.number_of_programs}
                    </span>
                    <span className={`${styles.textResume} ${isPastYear && styles.pastYearGrey1}`}>
                      {translate('Program', {
                        count: props.programSummary.number_of_programs,
                      })}
                    </span>
                  </div>
                </Grid.Column>
                <Grid.Column mobile={16} tablet={4} computer={4} className={styles.columnResume}>
                  <div className={styles.blockResume}>
                    <span className={`${styles.numberResume} ${isPastYear && styles.pastYearGreyMPB}`}>
                      {props.programSummary.courses.number_of_done}
                    </span>
                    <span className={`${styles.textResume} ${isPastYear && styles.pastYearGrey1}`}>
                      {translate('CourseAchived', {
                        count: props.programSummary.courses.number_of_done,
                      })}
                    </span>
                  </div>
                </Grid.Column>
                <Grid.Column mobile={16} tablet={4} computer={4} className={styles.columnResume}>
                  <div className={styles.blockResume}>
                    <span className={`${styles.numberResume} ${isPastYear && styles.pastYearGreyMPB}`}>
                      {props.programSummary.sessions.number_of_done}
                    </span>
                    <span className={`${styles.textResume} ${isPastYear && styles.pastYearGrey1}`}>
                      {translate('SessionAchived', {
                        count: props.programSummary.sessions.number_of_done,
                      })}
                    </span>
                  </div>
                </Grid.Column>
                <Grid.Column
                  mobile={16}
                  tablet={4}
                  computer={4}
                  className={`${styles.columnResume} ${styles.lastColumn}`}>
                  <div className={styles.blockResume}>
                    <span className={`${styles.numberResume} ${isPastYear && styles.pastYearGreyMPB}`}>
                      {props.programSummary.number_of_coachees}
                    </span>
                    <span className={`${styles.textResume} ${isPastYear && styles.pastYearGrey1}`}>
                      {translate('Coachee', {
                        count: props.programSummary.number_of_coachees,
                      })}
                    </span>
                  </div>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};

export default YearlySummary;
