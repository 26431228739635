import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Icon, Pagination } from 'semantic-ui-react';
import { useQuery } from 'urql';
import SearchSimple from '../../components/Common/SearchSimple/SearchSimple';
import {
  SortDirection,
  ListMediaResourcesSortableColumn,
  COACH_Actions,
  MediaResourcesSearchBy,
} from '../../models/enum';
import { MEDIARESOURCES_listResources } from '../../services/apis/mediaResourcesApis';
import styles from './coachMediaResourcesPage.module.scss';
import ResourcesCategoryBlock from './ResourcesCategoryBlock';

const CoachMediaResourcesPage: React.FC = (props) => {
  /*STATE*/

  const [queryVariables, setQueryVariables] = useState({
    count: 20,
    page: 1,
    search_by: MediaResourcesSearchBy.title,
    search_value: '',
    filter_format: '',
    filter_language: '',
    order_by: ListMediaResourcesSortableColumn.title,
    direction: SortDirection.ASC,
  });

  const mediaResourcesSelector = useSelector((state: any) => state.coachReducer.mediaResources);

  /*METHODS*/

  const [mediaResourceList] = useQuery({
    query: MEDIARESOURCES_listResources,
    variables: queryVariables,
    requestPolicy: 'cache-and-network',
  });

  const { t: translate } = useTranslation();

  const dispatch = useDispatch();

  const renderResourcesBlock = () => {
    const renderArray = [];

    for (let key in mediaResourcesSelector) {
      renderArray.push(
        <ResourcesCategoryBlock key={key} categoryTitle={key} resourcesArray={mediaResourcesSelector[key]} />,
      );
    }
    return renderArray;
  };

  const handleChangePage = (data: any) => {
    setQueryVariables({ ...queryVariables, page: data.activePage });
  };

  /*EFFECTs*/

  useEffect(() => {
    if (!mediaResourceList.data) return;
    dispatch({
      type: COACH_Actions.set_media_resources,
      payload: mediaResourceList.data?.listMediaResources?.media_resources,
    });
  }, [dispatch, mediaResourceList]);

  return (
    <Grid className={styles.customedCoachMediaResourceGrid}>
      <Grid.Row>
        <Grid.Column mobile='16' tablet='16' computer='16' widescreen='16' largeScreen='13'>
          <div className={styles.wrapper}>
            <div>
              <h3 className={styles.title}>{translate('Media resources')}</h3>
              <div className={styles.toolsBlock}>
                <div className={styles.searchWrapper}>
                  <SearchSimple
                    placeholder={translate('Enter resource name')}
                    handleSearch={(searchInput: string) => {
                      setQueryVariables({
                        ...queryVariables,
                        search_value: searchInput,
                      });
                    }}
                  />
                </div>
                <div className={styles.pagination} id='paginationId'>
                  <Pagination
                    defaultActivePage={1}
                    ellipsisItem={{
                      content: <Icon name='ellipsis horizontal' />,
                      icon: true,
                    }}
                    firstItem={null}
                    lastItem={null}
                    prevItem={{
                      content: <Icon name='angle left' />,
                      icon: true,
                    }}
                    nextItem={{
                      content: <Icon name='angle right' />,
                      icon: true,
                    }}
                    totalPages={1}
                    onPageChange={(event, data) => handleChangePage(data)}
                  />
                </div>
              </div>
            </div>
            {renderResourcesBlock()}
          </div>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default CoachMediaResourcesPage;
