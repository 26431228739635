import { ReactNode } from 'react';
import { Client, Operation, CombinedError, cacheExchange, fetchExchange, Provider } from 'urql';
import { AuthConfig, AuthUtilities, authExchange } from '@urql/exchange-auth';
import { STATUS_CODE } from '../models/enum';
import { API_userRefreshToken } from '../services/apis/userApi';
import { deleteAllLocalToken, getMilisecondExpiresDate } from '../utilities/authentication';
import { token as tokenConstant } from '../config/tokenConstant';
import { Token } from '../models/type';

interface UrqlProviderProps {
  children: ReactNode;
}

const UrqlProvider = ({ children }: UrqlProviderProps) => {
  const authRefresh = async (utils: AuthUtilities): Promise<AuthConfig> => {
    return {
      addAuthToOperation(operation: Operation) {
        return operation;
      },
      didAuthError(error: CombinedError, _operation: Operation) {
        return error.graphQLErrors.some((e) => e.extensions?.error_code === STATUS_CODE.UNAUTHORIZED);
      },
      willAuthError() {
        const milisecondTimeToExpired = 600000;
        const tempToken: Token = JSON.parse(localStorage.getItem(tokenConstant));
        if (tempToken && tempToken?.expires_date && tempToken?.expires_date - Date.now() < milisecondTimeToExpired)
          return true;
        return false;
      },
      async refreshAuth() {
        const res = await utils.mutate(API_userRefreshToken, {});
        if (res.data && res.data.refreshToken) {
          const infoToken: Token = {
            access_token: res.data.refreshToken.access_token,
            expires_date: getMilisecondExpiresDate(res.data.refreshToken.expires_in),
            expires_in: res.data.refreshToken.expires_in,
          };
          localStorage.setItem(tokenConstant, JSON.stringify(infoToken));
        } else {
          deleteAllLocalToken();
          window.location.replace(window.location.origin);
        }
      },
    };
  };

  const client = new Client({
    url: process.env.REACT_APP_BACKEND,
    exchanges: [cacheExchange, authExchange(authRefresh), fetchExchange],
    fetchOptions: () => {
      const token: Token = JSON.parse(localStorage.getItem(tokenConstant));
      return {
        headers: { Authorization: token ? `Bearer ${token.access_token}` : '' },
        mode: 'cors',
        credentials: 'same-origin',
      };
    },
  });

  return <Provider value={client}>{children}</Provider>;
};

export default UrqlProvider;
