export const API_userLogin = `mutation ($email: String!, $password: String!) {
  login (email: $email, password: $password) {
    token {
      access_token
      token_type
      expires_in
    }
    user {
        id
        email
        first_name
        last_name
        gdpr_consent
        user_roles {
          id
          role_name
      }
    }
  }
}
`;

export const API_userRefreshToken = `
mutation {
  refreshToken {
      access_token
      token_type
      expires_in
  }
}
`;

export const API_userForgetPassword = `
mutation($email: String!) {
  forgottenPassword(email : $email) {
    status_code
  }
}
`;

export const API_userResetPassword = `
mutation($email : String!, $password : String!, $token : String!) {
  resetPassword (email : $email, password : $password, token : $token) {
    status_code
  }
}`;

export const API_userLogout = `
mutation {
  logout {
      message
  }
}
`;
