const languages = {
  en: {
    translation: require("./en/en.json"),
  },
  fr: {
    translation: require("./fr/fr.json"),
  },
};

export default languages;
