import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Icon } from 'semantic-ui-react';
import { useMutation } from 'urql';
import { STATUS_CODE, PROGRAMMANGER_ACTIONS } from '../../models/enum';
import { ICoachee_extended } from '../../models/type';
import { PROGRAMMANAGER_remove_coachee } from '../../services/apis/programMangerApis';
import styles from './listCoacheeModalOptions.module.scss';

interface Props {
  onClose: () => void;
  handleRefetchCoacheeList: () => void;
  refetchProgramDetail: () => void;
}

const ModalOptionRemoveCoachee: React.FC<Props> = (props: Props) => {
  /*STATE*/

  const [state, setState] = useState({
    showSuccess: false,
  });

  /*PROPS*/
  const listCoacheeSelector: Array<ICoachee_extended> = useSelector(
    (state: any) => state.programManagerReducer.listOfCoacheePage.selectedCoacheeList,
  );

  const programID = useMemo(() => window.location.pathname.split('/').pop(), []);

  /*METHODS*/

  const { t: translate } = useTranslation();

  const dispatch = useDispatch();

  const [, executeRemoveCoachee] = useMutation(PROGRAMMANAGER_remove_coachee);

  const renderCoacheeName = (coacheeArray: ICoachee_extended[]) => {
    return coacheeArray.map((coachee: ICoachee_extended, index: number) => {
      return (
        <span className={styles.textHiglightInfo} key={coachee.id}>
          {' '}
          {!!index && ', '}
          {coachee.user_infos.first_name + ' ' + coachee.user_infos.last_name}{' '}
        </span>
      );
    });
  };

  const handleSubmit = () => {
    const coacheeIDs: Array<string> = listCoacheeSelector.map((coachee: ICoachee_extended) => {
      return coachee.id;
    });

    executeRemoveCoachee({ ids: coacheeIDs, program_id: programID })
      .then((res) => {
        if (+res.data?.removeCoacheeFromProgram?.status_code === +STATUS_CODE.NOCONTENT) {
          props.handleRefetchCoacheeList();
          props.refetchProgramDetail();
          dispatch({
            type: PROGRAMMANGER_ACTIONS.clear_selected_coachee,
          });
          setState({ ...state, showSuccess: true });
        } else {
          console.error(res.error);
        }
      })
      .catch((err) => console.error(err));
  };

  if (state.showSuccess) {
    return (
      <div>
        <div className={styles.successBlock}>
          <Icon name='check' size='huge' className={styles.iconCheck} />
          <span className={`${styles.successText} topLevelText`}>{translate('Congratulation')}</span>
          <p className={styles.successMessage}>
            {translate('RemovedCoacheeFromProgram', {
              count: listCoacheeSelector.length,
            })}
          </p>
        </div>
        <div className={styles.btnBlock}>
          <Button className={`${styles.cancelBtn} ${styles.styleDanger}`} onClick={props.onClose}>
            <span className='buttonText topLevelText'>{translate('Close')}</span>
          </Button>
        </div>
      </div>
    );
  }

  return (
    <>
      <div>
        <p className={styles.content}>
          {translate('Do you want to remove')}
          {renderCoacheeName(listCoacheeSelector)}
          {translate('from this coachee list?')}
        </p>
        <p className={styles.contentWarning}>
          <Icon name='info' className={styles.infoIcon} />
          {translate('You can modified upload coachee from excel file')}
        </p>
      </div>
      <div className={styles.btnBlock}>
        <Button className={styles.cancelBtn} onClick={props.onClose}>
          <span className='buttonText topLevelText'>{translate('Cancel')}</span>
        </Button>
        <Button className={`${styles.agreeBtn} ${styles.styleDanger}`} onClick={handleSubmit}>
          <span className='buttonText topLevelText'>{translate('Yes')}</span>
        </Button>
      </div>
    </>
  );
};

export default ModalOptionRemoveCoachee;
