import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { Segment } from 'semantic-ui-react';
import styles from './loginLayout.module.scss';
import { ReactComponent as Logo } from '../../assets/images/logo_svg.svg';

type Props = {
  logoPosition: string;
  background: string;
  formPostion: string;
  makeFormTransparent: boolean;
  children?: any;
};

const LoginLayout: FunctionComponent<Props> = (props: Props) => {
  const backgroundPhotoArray = useMemo(() => {
    return [
      {
        medium: styles.bg1_medium,
        large: styles.bg1_large,
      },
      {
        medium: styles.bg2_medium,
        large: styles.bg2_large,
      },
      {
        medium: styles.bg3_medium,
        large: styles.bg3_large,
      },
    ];
  }, []);

  const varWidth = useMemo(() => {
    return window.innerWidth > 0 ? window.innerWidth : window.screen.width;
  }, []);

  const makeRandomPhoto = useCallback(() => {
    if (varWidth <= 768) {
      return 'none';
    }
    const randomPosition = Math.floor(Math.random() * Math.floor(backgroundPhotoArray.length));
    if (varWidth < 1200) return backgroundPhotoArray[randomPosition].medium;
    return backgroundPhotoArray[randomPosition].large;
  }, [backgroundPhotoArray, varWidth]);

  /*METHODS*/

  return (
    <div
      className={`${styles.wrapper} ${props.background === 'photo' && makeRandomPhoto()}`}
      style={{
        // backgroundImage: props.background === "photo" && makeRandomPhoto(),
        display: varWidth <= 768 && 'flex',
        justifyContent: varWidth <= 768 && 'center',
        // alignItems: varWidth <= 768 && "center",
      }}>
      <Logo
        className={styles.branchLeft}
        style={props.logoPosition === 'left' ? { display: 'block' } : { display: 'none' }}
      />
      <div
        className={`${styles.formWrapper} ${
          props.formPostion === 'left' && varWidth >= 768 ? styles.formPositionLeft : styles.formPositionCenter
        } ${varWidth <= 768 && styles.absoluteCenter}`}>
        <Segment
          className={`${props.formPostion === 'center' && styles.clearBorder} ${
            varWidth <= 768 && styles.clearBorder
          } ${props.makeFormTransparent && styles.adjustBgTransparent}`}>
          <Logo
            className={styles.branchMiddle}
            style={props.logoPosition === 'middle' ? { display: 'block' } : { display: 'none' }}
          />
          {props.children}
        </Segment>
      </div>
    </div>
  );
};

export default LoginLayout;
