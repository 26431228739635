import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from 'semantic-ui-react';
import { useQuery } from 'urql';
import { SESSION_ACTIONS } from '../../models/enum';
import { ISession } from '../../models/type';
import { COURSE_get_course } from '../../services/apis/courseApis';
import CoacheeSessionCard from '../CoacheeSessionCard/CoacheeSessionCard';
import styles from './coacheeUpcomingSession.module.scss';
import IdeaIconPhoto from '../../assets/images/idea_icon.png';
import { Trans } from 'react-i18next';

const CoacheeUpcomingSession: React.FC = () => {
  /*PROPS*/

  const coursesOnStore = useSelector((state: any) => {
    return state.coacheeReducer.myCourseManagement;
  });
  const hasUpcomingSession = coursesOnStore.myUpComingSession.length > 0;

  /*METHODS*/

  const dispatch = useDispatch();

  const [getCourseResult, queryGetCourse] = useQuery({
    query: COURSE_get_course,
    variables: { course_id: window.location.search.slice(1) },
    requestPolicy: 'cache-and-network',
    pause: true,
  });

  const renderSessionCards = (
    sessions: Array<ISession>,
    totalSessions: number,
    offerConfigName: string,
    clientName: string,
  ) => {
    if (!sessions) return;
    return sessions.map((item: ISession) => {
      return (
        <CoacheeSessionCard
          session={item}
          totalSession={totalSessions}
          offerConfigName={offerConfigName}
          clientName={clientName}
          reFetchGetCourse={queryGetCourse}
          key={item.id}
        />
      );
    });
  };

  /*EFFECTS*/

  /**
   * Effect for fetching latest course detail after canceling a session
   */
  useEffect(() => {
    if (!!getCourseResult.data) {
      dispatch({
        type: SESSION_ACTIONS.set_sessions,
        payload: getCourseResult?.data.getCourse.course,
      });
    }
  }, [dispatch, getCourseResult]);

  return (
    <div className={styles.wrapper}>
      <Grid className={styles.customedGrid}>
        <Grid.Row className={`${styles.customedRow}  ${!hasUpcomingSession && styles.notHasUpcomingSession}`}>
          <Grid.Column mobile='16' tablet='16' computer='13' widescreen='10' largeScreen='10'>
            <div className={styles.topWrapper}>
              <div className={styles.topWrapper__icon}>
                <img src={IdeaIconPhoto} alt='Idea Icon' />
                <br />
                <span>Important</span>
              </div>
              <div className={styles.keyInformation}>
                <div>
                  <Trans i18nKey='Insert video link in the dedicated space for this' components={[<span></span>]} />
                </div>
                <div>
                  <Trans
                    i18nKey='During your session leave the windows open platform and video'
                    components={[<span></span>]}
                  />
                </div>
                <div>
                  <Trans
                    i18nKey='At the end of your session, remember to click on end session in the platform'
                    components={[<span></span>]}
                  />
                </div>
              </div>
            </div>
            {renderSessionCards(
              coursesOnStore.myUpComingSession,
              coursesOnStore.myCourse?.sessions.length,
              coursesOnStore.myCourse?.offer_configuration.name,
              coursesOnStore.myCourse?.client.name,
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};

export default CoacheeUpcomingSession;
