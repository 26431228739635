import React, { useEffect, useState } from 'react';
import { Grid, Icon } from 'semantic-ui-react';
import styles from './primaryLayout.module.scss';
import { ReactComponent as Logo } from '../../assets/images/logo_svg.svg';
import UserMenuModal from '../../components/UserMenuModal/UserMenuModal';
import useComponentVisible from '../../components/Common/useComponentVisible/ComponentVisible';
import { token_user } from '../../config/tokenConstant';
import { MaterialIconName, USER_ACTIONS } from '../../models/enum';
import { NavLink, Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import MessageNotifyIcon from './MessageNotifyIcon';
import { navLinkClasses } from '../../utilities/helper';

interface Props {
  primaryTitle?: string;
  primaryTitleIcon?: MaterialIconName;
  navLinkArary?: Array<any>;
  role_name: string;
  whiteBackground?: boolean;
  hideUserIcon?: boolean; //default false
  hideMessageIcon?: boolean; //default false
  toProfile: () => void;
  onClickPrimaryTitle: () => void;
  goToMessage?: () => void;
  children?: any;
}

const PrimaryLayout: React.FC<Props> = (props: Props) => {
  const [authenticated, setAuthenticated] = useState(true);

  const { ref, isComponentShowed, setComponentShowed } = useComponentVisible(false);

  const dispatch = useDispatch();

  const userName = useSelector((state: any) => state.userReducer.userName);

  const renderNavLink = () => {
    return props.navLinkArary.map((item: any, index: number) => {
      return (
        <NavLink
          to={`/${item.navPathname}`}
          className={(navLinkProps) => navLinkClasses(navLinkProps, styles.navLinkItem, styles.active)}
          key={index}>
          <span className={styles.navLinkText}>{item.navTitle}</span>
        </NavLink>
      );
    });
  };

  useEffect(() => {
    if (localStorage.getItem(token_user)) {
      const user = JSON.parse(localStorage.getItem(token_user));
      dispatch({
        type: USER_ACTIONS.set_user_name,
        payload: user,
      });
      return;
    }
    setAuthenticated(false);
  }, [dispatch]);

  if (!authenticated) return <Navigate to='/' />;

  return (
    <div className={`${styles.wrapper} ${props.whiteBackground && styles.whiteBackground}`}>
      <Grid className={styles.m0}>
        <Grid.Row className={`${styles.topRowWrapper} ${styles.p0}`} divided={false}>
          <Grid.Column computer={3} widescreen={2} largeScreen={2} className={`${styles.logoColumn}`}>
            <Logo className={styles.logo} />
          </Grid.Column>
          <Grid.Column
            mobile={10}
            tablet={12}
            computer={10}
            widescreen={12}
            largeScreen={12}
            className={`${styles.topMidColumn} ${!props.navLinkArary && !props.primaryTitle && styles.positionLeft}`}>
            {props.navLinkArary && <div className={styles.navBlock}>{renderNavLink()}</div>}

            {props.primaryTitle && (
              <div className={styles.iconAndTitleBlock}>
                <span className={`${styles.currentTitleIcon} material-icons-outlined`}>{props.primaryTitleIcon}</span>
                <span className={styles.currentTitle} onClick={props.onClickPrimaryTitle}>
                  {props.primaryTitle}
                </span>
              </div>
            )}

            <div className='flex items-center justify-center'>
              {!props.hideMessageIcon && (
                <div className={styles.notifiedIconBlock}>
                  <MessageNotifyIcon onClick={props.goToMessage ? props.goToMessage : () => {}} />
                </div>
              )}
            </div>
          </Grid.Column>
          <Grid.Column
            mobile={6}
            tablet={4}
            computer={3}
            widescreen={2}
            largeScreen={2}
            textAlign='center'
            verticalAlign='middle'>
            <div className={styles.topRightBlock}>
              <div className={styles.userNameAndTitleBlock}>
                <Icon
                  name='user'
                  className={`${styles.userIcon} ${props.hideUserIcon && styles.hideUserIcon}`}
                  onClick={props.toProfile}
                />
                <div className={styles.nameAndTitleText}>
                  <span className={`${styles.m0} ${styles.userName}`} onClick={props.toProfile}>
                    {userName}
                  </span>
                  <span className={styles.userTitle}>{props.role_name}</span>
                </div>
              </div>
              <div className={styles.userMenuCollapseBlock}>
                <Icon name='ellipsis vertical' className={styles.icon} onClick={() => setComponentShowed(true)} />
                <div ref={ref}>{isComponentShowed && <UserMenuModal toProfile={props.toProfile} />}</div>
              </div>
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <>{props.children}</>
    </div>
  );
};

export default PrimaryLayout;
