import { USER_ACTIONS } from '../../../models/enum';
import { IUserAction } from '../../../models/type';

const initinalState = {
  userName: '',
};

export const userReducer = (state = initinalState, action: IUserAction) => {
  switch (action.type) {
    case USER_ACTIONS.set_user_name:
      return { ...state, userName: action.payload.first_name + ' ' + action.payload.last_name };
    default:
      return state;
  }
};
