import { SESSION_STATUS } from '../models/enum';
import moment from 'moment';

/**
 * Conference link should be display if type online and status planned, started waiting or started
 *
 * @param sessionStatus are status of session as planned, started waiting, started, finished as in SESSION_STATUS enum
 * @param isOffline are offline or online
 * @returns {boolean}
 */
export const shouldDisplayConferenceLink = (sessionStatus: string, isOffline: boolean): boolean => {
  if (isOffline === true) return false;

  return [`${SESSION_STATUS.planned}`, `${SESSION_STATUS.started_waiting}`, `${SESSION_STATUS.started}`].includes(
    sessionStatus,
  );
};

/**
 * Whether should display a set video conference link reminder modal.
 * If it's offline session should not display the modal
 *
 * @param plannedAt UTC time session planned at
 * @param link video conference link
 * @param isOffline value of session isOffline
 * @returns {boolean}
 */
export const shouldDisplaySetLinkReminder = (plannedAt: string, link: string, isOffline: boolean): boolean => {
  if (isOffline === true) return false;

  if (!!plannedAt === true && !!link === false) return true;

  return false;
};

/**
 * Only allow switching between ONLINE and OFFLINE for unplanned or planned session
 *
 * @param sessionStatus are status of session as planned, started waiting, started, finished as in SESSION_STATUS enum
 * @returns {boolean}
 */
export const enableSwitchSessionType = (sessionStatus: string): boolean => {
  return [`${SESSION_STATUS.unplanned}`, `${SESSION_STATUS.planned}`].includes(sessionStatus);
};

/**
 * Offline session can be finished any time from startAt afterward
 *
 * @param startAt UTC time of planned_at
 * @returns {boolean}
 */
export const canFinishedOfflineSession = (startAt: string): boolean => {
  const startAtUnixTime: number = moment(startAt).unix();
  const now: number = moment().unix();

  if (now >= startAtUnixTime) return true;

  return false;
};
