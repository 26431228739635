import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Grid } from 'semantic-ui-react';
import { useQuery } from 'urql';
import ClockTimer from '../../components/ClockTimer/ClockTimer';
import AwaitingComp from '../../components/Common/AwaitingComp/AwaitingComp';
import CustomerSupportInfo from '../../components/CustomerSupportInfo/CustomerSupportInfo';
import PrimaryLayout from '../../layouts/PrimaryLayout/PrimaryLayout';
import {
  MaterialIconName,
  Role_ID,
  SESSIONS_PATH_HAVE_BG,
  SESSION_ACTIONS,
  SESSION_STATUS,
  STATUS_CODE,
  UserRoles,
} from '../../models/enum';
import { ICourse, ISession } from '../../models/type';
import { COURSE_get_course } from '../../services/apis/courseApis';
import { SESSION_coachee_get_sessions_done } from '../../services/apis/sessionApis';
import { getUserRoleID, navLinkClasses } from '../../utilities/helper';
import styles from './coacheeCourseManagementPage.module.scss';

const CoacheeCourseManagementPage = () => {
  const [condToHaveBg, setCondToHaveBg] = useState(false);

  /*PROPS*/
  const courseId = useMemo(() => {
    return window.location.search.slice(1);
  }, []);

  const [courseDetailQuery] = useQuery({
    query: COURSE_get_course,
    variables: { course_id: courseId },
  });

  const [sessionDoneResult] = useQuery({
    query: SESSION_coachee_get_sessions_done,
    variables: {
      user_role_id: getUserRoleID(UserRoles.COACHEE),
      course_id: window.location.search.slice(1),
    },
    requestPolicy: 'cache-and-network',
  });

  const myCourseSelector: ICourse = useSelector((state: any) => {
    return state.coacheeReducer.myCourseManagement?.myCourse;
  });

  const myDoneSessions: Array<ISession> = useSelector((state: any) => {
    return state.coacheeReducer.myCourseManagement?.myDoneSessions;
  });

  const courseStore = useSelector((state: any) => state.coacheeReducer.myCourseManagement);

  /*METHODS*/
  const dispatch = useDispatch();

  const { t: translate } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const gotToProfile = () => navigate(`/${Role_ID.COACHEE}/profile`);

  const shouldHaveBG =
    location.pathname.includes(SESSIONS_PATH_HAVE_BG.UPCOMING) ||
    location.pathname.includes(SESSIONS_PATH_HAVE_BG.CLOSED);

  /*Const*/
  //Course finished if done sessions length === length of total sessions in course
  const isCourseFinished: boolean = myDoneSessions?.length === myCourseSelector?.sessions?.length;

  const isMediaLink = () => {
    const location = window.location.pathname;
    return location.includes('media');
  };

  const isAtMessagePage = () => {
    const url = window.location.pathname;
    return url.includes('message');
  };

  useEffect(() => {
    const currentSession = courseStore?.myCurrentSession;
    const sessionStatusDontHaveBg = [SESSION_STATUS.started_waiting, SESSION_STATUS.started];

    if (!currentSession) {
      return;
    }

    const shouldPathHasBg = () => {
      let isProgressingSession = false;
      if (sessionStatusDontHaveBg.includes(currentSession.status)) {
        isProgressingSession = true;
      }

      const condition =
        (location.pathname.includes(SESSIONS_PATH_HAVE_BG.CURRENT) && !isProgressingSession) ||
        location.pathname.includes(SESSIONS_PATH_HAVE_BG.UPCOMING) ||
        location.pathname.includes(SESSIONS_PATH_HAVE_BG.CLOSED);

      setCondToHaveBg(condition);
    };
    shouldPathHasBg();
  }, [courseStore.myCurrentSession, location.pathname]);

  useEffect(() => {
    if (!!courseDetailQuery.data) {
      dispatch({
        type: SESSION_ACTIONS.set_sessions,
        payload: courseDetailQuery?.data.getCourse.course,
      });
    }
  }, [courseDetailQuery, dispatch]);

  useEffect(() => {
    if (+sessionDoneResult.data?.listMySessions?.status_code === +STATUS_CODE.SUCCESS) {
      dispatch({
        type: SESSION_ACTIONS.coachee_set_done_sessions,
        payload: sessionDoneResult.data.listMySessions.sessions,
      });
    } else if (sessionDoneResult.error) {
      console.error(sessionDoneResult.error);
    }
  }, [dispatch, sessionDoneResult]);

  /*MAIN RETURN*/

  return (
    <PrimaryLayout
      whiteBackground={true}
      primaryTitle={translate('My courses')}
      primaryTitleIcon={MaterialIconName.assignment}
      role_name={translate('Coachee')}
      toProfile={gotToProfile}
      hideUserIcon={true}
      onClickPrimaryTitle={() => navigate(`/${Role_ID.COACHEE}`)}
      goToMessage={() => navigate(`message/?${courseId}`)}>
      <div className={styles.wrapper}>
        <div className={styles.topWrapper}>
          <div>
            {isAtMessagePage() ? (
              <h3>{translate('Message')}</h3>
            ) : (
              <div>
                {myCourseSelector && (
                  <h3>{`${myCourseSelector.offer_configuration.name} : ${myCourseSelector.program.name}`}</h3>
                )}
              </div>
            )}

            <p>{!isMediaLink() && !isAtMessagePage() && translate('Courses list')}</p>
          </div>
        </div>
        <Grid className={`${styles.customedGrid} `}>
          <Grid.Row className={styles.customedGridRow}>
            <Grid.Column
              stretched
              mobile={16}
              tablet={16}
              computer={3}
              widescreen={2}
              largeScreen={2}
              className={styles.customedGridLeftColumn}>
              <div className={styles.leftBlock}>
                <NavLink
                  to={`current/?${courseId}`}
                  className={(navLinkProps) =>
                    navLinkClasses(
                      navLinkProps,
                      `${styles.navLink} ${isCourseFinished && styles.disabledNav}`,
                      styles.activeLink,
                    )
                  }>
                  {translate('Current session')}
                </NavLink>
                <NavLink
                  to={`upcoming/?${courseId}`}
                  className={(navLinkProps) =>
                    navLinkClasses(
                      navLinkProps,
                      `${styles.navLink} ${isCourseFinished && styles.disabledNav}`,
                      styles.activeLink,
                    )
                  }>
                  {translate('Upcoming session')}
                </NavLink>
                <NavLink
                  to={`closed/?${courseId}`}
                  className={(navLinkProps) => navLinkClasses(navLinkProps, styles.navLink, styles.activeLink)}>
                  {translate('Session doned')}
                </NavLink>
                {myCourseSelector?.offer_configuration.has_objectives && (
                  <NavLink
                    to={`objective/?${courseId}`}
                    className={(navLinkProps) =>
                      navLinkClasses(
                        navLinkProps,
                        `${styles.navLink} ${!myCourseSelector?.objectives?.length && styles.disabledNav}`,
                        styles.activeLink,
                      )
                    }>
                    {translate('Objective_plural')}
                  </NavLink>
                )}
                <NavLink
                  to={`media/?${courseId}`}
                  className={(navLinkProps) => navLinkClasses(navLinkProps, styles.navLink, styles.activeLink)}>
                  {translate('Media resources')}
                </NavLink>
                {courseDetailQuery.data?.getCourse.course.offer_configuration.has_self_diagnosis && (
                  <NavLink
                    to={`diagnostic/?${courseId}`}
                    className={(navLinkProps) => navLinkClasses(navLinkProps, styles.navLink, styles.activeLink)}>
                    {translate('My diagnostic')}
                  </NavLink>
                )}
                {!isCourseFinished && <ClockTimer />}
                <CustomerSupportInfo />
              </div>
            </Grid.Column>
            <Grid.Column
              stretched
              mobile={16}
              tablet={16}
              computer={13}
              widescreen={14}
              largeScreen={14}
              className={`${styles.rightBlockContainer} ${(condToHaveBg || shouldHaveBG) && styles.sessionBackground}`}>
              <div className={styles.rightBlock}>
                <Outlet />
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
      {courseDetailQuery.fetching && <AwaitingComp fluid={true} />}
    </PrimaryLayout>
  );
};

export default React.memo(CoacheeCourseManagementPage);
