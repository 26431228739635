export const COACH_detail = `
query($coach_id: ID!) {
    detailCoach (
        id: $coach_id
    ) {
        status_code
        coach {
            id 
            presentation
            quote
            additional_languages
            certifications
            personal_token
            picture
            user_infos {
                first_name
                last_name
                email
                position
                department
                gdpr_consent  
            }
        }
    }
}
`;

export const COACH_list_sessions = `
query($user_role_id: ID, $number_of_days: Int, $course_id : ID, $only_done : Boolean) {
    listMySessions(
        user_role_id: $user_role_id,
        number_of_days: $number_of_days
        course_id: $course_id,
        only_done : $only_done
    ) {
        status_code
        sessions {
            id
            status
            planned_at
            finished_at
            duration
            note
            evaluation
            evaluation_comment
            is_first
            is_last
            current_index
            videoconference_link
            attendees
            calendly_event_uri
            is_offline
            course_context {
                course_id
                number_of_sessions
                client_name
                program_name
                offer_name
                has_objectives
                self_diagnosis {
                    id
                }
                objectives {
                    id
                    description
                    key_indicators
                    evaluations {
                        id
                        grade
                        session_id
                        session_current_index
                    }
                }
            }
            coach {
                first_name
                last_name
                token
            }
            coachee {
                first_name
                last_name
            }
        }
        program {
            id
            name
        }
    }
}
`;

export const COACH_update = `
mutation(
    $coach_id: ID!,
    $firstName: String!, 
    $lastName: String!, 
    $email: String!, 
    $quote: String!, 
    $presentation: String!, 
    $additional_languages: [String!]!, 
    $certifications: [String!]!, 
    $personal_token: String,
    $file: Upload,
    $picture : String
    ) {
    updateCoach (
        id: $coach_id,
        first_name: $firstName, 
        last_name: $lastName, 
        email: $email,
        quote: $quote,
        presentation: $presentation, 
        additional_languages: $additional_languages, 
        certifications: $certifications,
        personal_token: $personal_token,
        picture : $picture,
        file: $file
    ) {
        status_code
        coach {
            id 
            presentation
            quote
            picture
            additional_languages
            certifications
            personal_token
            user_infos {
                first_name
                last_name
                email
                position
                department
                gdpr_consent  
            }
        }
    }
}
`;
