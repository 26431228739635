import React from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from 'semantic-ui-react';
import { ListMyCoursesOrderBy, SortDirection } from '../../models/enum';
import { ICourse } from '../../models/type';
import CoachCourseSuperviseRow from './CoachCourseSuperviseRow';
import styles from './coachCourseSuperviseTable.module.scss';

interface Props {
  courses: Array<ICourse>;
  handleSort: (direction: SortDirection, order_by: ListMyCoursesOrderBy) => void;
  currentDirection: SortDirection;
  currentOrderBy: ListMyCoursesOrderBy;
}

const CoachCourseSuperviseTable: React.FC<Props> = (props: Props) => {
  /*METHODS*/
  const { t: translate } = useTranslation();

  const clientSortedASC =
    props.currentDirection === SortDirection.ASC && props.currentOrderBy === ListMyCoursesOrderBy.client;

  const coacheeSortedASC =
    props.currentDirection === SortDirection.ASC && props.currentOrderBy === ListMyCoursesOrderBy.coachee;

  const offerSortedASC =
    props.currentDirection === SortDirection.ASC && props.currentOrderBy === ListMyCoursesOrderBy.offerCourseContext;

  const sessionSortedASC =
    props.currentDirection === SortDirection.ASC && props.currentOrderBy === ListMyCoursesOrderBy.session_index;

  const statusSortedASC =
    props.currentDirection === SortDirection.ASC && props.currentOrderBy === ListMyCoursesOrderBy.anomaly;

  const lastChangeSortedASC =
    props.currentDirection === SortDirection.ASC && props.currentOrderBy === ListMyCoursesOrderBy.last_change;

  return (
    <div className={styles.tableWrapper}>
      <table className={styles.table}>
        <thead className={styles.fixedHeader}>
          <tr className={styles.headerTR}>
            <th className={`${styles.headerTH} ${styles.clientTH}`}>
              <div className={styles.headerTitleBlock}>
                <span>{translate('Client')}</span>
                {clientSortedASC ? (
                  <Icon
                    name='angle up'
                    className={styles.sortIcon}
                    onClick={() => props.handleSort(SortDirection.DESC, ListMyCoursesOrderBy.client)}
                  />
                ) : (
                  <Icon
                    name='angle down'
                    className={styles.sortIcon}
                    onClick={() => props.handleSort(SortDirection.ASC, ListMyCoursesOrderBy.client)}
                  />
                )}
              </div>
            </th>
            <th className={`${styles.headerTH} ${styles.coacheeTH}`}>
              <div className={styles.headerTitleBlock}>
                <span>{translate('Coachee')}</span>
                {coacheeSortedASC ? (
                  <Icon
                    name='angle up'
                    className={styles.sortIcon}
                    onClick={() => props.handleSort(SortDirection.DESC, ListMyCoursesOrderBy.coachee)}
                  />
                ) : (
                  <Icon
                    name='angle down'
                    className={styles.sortIcon}
                    onClick={() => props.handleSort(SortDirection.ASC, ListMyCoursesOrderBy.coachee)}
                  />
                )}
              </div>
            </th>
            <th className={`${styles.headerTH} ${styles.offerTH}`}>
              <div className={styles.headerTitleBlock}>
                <span>{translate('Offer type')}</span>
                {offerSortedASC ? (
                  <Icon
                    name='angle up'
                    className={styles.sortIcon}
                    onClick={() => props.handleSort(SortDirection.DESC, ListMyCoursesOrderBy.offerCourseContext)}
                  />
                ) : (
                  <Icon
                    name='angle down'
                    className={styles.sortIcon}
                    onClick={() => props.handleSort(SortDirection.ASC, ListMyCoursesOrderBy.offerCourseContext)}
                  />
                )}
              </div>
            </th>
            <th className={`${styles.headerTH} ${styles.latestChangeTH}`}>
              <div className={styles.headerTitleBlock}>
                <span>{translate('Latest change')}</span>
                {lastChangeSortedASC ? (
                  <Icon
                    name='angle up'
                    className={styles.sortIcon}
                    onClick={() => props.handleSort(SortDirection.DESC, ListMyCoursesOrderBy.last_change)}
                  />
                ) : (
                  <Icon
                    name='angle down'
                    className={styles.sortIcon}
                    onClick={() => props.handleSort(SortDirection.ASC, ListMyCoursesOrderBy.last_change)}
                  />
                )}
              </div>
            </th>
            <th className={`${styles.headerTH} ${styles.orderTH}`}>
              <div className={styles.headerTitleBlock}>
                <span>{translate('Session order')}</span>
                {sessionSortedASC ? (
                  <Icon
                    name='angle up'
                    className={styles.sortIcon}
                    onClick={() => props.handleSort(SortDirection.DESC, ListMyCoursesOrderBy.session_index)}
                  />
                ) : (
                  <Icon
                    name='angle down'
                    className={styles.sortIcon}
                    onClick={() => props.handleSort(SortDirection.ASC, ListMyCoursesOrderBy.session_index)}
                  />
                )}
              </div>
            </th>
            <th className={`${styles.headerTH} ${styles.statusTH}`}>
              <div className={styles.headerTitleBlock}>
                <span>{translate('Status')}</span>
                {statusSortedASC ? (
                  <Icon
                    name='angle up'
                    className={styles.sortIcon}
                    onClick={() => props.handleSort(SortDirection.DESC, ListMyCoursesOrderBy.anomaly)}
                  />
                ) : (
                  <Icon
                    name='angle down'
                    className={styles.sortIcon}
                    onClick={() => props.handleSort(SortDirection.ASC, ListMyCoursesOrderBy.anomaly)}
                  />
                )}
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {props.courses.map((item: ICourse) => {
            return <CoachCourseSuperviseRow course={item} key={item.id} />;
          })}
        </tbody>
      </table>
    </div>
  );
};

export default CoachCourseSuperviseTable;
