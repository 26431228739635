import { useNavigate } from 'react-router-dom';
import { deleteAllLocalToken } from '../utilities/authentication';

const useLogOut = () => {
  const navigate = useNavigate();

  const logOut = () => {
    deleteAllLocalToken();
    navigate('/');
  };

  return logOut;
};

export default useLogOut;
