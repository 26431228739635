/**
 * Function for assign, unassign, remove ... in Program Manager Page
 */

import { OFFER_NAME_Capitalize, ProgramStatus } from '../models/enum';
import { IOfferConfiguration } from '../models/type';

export const shouldDisableButton = (programStatus: string): boolean => {
  if (programStatus === ProgramStatus.DONE) return true;
  return false;
};

/**
 * After checking general disabled condition, this assign Follow up button needs to check if there is any available Appui Flash course
 *
 * @param programOffer
 * @param generalDisableCondition is for all btn, if programStatus === ProgramStatus.DONE --> Disabled
 * @returns
 */
export const shouldDisableAssignFollowUpButton = (
  programOffer: IOfferConfiguration[],
  generalDisableCondition: boolean,
) => {
  if (!programOffer) return true;

  if (generalDisableCondition) return true;

  const flashOffer = programOffer.find((offer: IOfferConfiguration) => offer.name === OFFER_NAME_Capitalize.flash);

  if (flashOffer.number_of_unassigned <= 0) return true;

  return false;
};

/**
 * Message in scenarios:
 * - Coachee has no course yet
 * - No Flash course available
 * @param numOfCoacheeCourse
 * @returns string
 */
export const generatePopUpMessageForAssignFollowUpButton = (numOfCoacheeCourse: number) => {
  if (numOfCoacheeCourse === 0) return 'Coachee has no course to assign a follow up course';

  return 'No flash course available for follow-ups';
};
