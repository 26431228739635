import React, { useCallback, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Form, Button, Checkbox } from 'semantic-ui-react';
import { useMutation } from 'urql';

import styles from './login.module.scss';
import { errorMessage } from '../../services/validation/errorMessage';
import { API_userLogin } from '../../services/apis/userApi';
import { token, token_user } from '../../config/tokenConstant';
import { STATUS_CODE } from '../../models/enum';
import { Token } from '../../models/type';
import { getMilisecondExpiresDate } from '../../utilities/authentication';

const Login: React.FC = () => {
  const [{ fetching }, loginMethod] = useMutation(API_userLogin);

  const { t } = useTranslation();

  const [formState, setFormState] = useState({
    email: '',
    password: '',
    rememberMe: false,
    error: '',
  });

  const [userState, setUserState] = useState({
    toForgetPassword: false,
    toIntermediate: false,
    toRGDP: false,
    user: null,
  });

  const handleOnChange = (event: React.FormEvent<HTMLInputElement>) => {
    const name: string = event.currentTarget.name;
    const value: string = event.currentTarget.value;
    setFormState({ ...formState, [name]: value });
  };

  const handleOnCheck = useCallback(() => {
    setFormState({ ...formState, rememberMe: !formState.rememberMe });
  }, [formState]);

  const handleToForgetPassword = useCallback(() => {
    setUserState({ ...userState, toForgetPassword: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!formState.email || !formState.password) return;
    loginMethod({ email: formState.email, password: formState.password })
      .then((res: any) => {
        if (res.data) {
          const infoToken: Token = {
            access_token: res.data.login.token.access_token,
            expires_date: getMilisecondExpiresDate(res.data.login.token.expires_in),
            expires_in: res.data.login.token.expires_in,
          };
          localStorage.setItem(token, JSON.stringify(infoToken));
          localStorage.setItem(token_user, JSON.stringify(res.data.login.user));
          if (res.data.login.user.gdpr_consent) {
            setUserState({ ...userState, toIntermediate: true, user: res.data.login.user });
            return;
          }
          setUserState({ ...userState, toRGDP: true, user: res.data.login.user });
          return;
        }
        if (res.error.graphQLErrors[0].extensions.error_code === STATUS_CODE.UNAUTHORIZED) {
          setFormState({ ...formState, error: errorMessage.incorrectEmailOrPassword.frError });
          return;
        }
      })
      .catch((err) => console.error(err.message));
  };

  /*Redirect routing statement*/
  if (userState.toIntermediate) return <Navigate to='/roles' />;
  if (userState.toRGDP) return <Navigate to='/RGDP' state={{ user: userState.user }} />;
  if (userState.toForgetPassword) return <Navigate to='/forgetpassword' />;

  return (
    <Form className={styles.formBlock} onSubmit={handleSubmit}>
      <Form.Field className={styles.formField}>
        <input
          name='email'
          placeholder='Email'
          type='email'
          className={styles.inputField}
          autoComplete='true'
          onChange={handleOnChange}
        />
      </Form.Field>
      <Form.Field className={styles.formField}>
        <input
          name='password'
          placeholder='Password'
          type='password'
          className={styles.inputField}
          autoComplete='true'
          onChange={handleOnChange}
        />
      </Form.Field>
      <div className={styles.checkbox_wrapper}>
        <Checkbox fitted onClick={handleOnCheck} checked={formState.rememberMe} />
        <p className={styles.checkboxLabel}>Se souvenir de moi</p>
      </div>
      <p className={`${styles.errorMessage} ${formState.error && styles.visible}`}>{formState.error}</p>
      <Button
        type='submit'
        className={`${styles.submitButton} ${
          !formState.email || !formState.password ? styles.disableBtn : styles.activeBtn
        }`}
        disabled={fetching}>
        {t('Login')}
      </Button>
      <p className={styles.forgetPasswordText} onClick={handleToForgetPassword}>
        Mot de passe oublié
      </p>
    </Form>
  );
};

export default Login;
