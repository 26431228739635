import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown, Grid } from 'semantic-ui-react';
import { useQuery } from 'urql';
import { COACH_Actions, STATUS_CODE, UserRoles } from '../../models/enum';
import { COACH_list_sessions } from '../../services/apis/coachApis';
import { getUserRoleID } from '../../utilities/helper';
import CoachCardCourse from './CoachCardCourse';
import styles from './coachComingSessionsPage.module.scss';
import moment from 'moment';
import { ISession } from '../../models/type';
import CoachInsessionPage from '../CoachInsessionPage/CoachInsessionPage';
import '../../theme/customedDropDown.scss';

const CoachComingSessionsPage: React.FC = () => {
  /*STATE*/

  const [queryVariables, setVariables] = useState({
    user_role_id: getUserRoleID(UserRoles.COACH),
    number_of_days: 0,
    course_id: '',
    only_done: false,
  });

  const comingSessionsSelector = useSelector((state: any) => {
    return state.coachReducer.myCourses.comingSessions;
  });

  const selectedSessionSelector = useSelector((state: any) => state.coachReducer.myCourses.selectedSession);

  /*METHODS*/
  const { t: translate } = useTranslation();

  const dispatch = useDispatch();

  const [listCoachSessions, refetchListCoachSessions] = useQuery({
    query: COACH_list_sessions,
    variables: queryVariables,
    requestPolicy: 'cache-and-network',
  });

  const number_of_days_options_coach_sessions = [
    {
      key: 30,
      value: 30,
      text: translate('numberDay', { number: 30, count: 30 }),
    },
    {
      key: 7,
      value: 7,
      text: translate('numberDay', { number: 7, count: 7 }),
    },
    {
      key: 3,
      value: 3,
      text: translate('numberDay', { number: 3, count: 3 }),
    },
    {
      key: 1,
      value: 1,
      text: translate('numberDay', { number: 1, count: 1 }),
    },
  ];

  const renderCardSession = (array: Array<ISession>) => {
    return array.map((item: ISession) => (
      <CoachCardCourse key={item.id} session={item} refetchListCoachSession={refetchListCoachSessions} />
    ));
  };

  const renderDateMonthYearSessionBlock = (monthYearSessionObject: any) => {
    const renderArray = [];
    for (let key in monthYearSessionObject) {
      const monthName = moment(key, 'DD/MM/YYYY').format('MMMM');
      const formattedDate =
        moment(key, 'DD/MM/YYYY').format('DD') +
        ' ' +
        translate(monthName) +
        ' ' +
        moment(key, 'DD/MM/YYYY').format('YYYY');
      renderArray.push(
        <div className={styles.dateBlock} key={formattedDate}>
          <p className={`${styles.dateTitle} topLevelText`}>{formattedDate}</p>
          {renderCardSession(monthYearSessionObject[key])}
        </div>,
      );
    }
    return renderArray;
  };

  const renderMonthYearSessionBlock = () => {
    const renderArray = [];
    for (let key in comingSessionsSelector) {
      const shortMonthName = translate(moment(key, 'MM/YYYY').format('MMM'));
      const formatShortMonthYear = shortMonthName + ' ' + moment(key, 'MM/YYYY').format('YYYY');
      renderArray.push(
        <div className={styles.monthBlock} key={key}>
          <p className={`${styles.monthTitle} topLevelText`}>{formatShortMonthYear}</p>
          {renderDateMonthYearSessionBlock(comingSessionsSelector[key])}
        </div>,
      );
    }
    return renderArray;
  };

  /**EFFECT */

  useEffect(() => {
    if (+listCoachSessions.data?.listMySessions?.status_code === +STATUS_CODE.SUCCESS) {
      dispatch({
        type: COACH_Actions.set_my_sessions,
        payload: listCoachSessions.data.listMySessions.sessions,
      });
    }
  }, [dispatch, listCoachSessions]);

  /*MAIN RETURN*/

  if (selectedSessionSelector) {
    return <CoachInsessionPage refetchListCoachSession={refetchListCoachSessions} />;
  }

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column mobile='16' tablet='16' computer='14' widescreen='14' largeScreen='11'>
          <div className={styles.wrapper}>
            {/* TOOL BLOCK */}

            <div className={styles.toolsBlock}>
              <div className={styles.leftBlock}>
                <span className={styles.timeDropdownLabel}>{translate('Course in next')}</span>
                <div id='customedDropDownID'>
                  <Dropdown
                    placeholder={translate('numberDay', {
                      number: 30,
                      count: 30,
                    })}
                    basic
                    search
                    selection
                    options={number_of_days_options_coach_sessions}
                    value={queryVariables.number_of_days}
                    onChange={(e, { value }) =>
                      setVariables({
                        ...queryVariables,
                        number_of_days: +value,
                      })
                    }
                    className={styles.timeDropdown}
                  />
                </div>
              </div>
            </div>

            {/* END TOOLS BLOCK */}

            {/* MONTH BLOCK */}
            {comingSessionsSelector && renderMonthYearSessionBlock()}

            {/* END MONTH BLOCK */}
          </div>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default CoachComingSessionsPage;
