export const MEDIARESOURCES_listResources = `
query ($count: Int,
    $page: Int!,
    $search_by: MediaResourcesSearchBy,
    $search_value: String,
    $filter_format: MediaResourcesFormats,
    $filter_language: String,
    $order_by: ListMediaResourcesSortableColumn,
    $direction: SortDirection) {
    listMediaResources(
    count: $count,
    page: $page,
    search_by: $search_by,
    search_value: $search_value,
    filter_format : $filter_format,
    filter_language : $filter_language,
    order_by : $order_by,
    direction : $direction
    ) {
        status_code
        media_resources {
            id
            title
            description
            link
            language
            format
            created_at
            updated_at
            thematics {
                id
                user_position
                keyword
                title
            }
        }
    }
}
`;

export const MEDIARESOURCES_recommended_for_coachee = `
query(
    $course_id: ID!,
    $count: Int,
    $page: Int!,
    $search_by: MediaResourcesSearchBy,
    $search_value: String,
    $filter_format: MediaResourcesFormats,
    $filter_language: String,
    $order_by: ListMediaResourcesSortableColumn,
    $direction: SortDirection,
) {
    getMediaResourcesSelectionForCoachee(
        course_id: $course_id,
        count: $count,
        page: $page,
        search_by: $search_by,
        search_value: $search_value,
        filter_format: $filter_format,
        filter_language: $filter_language,
        order_by: $order_by,
        direction: $direction,
    ) {
        status_code
        media_resources {
            id
            title
            description
            link
            language
            format
            created_at
            updated_at
            thematics {
                id
                user_position
                keyword
                title
            }
        }
    }
}
`;
