import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { Button, Grid, Segment } from 'semantic-ui-react';
import { COACHEE_ACTIONS, COURSE_STATUS, Role_ID } from '../../models/enum';
import { ICourse } from '../../models/type';
import { convertMinuteToHour } from '../../utilities/helper';
import styles from './coacheCourseSelectionPage.module.scss';

interface Props {
  course: ICourse;
  openEvaluateCourse: () => void;
}

const CoacheeCourseCard: React.FC<Props> = (props: Props) => {
  /*Methods*/
  const { t: translate } = useTranslation();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const handleCourseButton = (course: ICourse) => {
    dispatch({
      type: COACHEE_ACTIONS.select_course,
      payload: course,
    });
    /*CASE COURSE OPEN and ON BOARDING: */
    if (course.status === COURSE_STATUS.open || course.status === COURSE_STATUS.started_onboarding) {
      //Go to On boarding page with state is this course_id:
      navigate(`/${Role_ID.COACHEE}/on-boarding`, { state: { selectedCourse: course } });
    } else if (course.status === COURSE_STATUS.started_self_diagnosis) {
      navigate(`/${Role_ID.COACHEE}/self-diagnostic`, { state: { selectedCourse: course } });
    } else if (course.status === COURSE_STATUS.started_coach_selection) {
      navigate(`/${Role_ID.COACHEE}/coach-selection`, { state: { selectedCourse: course } });
    } else if (course.status === COURSE_STATUS.started) {
      navigate(`/${Role_ID.COACHEE}/course-management/current/?${course.id}`, { state: { selectedCourse: course } });
    } else {
      //case status close
      navigate(`/${Role_ID.COACHEE}/course-management/closed/?${course.id}`, { state: { selectedCourse: course } });
    }
  };

  const getStatusText = (status: COURSE_STATUS): string => {
    if (status === COURSE_STATUS.open) {
      return translate('Start');
    }

    if (status === COURSE_STATUS.closed || status === COURSE_STATUS.interrupted) {
      return translate('Review');
    }

    if (
      status === COURSE_STATUS.started_onboarding ||
      status === COURSE_STATUS.started_self_diagnosis ||
      status === COURSE_STATUS.started_coach_selection ||
      status === COURSE_STATUS.started
    ) {
      return translate('Continue');
    }

    return '';
  };

  /*Constant*/

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column mobile={16} tablet={16} computer={14} widescreen={11}>
          <Segment className={styles.customedSegment}>
            <Grid>
              <Grid.Row stretched>
                <Grid.Column mobile={16} tablet={5} computer={5} widescreen={5} verticalAlign='middle'>
                  <p className={styles.courseName}>{props.course.offer_configuration.name}</p>
                </Grid.Column>
                <Grid.Column mobile={16} tablet={11} computer={7} widescreen={7}>
                  <table>
                    <tbody>
                      <tr>
                        <td className='topLevelText'>{translate('Number of sessions')}</td>
                        <td>{props.course.offer_configuration.number_of_sessions}</td>
                      </tr>
                      <tr>
                        <td className='topLevelText'>{translate('Session duration')}</td>
                        <td>{convertMinuteToHour(props.course.offer_configuration.duration_of_session, 'h:mm')}</td>
                      </tr>
                    </tbody>
                  </table>
                </Grid.Column>
                <Grid.Column mobile={16} tablet={16} computer={4} widescreen={4} verticalAlign='bottom'>
                  <Button
                    className={`${styles.btn} ${
                      props.course.status === COURSE_STATUS.closed || props.course.status === COURSE_STATUS.interrupted
                        ? styles.disable
                        : styles.active
                    }`}
                    onClick={() => handleCourseButton(props.course)}>
                    <span
                      className={`${styles.btnText} ${
                        props.course.status === COURSE_STATUS.closed && styles.closedBtnText
                      } topLevelText`}>
                      {getStatusText(props.course.status as COURSE_STATUS)}
                    </span>
                  </Button>
                  {props.course.evaluable && (
                    <Button className={styles.btnEvaluate} onClick={props.openEvaluateCourse}>
                      <span className='topLevelText buttonText'>{translate('Evaluate')}</span>
                    </Button>
                  )}
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default React.memo(CoacheeCourseCard);
