import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from 'semantic-ui-react';
import { useQuery } from 'urql';
import { COACH_Actions } from '../../models/enum';
import { IListMediaResourcesResponse } from '../../models/type';
import ResourcesCategoryBlock from '../../pages/CoachMediaResourcesPage/ResourcesCategoryBlock';
import { MEDIARESOURCES_listResources } from '../../services/apis/mediaResourcesApis';
import styles from './coacheeMediaResources.module.scss';
import { filterMediaResourceByTheme } from '../../utilities/helper';

interface Props {
  queryVariables: IListMediaResourcesResponse;
  theme: string[] | null;
}

const CoacheeMediaAll: React.FC<Props> = (props: Props) => {
  /*STATE*/
  const mediaResourcesSelector = useSelector((state: any) => state.coachReducer.mediaResources);

  /*METHODS*/
  const dispatch = useDispatch();

  const [mediaResourceList] = useQuery({
    query: MEDIARESOURCES_listResources,
    variables: props.queryVariables,
    requestPolicy: 'cache-and-network',
  });

  const renderResourcesBlock = () => {
    if (!props.theme) {
      return null;
    }

    const mediaResourceList = filterMediaResourceByTheme(mediaResourcesSelector, props.theme);
    const renderArray = [];

    for (let key in mediaResourceList) {
      renderArray.push(
        <ResourcesCategoryBlock key={key} categoryTitle={key} resourcesArray={(mediaResourceList as any)[key]} />,
      );
    }
    return renderArray;
  };

  /*EFFECTs*/

  useEffect(() => {
    return () => {
      dispatch({
        type: COACH_Actions.clear_media_resources,
      });
    };
  }, [dispatch]);

  useEffect(() => {
    if (!mediaResourceList.data) return;
    dispatch({
      type: COACH_Actions.set_media_resources,
      payload: mediaResourceList.data?.listMediaResources?.media_resources,
    });
  }, [dispatch, mediaResourceList]);

  return (
    <Grid className={styles.customedCoachMediaResourceGrid}>
      <Grid.Row>
        <Grid.Column mobile='16' tablet='16' computer='16' widescreen='16' largeScreen='13'>
          {renderResourcesBlock()}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default CoacheeMediaAll;
