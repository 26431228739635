import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon, Popup } from 'semantic-ui-react';

import ViewCoacheeDiagnosis from '../../pages/CoachInsessionPage/ViewCoacheeDiagnosis';
import styles from './previewDiagnosisResultModalIcon.module.scss';

interface Props {
  courseID: string;
}

const PreviewDiagnosisResultModalIcon: React.FC<Props> = (props: Props) => {
  const [state, setState] = useState<boolean>(false);

  const { t: translate } = useTranslation();

  const handleClick = (): void => {
    setState(true);
  };

  return (
    <>
      <Popup
        content={translate('Coachee diagnosis')}
        trigger={
          <Icon
            className={`${styles.previewSelfDiagnosis} cursor-pointer`}
            name='clipboard list'
            size='large'
            onClick={handleClick}
          />
        }
      />
      {state && <ViewCoacheeDiagnosis courseID={props.courseID} cancel={() => setState(false)} />}
    </>
  );
};

export default PreviewDiagnosisResultModalIcon;
