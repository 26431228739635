import React from 'react';
import ResetPassword from '../../components/ActivateAndResetPasswordComp/ActivateAndResetPasswordComp';
import LoginLayout from '../../layouts/LoginLayout/LoginLayout';

const ActivateAndResetPasswordPage: React.FC = () => {
  return (
    <div>
      <LoginLayout logoPosition='middle' background='white' formPostion='center' makeFormTransparent={false}>
        <ResetPassword />
      </LoginLayout>
    </div>
  );
};

export default ActivateAndResetPasswordPage;
