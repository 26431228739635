import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Icon } from 'semantic-ui-react';
import AlertModal from '../Common/AlertModal/AlertModal';
import styles from './editableProjectDirector.module.scss';
import { IUserInfos } from '../../models/type';
import { useMutation } from 'urql';
import { PROJECTDIRECTOR_update_detail } from '../../services/apis/projectDirectorApis';
import { useDispatch } from 'react-redux';
import { getUserRoleID } from '../../utilities/helper';
import { FormMessages, STATUS_CODE, UserRoles, USER_ACTIONS } from '../../models/enum';
import { token_user } from '../../config/tokenConstant';

const EditableProjectDirector = ({ projectDirector }: { projectDirector: IUserInfos }) => {
  /*STATE*/
  const [formState, setFormState] = useState({
    email: '',
    last_name: '',
    first_name: '',
    department: '',
    position: '',
    hasEdited: false,
  });

  const [helperState, setHelperState] = useState({
    isEditing: false,
    isModalShowed: false,
    message: '',
    backUpForm: null,
  });
  /*METHODS*/
  const { t: translate } = useTranslation();

  const [, executeUpdateProjectDirector] = useMutation(PROJECTDIRECTOR_update_detail);

  const dispatch = useDispatch();

  const handleOnChange = (e: React.FormEvent<HTMLInputElement>) => {
    setFormState({
      ...formState,
      [e.currentTarget.name]: e.currentTarget.value,
      hasEdited: true,
    });
  };

  const handleAgreeCancelEditing = useCallback(() => {
    setFormState(helperState.backUpForm);
    setHelperState({ ...helperState, isModalShowed: false, isEditing: false });
  }, [helperState]);

  const handleSubmit = () => {
    executeUpdateProjectDirector({
      id: getUserRoleID(UserRoles.PROJECT_DIRECTOR),
      email: formState.email,
      last_name: formState.last_name,
      first_name: formState.first_name,
      department: formState.department,
      position: formState.position,
    })
      .then((res) => {
        if (+res.data.updateProjectDirector.status_code === +STATUS_CODE.SUCCESS) {
          setHelperState({
            ...helperState,
            isEditing: false,
            message: FormMessages.Success,
          });
          setFormState({ ...formState, hasEdited: false });
          const tempUser = {
            ...JSON.parse(localStorage.getItem(token_user)),
            first_name: formState.first_name,
            last_name: formState.last_name,
            email: formState.email,
          };
          localStorage.setItem(token_user, JSON.stringify(tempUser));
          dispatch({
            type: USER_ACTIONS.set_user_name,
            payload: {
              first_name: formState.first_name,
              last_name: formState.last_name,
            },
          });
        } else {
          console.error(res.error);
          setHelperState({
            ...helperState,
            isEditing: false,
            message: FormMessages.Failed,
          });
        }
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    setFormState({
      ...formState,
      email: projectDirector.email,
      last_name: projectDirector.last_name,
      first_name: projectDirector.first_name,
      department: projectDirector.department,
      position: projectDirector.position,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.formWrapper}>
      <form>
        {!helperState.isEditing && (
          <Button
            className={`${styles.editBtn}`}
            onClick={() =>
              setHelperState({
                ...helperState,
                isEditing: true,
                message: '',
                backUpForm: formState,
              })
            }>
            <Icon name='pencil alternate' />
            <span className={`topLevelText buttonText`}>{translate('Edit')}</span>
          </Button>
        )}
        <p className={helperState.message === FormMessages.Success ? 'textInfoFormMessage' : 'textDangerFormMessage'}>
          {translate(helperState.message)}
        </p>
        <div className={styles.formRow}>
          <div className={styles.formField}>
            <div className={styles.labelWrapper}>
              <label className={styles.label}>
                {translate('First name')}
                {helperState.isEditing && <sup className={styles.sup}>*</sup>}
              </label>
            </div>
            <input
              className={`${styles.inputField} ${styles.widthFirstName}`}
              name='first_name'
              type='text'
              placeholder='Prénom'
              readOnly={!helperState.isEditing}
              value={formState.first_name}
              onChange={handleOnChange}
            />
          </div>
          <div className={`${styles.formField} ${styles.positioningInput} `}>
            <div className={`${styles.labelWrapper} `}>
              <label className={styles.label}>
                {translate('Last name')}
                {helperState.isEditing && <sup className={styles.sup}>*</sup>}
              </label>
            </div>
            <input
              className={`${styles.inputField}`}
              name='last_name'
              type='text'
              placeholder='Nom de famille'
              readOnly={!helperState.isEditing}
              value={formState.last_name}
              onChange={handleOnChange}
            />
          </div>
        </div>
        <div className={styles.formField}>
          <div className={styles.labelWrapper}>
            <label className={styles.label}>
              {translate('Email')}
              {helperState.isEditing && <sup className={styles.sup}>*</sup>}
            </label>
          </div>
          <input
            className={`${styles.inputField} `}
            name='email'
            type='email'
            placeholder='email@email.com'
            readOnly={!helperState.isEditing}
            value={formState.email}
            onChange={handleOnChange}
          />
        </div>
        <div className={styles.formField}>
          <div className={styles.labelWrapper}>
            <label className={styles.label}>Service</label>
          </div>
          <input
            className={`${styles.inputField} `}
            name='department'
            type='text'
            placeholder='HR'
            readOnly={!helperState.isEditing}
            value={formState.department}
            onChange={handleOnChange}
          />
        </div>

        <div className={styles.formField}>
          <div className={styles.labelWrapper}>
            <label className={styles.label}>{translate('Position')}</label>
          </div>
          <input
            className={`${styles.inputField} `}
            name='position'
            type='text'
            placeholder={translate('Position')}
            readOnly={!helperState.isEditing}
            value={formState.position}
            onChange={handleOnChange}
          />
        </div>
      </form>

      <div className={styles.rightGroup}>
        {helperState.isEditing && (
          <>
            {' '}
            <Button
              className={`${styles.btnSave} ${
                formState.first_name && formState.last_name && formState.email && styles.active
              }`}
              disabled={!formState.first_name || !formState.last_name || !formState.email}
              onClick={handleSubmit}>
              <span className={`buttonText topLevelText`}>{translate('Save')}</span>
            </Button>
            <Button
              className={`${styles.btnCancel} `}
              onClick={() => {
                if (formState.hasEdited) setHelperState({ ...helperState, isModalShowed: true });
                else setHelperState({ ...helperState, isEditing: false });
              }}>
              <span className={`topLevelText buttonText`}>{translate('Cancel')}</span>
            </Button>
          </>
        )}
      </div>

      {helperState.isModalShowed && (
        <AlertModal
          title={translate('Confirm cancel')}
          contentQuestion={translate('Do you want to undo?')}
          content={translate('You will be return to previous page with unsaved data')}
          cancel={() => setHelperState({ ...helperState, isModalShowed: false })}
          agree={() => handleAgreeCancelEditing()}
        />
      )}
    </div>
  );
};

export default EditableProjectDirector;
