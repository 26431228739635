import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './evaluationBar.module.scss';

interface Props {
  setGrade?: (grade: number) => void;
  grade?: number;
}

const EvaluationBar: React.FC<Props> = (props: Props) => {
  /*PROPS*/
  const maxEvaluationValue = useMemo(() => {
    return 10;
  }, []);

  /*METHODS*/

  const { t: translate } = useTranslation();

  const handleOnChangeEvaluate = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      props.setGrade(+e.currentTarget.value);
    },
    [props],
  );

  return (
    <div className={styles.evaluateBlock}>
      <span className={styles.evaluationText}>{translate('Evaluation')}{' : '}</span>
      <span className={styles.evaluationNumber}>{!!props.grade && props.grade}</span>
      <div className={styles.evaluateComp}>
        <input
          type='range'
          name='evaluation'
          min={0}
          max={maxEvaluationValue}
          className={styles.rangeInput}
          value={props.grade}
          onChange={handleOnChangeEvaluate}
        />
        <div className={styles.evaluateUnits}>
          {[...new Array(maxEvaluationValue + 1)].map((item, index) => {
            return <span key={`evaluationUnitKey${index}`}>{index}</span>;
          })}
        </div>
      </div>
    </div>
  );
};

export default React.memo(EvaluationBar);
