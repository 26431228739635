import React from 'react';
import { useSelector } from 'react-redux';
import { Checkbox } from 'semantic-ui-react';
import { ICoachee_extended, ICourse, ICourseRef } from '../../models/type';
import styles from './listCoacheeModalOptions.module.scss';
import { useTranslation } from 'react-i18next';
import { COURSE_STATUS } from '../../models/enum';

interface Props {
  handleOnChangeCheckbox: (value: any) => void;
  selectedCourses: string[];
}

const AssignMultiFollowUpCourseContent: React.FC<Props> = (props: Props) => {
  const selectedCoacheeSelector: ICoachee_extended = useSelector(
    (state: any) => state.programManagerReducer.listOfCoacheePage.selectedCoacheeList[0],
  );

  const coursesSelector: ICourse[] = useSelector((state: any) => state.programManagerReducer.program.courses);

  const { t: translate } = useTranslation();

  const renderCheckbox = () => {
    return selectedCoacheeSelector.courses.map((item: ICourseRef) => {
      const course = coursesSelector.find((course) => course.id === item.id);
      const { coach } = course;
      const coachFullname = !!coach ? coach.first_name + ' ' + coach.last_name : translate('No coach');
      const courseNotStarted = item.status !== COURSE_STATUS.started;

      return (
        <div key={item.id} className={`${styles.courseCheckboxItem} ${courseNotStarted && styles.disabledCheckbox}`}>
          <Checkbox
            className={styles.checkbox}
            value={item.id}
            onChange={(e, { value }) => props.handleOnChangeCheckbox(value)}
            checked={props.selectedCourses.includes(item.id)}
            disabled={courseNotStarted}
          />
          <span className={styles.courseCheckboxName}>{item.offer_configuration.name}. </span>
          {courseNotStarted ? (
            <span className={styles.courseAdditionalText}>({translate('Course has not been started')})</span>
          ) : (
            <span className={styles.courseAdditionalText}>{translate('Coach: ') + coachFullname}</span>
          )}
        </div>
      );
    });
  };

  return (
    <div className={styles.unAssignMultiWrapper}>
      <p className={styles.content}>
        {translate('Which course do you want to assign follow up course for coachee')}{' '}
        <span className={styles.textHiglightInfo}>
          {selectedCoacheeSelector.user_infos.first_name + ' ' + selectedCoacheeSelector.user_infos.last_name}
        </span>
        {' ?'}
      </p>
      <div className={styles.multiCourseCheckboxBlock}>{renderCheckbox()}</div>
    </div>
  );
};
export default AssignMultiFollowUpCourseContent;
