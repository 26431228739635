export const AUTHENTICATION_RGPDConsent = `
mutation ($user_id : ID! ) {
    updateGDPRConsent (
        user_id: $user_id,
        gdpr_consent: true
    ) {
        status_code
    }
}
`;
