export const COURSE_fetch_diagnosis = `
query ($course_id : ID!){
    getCourseSelfDiagnosis(
        course_id: $course_id
    ) {
        status_code
        self_diagnosis {
            id
            self_diagnosis_template {
                id
                question_collections {
                    id
                    collection_type
                    position
                    from_answers
                    title
                    questions {
                        id
                        title
                        question_type
                        min_answers
                        max_answers
                        position
                        answers {
                            id
                            text
                            value
                        }
                    }
                }
            }
            coachee_answers {
                id
                question {
                    id
                    title
                    question_type
                    min_answers
                    max_answers
                    position
                }
                answers {
                    id
                    text
                    value
                }
            }
        }
    }
}
`;

export const COURSE_get_coachs = `
query ($course_id : ID!){
    getCoachSelection(
        course_id: $course_id
    ) {
        status_code
        coaches {
            id
            picture
            presentation
            quote
            additional_languages
            certifications
            user_infos {
                first_name
                last_name
                languages
            }
        }
    }
}
`;

export const COURSE_get_diagnosis_synthesis = `
query($course_id: ID!) {
    getSelfDiagnosisSynthesis(
        course_id: $course_id
    ) {
        status_code
        self_diagnosis {
            id
            self_diagnosis_template {
                question_collections {
                    id
                    collection_type
                    position
                    questions {
                        id
                        title
                        question_type
                        position
                    }
                    title
                }
            }
            coachee_answers {
                id
                question {
                    id
                    title
                    question_type
                    min_answers
                    max_answers
                    position
                }
                answers {
                    id
                    text
                    value
                }
            }
            self_diagnosis_result {
                keywords,
                colors {
                    red,
                    yellow,
                    green,
                    blue,
                }
            }
        }
    }
}
`;

export const COURSE_get_course = `
query($course_id: ID!) {
    getCourse(
        id: $course_id
    ) {
        status_code
        course {
            id
            created_at
            started_at
            closed_at
            status
            evaluable
            offer_configuration {
                id
                name
                offer_id
                number_of_sessions
                duration_of_session
                has_objectives
                has_self_diagnosis
                number_of_courses
            }
            evaluation
            objectives {
                id
                description
                key_indicators
                evaluations {
                    id
                    grade
                    session_id
                    session_current_index
                }
            }
            client {
                name
            }
            program {
                name
            }
            coachee {
                first_name
                last_name
            }
            coach {
                first_name
                last_name
            }
            number_of_sessions_done
            sessions {
                id
                status
                planned_at
                finished_at
                duration
                note
                evaluation
                evaluation_comment
                is_first
                is_last
                current_index
                coach {
                    first_name
                    last_name
                    token
                }
                coachee {
                    first_name
                    last_name
                }
                videoconference_link
                calendly_event_uri
                attendees
                is_offline
            }
        }
    }
}
`;

export const COURSE_list_courses = `
query($coach_id: ID!, $count : Int, $page : Int!, $order_by : ListMyCoursesOrderBy, $direction : SortDirection, $search_by :  ListMyCourseSearchBy, $search_value : String) {
    listMyCourses(
        count: $count,
        page: $page,
        coach_id: $coach_id,
        order_by: $order_by,
        direction: $direction,
        search_by : $search_by,
        search_value: $search_value
    ) {
        status_code
        courses {
            id
            created_at
            started_at
            closed_at
            last_updated_at
            status
            offer_configuration {
                id
                name
                offer_id
                number_of_sessions
                duration_of_session
                has_objectives
                has_self_diagnosis
                number_of_courses
                number_of_started
                number_of_unassigned
                number_of_closed
                number_of_program_managers
                number_of_coaches
                number_of_coachees
            }
            evaluation
            coachee {
                first_name
                last_name
            }
            coach {
                first_name
                last_name
            }
            client {
                name
            }
            number_of_sessions_done
            number_of_sessions
            anomaly
        }
    }
}
`;

/*MUTATION*/
export const COURSE_answer_self_diagnostic = `
mutation($diagnosis_id: ID!, $coacheeAnswers: [CoacheeAnswerInput!]!) {
    answerSelfDiagnosis(
        id: $diagnosis_id,
        coachee_answers: $coacheeAnswers
    ) {
        status_code
    }
}
`;

export const COURSE_finish_diagnosis = `
mutation($diagnosis_id: ID!, $coacheeAnswers: [CoacheeAnswerInput!]!) {
    finishSelfDiagnosis(
        id: $diagnosis_id,
        coachee_answers: $coacheeAnswers
    ) {
        status_code
        self_diagnosis_result {
            keywords,
            colors {
                red,
                yellow,
                green,
                blue,
            }
        }
    }
}
`;

export const COURSE_start_course = `
mutation($course_id : ID!) {
    startCourse(
        id: $course_id
    ) {
        status_code
        course {
            id
            status
        }
    }
}
`;

export const COURSE_course_on_boarded = `
mutation ($course_id : ID!){
    courseOnboarded(
        id: $course_id
    ) {
        status_code
        course {
            id
            status
        }
    }
}
`;

export const COURSE_select_coach = `
mutation($course_id: ID!, $coach_id: ID!) {
    selectCourseCoach(
        course_id: $course_id
        coach_id: $coach_id
    ) {
        status_code
        course {
            id
            status
        }
    }
}
`;

export const COURSE_save_objectives = `
mutation($session_id: ID!, $objectives: [ObjectiveInput!]!) {
    saveObjectives(
        session_id: $session_id,
        objectives: $objectives
    ) {
        status_code
        objectives {
            id
            description
            key_indicators
            evaluations {
                id
                grade
                session_id
                session_current_index
            }
        }
    }
}
`;

export const COURSE_reevaluate_objectives = `
mutation($session_id: ID!, $evaluations: [ObjectiveEvaluationInput!]!) {
    saveObjectivesProgress(
        session_id: $session_id,
        evaluations: $evaluations
    ) {
        status_code
        objectives {
            id
            description
            key_indicators
            evaluations {
                id
                grade
                session_id
                session_current_index 
            }
        }
    }
}

`;

export const COURSE_evaluate_course = `
mutation(
    $course_id: ID!,
    $operational_performance: Int!,
    $work_life_quality: Int!,
    $professional_relationships: Int!,
    $leadership: Int!,
    $personal_organization: Int!,
    $comment: String
    ) {
    evaluateCourse(
        course_id: $course_id,
        operational_performance: $operational_performance,
        work_life_quality: $work_life_quality,
        professional_relationships: $professional_relationships,
        leadership: $leadership,
        personal_organization: $personal_organization,
        comment: $comment
    ) {
        status_code
    }
}
`