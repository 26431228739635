import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Grid } from 'semantic-ui-react';
import { useMutation, useQuery } from 'urql';
import {
  COACHEE_ACTIONS,
  MaterialIconName,
  SESSION_ACTIONS,
  SESSION_CONSTANT,
  SESSION_STATUS,
  STATUS_CODE,
} from '../../models/enum';
import { SESSION_declare_absent, SESSION_get_status, SESSION_save_note } from '../../services/apis/sessionApis';
import { checkExcessStartTimeByAGivenMinute } from '../../utilities/helper';
import NoteReviewComp from '../NoteReviewComp/NoteReviewComp';
import styles from './insessionComp.module.scss';
import moment from 'moment';
import CoacheeSetObjectiveComp from '../CoacheeSetObjectiveComp/CoacheeSetObjectiveComp';
import RateSessionComp from '../RateSessionComp/RateSessionComp';
import AlertModal from '../Common/AlertModal/AlertModal';
import { IObjective, IObjectiveEvaluation } from '../../models/type';
import EditVideoConferenceLinkModal from '../Common/EditVideoConeferenceLinkModal/EditVideoConferenceLinkModal';

const InsessionComp = ({ queryGetCourse }: { queryGetCourse: () => void }) => {
  const [state, setState] = useState({
    saveNoteActive: false,
    showAbsentButton: false,
    showSetObjective: false,
    showRatingSession: false,
    showAlertAbsent: false,
    needTosetObjective: false,
    showEditVideoLink: false,
    showNeedToEvaluateBeforeFinishSession: false,
    message: ' ',
  });

  const intervalID: any = useRef();
  const courseStore = useSelector((state: any) => state.coacheeReducer.myCourseManagement);
  const [sessionStatusResult, querySessionStatus] = useQuery({
    query: SESSION_get_status,
    variables: { session_id: courseStore.myCurrentSession?.id },
    pause: true,
  });

  /*METHODS*/
  const { t: translate } = useTranslation();
  const dispatch = useDispatch();
  const [, executeDeclareAbsent] = useMutation(SESSION_declare_absent);
  const [, executeSaveNote] = useMutation(SESSION_save_note);

  const handleDeclareAbsent = (sessionID: string) => {
    executeDeclareAbsent({ session_id: sessionID })
      .then((res) => {
        setState({ ...state, showAlertAbsent: false });
        queryGetCourse();
      })
      .catch((err) => console.error(err));
  };

  const handleOnChange = (e: React.FormEvent<HTMLTextAreaElement>) => {
    setState({ ...state, saveNoteActive: true, message: '' });
    dispatch({ type: SESSION_ACTIONS.write_note, payload: e.currentTarget.value });
  };

  const handleSaveNote = (sessionID: string, note: string) => {
    executeSaveNote({ session_id: sessionID, note: note })
      .then((res) => {
        if (res.data.saveSessionNote.status_code === +STATUS_CODE.SUCCESS) {
          setState({ ...state, saveNoteActive: false, message: translate('Your note saved') });
        } else console.error(res.error);
      })
      .catch((err) => console.error(err));
  };

  const checkSessionEvaluated = (currentSessionID: string): boolean => {
    let result: boolean = false;
    courseStore.myCourse.objectives.forEach((item: IObjective) => {
      const indexFound = item.evaluations.findIndex((evaluationItem: IObjectiveEvaluation) => {
        return evaluationItem.session_id === currentSessionID;
      });
      if (indexFound !== -1) {
        result = true;
        return;
      }
    });
    return result;
  };

  const handleUpdateVideoConferenceLink = (videoConfLink: string) => {
    dispatch({
      type: COACHEE_ACTIONS.update_video_conference_link,
      payload: { videoConfLink: videoConfLink, sessionID: courseStore.myCurrentSession?.id },
    });
  };

  const handleFinishSessionButton = () => {
    const isLastSession = !courseStore.myUpComingSession?.length;
    if (isLastSession && courseStore.myCourse?.offer_configuration.has_objectives) {
      if (!checkSessionEvaluated(courseStore.myCurrentSession.id)) {
        setState({ ...state, showNeedToEvaluateBeforeFinishSession: true });
        return;
      }
    }
    setState({ ...state, showRatingSession: true });
  };

  /*CONST*/

  // Show set objective when course has objective, objectives array empty and session started
  const isNeededToshowSetObjective: boolean =
    courseStore.myCourse?.offer_configuration.has_objectives &&
    !courseStore.myCourse?.objectives?.length &&
    courseStore.myCurrentSession.status === SESSION_STATUS.started;

  //When do not need set objective and status started => can show finish button
  const canShowFinishButton: boolean =
    !isNeededToshowSetObjective && courseStore.myCurrentSession.status === SESSION_STATUS.started;

  //When objectives set and not in first session:
  const canShowEvaluationProgress: boolean =
    !isNeededToshowSetObjective &&
    courseStore.myCourse?.offer_configuration.has_objectives &&
    courseStore.myCurrentSession.status === SESSION_STATUS.started &&
    !checkSessionEvaluated(courseStore.myCurrentSession.id);

  //Is last session : when coming sessions array length = 0
  const isLastSession: boolean = !courseStore?.myUpComingSession?.length;
  /* EFFECT for re-query session status every 3mins
   */

  useEffect(() => {
    //Should show absent button:
    if (
      checkExcessStartTimeByAGivenMinute(
        courseStore.myCurrentSession.planned_at,
        SESSION_CONSTANT.absent_buffer_time,
      ) &&
      courseStore.myCurrentSession.status === SESSION_STATUS.started_waiting
    ) {
      setState({ ...state, showAbsentButton: true });
    }

    //Interver for get session status
    intervalID.current = setInterval(() => {
      if (sessionStatusResult.data?.getSessionStatus.session.status === SESSION_STATUS.started) return;
      querySessionStatus();
    }, SESSION_CONSTANT.requery_time);
    return () => clearInterval(intervalID.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * EFFECT after re-query session => check if:
   *  - Passed 15mins yet
   *  - Both are join yet
   */
  useEffect(() => {
    //First time component mounted, no data return
    if (!sessionStatusResult.data) return;

    //If status return by API is started then update status on redux:
    if (sessionStatusResult.data.getSessionStatus.session.status === SESSION_STATUS.started) {
      setState({ ...state, showAbsentButton: false });
      dispatch({
        type: SESSION_ACTIONS.update_status,
        payload: sessionStatusResult.data.getSessionStatus.session,
      });
      clearInterval(intervalID.current);
      return;
    }

    //Passed 15min && status == started_waiting => show Absent button
    if (
      checkExcessStartTimeByAGivenMinute(
        courseStore.myCurrentSession.planned_at,
        SESSION_CONSTANT.absent_buffer_time,
      ) &&
      sessionStatusResult.data?.getSessionStatus.session.status === SESSION_STATUS.started_waiting
    ) {
      setState({ ...state, showAbsentButton: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionStatusResult]);

  if (!courseStore.myCurrentSession) return <></>;

  return (
    <div>
      <div className={styles.plannedHeader}>
        <div className={styles.headerCoachAndNumberSession}>
          <span className={`${styles.boldSession} topLevelText`}>
            {translate('Session') +
              ' ' +
              `${courseStore.myCurrentSession.current_index}/${courseStore.myCourse?.sessions?.length}`}
          </span>
          <span className={styles.text1}>
            {`${translate('Coach')} : `}
            <span>
              {`${courseStore.myCurrentSession.coach.first_name + ' ' + courseStore.myCurrentSession.coach.last_name}`}
            </span>
          </span>
        </div>
        {canShowEvaluationProgress ? (
          <Button
            className={styles.reEvaluateBtn}
            onClick={() =>
              setState({
                ...state,
                showSetObjective: true,
                needTosetObjective: false,
              })
            }>
            <span className='.secondary_button_text topLevelText'>{translate('Evaluate my progress')}</span>
          </Button>
        ) : (
          <div>
            <span className={styles.text2}>
              {translate('Date')}
              <span>{moment(courseStore.myCurrentSession.planned_at).format('DD/MM/YYYY')}</span>
            </span>
            <span className={styles.text2}>
              {translate('Hour')}
              <span>{moment(courseStore.myCurrentSession.planned_at).format('HH:mm')}</span>
            </span>
          </div>
        )}
      </div>

      <div className={styles.mainContentBlock}>
        <Grid>
          <Grid.Row>
            <Grid.Column mobile='16' tablet='16' computer='16' widescreen='12' largeScreen='10'>
              <div className={styles.meetingLinkBlock}>
                <div className={styles.topMeetingBlock}>
                  <span className={`${styles.videoIcon} material-icons-outlined`}>{MaterialIconName.video_call}</span>
                  <span className={styles.linkText} onClick={() => setState({ ...state, showEditVideoLink: true })}>
                    {courseStore?.myCurrentSession?.videoconference_link
                      ? translate('Edit meeting link')
                      : translate('Insert new video conference link')}
                  </span>
                </div>
                <a href={courseStore.myCurrentSession.videoconference_link} target='_blank' rel='noreferrer'>
                  <Button className={styles.joinBtn}>
                    <span className={`buttonText topLevelText`}>{translate('Join video conference')}</span>
                  </Button>
                </a>
              </div>
              <p className={styles.text3Alt}>{translate('Your note frame')}</p>
              <p className={styles.textMessage}>{state.message}</p>
              <div className={styles.noteFrame}>
                <textarea
                  className={styles.noteArea}
                  name='note'
                  onChange={handleOnChange}
                  value={courseStore.myCurrentSession.note}
                />
                <div className={styles.noteBtnBlock}>
                  <Button
                    className={styles.saveNoteBtn}
                    disabled={!state.saveNoteActive}
                    onClick={() => handleSaveNote(courseStore.myCurrentSession.id, courseStore.myCurrentSession.note)}>
                    <span className='buttonText topLevelText'>{translate('Save')}</span>
                  </Button>
                </div>
              </div>
              <div className={styles.endSessionBtnBlock}>
                {isNeededToshowSetObjective && (
                  <Button
                    className={styles.setObjectiveBtn}
                    onClick={() =>
                      setState({
                        ...state,
                        showSetObjective: true,
                        needTosetObjective: true,
                      })
                    }>
                    <span className='buttonText topLevelText'>{translate('Set my objectives')}</span>
                  </Button>
                )}
                {canShowFinishButton && (
                  <Button className={styles.endSessionBtn} onClick={handleFinishSessionButton}>
                    <span className='buttonText topLevelText'>{translate('End session')}</span>
                  </Button>
                )}
                {state.showAbsentButton && (
                  <Button
                    className={styles.coachAbsentBtn}
                    onClick={() => setState({ ...state, showAlertAbsent: true })}>
                    <span className='buttonText topLevelText'>{translate('Declare coach absent')}</span>
                  </Button>
                )}
              </div>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column mobile='16' tablet='16' computer='16' widescreen='12' largeScreen='10'>
              {courseStore.myCurrentSession.current_index !== 1 && (
                <NoteReviewComp
                  session={
                    !!courseStore.myDoneSessions?.length &&
                    courseStore.myDoneSessions[courseStore.myDoneSessions?.length - 1]
                  }
                />
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
      {state.showSetObjective && (
        <CoacheeSetObjectiveComp
          closeSetObjective={() => setState({ ...state, showSetObjective: false })}
          needToSetObjective={isNeededToshowSetObjective}
        />
      )}
      {state.showRatingSession && (
        <RateSessionComp
          session={courseStore.myCurrentSession}
          finishSession={true}
          isLastSession={isLastSession}
          closeRateSession={() => setState({ ...state, showRatingSession: false })}
          queryGetCourse={queryGetCourse}
        />
      )}
      {state.showAlertAbsent && (
        <AlertModal
          title={translate('Declare absent')}
          contentQuestion={translate('Are you sure to declare your coach absent?')}
          content={translate('Click yes to declare coach absent and session will be consider done')}
          cancel={() => setState({ ...state, showAlertAbsent: false })}
          agree={() => handleDeclareAbsent(courseStore.myCurrentSession.id)}
        />
      )}
      {state.showNeedToEvaluateBeforeFinishSession && (
        <AlertModal
          title={translate('Must evaluate your progress')}
          contentQuestion={translate('Last session must be evaluated progress')}
          content={translate('Please evaluate your progress before finish this session')}
          cancel={() => setState({ ...state, showNeedToEvaluateBeforeFinishSession: false })}
          agree={() => {}}
          hideAgreeButton={true}
        />
      )}
      {state.showEditVideoLink && (
        <EditVideoConferenceLinkModal
          session={courseStore.myCurrentSession}
          cancel={() => setState({ ...state, showEditVideoLink: false })}
          onSuccess={(videoConfLink) => handleUpdateVideoConferenceLink(videoConfLink)}
        />
      )}
    </div>
  );
};

export default React.memo(InsessionComp);
