import React, { useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown, DropdownProps, Form } from 'semantic-ui-react';

import { COACHEE_ACTIONS } from '../../models/enum';
import { MONTH_LABEL, YEAR_LABEL, monthLastAnswerIndex } from '../../config/helperConstant';
import { ICoacheeAnswerInput, IDiagnosisAnswer, IDiagnosisQuestion } from '../../models/type';
import { createDropDownMenu, createMonthDropDownSelfDiagnostisOptions } from '../../utilities/helper';

import styles from './questionStep.module.scss';

interface Props {
  dateQuestion: IDiagnosisQuestion;
}

const DateQuestionStep: React.FC<Props> = (props: Props) => {
  /*STATE*/
  const answerRef = useRef<any[]>();

  const coacheeAnswers: Array<ICoacheeAnswerInput> = useSelector((state: any) => {
    return state.coacheeReducer.selfDiagnostic.coacheeAnswerDiagnosisArr;
  });

  /*Const*/
  const months: Array<IDiagnosisAnswer> = props.dateQuestion.answers.slice(0, monthLastAnswerIndex);
  const years: Array<IDiagnosisAnswer> = props.dateQuestion.answers.slice(12, props.dateQuestion.answers.length);
  /*METHODS*/
  const { t: translate } = useTranslation();

  const dispatch = useDispatch();

  const handleOnChange = (e: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps, name: string) => {
    if (name === MONTH_LABEL) answerRef.current[0] = data.value;
    if (name === YEAR_LABEL) answerRef.current[1] = data.value;
    dispatch({
      type: COACHEE_ACTIONS.choose_self_diagnosis_answer,
      payload: {
        max_answers: props.dateQuestion.max_answers,
        min_answers: props.dateQuestion.min_answers,
        question_type: props.dateQuestion.question_type,
        answerID: answerRef.current,
        questionID: props.dateQuestion.id,
      },
    });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getValueFromAnswers = (questionID: string, name: string) => {
    const indexFound = coacheeAnswers.findIndex((item) => {
      return item.question_id === questionID;
    });
    if (indexFound === -1) return '';
    if (name === MONTH_LABEL) {
      return coacheeAnswers[indexFound].answers[0];
    }
    if (name === YEAR_LABEL) {
      return coacheeAnswers[indexFound].answers[1];
    }
  };

  const listMonth = useMemo(() => {
    const selectedYearByUserValue: string = years.find(
      (item) => item.id === getValueFromAnswers(props.dateQuestion.id, YEAR_LABEL),
    )?.value;
    return createMonthDropDownSelfDiagnostisOptions(months, translate, selectedYearByUserValue);
  }, [getValueFromAnswers, months, props.dateQuestion.id, translate, years]);

  const listYear = useMemo(() => {
    const selectedMonthByUserValue: string = months.find(
      (item) => item.id === getValueFromAnswers(props.dateQuestion.id, MONTH_LABEL),
    )?.value;
    return createDropDownMenu(years, selectedMonthByUserValue);
  }, [getValueFromAnswers, months, props.dateQuestion.id, years]);

  useEffect(() => {
    answerRef.current = [
      getValueFromAnswers(props.dateQuestion.id, MONTH_LABEL),
      getValueFromAnswers(props.dateQuestion.id, YEAR_LABEL),
    ];
  }, [coacheeAnswers, getValueFromAnswers, props.dateQuestion.id]);

  return (
    <div className={styles.questionWrapper}>
      <Form.Field className={styles.customedField}>
        <span className={styles.questionTitle}>{props.dateQuestion.title}</span>
        <div className={styles.dropDownBlock}>
          <div className={styles.dropDownItem}>
            <Dropdown
              className={styles.customedDropDown}
              placeholder={translate('Month')}
              search
              fluid
              scrolling
              options={listMonth}
              value={getValueFromAnswers(props.dateQuestion.id, MONTH_LABEL)}
              onChange={(e, data) => handleOnChange(e, data, MONTH_LABEL)}
            />
          </div>
          <span className={styles.flash}>/</span>
          <div className={styles.dropDownItem}>
            <Dropdown
              className={styles.customedDropDown}
              placeholder={translate('Year')}
              search
              fluid
              scrolling
              options={listYear}
              value={getValueFromAnswers(props.dateQuestion.id, YEAR_LABEL)}
              onChange={(e, data) => handleOnChange(e, data, YEAR_LABEL)}
            />
          </div>
        </div>
      </Form.Field>
    </div>
  );
};

export default DateQuestionStep;
