export const MESSAGE_get_notify = `
query {
    getMessagesNotification {
        status_code
        number_of_unread_messages
    }
}
`;

export const MESSAGE_get_conversations = `
query {
    getMyConversations {
        status_code
        conversations {
            id
            created_at
            has_unread_messages
            conversers {
                id
                first_name
                last_name
                department
                position
                client {
                    id
                    name
                }
            }
        }
    }
}
`;

export const MESSAGE_get_conversation_content = `
mutation($conversation_id: ID!) {
    getConversation(
        id: $conversation_id,
        mark_as_read: true
    ) {
        status_code
        conversation {
            id
            created_at
            conversers {
                id
                first_name
                last_name
                department
                position
                client {
                    id
                    name
                }
            }
            messages {
                id
                sender
                title
                content
                created_at
                sent_at
                received_at
                read_at
                is_private
            }
        }
    }
}
`;

export const MESSAGE_send = `
mutation($conversation_id: ID!, $receiver_id : ID, $content : String!, $title : String) {
    sendMessage(
        conversation_id: $conversation_id,
        receiver_id: $receiver_id,
        content: $content
        title: $title
    ) {
        status_code
    }
}
`;
