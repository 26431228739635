import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Button, Icon } from 'semantic-ui-react';
import { IObjective } from '../../models/type';
import CoacheeObjective from '../CoacheeObjective/CoacheeObjective';
import styles from './coacheeObjectiveModal.module.scss';

interface Props {
  cancel: () => void;
}

const CoacheeObjectiveModal: React.FC<Props> = (props: Props) => {
  /*STATE*/
  const objectivesSelector: Array<IObjective> = useSelector(
    (state: any) => state.coachReducer.myCourses.selectedSession?.course_context?.objectives,
  );
  /*Methods*/

  const { t: translate } = useTranslation();

  return (
    <div className={styles.modal}>
      <div className={styles.wrapper}>
        <h1 className={`${styles.title} topLevelText`}>
          {translate('Objectives of coachee')}
          <Icon name='cancel' className={styles.closeIcon} onClick={props.cancel} />
        </h1>

        <div className={styles.contentWrapper}>
          <CoacheeObjective objectives={objectivesSelector} />
          <div className={styles.buttonsWrapper}>
            <Button className={`${styles.closeBtn} `} onClick={props.cancel}>
              <span className='topLevelText buttonText'>{translate('Close')}</span>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoacheeObjectiveModal;
