import { useMemo, useState, memo } from 'react';
import { EventScheduledEvent, PopupModal, useCalendlyEventListener } from 'react-calendly';
import styles from './calendlyComp.module.scss';
import { useTranslation } from 'react-i18next';
import { Button } from 'semantic-ui-react';
import Axios from 'axios';
import { useMutation } from 'urql';
import { SESSION_plan } from '../../services/apis/sessionApis';
import moment from 'moment';
import { STATUS_CODE } from '../../models/enum';
import { token_user } from '../../config/tokenConstant';
import { calendlyAPIURL } from '../../config/helperConstant';

interface Props {
  sessionDuration: number;
  sessionID: string;
  clientName: string;
  session: string;
  course: string;
  token: string;
  reFetchGetCourse: () => void;
}

const CalendlyComp = (props: Props) => {
  const [planningBtnLoading, setPlanningBtnLoading] = useState(false);
  const [sessionIdPlanning, setSessionIdPlanning] = useState('');
  const [calendlyPopUp, setCalendly] = useState({ open: false, url: '' });
  const userInfo = useMemo(() => JSON.parse(localStorage.getItem(token_user)), []);
  const { t: translate } = useTranslation();
  const [, executePlanSession] = useMutation(SESSION_plan);
  const token = `Bearer ${props.token}`;

  const onEventScheduled = (e: EventScheduledEvent) => {
    if (sessionIdPlanning !== props.sessionID) {
      return;
    }

    Axios({ url: e.data.payload.event.uri, method: 'GET', headers: { Authorization: token } })
      .then((res) => {
        const date = moment(res.data.resource.start_time).format();
        const eventId = res.data.resource.uri.split('/').pop();

        executePlanSession({ session_id: props.sessionID, planned_at: date, calendly_event_uri: eventId })
          .then((res) => {
            if (+res.data.planSession.status_code === +STATUS_CODE.SUCCESS) {
              setCalendly({ open: false, url: '' });
              props.reFetchGetCourse();
            }

            console.error(res);
          })
          .catch((err) => console.error(err));
      })
      .catch((err) => console.error(err));
  };

  const onClick = () => {
    if (planningBtnLoading) {
      return;
    }

    setPlanningBtnLoading(true);
    setSessionIdPlanning(props.sessionID);

    Axios({ url: calendlyAPIURL, method: 'GET', headers: { Authorization: token } })
      .then((res) => setCalendly({ open: true, url: `${res.data.resource.scheduling_url}/s${props.sessionDuration}` }))
      .catch((err) => console.error(err))
      .finally(() => {
        setPlanningBtnLoading(false);
      });
  };

  useCalendlyEventListener({ onEventScheduled });

  return (
    <>
      <Button className={styles.btnPlan} onClick={onClick} loading={planningBtnLoading}>
        <span className='topLevelText buttonText'>{translate('Plan')}</span>
      </Button>
      <PopupModal
        open={calendlyPopUp.open}
        rootElement={document.getElementById('root')}
        url={calendlyPopUp.url}
        onModalClose={() => setCalendly({ url: calendlyPopUp.url, open: false })}
        prefill={{
          email: userInfo.email,
          firstName: userInfo.first_name,
          lastName: userInfo.last_name,
          name: `${userInfo.first_name} ${userInfo.last_name}`,
          customAnswers: { a1: props.clientName, a2: props.course },
        }}
      />
    </>
  );
};

export default memo(CalendlyComp);
