import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from 'semantic-ui-react';
import { useQuery } from 'urql';

import ClosedSessionNote from '../ClosedSessionNote/ClosedSessionNote';
import styles from './coacheeClosedSession.module.scss';
import { ISession } from '../../models/type';
import { SESSION_ACTIONS, SESSION_STATUS, STATUS_CODE, UserRoles } from '../../models/enum';
import { coacheeSessionStatusTranslation_map } from '../../config/helperConstant';
import { SESSION_coachee_get_sessions_done } from '../../services/apis/sessionApis';
import { getUserRoleID } from '../../utilities/helper';

const CoacheeClosedSession: React.FC = () => {
  /*PROPS*/
  const [state, setState] = useState({
    sessionIndex: null,
    sessionNote: '',
    showClosedSessionNote: false,
  });

  const coursesOnStore = useSelector((state: any) => state.coacheeReducer.myCourseManagement);

  /*METHODS*/

  const { t: translate } = useTranslation();
  const dispatch = useDispatch();

  const [sessionDoneResult] = useQuery({
    query: SESSION_coachee_get_sessions_done,
    variables: {
      user_role_id: getUserRoleID(UserRoles.COACHEE),
      course_id: window.location.search.slice(1),
    },
    requestPolicy: 'cache-and-network',
  });

  const mapNoteToSession = (selectedDoneSession: ISession) => {
    const sessionFound: ISession = coursesOnStore?.myCourse?.sessions.find(
      (item: ISession) => item.id === selectedDoneSession.id,
    );
    setState({
      ...state,
      showClosedSessionNote: true,
      sessionIndex: selectedDoneSession.current_index,
      sessionNote: sessionFound ? sessionFound.note : translate('Cannot find note for this session'),
    });
  };

  const renderSession = useCallback((closedSessions: Array<ISession>) => {
    const sortedDESCSessionsByFinDate = closedSessions.sort(
      (s1: ISession, s2: ISession) => moment(s2.finished_at).unix() - moment(s1.finished_at).unix(),
    );
    return sortedDESCSessionsByFinDate.map((item: ISession) => {
      return (
        <tr key={item.id}>
          <td
            className={styles.tdNumberSession}>{`${item.current_index}/${item.course_context.number_of_sessions}`}</td>
          <td className={styles.tdDate}>
            {item.finished_at ? moment(item.finished_at).format('DD/MM/YYYY HH:mm') : '-/-/---'}
          </td>
          <td>{translate(coacheeSessionStatusTranslation_map.get(item.status))}</td>
          <td className={styles.tdCoach}>{item.coach && item.coach.first_name + ' ' + item.coach.last_name}</td>
          <td>
            {[`${SESSION_STATUS.done}`].includes(item.status) && (
              <span
                className={styles.tdNote}
                onClick={() => {
                  mapNoteToSession(item);
                }}>
                {translate('List my note')}
              </span>
            )}
          </td>
        </tr>
      );
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [translate]);

  useEffect(() => {
    if (+sessionDoneResult.data?.listMySessions?.status_code === +STATUS_CODE.SUCCESS) {
      dispatch({
        type: SESSION_ACTIONS.coachee_set_done_sessions,
        payload: sessionDoneResult.data.listMySessions.sessions,
      });
    } else if (sessionDoneResult.error) {
      console.error(sessionDoneResult.error);
    }
  }, [dispatch, sessionDoneResult]);

  return (
    <div className={styles.wrapper}>
      <Grid>
        <Grid.Row>
          <Grid.Column mobile='16' tablet='16' computer='16' largeScreen='14' widescreen='12'>
            <table className={styles.table}>
              <thead>
                <tr>
                  <th className={`${styles.thNumberSession} `}>{translate('Number of session / total')}</th>
                  <th className={`${styles.thDate} `}>{translate('Finish date')}</th>
                  <th className={`${styles.thStatus} `}>{translate('Status')}</th>
                  <th className={`${styles.thCoach} `}>{translate('Coach')}</th>
                  <th className={`${styles.thNote} `}></th>
                </tr>
              </thead>
              <tbody>
                {!!coursesOnStore.myDoneSessions?.length ? (
                  renderSession(coursesOnStore.myDoneSessions)
                ) : (
                  <tr>
                    <td colSpan={5} className={styles.noSessionTD}>
                      {translate('No session done')}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      {state.showClosedSessionNote && (
        <ClosedSessionNote
          sessionIndex={state.sessionIndex}
          sessionNote={state.sessionNote}
          closeSessionNote={() => setState({ ...state, showClosedSessionNote: false })}
        />
      )}
    </div>
  );
};
export default React.memo(CoacheeClosedSession);
