import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import PrimaryLayout from '../../layouts/PrimaryLayout/PrimaryLayout';
import { MaterialIconName, Role_ID } from '../../models/enum';
import CoachMainPageContent from './CoachMainPageContent';

const CoachMainPage: React.FC = () => {
  /*METHODS*/
  const navigate = useNavigate();
  const { t: translate } = useTranslation();

  const gotToProfile = () => navigate(`/${Role_ID.COACH}/profile`);
  const goToMessage = () => navigate(`/${Role_ID.COACH}/message`);

  useEffect(() => {
    navigate(`/${Role_ID.COACH}/coming-sessions`);
  }, [navigate]);

  return (
    <PrimaryLayout
      primaryTitle={translate('Dashboard')}
      primaryTitleIcon={MaterialIconName.assignment}
      role_name={translate('Coach')}
      toProfile={gotToProfile}
      hideUserIcon={true}
      goToMessage={goToMessage}
      onClickPrimaryTitle={() => navigate(`/${Role_ID.COACH}/coming-sessions`)}>
      <CoachMainPageContent />
    </PrimaryLayout>
  );
};

export default CoachMainPage;
