import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ISession } from '../../models/type';
import styles from './coachInsessionPage.module.scss';
import moment from 'moment';
import { Button, Grid } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import { COACH_Actions, MaterialIconName, SESSION_CONSTANT, SESSION_STATUS, STATUS_CODE } from '../../models/enum';
import { useMutation, useQuery } from 'urql';
import { SESSION_declare_absent, SESSION_get_status } from '../../services/apis/sessionApis';
import { checkExcessStartTimeByAGivenMinute } from '../../utilities/helper';
import AlertModal from '../../components/Common/AlertModal/AlertModal';
import EditVideoConferenceLinkModal from '../../components/Common/EditVideoConeferenceLinkModal/EditVideoConferenceLinkModal';
import ViewCoacheeDiagnosis from './ViewCoacheeDiagnosis';
import RemindCoacheeSetObjectives from './RemindCoacheeSetObjectives';

interface Props {
  refetchListCoachSession: () => void;
}

const CoachInsessionPage = (props: Props) => {
  const [state, setState] = useState({
    saveNoteActive: false,
    showAbsentButton: false,
    showAlertAbsent: false,
    showEndSessionButton: false,
    showCoacheeDiagnosis: false,
    showEditVideoLink: false,
    message: ' ',
  });
  const selectedSessionSelector: ISession = useSelector((state: any) => state.coachReducer.myCourses?.selectedSession);
  const { t: translate } = useTranslation();
  const dispatch = useDispatch();
  const [, executeDeclareAbsent] = useMutation(SESSION_declare_absent);
  const [sessionStatusResult, querySessionStatus] = useQuery({
    query: SESSION_get_status,
    variables: { session_id: selectedSessionSelector.id },
    pause: true,
  });

  const handleDeclareAbsent = useCallback(
    (sessionID: string) => {
      executeDeclareAbsent({ session_id: sessionID })
        .then((res) => {
          if (+res.data?.declareAnAbsence?.status_code === +STATUS_CODE.SUCCESS) {
            setState({ ...state, showAlertAbsent: false });
            //Clear selected session store
            dispatch({
              type: COACH_Actions.clear_select_session,
            });
            props.refetchListCoachSession();
          } else {
            console.error(res.error);
          }
        })
        .catch((err) => console.error(err));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state],
  );

  const handleFinishSession = () => {
    dispatch({
      type: COACH_Actions.clear_select_session,
    });
    props.refetchListCoachSession();
  };

  /**
   * EFFECT for re-query session status every 3mins
   */
  useEffect(() => {
    if (!selectedSessionSelector) return;

    if (selectedSessionSelector.status === SESSION_STATUS.started) return;
    //Should show absent button:
    if (
      checkExcessStartTimeByAGivenMinute(selectedSessionSelector.planned_at, SESSION_CONSTANT.absent_buffer_time) &&
      selectedSessionSelector.status === SESSION_STATUS.started_waiting
    ) {
      setState({ ...state, showAbsentButton: true });
    }
    //Interver for get session status
    const interval = setInterval(() => {
      if (sessionStatusResult.data?.getSessionStatus.session.status === SESSION_STATUS.started) return;
      querySessionStatus();
    }, SESSION_CONSTANT.requery_time);
    return () => clearInterval(interval);
  }, [querySessionStatus, selectedSessionSelector, sessionStatusResult.data?.getSessionStatus.session.status, state]);

  /**
   * EFFECT after re-query session => check if:
   *  - Passed 15mins yet
   *  - Both are join yet
   */
  useEffect(() => {
    //First time component mounted, no data return
    if (!sessionStatusResult.data) return;

    //If status return by API is started then update status on redux:
    if (sessionStatusResult.data.getSessionStatus.session.status === SESSION_STATUS.started) {
      dispatch({
        type: COACH_Actions.update_ongoing_session,
        payload: sessionStatusResult.data.getSessionStatus.session,
      });
      return;
    }

    //Passed 15min && status == started_waiting => show Absent button
    if (
      checkExcessStartTimeByAGivenMinute(selectedSessionSelector.planned_at, SESSION_CONSTANT.absent_buffer_time) &&
      sessionStatusResult.data?.getSessionStatus.session.status === SESSION_STATUS.started_waiting
    ) {
      setState({ ...state, showAbsentButton: true });
    } else if (
      checkExcessStartTimeByAGivenMinute(selectedSessionSelector.planned_at, SESSION_CONSTANT.absent_buffer_time) &&
      sessionStatusResult.data?.getSessionStatus.session.status === SESSION_STATUS.started
    ) {
      setState({ ...state, showAbsentButton: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSessionSelector]);

  return (
    <div>
      <div className={styles.headerWrapper}>
        <p className={styles.sessionTitle}>
          {selectedSessionSelector.course_context.offer_name +
            ' : ' +
            selectedSessionSelector.course_context.program_name}
        </p>
      </div>
      <div className={styles.plannedHeader}>
        <div className={styles.headerCoachAndNumberSession}>
          <span className={`${styles.boldSession} topLevelText`}>
            {translate('Session') +
              ' ' +
              `${selectedSessionSelector.current_index}/${selectedSessionSelector.course_context.number_of_sessions}`}
          </span>
          <span className={styles.text1}>
            {`${translate('Coachee')} : `}
            <span>
              {`${selectedSessionSelector.coachee.first_name + ' ' + selectedSessionSelector.coachee.last_name}`}
            </span>
          </span>
        </div>
        <div>
          <span className={styles.text2}>
            {translate('Date')}
            <span>{moment(selectedSessionSelector.planned_at).format('DD/MM/YYYY')}</span>
          </span>
          <span className={styles.text2}>
            {translate('Hour')}
            <span>{moment(selectedSessionSelector.planned_at).format('HH:mm')}</span>
          </span>
        </div>
      </div>

      <div className={styles.mainContentBlock}>
        <Grid>
          <Grid.Row>
            <Grid.Column mobile='16' tablet='16' computer='16' widescreen='12' largeScreen='10'>
              <div className={styles.meetingLinkBlock}>
                <div className={styles.topMeetingBlock}>
                  <span className={`${styles.videoIcon} material-icons-outlined`}>{MaterialIconName.video_call}</span>
                  <span className={styles.linkText} onClick={() => setState({ ...state, showEditVideoLink: true })}>
                    {translate('Edit meeting link')}
                  </span>
                </div>
                <div className={styles.bottomMeetingBlock}>
                  <a href={selectedSessionSelector.videoconference_link} target='_blank' rel='noreferrer'>
                    <Button className={styles.joinBtn}>
                      <span className={`buttonText topLevelText`}>{translate('Join video conference')}</span>
                    </Button>
                  </a>
                  {selectedSessionSelector.course_context.self_diagnosis && (
                    <Button
                      className={styles.coacheeDiagnosisBtn}
                      onClick={() => setState({ ...state, showCoacheeDiagnosis: true })}>
                      <span className='topLevelText buttonText'>{translate('Coachee diagnosis')}</span>
                    </Button>
                  )}
                </div>
              </div>

              <p className={styles.text3Alt}>{translate('Note from coachee')}</p>
              <p className={styles.textMessage}>{state.message}</p>
              <div className={styles.noteFrame}>
                <textarea
                  className={styles.noteArea}
                  name='note'
                  value={selectedSessionSelector?.note}
                  readOnly={true}
                />
                <div className={styles.noteBtnBlock}></div>
              </div>
              <div className={styles.endSessionBtnBlock}>
                <Button className={styles.endSessionBtn} onClick={handleFinishSession}>
                  <span className='buttonText topLevelText'>{translate('Quit session')}</span>
                </Button>
                {state.showAbsentButton && (
                  <Button
                    className={styles.coachAbsentBtn}
                    onClick={() => setState({ ...state, showAlertAbsent: true })}>
                    <span className='buttonText topLevelText'>{translate('Declare coachee absent')}</span>
                  </Button>
                )}
              </div>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column mobile='16' tablet='16' computer='16' widescreen='12' largeScreen='10'>
              {/* {courseStore.myCurrentSession.current_index !== 1 && (
                <NoteReviewComp
                  session={
                    !!courseStore.myDoneSessions.length &&
                    courseStore.myDoneSessions[
                      courseStore.myDoneSessions.length - 1
                    ]
                  }
                />
              )} */}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
      {state.showAlertAbsent && (
        <AlertModal
          title={translate('Declare absent')}
          contentQuestion={translate('Do you want to declare coachee absent')}
          content={translate('Click yes to declare coach absent and session will be consider done')}
          cancel={() => setState({ ...state, showAlertAbsent: false })}
          agree={() => handleDeclareAbsent(selectedSessionSelector.id)}
        />
      )}
      {state.showEditVideoLink && (
        <EditVideoConferenceLinkModal
          cancel={() => setState({ ...state, showEditVideoLink: false })}
          onGoingSession={selectedSessionSelector}
        />
      )}
      {state.showCoacheeDiagnosis && (
        <ViewCoacheeDiagnosis
          courseID={selectedSessionSelector.course_context.course_id}
          cancel={() => setState({ ...state, showCoacheeDiagnosis: false })}
        />
      )}
      <RemindCoacheeSetObjectives session={selectedSessionSelector} />
    </div>
  );
};

export default CoachInsessionPage;
