import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Icon, Pagination } from 'semantic-ui-react';
import SearchInput from '../../components/SearchInput/SearchInput';
import ListCoacheeModalOptions from './ListCoacheeModalOptions';
import styles from './programMangerListCoacheePage.module.scss';
import {
  ICoacheeTableToolsBlockContext,
  CoacheeTableToolsBlockProvider,
  CoacheeListModalName,
} from './Context/CoacheeTableToolsBlockContext';
import AssignButton from './Buttons/AssignButton';
import AssignFollowUpButton from './Buttons/AssignFollowUpButton';
import UnassignButton from './Buttons/UnassignButton';
import RemoveButton from './Buttons/RemoveButton';
import { shouldDisableButton } from '../../functions/tool-block-coachee-table';

export interface State extends ICoacheeTableToolsBlockContext {
  showModal: boolean;
}

interface Props {
  handleSearch: (value: string) => void;
  handleChangePage: (number: number) => void;
  handleRefectCoacheeList: () => void;
  programStatus: string;
}

const ToolBlockCoacheeTable: React.FC<Props> = (props: Props) => {
  /*STATE*/
  const [state, setState] = useState<State>({
    showModal: false,
    modalContent: {
      title: '',
      titleDanger: false,
      contentText: '',
      openModal: null,
    },
  });

  const listCoacheeSelector = useSelector((state: any) => state.programManagerReducer.listOfCoacheePage);

  /*METHODS*/
  const { t: translate } = useTranslation();

  const handleOpenRemoveCoachee = () => {
    setState({
      ...state,
      showModal: true,
      modalContent: {
        ...state.modalContent,
        title: translate('Confirm deletion'),
        titleDanger: true,
        openModal: CoacheeListModalName.removeCoachee,
      },
    });
  };

  const handleOpenAssignModal = () => {
    setState({
      ...state,
      showModal: true,
      modalContent: {
        title: translate('Assign course_plural'),
        titleDanger: false,
        contentText: translate('Choose course to assign') + ' ',
        openModal: CoacheeListModalName.assignCourse,
      },
    });
  };

  const handleOpenUnassignModal = () => {
    setState({
      ...state,
      showModal: true,
      modalContent: {
        ...state.modalContent,
        title: translate('Unassign course_plural'),
        titleDanger: true,
        openModal: CoacheeListModalName.unAssignCourse,
      },
    });
  };

  const handleOpenAssignFollowUpModal = () => {
    setState({
      ...state,
      showModal: true,
      modalContent: {
        ...state.modalContent,
        title: translate('Assign follow-up course'),
        titleDanger: false,
        contentText: '',
        openModal: CoacheeListModalName.assignFollowUpCourse,
      },
    });
  };

  return (
    <CoacheeTableToolsBlockProvider value={state}>
      <div className={styles.secondBlock}>
        <div className={`${styles.leftBlock} ${!listCoacheeSelector.selectedCoacheeList.length && styles.invisible} `}>
          <AssignButton
            disabled={shouldDisableButton(props.programStatus) || !listCoacheeSelector.selectedCoacheeList.length}
            handleClick={handleOpenAssignModal}
          />
          <AssignFollowUpButton
            disabled={
              shouldDisableButton(props.programStatus) ||
              listCoacheeSelector.selectedCoacheeList.length > 1 ||
              !listCoacheeSelector.selectedCoacheeList[0]?.courses.length
            }
            handleClick={handleOpenAssignFollowUpModal}
            numbOfCourse={listCoacheeSelector.selectedCoacheeList[0]?.courses.length}
          />
          <UnassignButton
            disabled={
              shouldDisableButton(props.programStatus) ||
              listCoacheeSelector.selectedCoacheeList.length > 1 ||
              !listCoacheeSelector.selectedCoacheeList[0]?.courses.length
            }
            handleClick={handleOpenUnassignModal}
          />
          <RemoveButton
            disabled={shouldDisableButton(props.programStatus) || !listCoacheeSelector.selectedCoacheeList.length}
            handleClick={handleOpenRemoveCoachee}
          />
        </div>
        <div className={styles.rightBlock}>
          <SearchInput placeholder='Rechercher un coaché' handleSearch={(value: string) => props.handleSearch(value)} />
          <div className={styles.pagination} id='paginationId'>
            <Pagination
              defaultActivePage={1}
              ellipsisItem={{
                content: <Icon name='ellipsis horizontal' />,
                icon: true,
              }}
              firstItem={null}
              lastItem={null}
              prevItem={{
                content: <Icon name='angle left' />,
                icon: true,
              }}
              nextItem={{
                content: <Icon name='angle right' />,
                icon: true,
              }}
              totalPages={listCoacheeSelector.pagination.lastPage}
              onPageChange={(event, data) => {
                props.handleChangePage(+data.activePage);
              }}
            />
          </div>
        </div>
        {state.showModal && (
          <ListCoacheeModalOptions
            onClose={() => setState({ ...state, showModal: false })}
            handleRefetchCoacheeList={props.handleRefectCoacheeList}
          />
        )}
      </div>
    </CoacheeTableToolsBlockProvider>
  );
};

export default ToolBlockCoacheeTable;
