import React from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { Button, Container, Icon } from 'semantic-ui-react';
import { useMutation } from 'urql';
import { useTranslation } from 'react-i18next';

import { ReactComponent as Logo } from '../../assets/images/logo_svg.svg';
import { AUTHENTICATION_RGPDConsent } from '../../services/apis/authApis';
import { STATUS_CODE } from '../../models/enum';
import styles from './RGPD.module.scss';
import { token_user } from '../../config/tokenConstant';
import { checkRGPD } from '../../services/Auth/Authentication';
import useLogOut from '../../hooks/useLogOut';

const RGPDPage: React.FC = () => {
  const location = useLocation();
  const logOut = useLogOut();

  /*METHODS*/
  const navigate = useNavigate();
  const { t: translate } = useTranslation();
  const [acceptRGPDResult, executeAcceptRGPD] = useMutation(AUTHENTICATION_RGPDConsent);
  const handleDisagreeButton = () => logOut();

  /*MAIN RETURN*/
  if (!localStorage.getItem(token_user)) return <Navigate to='/login' />;
  if (checkRGPD()) return <Navigate to='/roles' />;

  return (
    <Container fluid>
      <Logo className={`logo logoPosition_normal`} />
      <div className={styles.contentBlock}>
        <div>
          <h1 className={styles.title}>{translate('RGPD page title')}</h1>
          <p className={styles.textContent}>{translate('RGPD first paragraph')}</p>
          <br />
          <p className={styles.textContent}>
            {translate('RGPD second paragraph')}{' '}
            <a className={styles.email} href='mailto:contact@mypositivebooster.fr'>
              {translate('mpbemail')}
            </a>
          </p>
        </div>
        <div className={styles.btnBlock}>
          <Button
            icon
            size='huge'
            className={styles.btn}
            disabled={acceptRGPDResult.fetching}
            onClick={handleDisagreeButton}>
            <Icon name='arrow left' />
            <span className={styles.btnTitle}>{translate('Go back')}</span>
          </Button>
          <Button
            icon
            size='huge'
            className={styles.btn}
            disabled={acceptRGPDResult.fetching}
            onClick={() => {
              executeAcceptRGPD({ user_id: location.state.user.id })
                .then((res) => {
                  if (res.data?.updateGDPRConsent.status_code === STATUS_CODE.SUCCESS) {
                    localStorage.setItem(token_user, JSON.stringify({ ...location.state.user, gdpr_consent: true }));
                    navigate('/roles'); //push to intermediate page
                    return;
                  }
                  console.error(res.error.graphQLErrors[0].message);
                })
                .catch((err) => console.error(err));
            }}>
            <Icon name='check' />
            <span className={styles.btnTitle}>{translate('Agree')}</span>
          </Button>
        </div>
      </div>
    </Container>
  );
};

export default RGPDPage;
