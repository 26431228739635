import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Grid, Icon } from 'semantic-ui-react';
import { useMutation } from 'urql';
import { courseEvaluationQuestionsCollection } from '../../config/helperConstant';
import { IEvaluateCourseRatingItem } from '../../models/type';
import styles from './coacheeEvaluateFinishCourse.module.scss';
import EvaluateCourseRatingItem from './EvaluateCourseRatingItem';
import { COURSE_evaluate_course } from '../../services/apis/courseApis';
import { STATUS_CODE } from '../../models/enum';

interface Props {
  close: () => void;
  requeryCoacheeDetail: () => void;
  courseID: string;
}

const CoacheeEvaluateFinishedCourse: React.FC<Props> = (props: Props) => {
  /*State*/

  const [formState, setFormState] = useState({
    course_id: props.courseID,
    operational_performance: 0,
    work_life_quality: 0,
    professional_relationships: 0,
    leadership: 0,
    personal_organization: 0,
    comment: '',
  });

  /*Methods*/
  const { t: translate } = useTranslation();

  const [, executeEvaluateCourse] = useMutation(COURSE_evaluate_course);

  const handleOnChangeRating = (name: string, rating: number) => {
    setFormState({ ...formState, [name]: rating });
  };

  const handleSubmit = () => {
    const payload = { ...formState };
    if (!payload.comment) delete payload.comment;
    executeEvaluateCourse(payload)
      .then((res) => {
        if (+res.data.evaluateCourse.status_code === +STATUS_CODE.SUCCESS) {
          props.requeryCoacheeDetail();
          props.close();
        } else {
          props.close();
          console.error(res.error);
        }
      })
      .catch((err) => console.error(err));
  };

  const handleRenderRatingItem = () => {
    return courseEvaluationQuestionsCollection.map((item: IEvaluateCourseRatingItem) => {
      return (
        <EvaluateCourseRatingItem
          item={item}
          key={item.name}
          onChange={(name, rating) => handleOnChangeRating(name, rating)}
        />
      );
    });
  };

  const handleOnChange = (e: React.FormEvent<HTMLTextAreaElement>) => {
    setFormState({
      ...formState,
      [e.currentTarget.name]: e.currentTarget.value,
    });
  };

  /*CONST*/
  const canActiveSubmitBtn =
    formState.course_id &&
    formState.leadership > 0 &&
    formState.operational_performance > 0 &&
    formState.personal_organization > 0 &&
    formState.professional_relationships > 0 &&
    formState.work_life_quality > 0;

  return (
    <div className={styles.modal}>
      <div className={styles.wrapper}>
        <h1 className={`${styles.title} topLevelText`}>{translate('Final course evaluation')}</h1>
        <div className={styles.contentWrapper}>
          <p className={styles.generalQuestion}>{translate('What impacts did effect you to reach your goals?')}</p>
          <Grid>{handleRenderRatingItem()}</Grid>

          <Grid>
            <Grid.Row stretched>
              <Grid.Column mobile='16' tablet='16' computer='12'>
                <p className={styles.text1}>{translate('Other')}</p>
                <textarea
                  name='comment'
                  className={styles.textArea}
                  placeholder={translate('Tell us more')}
                  value={formState.comment}
                  onChange={handleOnChange}></textarea>
              </Grid.Column>
              <Grid.Column mobile='16' tablet='16' computer='4'>
                <div className={styles.buttonBlock}>
                  <Button
                    className={`${styles.btnDisable} ${canActiveSubmitBtn && styles.btnActive}`}
                    disabled={!canActiveSubmitBtn}
                    onClick={handleSubmit}>
                    <span className='buttonText topLevelText'>{translate('Send')}</span>
                  </Button>
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
        <Icon name='cancel' className={styles.closeIcon} onClick={props.close} />
      </div>
    </div>
  );
};

export default CoacheeEvaluateFinishedCourse;
