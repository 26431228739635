import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Icon } from 'semantic-ui-react';
import CoacheeRow from '../../components/CoacheeRow/CoacheeRow';
import { ListCoacheesFromProgramSortableColumn, SortDirection } from '../../models/enum';
import { ICoachee_extended } from '../../models/type';
import styles from './programMangerListCoacheePage.module.scss';

interface Props {
  coacheeArray: Array<ICoachee_extended>;
  currentOrderBy?: ListCoacheesFromProgramSortableColumn;
  currentDirection?: SortDirection;
  programStatus: string;
  handleSort: (order_by: ListCoacheesFromProgramSortableColumn, direction: SortDirection) => void;
}

const CoacheeTable: React.FC<Props> = (props: Props) => {
  /*Props*/

  const selectedCoacheeIDArray: Array<string> = useSelector((state: any) => {
    return state.programManagerReducer.listOfCoacheePage.selectedCoacheeList.map((coachee: ICoachee_extended) => {
      return coachee.id;
    });
  });

  /*CONST*/
  const nameSortedASC =
    props.currentOrderBy === ListCoacheesFromProgramSortableColumn.NAME && props.currentDirection === SortDirection.ASC;

  const businessSortedASC =
    props.currentOrderBy === ListCoacheesFromProgramSortableColumn.DEPARTMENT &&
    props.currentDirection === SortDirection.ASC;

  const courseSortedASC =
    props.currentOrderBy === ListCoacheesFromProgramSortableColumn.OFFER_NAME &&
    props.currentDirection === SortDirection.ASC;

  const courseStartedSortedASC =
    props.currentOrderBy === ListCoacheesFromProgramSortableColumn.IS_STARTED &&
    props.currentDirection === SortDirection.ASC;

  const courseEndSortedASC =
    props.currentOrderBy === ListCoacheesFromProgramSortableColumn.NUMBER_OF_SESSIONS_DONE &&
    props.currentDirection === SortDirection.ASC;

  const courseStatusSortedASC =
    props.currentOrderBy === ListCoacheesFromProgramSortableColumn.STATUS &&
    props.currentDirection === SortDirection.ASC;

  const courseCreateDateSortedASC =
    props.currentOrderBy === ListCoacheesFromProgramSortableColumn.CREATED_AT &&
    props.currentDirection === SortDirection.ASC;

  /*METHODS*/

  const { t: translate } = useTranslation();

  const renderCoacheeRow = (coacheeArray: Array<ICoachee_extended>) => {
    return coacheeArray.map((item: ICoachee_extended) => {
      return (
        <CoacheeRow
          key={item.id}
          coachee={item}
          checked={selectedCoacheeIDArray.includes(item.id)}
          programStatus={props.programStatus}
        />
      );
    });
  };

  return (
    <table>
      <thead>
        <tr>
          <th className={styles.selectLength}>
            <span>{translate('Choose')}</span>
          </th>
          <th className={styles.nomLength}>
            <div className={styles.headerTitleBlock}>
              <span>{translate('Last name')}</span>
              {nameSortedASC ? (
                <Icon
                  name='angle up'
                  className={styles.sortIcon}
                  onClick={() => props.handleSort(ListCoacheesFromProgramSortableColumn.NAME, SortDirection.DESC)}
                />
              ) : (
                <Icon
                  name='angle down'
                  className={styles.sortIcon}
                  onClick={() => props.handleSort(ListCoacheesFromProgramSortableColumn.NAME, SortDirection.ASC)}
                />
              )}
            </div>
          </th>
          <th className={styles.departmentLength}>
            <div className={styles.headerTitleBlock}>
              <span>{translate('Business area')}</span>
              {businessSortedASC ? (
                <Icon
                  name='angle up'
                  className={styles.sortIcon}
                  onClick={() => props.handleSort(ListCoacheesFromProgramSortableColumn.DEPARTMENT, SortDirection.DESC)}
                />
              ) : (
                <Icon
                  name='angle down'
                  className={styles.sortIcon}
                  onClick={() => props.handleSort(ListCoacheesFromProgramSortableColumn.DEPARTMENT, SortDirection.ASC)}
                />
              )}
            </div>
          </th>
          <th className={styles.courseLength}>
            <div className={styles.headerTitleBlock}>
              <span>{translate('AssignedCourse', { count: 2 })}</span>
              {courseSortedASC ? (
                <Icon
                  name='angle up'
                  className={styles.sortIcon}
                  onClick={() => props.handleSort(ListCoacheesFromProgramSortableColumn.OFFER_NAME, SortDirection.DESC)}
                />
              ) : (
                <Icon
                  name='angle down'
                  className={styles.sortIcon}
                  onClick={() => props.handleSort(ListCoacheesFromProgramSortableColumn.OFFER_NAME, SortDirection.ASC)}
                />
              )}
            </div>
          </th>
          <th className={styles.startedCourseLength}>
            <div className={styles.headerTitleBlock}>
              <span>{translate('StartedCourse', { count: 2 })}</span>
              {courseStartedSortedASC ? (
                <Icon
                  name='angle up'
                  className={styles.sortIcon}
                  onClick={() => props.handleSort(ListCoacheesFromProgramSortableColumn.IS_STARTED, SortDirection.DESC)}
                />
              ) : (
                <Icon
                  name='angle down'
                  className={styles.sortIcon}
                  onClick={() => props.handleSort(ListCoacheesFromProgramSortableColumn.IS_STARTED, SortDirection.ASC)}
                />
              )}
            </div>
          </th>
          <th className={styles.sessionLength}>
            <div className={styles.headerTitleBlock}>
              <span>{translate('Ongoing session_plural', { count: 2 })}</span>
              {courseEndSortedASC ? (
                <Icon
                  name='angle up'
                  className={styles.sortIcon}
                  onClick={() =>
                    props.handleSort(ListCoacheesFromProgramSortableColumn.NUMBER_OF_SESSIONS_DONE, SortDirection.DESC)
                  }
                />
              ) : (
                <Icon
                  name='angle down'
                  className={styles.sortIcon}
                  onClick={() =>
                    props.handleSort(ListCoacheesFromProgramSortableColumn.NUMBER_OF_SESSIONS_DONE, SortDirection.ASC)
                  }
                />
              )}
            </div>
          </th>
          <th className={styles.statusLength}>
            <div className={styles.headerTitleBlock}>
              <span>{translate('Progress on objectives')}</span>
            </div>
          </th>
          <th className={styles.statusLength}>
            <div className={styles.headerTitleBlock}>
              <span>{translate('Status')}</span>
              {courseStatusSortedASC ? (
                <Icon
                  name='angle up'
                  className={styles.sortIcon}
                  onClick={() => props.handleSort(ListCoacheesFromProgramSortableColumn.STATUS, SortDirection.DESC)}
                />
              ) : (
                <Icon
                  name='angle down'
                  className={styles.sortIcon}
                  onClick={() => props.handleSort(ListCoacheesFromProgramSortableColumn.STATUS, SortDirection.ASC)}
                />
              )}
            </div>
          </th>

          <th className={styles.createDateLength}>
            <div className={styles.headerTitleBlock}>
              <span>{translate('Create date')}</span>
              {courseCreateDateSortedASC ? (
                <Icon
                  name='angle up'
                  className={styles.sortIcon}
                  onClick={() => props.handleSort(ListCoacheesFromProgramSortableColumn.CREATED_AT, SortDirection.DESC)}
                />
              ) : (
                <Icon
                  name='angle down'
                  className={styles.sortIcon}
                  onClick={() => props.handleSort(ListCoacheesFromProgramSortableColumn.CREATED_AT, SortDirection.ASC)}
                />
              )}
            </div>
          </th>
        </tr>
      </thead>
      <tbody>{renderCoacheeRow(props.coacheeArray)}</tbody>
    </table>
  );
};

export default CoacheeTable;
