import { RouteObject } from 'react-router-dom';
import CoachComingSessionsPage from '../../pages/CoachComingSessionsPage/CoachComingSessionsPage';
import CoachCourseSupervisionPage from '../../pages/CoachCourseSupervisionPage/CoachCourseSupervisionPage';
import CoachFinishedSessionsPage from '../../pages/CoachFinishedSessionsPage/CoachFinishedSessionsPage';
import CoachMediaResourcesPage from '../../pages/CoachMediaResourcesPage/CoachMediaResourcesPage';
import CoachMessagePage from '../../pages/CoachMessagePage/CoachMessagePage';

const coachMainPageRouter: RouteObject[] = [
  { path: 'coming-sessions', element: <CoachComingSessionsPage /> },
  { path: 'finished-sessions', element: <CoachFinishedSessionsPage /> },
  { path: 'course-supervision', element: <CoachCourseSupervisionPage /> },
  { path: 'media-resources', element: <CoachMediaResourcesPage /> },
  { path: 'message', element: <CoachMessagePage /> },
];

export default coachMainPageRouter;
