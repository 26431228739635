import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Icon } from 'semantic-ui-react';
import { IMessageReducer } from '../../services/redux/Reducers/MessageReducer';
import styles from './welcomeMessage.module.scss';

const WelcomeMessage = () => {
  const { receiver }: IMessageReducer = useSelector((state: any) => state.messageReducer);
  const sender = useSelector((state: any) => state.userReducer.userName);

  const { t: translate } = useTranslation();

  return (
    <div className={styles.welcomeMessage}>
      <p className={styles.sentence1}>{translate('Welcome come to mailbox')}</p>
      <p className={styles.sentence2}>
        {translate('Hello') + ' '}
        <span className={styles.nameText}>{sender}</span>, {translate('start conversation with')}{' '}
        <span className={styles.nameText}>{receiver && receiver.first_name + ' ' + receiver.last_name}</span>
        . <Icon name='smile outline' className={styles.smileIcon} />
      </p>
    </div>
  );
};

export default React.memo(WelcomeMessage);
