import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Checkbox } from 'semantic-ui-react';
import { COURSE_STATUS, PROGRAMMANGER_ACTIONS, ProgramStatus } from '../../models/enum';
import { ICoachee_extended, ICourseProgression, ICourseRef } from '../../models/type';
import styles from './coacheeRow.module.scss';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { renderPercentage } from '../../services/ProgramManager/progression';
import ZzzIcon from '../ZzzIcon/ZzzIcon';

interface Props {
  coachee: ICoachee_extended;
  checked: boolean;
  programStatus: string;
}

const CoacheeRow: React.FC<Props> = (props: Props) => {
  const progressionSelector: Array<ICourseProgression> = useSelector(
    (state: any) => state.programManagerReducer.coursesProgression.progressionCollection,
  );

  /*METHODS*/
  const { t: translate } = useTranslation();

  const dispatch = useDispatch();

  const handleSelect = (coachee: ICoachee_extended) => {
    dispatch({
      type: PROGRAMMANGER_ACTIONS.select_coachee,
      payload: coachee,
    });
  };

  const findCourseProgression = (courseID: string) => {
    if (progressionSelector.length === 0) return;

    const result = progressionSelector.find((courseProgression: ICourseProgression) => {
      return courseProgression.courseID === courseID;
    });

    return result?.progressionPoint; // After unassign or remove a coachee. progressionSelector don't have progression of that coachee anymore.
  };

  const shouldDisable = (): boolean => {
    if (props.programStatus === ProgramStatus.DONE) return true;
    return false;
  };

  /*MAIN RETURN*/

  return (
    <tr className={`${styles.row} ${props.checked && styles.selected}`}>
      <td>
        <Checkbox onChange={() => handleSelect(props.coachee)} checked={props.checked} disabled={shouldDisable()} />
      </td>
      <td>
        <div className={styles.fNameCol}>
          {props.coachee.user_infos.first_name + ' ' + props.coachee.user_infos.last_name}{' '}
          <span>{props.coachee.user_infos.gdpr_consent === false && <ZzzIcon />}</span>
        </div>
      </td>
      <td>{props.coachee.user_infos.department ? props.coachee.user_infos.department : '-'}</td>
      <td>
        <ul>
          {props.coachee.courses.length
            ? props.coachee.courses.map((course: ICourseRef) => {
                return <li key={course.id}>{course.offer_configuration.name}</li>;
              })
            : '-'}
        </ul>
      </td>
      <td>
        <ul>
          {props.coachee.courses.length
            ? props.coachee.courses.map((course: ICourseRef) => {
                return (
                  <li key={course.id}>{course.status === COURSE_STATUS.open ? translate('No') : translate('Yes')}</li>
                );
              })
            : '-'}
        </ul>
      </td>
      <td>
        <ul>
          {props.coachee.courses.length
            ? props.coachee.courses.map((course: ICourseRef) => {
                return <li key={course.id}>{course.number_of_sessions_done}</li>;
              })
            : '-'}
        </ul>
      </td>
      <td>
        <ul>
          {props.coachee.courses.length === 0
            ? '-'
            : props.coachee.courses.map((course: ICourseRef) => {
                return <li key={course.id}>{renderPercentage(findCourseProgression(course.id))}</li>;
              })}
        </ul>
      </td>
      <td>
        <ul>
          {props.coachee.courses.length
            ? props.coachee.courses.map((course: ICourseRef) => {
                return (
                  <li key={course.id}>
                    {course.status === COURSE_STATUS.closed && translate('Finished')}
                    {course.status === COURSE_STATUS.open && translate('Open')}
                    {[
                      `${COURSE_STATUS.started_onboarding}`,
                      `${COURSE_STATUS.started_self_diagnosis}`,
                      `${COURSE_STATUS.started_coach_selection}`,
                      `${COURSE_STATUS.started}`,
                    ].includes(course.status) && translate('In progress')}
                  </li>
                );
              })
            : '-'}
        </ul>
      </td>
      <td>{props.coachee?.created_at ? moment(props.coachee.created_at).format('DD/MM/YYYY') : '--/--/----'}</td>
    </tr>
  );
};

export default React.memo(CoacheeRow);
