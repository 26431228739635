import React from 'react';
import styles from './coachCardComp.module.scss';
import { Grid, Segment, Image } from 'semantic-ui-react';
import { ICoachSummary } from '../../models/type';
import defaultPhoto from '../../assets/images/default_profile_photo.png';
import { useTranslation } from 'react-i18next';
import { domainName } from '../../config/helperConstant';
import { LANGUGAGE_map } from '../../config/helperConstant';

interface Props {
  coach: ICoachSummary;
  selectCoach: () => void;
  isSelected: boolean;
}

const CoachCardComp: React.FC<Props> = (props: Props) => {
  /*PROPS*/

  /*METHODS*/
  const { t: translate } = useTranslation();

  return (
    <Grid.Row>
      <Grid.Column mobile='16' tablet='16' computer='14' stretched>
        <Segment
          className={`${styles.customeSegment} ${props.isSelected && styles.selectedActive}`}
          onClick={props.selectCoach}>
          <Grid>
            <Grid.Row divided>
              <Grid.Column mobile='16' tablet='16' computer='4' stretched textAlign='center' verticalAlign='middle'>
                <span className={styles.coachName}>
                  {props.coach.user_infos.first_name + ' ' + props.coach.user_infos.last_name}
                </span>
                <div className={styles.imgWrapper}>
                  <Image
                    src={props.coach.picture ? domainName + '/' + props.coach.picture : defaultPhoto}
                    size='small'
                  />
                </div>
              </Grid.Column>
              <Grid.Column mobile='16' tablet='16' computer='12' stretched>
                <Grid>
                  <Grid.Row>
                    <Grid.Column mobile='16' tablet='16' computer='4' verticalAlign='top'>
                      <span className={`${styles.categoryText} topLevelText`}>{translate('Introduction')}</span>
                    </Grid.Column>
                    <Grid.Column mobile='16' tablet='16' computer='12'>
                      <div className={styles.introWrapper}>
                        <p>{props.coach.presentation}</p>
                      </div>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column mobile='16' tablet='16' computer='4' verticalAlign='middle'>
                      <span className={`${styles.categoryText} topLevelText`}>{translate('Certificate')}</span>
                    </Grid.Column>
                    <Grid.Column mobile='16' tablet='16' computer='12'>
                      <ul className={styles.listing}>
                        {props.coach.certifications?.length
                          ? props.coach.certifications.map((item: string, index: number) => {
                              return (
                                <li key={index}>
                                  <b>{item}</b>
                                </li>
                              );
                            })
                          : ''}
                      </ul>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column mobile='16' tablet='16' computer='4' verticalAlign='middle'>
                      <span className={`${styles.categoryText} topLevelText`}>{translate('Language_plural')}</span>
                    </Grid.Column>
                    <Grid.Column mobile='16' tablet='16' computer='12'>
                      <ul className={styles.listing}>
                        {props.coach.additional_languages?.length
                          ? props.coach.additional_languages.map((item: string, index: number) => {
                              return (
                                <li key={index}>
                                  <b>{translate(LANGUGAGE_map.get(item))}</b>
                                </li>
                              );
                            })
                          : ''}
                      </ul>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
      </Grid.Column>
    </Grid.Row>
  );
};

export default React.memo(CoachCardComp);
