import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown, Grid, Icon, Menu, Pagination } from 'semantic-ui-react';
import { ListMediaResourcesSortableColumn, MediaResourcesSearchBy, SortDirection } from '../../models/enum';
import { translateDropDownOption } from '../../utilities/helper';
import SearchSimple from '../Common/SearchSimple/SearchSimple';
import CoacheeMediaAll from './CoacheeMediaAll';
import CoacheeMediaRecommended from './CoacheeMediaRecommended';
import { mediaResourcesFormat, multimediaResourceThemes } from '../../config/helperConstant';
import styles from './coacheeMediaResources.module.scss';
import { useSelector } from 'react-redux';
import { ICourse } from '../../models/type';
import '../../theme/customedDropDown.scss';

const recommend = 'recommend';
const all = 'all';

const CoacheeMediaResources = () => {
  const [activeTab, setActiveTab] = useState(recommend);
  const [theme, setTheme] = useState<string[] | null>(null);
  const myCourseSelector: ICourse = useSelector((state: any) => state.coacheeReducer.myCourseManagement.myCourse);
  const [queryVariables, setQueryVariables] = useState({
    count: 20,
    page: 1,
    search_by: MediaResourcesSearchBy.title,
    search_value: '',
    filter_format: '',
    filter_language: '',
    order_by: ListMediaResourcesSortableColumn.title,
    direction: SortDirection.ASC,
  });
  const { t: translate } = useTranslation();

  const conditionToRenderTheme = !theme && activeTab === all;

  const handleChangePage = (data: any) => {
    setQueryVariables({ ...queryVariables, page: data.activePage });
  };

  const handleClickTheme = (theme: string[]) => {
    setTheme(theme);
  };

  const renderThemes = () => {
    return multimediaResourceThemes.map((t) => (
      <Grid.Column
        mobile='16'
        tablet='8'
        computer='5'
        stretched
        textAlign='center'
        verticalAlign='middle'
        key={t.theme}>
        <div className={styles.themeItem} onClick={() => handleClickTheme(t.passcodes)}>
          <img className={styles.themeImage} src={t.image} alt={translate('theme image')} />
          <div className={styles.themeTitle}>{translate(t.theme)}</div>
        </div>
      </Grid.Column>
    ));
  };

  useEffect(() => {
    if (myCourseSelector?.offer_configuration.has_self_diagnosis) {
      setActiveTab(recommend);
    } else {
      setActiveTab(all);
    }
  }, [myCourseSelector]);

  return (
    <div className={styles.wrapper}>
      {myCourseSelector && (
        <div id='menu'>
          <Menu pointing secondary>
            {myCourseSelector?.offer_configuration.has_self_diagnosis && (
              <Menu.Item active={activeTab === recommend} onClick={() => setActiveTab(recommend)}>
                {translate('Recommend media resources')}
              </Menu.Item>
            )}
            <Menu.Item active={activeTab === all} className={styles.customedMenu} onClick={() => setActiveTab(all)}>
              {translate('All media resources')}
            </Menu.Item>
          </Menu>
        </div>
      )}

      {conditionToRenderTheme && (
        <div className={`${styles.sessionOverlay}`}>
          <div className={styles.themeContainer}>
            <Grid>
              <Grid.Row>{renderThemes()}</Grid.Row>
            </Grid>
          </div>
        </div>
      )}

      <div className={`${styles.toolsWrapper} ${conditionToRenderTheme && styles.toolsWrapperHidden}`}>
        <div className={`${styles.backWrapper} ${activeTab === recommend && styles.backWrapperHidden}`}>
          <span className={styles.backIconWrapper} onClick={() => setTheme(null)}>
            <Icon name='long arrow alternate left' size='big' />
            <span>{translate('Return to theme selection')}</span>
          </span>
        </div>
        <div className={styles.toolsWrapperContainer}>
          <div className={styles.rightToolsModuleBlock}>
            <div id='customedDropDownID' className={styles.toolsBlock}>
              <Dropdown
                placeholder={translate('Format')}
                selection
                name='filter_format'
                className={styles.dropDown}
                options={translateDropDownOption(mediaResourcesFormat, translate)}
                onChange={(_e, { name, value }) => setQueryVariables({ ...queryVariables, [name]: value })}
              />
            </div>
          </div>
          <div className={styles.toolsBlock}>
            <div className={styles.searchWrapper}>
              <SearchSimple
                placeholder={translate('Enter resource name')}
                handleSearch={(searchInput: string) => {
                  setQueryVariables({
                    ...queryVariables,
                    search_value: searchInput,
                  });
                }}
              />
            </div>
            <div className={styles.pagination} id='paginationId'>
              <Pagination
                defaultActivePage={1}
                ellipsisItem={{
                  content: <Icon name='ellipsis horizontal' />,
                  icon: true,
                }}
                firstItem={null}
                lastItem={null}
                prevItem={{
                  content: <Icon name='angle left' />,
                  icon: true,
                }}
                nextItem={{
                  content: <Icon name='angle right' />,
                  icon: true,
                }}
                totalPages={1}
                onPageChange={(_event, data) => handleChangePage(data)}
              />
            </div>
          </div>
        </div>
      </div>
      <div className={styles.contentBlock}>
        {activeTab === recommend ? (
          <CoacheeMediaRecommended queryVariables={queryVariables} />
        ) : (
          <CoacheeMediaAll queryVariables={queryVariables} theme={theme} />
        )}
      </div>
    </div>
  );
};

export default CoacheeMediaResources;
