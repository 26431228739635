import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Icon, Pagination } from 'semantic-ui-react';
import { useQuery } from 'urql';
import SearchInput from '../../components/SearchInput/SearchInput';
import { ListMyCourseSearchBy, ListMyCoursesOrderBy, SortDirection, STATUS_CODE, UserRoles } from '../../models/enum';
import { COURSE_list_courses } from '../../services/apis/courseApis';
import { getUserRoleID } from '../../utilities/helper';
import CoachCourseSuperviseTable from './CoachCourseSuperviseTable';
import styles from './coachCourseSupervisionPage.module.scss';

const CoachCourseSupervisionPage = () => {
  /*STATE*/
  const [queryVariables, setQueryVariables] = useState<any>({
    count: 20,
    page: 1,
    coach_id: getUserRoleID(UserRoles.COACH),
    order_by: ListMyCoursesOrderBy.session_index,
    direction: SortDirection.DESC,
  });

  /*METHODS*/
  const { t: translate } = useTranslation();

  const handleSort = (direction: SortDirection, order_by: ListMyCoursesOrderBy) => {
    setQueryVariables({ ...queryVariables, direction, order_by });
  };

  //TODO : ADD SEARCH  FOR LISTMYCOURSES API
  const handleSearch = (searchInput: string) => {
    setQueryVariables({
      ...queryVariables,
      page: 1,
      search_by: ListMyCourseSearchBy.coachee_name,
      search_value: searchInput,
    });
  };

  const [listMyCourses] = useQuery({
    query: COURSE_list_courses,
    variables: queryVariables,
    requestPolicy: 'cache-and-network',
  });

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column mobile='16' tablet='16' computer='16' widescreen='16' largeScreen='14'>
          <div className={styles.wrapper}>
            <div className={styles.toolsBlock}>
              <Grid>
                <Grid.Row>
                  <Grid.Column mobile='16' tablet='16' computer='16' largeScreen='16' widescreen='5'>
                    <div className={styles.legendsBlock}>
                      <span className={styles.legendTitle}>{translate('Legends of status')} : </span>
                      <div className={`${styles.legendItem} ${styles.normalLegend}`}>: {translate('Normal')}</div>
                      <div className={`${styles.legendItem} ${styles.anomalyLegend}`}>: {translate('Anomaly')}</div>
                      <div className={`${styles.legendItem} ${styles.delayLegend}`}>: {translate('Delay')}</div>
                    </div>
                  </Grid.Column>

                  <Grid.Column mobile='16' tablet='16' computer='16' largeScreen='16' widescreen='11'>
                    <div className={styles.searchAndPaginatorBlock}>
                      <div className={styles.searchBar}>
                        <SearchInput
                          placeholder={translate('Search a coachee')}
                          handleSearch={(searchInput) => handleSearch(searchInput)}
                        />
                      </div>
                      <div className={styles.pagination} id='paginationId'>
                        <Pagination
                          defaultActivePage={1}
                          ellipsisItem={{
                            content: <Icon name='ellipsis horizontal' />,
                            icon: true,
                          }}
                          firstItem={null}
                          lastItem={null}
                          prevItem={{
                            content: <Icon name='angle left' />,
                            icon: true,
                          }}
                          nextItem={{
                            content: <Icon name='angle right' />,
                            icon: true,
                          }}
                          totalPages={1}
                          // onPageChange={(event, data) => handleChangePage(data)}
                        />
                      </div>
                    </div>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </div>
            {+listMyCourses.data?.listMyCourses?.status_code === +STATUS_CODE.SUCCESS && (
              <CoachCourseSuperviseTable
                courses={listMyCourses.data.listMyCourses.courses}
                handleSort={(direction, order_by) => handleSort(direction, order_by)}
                currentDirection={queryVariables.direction}
                currentOrderBy={queryVariables.order_by}
              />
            )}
          </div>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default CoachCourseSupervisionPage;
