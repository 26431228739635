import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Grid, Segment } from 'semantic-ui-react';
import { useDispatch } from 'react-redux';
import Axios from 'axios';
import { useMutation } from 'urql';
import moment from 'moment';

import { ISession } from '../../models/type';
import {
  calendlyScheduledEventURL,
  checkCanJoinSession,
  checkOnTimeInMinute,
  convertMinuteToHour,
  getUserRoleID,
} from '../../utilities/helper';
import styles from './coachCardCourse.module.scss';
import { SESSION_cancel, SESSION_start } from '../../services/apis/sessionApis';
import {
  STATUS_CODE,
  SESSION_CONSTANT,
  COACH_Actions,
  SESSION_STATUS,
  CalendlyStatus,
  UserRoles,
  MaterialIconName,
} from '../../models/enum';
import AlertModal from '../../components/Common/AlertModal/AlertModal';
import EditVideoConferenceLinkModal from '../../components/Common/EditVideoConeferenceLinkModal/EditVideoConferenceLinkModal';
import SwitchSessionTypeButton from '../../components/SwitchSessionTypeButton/SwitchSessionTypeButton';
import { shouldDisplayConferenceLink, shouldDisplaySetLinkReminder } from '../../functions/session-card';
import { shouldDisplayJoinSessionBtn } from '../../functions/coach-card-course';
import PreviewDiagnosisResultModalIcon from '../../components/PreviewDiagnosisResultModalIcon/PreviewDiagnosisResultModalIcon';

interface Props {
  session?: ISession;
  refetchListCoachSession: () => void;
}

const CoachCardCourse: React.FC<Props> = (props: Props) => {
  /*PROPS*/
  const [state, setState] = useState({
    showAlertModal: false,
    showConfirmCancelCalendly: false,
    showAlertSessionNotCanceled: false,
    showEditConferenceLink: false,
  });

  /*METHODS*/
  const { t: translate } = useTranslation();
  const dispatch = useDispatch();
  const [, executeCancelSession] = useMutation(SESSION_cancel);
  const [, executeStartSession] = useMutation(SESSION_start);

  const handleAgreeCancelSession = (sessionToCancel: ISession) => {
    const myNewWindow = window.open();
    //Call Calendly to get cancellation link:
    Axios({
      url: calendlyScheduledEventURL(sessionToCancel.calendly_event_uri),
      method: 'GET',
      headers: { Authorization: `Bearer ${sessionToCancel.coach.token}` },
    })
      .then((res) => {
        myNewWindow.location.href = res.data.collection[0].cancel_url;
        localStorage.setItem(sessionToCancel.id, SESSION_STATUS.cancel_waiting);
        setState({ ...state, showAlertModal: false, showConfirmCancelCalendly: true });
      })
      .catch((err) => console.error(err));
  };

  const handleConfirmDidNotCancel = () => {
    setState({ ...state, showConfirmCancelCalendly: false });
  };

  const handleAbortCancellation = (sessionID: string) => {
    localStorage.removeItem(sessionID);
    setState({ ...state, showAlertSessionNotCanceled: false });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleConfirmCanceled = (sessionToCancel: ISession) => {
    //Check status event on cadenly is canceled
    Axios({
      url: calendlyScheduledEventURL(sessionToCancel.calendly_event_uri),
      method: 'GET',
      headers: { Authorization: `Bearer ${sessionToCancel.coach.token}` },
    })
      .then((res) => {
        if (res.data.collection[0].status === CalendlyStatus.canceled) {
          executeCancelSession({ session_id: sessionToCancel.id })
            .then((res) => {
              if (+res.data.cancelSession.status_code === +STATUS_CODE.SUCCESS) {
                localStorage.removeItem(sessionToCancel.id);
                props.refetchListCoachSession();
                setState({ ...state, showConfirmCancelCalendly: false });
              } else console.error(res.error);
            })
            .catch((err) => console.error(err));
        } else {
          setState({ ...state, showConfirmCancelCalendly: false, showAlertSessionNotCanceled: true });
        }
      })
      .catch((err) => console.error(err));
  };

  const handleJoinSession = (session: ISession) => {
    if (session.status === SESSION_STATUS.started) {
      dispatch({ type: COACH_Actions.select_session_to_join, payload: session });
      return;
    }
    if (
      session.status === SESSION_STATUS.started_waiting &&
      session.attendees.includes(getUserRoleID(UserRoles.COACH))
    ) {
      dispatch({ type: COACH_Actions.select_session_to_join, payload: session });
      return;
    }
    //Call API to start session
    executeStartSession({
      session_id: session.id,
    })
      .then((res) => {
        if (+res.data.startSession.status_code === +STATUS_CODE.SUCCESS) {
          dispatch({
            type: COACH_Actions.select_session_to_join,
            payload: {
              ...session,
              status: res.data.startSession.session.status,
              attendees: res.data.startSession.session.attendees,
            },
          });
        } else console.error(res.error);
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    //IS this session has cancel wating
    const isCancelWaiting = localStorage.getItem(props.session.id) === SESSION_STATUS.cancel_waiting;
    if (!isCancelWaiting) return; // NO
    handleConfirmCanceled(props.session); // YES
  }, [handleConfirmCanceled, props.session]);

  return (
    <>
      <Segment
        className={`${styles.customSegment} ${
          shouldDisplaySetLinkReminder(
            props.session.planned_at,
            props.session.videoconference_link,
            props.session.is_offline,
          ) && styles.redBorder
        } ${props.session.is_offline && styles.violetBorder}`}>
        <div className={styles.topBlock}>
          <Grid>
            <Grid.Row>
              <Grid.Column computer='6' tablet='6' mobile='16'>
                <div className={styles.topLeft}>
                  <span className={`${styles.text1} topLevelText `}>
                    {translate('Session') +
                      ' ' +
                      `${props.session.current_index}/${props.session.course_context.number_of_sessions}`}
                  </span>
                  <span className={`${styles.text1}`}>{props.session.course_context.offer_name}</span>
                </div>
              </Grid.Column>
              <Grid.Column computer='10' tablet='10' mobile='16'>
                <div className={styles.topRight}>
                  <span className={`${styles.text2}`}>{convertMinuteToHour(props.session.duration, 'h:mm')}</span>
                  <span className={`${styles.text3}`}>
                    Date{' '}
                    <span className={`${styles.text4}`}>{moment(props.session.planned_at).format('DD/MM/YYYY')}</span>
                  </span>
                  <span className={`${styles.text3}`}>
                    Heure <span className={`${styles.text4}`}>{moment(props.session.planned_at).format('HH:mm')}</span>
                  </span>
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>

        <div className={styles.bottomBlock}>
          <Grid>
            <Grid.Row>
              <Grid.Column computer='6' tablet='6' mobile='8'>
                <div className={styles.bottomLeft}>
                  <span className={`${styles.text5} ${styles.text}`}>
                    {translate('Coachee') +
                      ' : ' +
                      props.session.coachee.first_name +
                      ' ' +
                      props.session.coachee.last_name}
                  </span>
                  <span className={`${styles.text6} ${styles.text}`}>
                    <span className={styles.bold700}>{translate('Client')}</span> :{' '}
                    {props.session.course_context.client_name}
                  </span>
                  <span className={`${styles.text6} ${styles.text}`}>
                    <span className={styles.bold700}>{translate('Program')}</span> :{' '}
                    {props.session.course_context.program_name}
                  </span>
                  <div className={styles.actionContainer}>
                    <SwitchSessionTypeButton
                      sessionID={props.session.id}
                      sessionStatus={props.session.status}
                      isOffline={props.session.is_offline}
                      triggerBy='coach'
                      sessionDate={props.session.planned_at}
                    />
                    {props.session.course_context.self_diagnosis && (
                      <PreviewDiagnosisResultModalIcon courseID={props.session.course_context.course_id} />
                    )}
                  </div>
                </div>
              </Grid.Column>
              <Grid.Column computer='4' tablet='4' mobile='8'>
                {shouldDisplayConferenceLink(props.session.status, props.session.is_offline) && (
                  <div className={styles.bottomMid}>
                    <div
                      className={`${styles.editVideoLinkBlock} ${
                        !props.session.videoconference_link && styles.needSetVideoLink
                      }`}
                      onClick={() => setState({ ...state, showEditConferenceLink: true })}>
                      <span
                        className={`${styles.videoIcon} ${
                          !!props.session.videoconference_link === false && styles.videoIconDanger
                        } material-icons-outlined`}>
                        {MaterialIconName.video_call}
                      </span>
                      <span
                        className={`${styles.text7} ${
                          !!props.session.videoconference_link === false && styles.text7Danger
                        }`}>
                        {props.session.videoconference_link
                          ? translate('Edit meeting link')
                          : translate('Insert new video conference link')}
                      </span>
                    </div>
                  </div>
                )}
              </Grid.Column>
              <Grid.Column computer='6' tablet='6' mobile='16'>
                <div className={styles.bottomRight}>
                  {shouldDisplayJoinSessionBtn(props.session.is_offline) && (
                    <Button
                      className={`${styles.joinBtn} ${
                        !checkCanJoinSession(props.session.planned_at, props.session.duration, props.session.status) &&
                        ![`${SESSION_STATUS.started}`, `${SESSION_STATUS.started_waiting}`].includes(
                          props.session.status,
                        ) &&
                        styles.disableBtn
                      }`}
                      disabled={
                        !checkCanJoinSession(props.session.planned_at, props.session.duration, props.session.status) &&
                        ![`${SESSION_STATUS.started}`, `${SESSION_STATUS.started_waiting}`].includes(
                          props.session.status,
                        )
                      }
                      onClick={() => handleJoinSession(props.session)}>
                      <span className='buttonText topLevelText'>{translate('Join')}</span>
                    </Button>
                  )}
                  <Button
                    className={`${styles.cancelBtn} ${
                      props.session.status === SESSION_STATUS.started && styles.invisible
                    }`}
                    onClick={() => setState({ ...state, showAlertModal: true })}>
                    <span className='buttonText topLevelText'>{translate('Cancel session')}</span>
                  </Button>
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </Segment>
      {state.showAlertModal && (
        <AlertModal
          title={translate('Confirm cancel session title')}
          contentQuestion={
            checkOnTimeInMinute(SESSION_CONSTANT.dealine_before_cancel, props.session.planned_at)
              ? translate('Do you want cancel this session_stronger')
              : translate('Do you want cancel this session')
          }
          content={
            checkOnTimeInMinute(SESSION_CONSTANT.dealine_before_cancel, props.session.planned_at)
              ? translate('Coach click yes to cancel session_stronger')
              : translate('Click yes to cancel session')
          }
          cancel={() => setState({ ...state, showAlertModal: false })}
          agree={() => handleAgreeCancelSession(props.session)}
        />
      )}
      {state.showConfirmCancelCalendly && (
        <AlertModal
          title={translate('Confirm cancel session title')}
          contentQuestion={translate('Have you cancelled your session on Calendly')}
          content={''}
          hideCloseIcon={true}
          cancelButtonTitle='No'
          cancel={() => handleConfirmDidNotCancel()}
          agree={() => handleConfirmCanceled(props.session)}
        />
      )}
      {state.showAlertSessionNotCanceled && (
        <AlertModal
          title={translate('Confirm cancel session title')}
          hideCloseIcon={true}
          contentQuestion={translate('Session has not been canceled on Calendly')}
          content={translate('Coach_Session was not canceled, do you want to continue cancel', {
            sessionNo: props.session.current_index,
            programName: props.session.course_context.program_name,
            clientName: props.session.course_context.client_name,
          })}
          cancelButtonTitle='No'
          agreeButtonTitle='Yes'
          cancel={() => handleAbortCancellation(props.session.id)}
          agree={() => handleAgreeCancelSession(props.session)}
        />
      )}
      {state.showEditConferenceLink && (
        <EditVideoConferenceLinkModal
          cancel={() => setState({ ...state, showEditConferenceLink: false })}
          session={props.session}
          refetchListCoachSession={props.refetchListCoachSession}
        />
      )}
    </>
  );
};

export default React.memo(CoachCardCourse);
