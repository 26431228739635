import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Checkbox, Icon } from 'semantic-ui-react';
import { useMutation } from 'urql';
import { COURSE_STATUS, PROGRAMMANGER_ACTIONS, STATUS_CODE } from '../../models/enum';
import { ICoachee_extended, ICourseRef } from '../../models/type';
import { PROGRAMMANAGER_unassign_coachee } from '../../services/apis/programMangerApis';
import styles from './listCoacheeModalOptions.module.scss';
import moment from 'moment';

interface Props {
  onClose: () => void;
  handleRefetchCoacheeList: () => void;
  refetchProgramDetail: () => void;
}

const ModalOptionUnassignCourse = (props: Props) => {
  const [state, setState] = useState({
    showSuccess: false,
    selectedCourses: [],
  });
  const selectedCoacheeSelector: ICoachee_extended = useSelector(
    (state: any) => state.programManagerReducer.listOfCoacheePage.selectedCoacheeList[0],
  );

  const { t: translate } = useTranslation();

  const dispatch = useDispatch();

  const [, executeUnassignCoacheeFromCourse] = useMutation(PROGRAMMANAGER_unassign_coachee);

  const handleOnChangeCheckbox = (value: any) => {
    const tempSelectedCourse = [...state.selectedCourses];
    const indexFound = tempSelectedCourse.findIndex((item: string) => item === value);
    if (indexFound === -1) {
      tempSelectedCourse.push(value);
    } else {
      tempSelectedCourse.splice(indexFound, 1);
    }
    setState({ ...state, selectedCourses: tempSelectedCourse });
  };

  const handleSubmit = () => {
    executeUnassignCoacheeFromCourse({ courses_ids: state.selectedCourses })
      .then((res) => {
        if (+res.data?.unassignCoursesFromCoachee.status_code === +STATUS_CODE.SUCCESS) {
          props.handleRefetchCoacheeList();
          setState({ ...state, showSuccess: true });
          props.refetchProgramDetail();
          dispatch({
            type: PROGRAMMANGER_ACTIONS.clear_selected_coachee,
          });
        } else {
          console.error(res.error);
        }
      })
      .catch((err) => console.error(err));
  };

  const UnAssignOneCourseContent = () => {
    return (
      <div className={styles.unAssignWrapper}>
        <p className={styles.content}>
          {translate('Unassign this coacheeName') + ' '}
          <span className={styles.textHiglightInfo}>
            {selectedCoacheeSelector.user_infos.first_name + ' ' + selectedCoacheeSelector.user_infos.last_name}
          </span>{' '}
          {translate('from course')}{' '}
          <span className={styles.textHiglightInfo}>{selectedCoacheeSelector.courses[0].offer_configuration.name}</span>
          {' ?'}
        </p>
      </div>
    );
  };

  const UnAssignMultiCourseContent = () => {
    const renderCheckbox = () => {
      return selectedCoacheeSelector.courses.map((item: ICourseRef) => {
        const isCourseOpen: boolean = item.status === COURSE_STATUS.open;
        return (
          <div key={item.id} className={`${styles.courseCheckboxItem} ${!isCourseOpen && styles.disabledCheckbox}`}>
            <Checkbox
              className={styles.checkbox}
              disabled={!isCourseOpen}
              value={item.id}
              onChange={(_e, { value }) => handleOnChangeCheckbox(value)}
              checked={state.selectedCourses.includes(item.id)}
            />
            <span className={styles.courseCheckboxName}>{item.offer_configuration.name}</span>
            {isCourseOpen ? (
              <span className={styles.courseAdditionalText}>
                {' (' + translate('Assign on') + ' : ' + moment(item.assigned_at).format('DD/MM/YYYY') + ')'}
              </span>
            ) : (
              <span className={styles.courseAdditionalText}>{' (' + translate('Course already started') + ')'}</span>
            )}
          </div>
        );
      });
    };

    return (
      <div className={styles.unAssignMultiWrapper}>
        <p className={styles.content}>
          {translate('Which course you want to unassign')}{' '}
          <span className={styles.textHiglightInfo}>
            {selectedCoacheeSelector.user_infos.first_name} {selectedCoacheeSelector.user_infos.last_name}
          </span>
          {' ?'}
        </p>
        <div className={styles.multiCourseCheckboxBlock}>{renderCheckbox()}</div>
      </div>
    );
  };

  /*EFFECT*/
  useEffect(() => {
    if (!selectedCoacheeSelector) return;
    if (selectedCoacheeSelector.courses.length === 1) {
      setState({
        ...state,
        selectedCourses: [...state.selectedCourses, selectedCoacheeSelector.courses[0].id],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCoacheeSelector]);

  if (state.showSuccess) {
    return (
      <>
        <div className={styles.successBlock}>
          <Icon name='check' size='huge' className={styles.iconCheck} />
          <span className={`${styles.successText} topLevelText`}>{translate('Congratulation')}</span>
          <p className={styles.successMessage}>{translate('Unassign course success')}</p>
        </div>
        <div className={styles.btnBlock}>
          <Button className={`${styles.cancelBtn} ${styles.styleDanger}`} onClick={props.onClose}>
            <span className='buttonText topLevelText'>{translate('Close')}</span>
          </Button>
        </div>
      </>
    );
  }

  return (
    <>
      {selectedCoacheeSelector.courses.length === 1 ? UnAssignOneCourseContent() : UnAssignMultiCourseContent()}
      <div className={styles.btnBlock}>
        <Button className={styles.cancelBtn} onClick={props.onClose}>
          <span className='buttonText topLevelText'>{translate('Cancel')}</span>
        </Button>
        <Button
          className={`${styles.agreeBtn} ${state.selectedCourses.length && styles.styleDanger} `}
          disabled={!state.selectedCourses.length}
          onClick={handleSubmit}>
          <span className='buttonText topLevelText'>{translate('Yes')}</span>
        </Button>
      </div>
    </>
  );
};

export default ModalOptionUnassignCourse;
