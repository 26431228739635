import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from 'semantic-ui-react';
import styles from './searchContact.module.scss';

interface Props {
  handleSearch: (value: string) => void;
}

const SearchContact: React.FC<Props> = (props: Props) => {
  /*STATE*/
  const [state, setState] = useState({
    input: '',
  });

  /*METHODS*/

  const { t: translate } = useTranslation();

  const handleOnChange = (e: React.FormEvent<HTMLInputElement>) => {
    setState({ ...state, [e.currentTarget.name]: e.currentTarget.value });
  };

  return (
    <form
      autoComplete="off"
      className={styles.wrapper}
      onSubmit={(e) => {
        e.preventDefault();
        if (!state.input) return;
        props.handleSearch(state.input);
      }}
    >
      <input
        type="text"
        autoComplete="off"
        className={styles.input}
        placeholder={translate('Search contact')}
        name="input"
        value={state.input}
        onChange={(e) => handleOnChange(e)}
        spellCheck={false}
      />
      <button type="submit" className={styles.submitBtn}>
        <Icon name="search" className={styles.searchIcon} />
      </button>
    </form>
  );
};

export default SearchContact;
