import React, { useMemo } from 'react';
import DiagnosisFinishedResult from '../DiagnosisFinishedResult/DiagnosisFinishedResult';
import styles from './coacheeAutoDiagnosis.module.scss';

const CoacheeAutoDiagnosis: React.FC = () => {
  /*PROPS*/
  const courseId = useMemo(() => {
    return window.location.search.slice(1);
  }, []);

  /*METHODS*/

  /*MAIN RETURN*/

  return (
    <div className={styles.wrapper}>
      <DiagnosisFinishedResult showCheckIcon={false} showNextButton={false} courseID={courseId} />
    </div>
  );
};

export default React.memo(CoacheeAutoDiagnosis);
