export const PROGRAM_fetch_programs_by_user_role_ID = `
query ($page : Int!, $order_by: ProgramOrderableColumn, $direction : SortDirection!) {
    listMyPrograms(
        page: $page, 
        order_by: $order_by, 
        direction: $direction
    ) {
        status_code
        programs {
            id
            name
            status
            creation_date
            close_date
            client {
                name
            }
            offerConfigurations {
                id
                name
                offer_id
                number_of_sessions
                duration_of_session
                has_objectives
                has_self_diagnosis
                number_of_coachees
                number_of_courses
            }
            number_of_courses
            number_of_program_managers
            number_of_coaches
            number_of_coachees
        }
        paginatorInfo {
            currentPage
            lastPage
        }
    }
}
`;

export const PROGRAM_export_coachees = `
query ($program_id : ID!) {
    exportCoacheesFromProgram(id: $program_id) {
        status_code
        file_url
    }
}
`;
