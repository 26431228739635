import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Grid } from 'semantic-ui-react';
import { useQuery } from 'urql';
import EditableProjectDirector from '../../components/EditableProjectDirector/EditableProjectDirector';
import PrimaryLayout from '../../layouts/PrimaryLayout/PrimaryLayout';
import { MaterialIconName, Role_ID, STATUS_CODE, UserRoles } from '../../models/enum';
import { PROJECTDIRECTOR_get_detail } from '../../services/apis/projectDirectorApis';
import { getUserRoleID } from '../../utilities/helper';
import styles from './projectDirectorProfilePage.module.scss';

const ProjectDirectorProfilePage = () => {
  /*METHODS*/
  const { t: translate } = useTranslation();
  const navigate = useNavigate();

  const [projectDirectorDetail] = useQuery({
    query: PROJECTDIRECTOR_get_detail,
    variables: { id: getUserRoleID(UserRoles.PROJECT_DIRECTOR) },
    requestPolicy: 'cache-and-network',
  });

  const gotToProfile = () => navigate(`/${Role_ID.PROJECT_DIRECTOR}/profile`);

  return (
    <PrimaryLayout
      primaryTitle={'Mes parcours'}
      primaryTitleIcon={MaterialIconName.assignment}
      role_name={translate('Project director')}
      toProfile={gotToProfile}
      hideUserIcon={true}
      hideMessageIcon={true}
      onClickPrimaryTitle={() => navigate(`/${Role_ID.PROJECT_DIRECTOR}`)}>
      <Grid className={styles.gridWrapper}>
        <Grid.Row>
          <Grid.Column mobile='16' tablet='16' computer='16' widescreen='14' largeScreen='13'>
            <div className={styles.wrapper}>
              <div className={styles.titleBlock}>
                <h1 className={styles.title}>{translate('My profile')}</h1>
              </div>
              {+projectDirectorDetail.data?.detailProjectDirector?.status_code === +STATUS_CODE.SUCCESS && (
                <EditableProjectDirector
                  projectDirector={projectDirectorDetail.data.detailProjectDirector.user_infos}
                />
              )}
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </PrimaryLayout>
  );
};

export default ProjectDirectorProfilePage;
