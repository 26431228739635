import React, { useEffect, useState } from 'react';
import { Button, Grid, Segment } from 'semantic-ui-react';
import {
  CalendlyStatus,
  COACHEE_ACTIONS,
  MaterialIconName,
  SESSION_ACTIONS,
  SESSION_CONSTANT,
  SESSION_STATUS,
  STATUS_CODE,
} from '../../models/enum';
import { ISession } from '../../models/type';
import styles from './coacheeSessionCard.module.scss';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import CalendlyComp from '../CalendlyComp/CalendlyComp';
import { calendlyScheduledEventURL, checkCanJoinSession, checkOnTimeInMinute } from '../../utilities/helper';
import { useMutation } from 'urql';
import { SESSION_cancel, SESSION_start } from '../../services/apis/sessionApis';
import { useDispatch } from 'react-redux';
import AlertModal from '../Common/AlertModal/AlertModal';
import Axios from 'axios';
import EditVideoConferenceLinkModal from '../Common/EditVideoConeferenceLinkModal/EditVideoConferenceLinkModal';
import { shouldDisplayConferenceLink, shouldDisplaySetLinkReminder } from '../../functions/session-card';
import SwitchSessionTypeButton from '../SwitchSessionTypeButton/SwitchSessionTypeButton';
import FinishSessionBtn from './FinishSessionBtn';

interface Props {
  session: ISession;
  totalSession: number;
  offerConfigName: string;
  clientName: string;
  reFetchGetCourse: () => void;
}

const CoacheeSessionCard: React.FC<Props> = (props: Props) => {
  //STATE
  const [state, setState] = useState({
    showAlertModal: false,
    showConfirmCancelCalendly: false,
    showAlertSessionNotCanceled: false,
    showEditConferenceLink: false,
  });

  //METHODS
  const { t: translate } = useTranslation();
  const dispatch = useDispatch();
  const [, executeStartSession] = useMutation(SESSION_start);
  const [, executeCancelSession] = useMutation(SESSION_cancel);

  const handleJoinSession = (sessionID: string) => {
    //CALL API TO CHANGE STATUS FROM PLANNED TO STARTED_WAITING
    executeStartSession({ session_id: sessionID })
      .then((res) => {
        if (+res.data.startSession.status_code === +STATUS_CODE.SUCCESS) {
          dispatch({ type: SESSION_ACTIONS.update_status, payload: res.data.startSession.session });
        } else console.error(res.error);
      })
      .catch((err) => console.error(err));
  };

  const handleAgreeCancelSession = (sessionToCancel: ISession) => {
    const newWindow = window.open();
    //Call Calendly to get cancellation link:
    Axios({
      url: calendlyScheduledEventURL(sessionToCancel.calendly_event_uri),
      method: 'GET',
      headers: { Authorization: `Bearer ${sessionToCancel.coach.token}` },
    })
      .then((res) => {
        newWindow.location.href = res.data.collection[0].cancel_url;
        localStorage.setItem(sessionToCancel.id, SESSION_STATUS.cancel_waiting);
        setState({ ...state, showAlertModal: false, showConfirmCancelCalendly: true });
      })
      .catch((err) => console.error(err));
  };

  const handleConfirmCanceled = (sessionToCancel: ISession) => {
    //Check status event on cadenly is canceled
    Axios({
      url: calendlyScheduledEventURL(sessionToCancel.calendly_event_uri),
      method: 'GET',
      headers: { Authorization: `Bearer ${sessionToCancel.coach.token}` },
    })
      .then((res) => {
        if (res.data.collection[0].status === CalendlyStatus.canceled) {
          executeCancelSession({ session_id: sessionToCancel.id })
            .then((res) => {
              if (+res.data.cancelSession.status_code === +STATUS_CODE.SUCCESS) {
                localStorage.removeItem(sessionToCancel.id);
                props.reFetchGetCourse();
                setState({ ...state, showConfirmCancelCalendly: false });
              } else console.error(res.error);
            })
            .catch((err) => console.error(err));
        } else {
          setState({ ...state, showConfirmCancelCalendly: false, showAlertSessionNotCanceled: true });
        }
      })
      .catch((err) => console.error(err));
  };

  const handleConfirmDidNotCancel = () => {
    setState({ ...state, showConfirmCancelCalendly: false });
  };

  const handleAbortCancellation = (sessionID: string) => {
    localStorage.removeItem(sessionID);
    setState({ ...state, showAlertSessionNotCanceled: false });
  };

  const handleUpdateVideoConferenceLink = (videoConfLink: string) => {
    dispatch({
      type: COACHEE_ACTIONS.update_video_conference_link,
      payload: { videoConfLink: videoConfLink, sessionID: props.session.id },
    });
  };

  const renderJoinOrFinishButton = (isOffline: boolean) => {
    if (isOffline) return <FinishSessionBtn session={props.session} queryGetCourse={props.reFetchGetCourse} />;

    return (
      <Button
        className={`${styles.joinBtn} ${
          !checkCanJoinSession(props.session.planned_at, props.session.duration, props.session.status) &&
          styles.disableBtn
        }`}
        disabled={!checkCanJoinSession(props.session.planned_at, props.session.duration, props.session.status)}
        onClick={() => handleJoinSession(props.session.id)}>
        <span className='buttonText topLevelText'>{translate('Join')}</span>
      </Button>
    );
  };

  /*EFFECT*/
  useEffect(() => {
    //IS this session has cancel wating
    const isCancelWaiting = localStorage.getItem(props.session.id) === SESSION_STATUS.cancel_waiting;
    if (!isCancelWaiting) return; // NO
    handleConfirmCanceled(props.session); // YES
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Segment
        className={`${styles.sessionBlock} ${
          shouldDisplaySetLinkReminder(
            props.session.planned_at,
            props.session.videoconference_link,
            props.session.is_offline,
          ) && styles.redBorder
        } ${props.session.is_offline && styles.violetBorder}`}>
        <div className={`${styles.topBlock} ${styles.borderBottom}`}>
          <span>{`SESSION ${props.session.current_index}/${props.totalSession}`}</span>
          {[`${SESSION_STATUS.planned}`, `${SESSION_STATUS.started_waiting}`].includes(props.session.status) && (
            <div className={styles.text2Wrapper}>
              <span className={styles.text2}>
                {translate('Date')}
                <span>{moment(props.session.planned_at).format('DD/MM/YYYY')}</span>
              </span>
              <span className={styles.text2}>
                {translate('Hour')}
                <span>{moment(props.session.planned_at).format('HH:mm')}</span>
              </span>
            </div>
          )}
        </div>
        <div className={styles.bottomBlock}>
          <Grid>
            <Grid.Row>
              <Grid.Column computer='6' tablet='6' mobile='16'>
                <div className={styles.leftInfo}>
                  <p className={styles.sessionStatusExplaination}>
                    {translate(
                      props.session.status === SESSION_STATUS.unplanned ? 'Waiting to be planned' : 'Scheduled',
                    )}
                  </p>
                  <p className={styles.coachName}>{`${translate('Coach')} : ${
                    props.session.coach.first_name + ' ' + props.session.coach.last_name
                  }`}</p>
                  <SwitchSessionTypeButton
                    sessionID={props.session.id}
                    isOffline={props.session.is_offline}
                    sessionStatus={props.session.status}
                    triggerBy='coachee'
                  />
                </div>
              </Grid.Column>
              <Grid.Column computer='5' tablet='5' mobile='16'>
                {shouldDisplayConferenceLink(props.session.status, props.session.is_offline) && (
                  <div className={styles.bottomMid}>
                    <div
                      className={`${styles.editVideoLinkBlock} ${
                        !props.session.videoconference_link && styles.needSetVideoLink
                      }`}
                      onClick={() => setState({ ...state, showEditConferenceLink: true })}>
                      <span
                        className={`${styles.videoIcon} ${
                          !!props.session.videoconference_link === false && styles.videoIconDanger
                        } material-icons-outlined`}>
                        {MaterialIconName.video_call}
                      </span>

                      <span
                        className={`${styles.text7} ${
                          !!props.session.videoconference_link === false && styles.text7Danger
                        }`}>
                        {props.session.videoconference_link
                          ? translate('Edit meeting link')
                          : translate('Insert new video conference link')}
                      </span>
                    </div>
                  </div>
                )}
              </Grid.Column>
              <Grid.Column computer='5' tablet='5' mobile='16'>
                <div className={styles.rightBtn}>
                  {props.session.status === SESSION_STATUS.unplanned ? (
                    <CalendlyComp
                      sessionDuration={props.session.duration}
                      sessionID={props.session.id}
                      course={props.offerConfigName}
                      token={props.session.coach.token}
                      session={`${props.session.current_index}`}
                      clientName={props.clientName}
                      reFetchGetCourse={props.reFetchGetCourse}
                    />
                  ) : (
                    renderJoinOrFinishButton(props.session.is_offline)
                  )}
                  {props.session.status === SESSION_STATUS.unplanned ? (
                    ''
                  ) : (
                    <Button
                      className={styles.cancelSessionBtn}
                      onClick={() => setState({ ...state, showAlertModal: true })}>
                      <span className='secondary_button_text topLevelText'>{translate('Cancel session')}</span>
                    </Button>
                  )}
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </Segment>
      {state.showAlertModal && (
        <AlertModal
          title={translate('Confirm cancel session title')}
          contentQuestion={
            checkOnTimeInMinute(SESSION_CONSTANT.dealine_before_cancel, props.session.planned_at)
              ? translate('Do you want cancel this session_stronger')
              : translate('Do you want cancel this session')
          }
          content={
            checkOnTimeInMinute(SESSION_CONSTANT.dealine_before_cancel, props.session.planned_at)
              ? translate('Click yes to cancel session_stronger')
              : translate('Click yes to cancel session')
          }
          cancel={() => setState({ ...state, showAlertModal: false })}
          agree={() => handleAgreeCancelSession(props.session)}
        />
      )}
      {state.showConfirmCancelCalendly && (
        <AlertModal
          title={translate('Confirm cancel session title')}
          contentQuestion={translate('Have you cancelled your session on Calendly')}
          content={''}
          hideCloseIcon={true}
          cancelButtonTitle='No'
          cancel={() => handleConfirmDidNotCancel()}
          agree={() => handleConfirmCanceled(props.session)}
        />
      )}
      {state.showAlertSessionNotCanceled && (
        <AlertModal
          title={translate('Confirm cancel session title')}
          hideCloseIcon={true}
          contentQuestion={translate('Session has not been canceled on Calendly')}
          content={translate('Session was not canceled, do you want to continue cancel')}
          cancelButtonTitle='No'
          agreeButtonTitle='Yes'
          cancel={() => handleAbortCancellation(props.session.id)}
          agree={() => handleAgreeCancelSession(props.session)}
        />
      )}
      {state.showEditConferenceLink && (
        <EditVideoConferenceLinkModal
          cancel={() => setState({ ...state, showEditConferenceLink: false })}
          session={props.session}
          onSuccess={(videoConfLink) => handleUpdateVideoConferenceLink(videoConfLink)}
        />
      )}
    </div>
  );
};

export default React.memo(CoacheeSessionCard);
