import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button, Icon } from 'semantic-ui-react';
import { useQuery } from 'urql';
import { COURSE_STATUS, Role_ID, STATUS_CODE } from '../../models/enum';
import { ICourse, IDiagnosisAnswer, IDiagnosisCoacheeAnswer, IDiagnosisQuestionCollection } from '../../models/type';
import { COURSE_get_diagnosis_synthesis } from '../../services/apis/courseApis';
import styles from './diagnosisFinishedResult.module.scss';
import { capitalizeFirstLetter } from '../../utilities/helper';
import moment from 'moment';
import ResultCheckBox from './ResultCheckBox';

interface Props {
  selectedCourse?: ICourse;
  courseID?: string;
  showCheckIcon: boolean;
  showNextButton: boolean;
}

const DiagnosisFinishedResult: React.FC<Props> = (props: Props) => {
  /*PROPS*/

  const [diagnosisSynthesisResult] = useQuery({
    query: COURSE_get_diagnosis_synthesis,
    variables: {
      course_id: props.selectedCourse ? props.selectedCourse.id : props.courseID,
    },
    requestPolicy: 'network-only',
  });

  /*METHODS*/

  const { t: translate } = useTranslation();

  const navigate = useNavigate();

  const handleSubmit = useCallback(() => {
    navigate(`/${Role_ID.COACHEE}/coach-selection`, {
      state: {
        selectedCourse: { ...props.selectedCourse, status: COURSE_STATUS.started_coach_selection },
      },
    });
  }, [navigate, props.selectedCourse]);

  const getAnswerCollectionTitle = (titlesCollection: any, questionID: string) => {
    let foundTitle = '';
    for (let key in titlesCollection) {
      if (titlesCollection[key].includes(questionID)) foundTitle = key;
    }
    return foundTitle;
  };

  const renderDateAnswer = (answerArray: Array<IDiagnosisAnswer>) => {
    const tempArray = [...answerArray];
    tempArray.sort((a, b) => +a.value - +b.value);
    return translate(moment(tempArray[0].value, 'MM').format('MMM')) + ' ' + tempArray[1].value;
  };

  const renderAnswers = (
    coacheeAnswerArray: Array<IDiagnosisCoacheeAnswer>,
    questionCollection: Array<IDiagnosisQuestionCollection>,
  ) => {
    const renderObject: any = new Map();
    const renderArray = [];
    const titlesCollection: any = {};
    questionCollection.forEach((item) => {
      let arrayRef: Array<string> = [];
      if (titlesCollection[item.title]) {
        arrayRef = [...titlesCollection[item.title]];
      }
      const flattenQuestionIDArray = item.questions.map((question) => question.id);

      titlesCollection[item.title] = [...flattenQuestionIDArray, ...arrayRef];
    });
    coacheeAnswerArray.forEach((coacheeAnswerItem) => {
      const thisCollectionTitle = getAnswerCollectionTitle(titlesCollection, coacheeAnswerItem.question.id);
      if (!renderObject.get(thisCollectionTitle)) {
        renderObject.set(thisCollectionTitle, [coacheeAnswerItem]);
      } else {
        renderObject.set(thisCollectionTitle, [...renderObject.get(thisCollectionTitle), coacheeAnswerItem]);
      }
    });
    for (const [key, value] of renderObject.entries()) {
      renderArray.push(
        <div key={key}>
          <p className={styles.question}>{capitalizeFirstLetter(key.toLowerCase())} :</p>
          {value.map((item: IDiagnosisCoacheeAnswer, index: number) => {
            if (item.question.question_type === 'radio') {
              return (
                <div key={index}>
                  <p className={styles.answer}>
                    <span className={styles.question}>{item.question.title}{' : '}</span>
                    {item.answers[0].text}
                  </p>
                </div>
              );
            } else if (item.question.question_type === 'checkbox') {
              return (
                <div key={index}>
                  {item.answers.map((answer: IDiagnosisAnswer) => {
                    return (
                      <div className={styles.multiAnswerContainer} key={answer.id}>
                        <div className={styles.multiAnswer}>
                          <ResultCheckBox answer={answer} />
                          <span className={styles.checkbox_answer}>{answer.text}</span>
                        </div>
                      </div>
                    );
                  })}
                </div>
              );
            } else if (item.question.question_type === 'date') {
              return (
                <p className={styles.answer} key={index}>
                  <span className={styles.question}>{item.question.title}{' : '}</span>
                  <span className={styles.dateAnswer}>
                    {/* {item.answers.map((answer) => answer.text)} */}
                    {renderDateAnswer(item.answers)}
                  </span>
                </p>
              );
            }

            return '';
          })}
        </div>,
      );
    }
    return renderArray;
  };

  /*MAIN RETURN*/

  if (+diagnosisSynthesisResult.error?.graphQLErrors[0]?.extensions?.error_code === +STATUS_CODE.NOTFOUND)
    return <h6>{translate("This course doesn't have self diagnosis")}</h6>;

  if (!diagnosisSynthesisResult.data) return <></>;

  return (
    <div className={styles.marginBottom}>
      {props.showCheckIcon && (
        <div className={styles.topBlock}>
          <Icon name='checkmark' className={styles.checkIcon} size='huge' />
        </div>
      )}
      <div className={styles.answerSummaryBlock}>
        {renderAnswers(
          diagnosisSynthesisResult.data.getSelfDiagnosisSynthesis.self_diagnosis.coachee_answers,
          diagnosisSynthesisResult.data.getSelfDiagnosisSynthesis.self_diagnosis.self_diagnosis_template
            .question_collections,
        )}
      </div>

      {props.showNextButton && (
        <div className={styles.buttonBlock}>
          <Button className={styles.nextBtn} onClick={handleSubmit}>
            <span className={'buttonText topLevelText'}>{translate('Next')}</span>
          </Button>
        </div>
      )}
    </div>
  );
};

export default DiagnosisFinishedResult;
