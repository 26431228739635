import React, { useState } from 'react';
import { Button } from 'semantic-ui-react';
import styles from './finishSessionBtn.module.scss';
import { useTranslation } from 'react-i18next';
import { ISession } from '../../models/type';
import { canFinishedOfflineSession } from '../../functions/session-card';
import RateSessionComp from '../RateSessionComp/RateSessionComp';
import { useSelector } from 'react-redux';

interface Props {
  session: ISession;
  queryGetCourse: () => void;
}

const FinishSessionBtn: React.FC<Props> = (props: Props) => {
  const [state, setState] = useState({
    showRateSession: false,
  });

  const upcomingSessions = useSelector((state: any) => state.coacheeReducer.myCourseManagement?.myUpComingSession);

  //Is last session : when coming sessions array length = 0
  const isLastSession: boolean = !upcomingSessions?.length;

  const { t: translate } = useTranslation();

  const openEvaluateSession = () => {
    setState({ ...state, showRateSession: true });
  };

  const closeEvaluateSession = () => {
    setState({ ...state, showRateSession: false });
  };

  return (
    <>
      <Button
        className={`${styles.joinBtn} ${!canFinishedOfflineSession(props.session.planned_at) && styles.disableBtn}`}
        disabled={!canFinishedOfflineSession(props.session.planned_at)}
        onClick={openEvaluateSession}>
        <span className='buttonText topLevelText'>{translate('Finish')}</span>
      </Button>
      {state.showRateSession && (
        <RateSessionComp
          session={props.session}
          finishSession={true}
          closeRateSession={closeEvaluateSession}
          isLastSession={isLastSession}
          queryGetCourse={props.queryGetCourse}
        />
      )}
    </>
  );
};

export default FinishSessionBtn;
