import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Button, Icon } from 'semantic-ui-react';
import { useMutation } from 'urql';
import { STATUS_CODE, COACH_Actions } from '../../../models/enum';
import { ISession } from '../../../models/type';
import { SESSION_set_video_conference_link } from '../../../services/apis/sessionApis';
import { validURL } from '../../../utilities/helper';
import styles from './editVideoConferenceLinkModal.module.scss';

interface Props {
  cancel: () => void;
  onGoingSession?: ISession;
  session?: ISession;
  refetchListCoachSession?: () => void;
  onSuccess?: (videoConfLink: string) => void;
}

const EditVideoConferenceLinkModal: React.FC<Props> = (props: Props) => {
  const { cancel, onGoingSession, session, refetchListCoachSession, onSuccess } = props;
  const [videoLink, setVideoLink] = useState('');
  const { t: translate } = useTranslation();
  const dispatch = useDispatch();
  const [, setMeetingLink] = useMutation(SESSION_set_video_conference_link);

  const handleOnChange = (e: React.FormEvent<HTMLInputElement>) => {
    setVideoLink(e.currentTarget.value);
  };

  const handleSubmit = () => {
    if (session) {
      setMeetingLink({
        session_id: session.id,
        video_conference_link: videoLink,
      })
        .then((res) => {
          if (+res.data?.setSessionConferenceLink?.status_code === +STATUS_CODE.SUCCESS) {
            refetchListCoachSession && refetchListCoachSession();
            onSuccess && onSuccess(videoLink);
            cancel();
          }

          console.error(res.error);
        })
        .catch((err) => console.error(err));
    } else if (onGoingSession) {
      setMeetingLink({
        session_id: onGoingSession.id,
        video_conference_link: videoLink,
      })
        .then((res) => {
          if (+res.data?.setSessionConferenceLink?.status_code === +STATUS_CODE.SUCCESS) {
            dispatch({
              type: COACH_Actions.update_video_conference_link,
              payload: videoLink,
            });

            cancel();
          }

          console.error(res.error);
        })
        .catch((err) => console.error(err));
    }
  };

  /*EFFECT*/
  useEffect(() => {
    if (session) {
      setVideoLink(session.videoconference_link);
    } else if (onGoingSession) {
      setVideoLink(onGoingSession.videoconference_link);
    }
  }, [onGoingSession, session]);

  return (
    <div className={styles.modal}>
      <div className={styles.wrapper}>
        <h1 className={`${styles.title} topLevelText`}>{translate('Enter video conference link')}</h1>
        <div className={styles.contentWrapper}>
          <input
            className={styles.input}
            placeholder='https://videoconferencelink.com'
            name='video_link'
            value={videoLink}
            spellCheck={false}
            onChange={(e) => handleOnChange(e)}
          />
        </div>
        <div className={styles.buttonsWrapper}>
          <Button className={`${styles.btn} ${styles.cancelBtn} `} onClick={cancel}>
            <span className='topLevelText buttonText'>{translate('Cancel')}</span>
          </Button>
          <Button
            className={`${styles.btn} ${styles.agreeBtn} ${validURL(videoLink) && styles.activeSaveBtn} `}
            disabled={!validURL(videoLink)}
            onClick={handleSubmit}>
            <span className='topLevelText buttonText'>{translate('Save')}</span>
          </Button>
        </div>
        <Icon name='cancel' className={styles.closeIcon} onClick={cancel} />
      </div>
    </div>
  );
};

export default EditVideoConferenceLinkModal;
