import React from 'react';
import moment from 'moment';

import { COURSE_STATUS, CourseAnomalies } from '../../models/enum';
import { ICourse } from '../../models/type';

import styles from './coachCourseSuperviseTable.module.scss';

interface Props {
  course: ICourse;
}

const CoachCourseSuperviseRow: React.FC<Props> = (props: Props) => {
  return (
    <tr
      className={`tableListRow ${props.course.status === COURSE_STATUS.interrupted && styles.tableListRowInterrupted}`}>
      <td className={`${styles.tbodyTD} secondLevelText`}>{props.course.client ? props.course.client.name : '-'}</td>
      <td className={`${styles.tbodyTD} secondLevelText`}>
        {props.course.coachee.first_name + ' ' + props.course.coachee.last_name}
      </td>
      <td className={`${styles.tbodyTD} secondLevelText`}>{props.course.offer_configuration.name}</td>
      <td className={`${styles.tbodyTD} secondLevelText`}>
        {props.course.last_updated_at ? moment(props.course.last_updated_at).format('DD/MM/YYYY') : '--/--/----'}
      </td>
      <td className={`${styles.tbodyTD} secondLevelText`}>
        {props.course.number_of_sessions_done + '/' + props.course.number_of_sessions}
      </td>
      <td className={`${styles.tbodyTD} secondLevelText`}>
        <div
          className={`${styles.statusItem} ${props.course.anomaly === CourseAnomalies.error && styles.colorAnomaly} ${
            props.course.anomaly === CourseAnomalies.warning && styles.colorDelay
          } ${props.course.status === COURSE_STATUS.interrupted && styles.colorInterrupted}`}></div>
      </td>
    </tr>
  );
};

export default React.memo(CoachCourseSuperviseRow);
