import React, { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { NavLink, Outlet, useNavigate } from 'react-router-dom';
import PrimaryLayout from '../../layouts/PrimaryLayout/PrimaryLayout';
import { MaterialIconName, PROGRAMMANGER_ACTIONS, Role_ID } from '../../models/enum';
import styles from './programManagerMainPage.module.scss';
import { useTranslation } from 'react-i18next';
import NameOfProgram from './NameOfProgram';
import { useQuery } from 'urql';
import { PROGRAMMANAGER_get_program } from '../../services/apis/programMangerApis';
import DownloadReportButton from '../../components/DonwloadReportButton/DownloadReportButton';
import { navLinkClasses } from '../../utilities/helper';

const ProgramManagerMainPage: React.FC = (props: any) => {
  const programID = useMemo(() => window.location.pathname.split('/').pop(), []);

  const [programQueryResult] = useQuery({
    query: PROGRAMMANAGER_get_program,
    variables: { program_id: programID },
    requestPolicy: 'cache-and-network',
  });

  const { t: translate } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const gotToProfile = () => navigate(`/${Role_ID.PROGRAM_MANAGER}/profile`);

  useEffect(() => {
    // This set program data in redux to use later for Assign, Unassign and Remove coachee
    if (programQueryResult.data) {
      dispatch({
        type: PROGRAMMANGER_ACTIONS.set_program_info_viewing,
        payload: programQueryResult.data.getProgram.program,
      });
    }
  }, [dispatch, programQueryResult]);

  useEffect(() => {
    return () => {
      dispatch({
        type: PROGRAMMANGER_ACTIONS.clear_store,
      });
    };
  }, [dispatch]);

  return (
    <PrimaryLayout
      primaryTitle={translate('Manage my program')}
      primaryTitleIcon={MaterialIconName.assignment}
      role_name='Gestionnaire de programme'
      toProfile={gotToProfile}
      hideUserIcon={true}
      hideMessageIcon={true}
      onClickPrimaryTitle={() => navigate(`/${Role_ID.PROGRAM_MANAGER}`)}>
      <div className={styles.titleAndNavBlock}>
        <NameOfProgram />
        <div className={`relative ${styles.navBlock}`}>
          <NavLink
            to={`dashboard/${programID}`}
            className={(navLinkProps) => navLinkClasses(navLinkProps, styles.navItem, styles.active)}>
            <p className={styles.navItem_text}>{translate('Dashboard')}</p>
          </NavLink>
          <NavLink
            to={`list_coachee/${programID}`}
            className={(navLinkProps) => navLinkClasses(navLinkProps, styles.navItem, styles.active)}>
            <p className={styles.navItem_text}>{translate('List of coachees')}</p>
          </NavLink>
          <div className={styles.downloadReport}>
            <DownloadReportButton programID={programID} />
          </div>
        </div>
      </div>
      <Outlet />
    </PrimaryLayout>
  );
};

export default ProgramManagerMainPage;
