import React, { useEffect, useState } from 'react';
import { Button, Form, Icon } from 'semantic-ui-react';
import Annoucement from '../Common/Annoucement/Annoucement';
import styles from './activateAndResetPasswordComp.module.scss';
import { passwordRule } from '../../services/validation/errorMessage';
import Validator from '../../services/validation/validation';
import { useMutation } from 'urql';
import { API_userResetPassword } from '../../services/apis/userApi';
import { FormBehavior } from '../../models/enum';
import { useTranslation } from 'react-i18next';

const ActivateAndResetPasswordComp: React.FC = () => {
  const [{ fetching }, resetPasswordMethod] = useMutation(API_userResetPassword);

  const [formState, setFormstate] = useState({
    password: {
      value: '',
    },
    repassword: {
      value: '',
    },
    isPassedMinLength: false,
    isPassedNumber: false,
    isPassedSpecialChar: false,
    isPassedUpperCase: false,
    isPassedLowerCase: false,
    isPassedSame: false,
    setNewPasswordSuccess: false,
  });

  const [formBehavior, setFormBehavior] = useState({
    function: null,
    title: '',
    titleDesc: '',
    submitBtnTile: '',
    annoucementTitle: '',
    annoucementDescription: '',
    annoucementBtnTitle: '',
  });

  /*METHODS*/
  const { t: translate } = useTranslation();

  const handleOnChange = (event: React.FormEvent<HTMLInputElement>) => {
    const name: string = event.currentTarget.name;
    const value: string = event.currentTarget.value;
    const tempState = { ...formState };

    if (name === 'password' || name === 'repassword') {
      tempState[name].value = value;
    }

    tempState.isPassedMinLength = !Validator.checkLength(tempState.password.value, 8, 'min');

    tempState.isPassedNumber = Validator.checkHasNumber(tempState.password.value);

    tempState.isPassedSpecialChar = Validator.checkHasSpecialChar(tempState.password.value);

    tempState.isPassedUpperCase = Validator.checkHasUpperCase(tempState.password.value);

    tempState.isPassedLowerCase = Validator.checkHasLowerCase(tempState.password.value);

    tempState.isPassedSame =
      tempState.password.value === tempState.repassword.value && tempState.password.value.length !== 0;

    setFormstate(tempState);
  };

  const handleSubmit = () => {
    if (
      !formState.isPassedMinLength ||
      !formState.isPassedNumber ||
      !formState.isPassedLowerCase ||
      !formState.isPassedUpperCase ||
      !formState.isPassedSpecialChar ||
      !formState.isPassedSame
    )
      return;

    //url/token/email
    const pathname = window.location.search;
    const token = pathname.split('&')[0].split('=')[1];
    const email = decodeURIComponent(pathname.split('&')[1].split('=')[1]);
    //call switch case to choose function reset or set new pass
    resetPasswordMethod({
      email: email,
      password: formState.password.value,
      token: token,
    })
      .then((res) => {
        if (res.data.resetPassword.status_code === 200) {
          setFormstate({ ...formState, setNewPasswordSuccess: true });
        }
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    switch (window.location.pathname) {
      case FormBehavior.ACTIVATE_ACCOUNT:
        setFormBehavior({
          function: FormBehavior.ACTIVATE_ACCOUNT,
          title: translate('Account activation'),
          titleDesc: translate('Create your new password'),
          submitBtnTile: translate('Account activation2'),
          annoucementTitle: translate('Account activated'),
          annoucementDescription: translate('Account activated success message'),
          annoucementBtnTitle: translate('Back to login'),
        });
        return;
      case FormBehavior.RESET_PASSWORD:
        setFormBehavior({
          function: FormBehavior.RESET_PASSWORD,
          title: translate('Reset password'),
          titleDesc: '',
          submitBtnTile: translate('Reset password'),
          annoucementTitle: translate('Password reseted'),
          annoucementDescription: translate('Password reseted success message'),
          annoucementBtnTitle: translate('Back to login'),
        });
        return;
    }
  }, [translate]);

  return (
    <>
      {!formState.setNewPasswordSuccess ? (
        <Form className={styles.formBlock} onSubmit={handleSubmit}>
          <Icon name='unlock' size='big' />
          <p className={`${styles.title} topLevelText`}>{formBehavior.title}</p>
          {formBehavior.titleDesc && (
            <p className={styles.titleDesc}>
              {formBehavior.titleDesc} <span className={styles.userEmail}>user01@email.com</span>
            </p>
          )}

          <div className={styles.ruleWrapper}>
            <p className={styles.ruleTitle}>{translate('The password must have')}</p>
            <div className={styles.ruleContainer}>
              <p className={`${styles.rule} ${formState.isPassedMinLength && styles.ruleCorrect}`}>
                {translate(passwordRule[0])}
              </p>
              <p className={`${styles.rule} ${formState.isPassedNumber && styles.ruleCorrect}`}>
                {translate(passwordRule[1])}
              </p>
              <p className={`${styles.rule} ${formState.isPassedSpecialChar && styles.ruleCorrect}`}>
                {translate(passwordRule[2])}
              </p>
              <p className={`${styles.rule} ${formState.isPassedUpperCase && styles.ruleCorrect}`}>
                {translate(passwordRule[3])}
              </p>
              <p className={`${styles.rule} ${formState.isPassedLowerCase && styles.ruleCorrect}`}>
                {translate(passwordRule[4])}
              </p>
              <p className={`${styles.rule} ${formState.isPassedSame && styles.ruleCorrect}`}>
                {translate(passwordRule[5])}
              </p>
            </div>
          </div>

          <Form.Field className={styles.formField}>
            <input
              name='password'
              placeholder={translate('New password')}
              type='password'
              className={styles.inputField}
              onChange={(e) => handleOnChange(e)}
            />
          </Form.Field>
          <Form.Field className={styles.formField}>
            <input
              name='repassword'
              placeholder={translate('Re-enter new password')}
              type='password'
              className={styles.inputField}
              onChange={(e) => handleOnChange(e)}
            />
          </Form.Field>

          <Button
            type='submit'
            className={`${styles.submitButton} ${
              formState.isPassedMinLength &&
              formState.isPassedLowerCase &&
              formState.isPassedNumber &&
              formState.isPassedUpperCase &&
              formState.isPassedSpecialChar &&
              formState.isPassedSame &&
              styles.btnActive
            }`}
            onClick={handleSubmit}
            disabled={fetching}>
            {formBehavior.submitBtnTile}
          </Button>
        </Form>
      ) : (
        <Annoucement
          title={formBehavior.annoucementTitle}
          description={formBehavior.annoucementDescription}
          btnTitle={formBehavior.annoucementBtnTitle}
        />
      )}
    </>
  );
};

export default ActivateAndResetPasswordComp;
