import React from 'react';

export enum CoacheeListModalName {
  assignCourse = 'assign',
  unAssignCourse = 'unassign',
  removeCoachee = 'remove',
  assignFollowUpCourse = 'assignFollowUp',
}

export interface ICoacheeTableToolsBlockContext {
  modalContent: {
    title: string;
    titleDanger: boolean;
    contentText: string;
    openModal: CoacheeListModalName;
  };
}

const CoacheeTableToolsBlockContext = React.createContext<ICoacheeTableToolsBlockContext>(null);

export const CoacheeTableToolsBlockProvider = CoacheeTableToolsBlockContext.Provider;

export default CoacheeTableToolsBlockContext;
