import React from 'react';
import { Segment } from 'semantic-ui-react';
import { useMutation } from 'urql';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import styles from './userMenuModal.module.scss';
import { API_userLogout } from '../../services/apis/userApi';
import { countUserRoleWithoutStaff } from '../../utilities/helper';
import useLogOut from '../../hooks/useLogOut';

interface Props {
  toProfile: () => void;
}

const UserMenuModal: React.FC<Props> = (props: Props) => {
  const numberOfRoles = countUserRoleWithoutStaff();

  const [, logoutMethod] = useMutation(API_userLogout);

  /*METHODS*/
  const { t: translate } = useTranslation();
  const navigate = useNavigate();
  const logOut = useLogOut();

  const handleLogout = () => {
    logoutMethod()
      .then((res) => logOut())
      .catch((err) => console.error(err));
  };

  const handleGoToRolesPage = () => navigate('/roles');

  return (
    <div className={styles.wrapper}>
      <Segment className={styles.customedSegment}>
        <p className={styles.textItem} onClick={props.toProfile}>
          {translate('My profile')}
        </p>
      </Segment>

      {numberOfRoles > 1 && (
        <Segment className={styles.customedSegment}>
          <p className={styles.textItem} onClick={handleGoToRolesPage}>
            {translate('My roles')}
          </p>
        </Segment>
      )}
      <Segment className={styles.customedSegment}>
        <p className={styles.textItem} onClick={handleLogout}>
          {translate('Log out')}
        </p>
      </Segment>
    </div>
  );
};

export default React.memo(UserMenuModal);
