import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Grid, Icon, Segment } from 'semantic-ui-react';
import { useMutation } from 'urql';
import { ICoachee_extended, IOfferConfiguration } from '../../models/type';
import CoacheeTableToolsBlockContext, { ICoacheeTableToolsBlockContext } from './Context/CoacheeTableToolsBlockContext';
import styles from './listCoacheeModalOptions.module.scss';
import { PROGRAMMANAGER_assign_coachee } from '../../services/apis/programMangerApis';
import { PROGRAMMANGER_ACTIONS, STATUS_CODE } from '../../models/enum';

interface State {
  showSuccess: boolean;
  selectedOffer: IOfferConfiguration;
}

interface Props {
  onClose: () => void;
  handleRefetchCoacheeList: () => void;
}

const ModalOptionAssignCourse: React.FC<Props> = (props: Props) => {
  /*STATE*/

  const [state, setState] = useState<State>({
    showSuccess: false,
    selectedOffer: null,
  });
  /*PROPS*/
  const modalContentContext: ICoacheeTableToolsBlockContext = useContext(CoacheeTableToolsBlockContext);

  const listCoacheeSelector: Array<ICoachee_extended> = useSelector(
    (state: any) => state.programManagerReducer.listOfCoacheePage.selectedCoacheeList,
  );

  const programOfferSelector: Array<IOfferConfiguration> = useSelector(
    (state: any) => state.programManagerReducer.program.offerConfigurations,
  );

  /*METHODS*/

  const { t: translate } = useTranslation();

  const dispatch = useDispatch();

  const [, executeAssignCourseToCoachee] = useMutation(PROGRAMMANAGER_assign_coachee);

  const renderName = (selectedArray: Array<ICoachee_extended>) => {
    return selectedArray.map((item: ICoachee_extended, index: number) => {
      return (
        <span className={styles.textHiglightInfo} key={item.id}>
          {!!index && ','} {item.user_infos.first_name + ' ' + item.user_infos.last_name}
        </span>
      );
    });
  };

  const renderOffer = (offerArray: IOfferConfiguration[], numberOfCoachee: number) => {
    return offerArray.map((item: IOfferConfiguration) => {
      const canCourseBeAssigned: boolean = !!item.number_of_unassigned && item.number_of_unassigned >= numberOfCoachee;
      return (
        <Grid.Column computer='5' tablet='8' mobile='16' key={item.id}>
          <Segment
            className={`${styles.courseItem} ${canCourseBeAssigned && styles.activeCourseItem} ${
              item.offer_id === state.selectedOffer?.offer_id && styles.selectedCourse
            }`}
            onClick={() => {
              setState({ ...state, selectedOffer: item });
            }}>
            <p className={styles.offerName}>{item.name}</p>
            <p className={styles.offerDesc}>
              <span className={styles.offerDescInfo}>{item.number_of_unassigned + ' ' + translate('Course')}</span>{' '}
              {translate('Available', { count: item.number_of_unassigned })}
            </p>
          </Segment>
        </Grid.Column>
      );
    });
  };

  const handleSubmit = () => {
    const coacheeIDs: string[] = listCoacheeSelector.map((coachee: ICoachee_extended) => {
      return coachee.id;
    });
    executeAssignCourseToCoachee({
      conf_id: state.selectedOffer.id,
      coachee_ids: coacheeIDs,
    })
      .then((res) => {
        if (+res.data.assignCoursesToCoachees.status_code === +STATUS_CODE.SUCCESS) {
          props.handleRefetchCoacheeList();
          dispatch({
            type: PROGRAMMANGER_ACTIONS.assign_coachee_to_course,
            payload: {
              selectedOfferID: state.selectedOffer.id,
              coacheeIDs: coacheeIDs,
            },
          });
          setState({ ...state, showSuccess: true });
        } else {
          console.error(res.error);
        }
      })
      .catch((err) => console.error(err));
  };

  /*METHODS*/

  if (state.showSuccess) {
    return (
      <div>
        <div className={styles.successBlock}>
          <Icon name='check' size='huge' className={styles.iconCheck} />
          <span className={`${styles.successText} topLevelText`}>{translate('Congratulation')}</span>
          <p className={styles.successMessage}>{translate('Course assigned success')}</p>
        </div>
        <div className={styles.btnBlock}>
          <Button className={`${styles.agreeBtn} ${state.selectedOffer && styles.styleInfo}`} onClick={props.onClose}>
            <span className='buttonText topLevelText'>{translate('Close')}</span>
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div>
      <div>
        <p className={styles.content}>
          {modalContentContext.modalContent.contentText} {renderName(listCoacheeSelector)}
        </p>
        <div className={styles.courseItemsBlock}>
          <Grid stretched columns='equal'>
            <Grid.Row textAlign='center' verticalAlign='middle'>
              {renderOffer(programOfferSelector, listCoacheeSelector.length)}
            </Grid.Row>
          </Grid>
        </div>
      </div>
      <div className={styles.btnBlock}>
        <Button className={styles.cancelBtn} onClick={props.onClose}>
          <span className='buttonText topLevelText'>{translate('Cancel')}</span>
        </Button>
        <Button
          className={`${styles.agreeBtn} ${state.selectedOffer && styles.styleInfo}`}
          disabled={!state.selectedOffer}
          onClick={handleSubmit}>
          <span className='buttonText topLevelText'>{translate('Assign')}</span>
        </Button>
      </div>
    </div>
  );
};

export default ModalOptionAssignCourse;
