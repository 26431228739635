import { combineReducers, createStore } from 'redux';
import { userReducer } from './Reducers/UserReducer';
import { coacheeReducer } from './Reducers/CoacheeReducer';
import programManagerReducer from './Reducers/ProgramManagerReducer';
import { coachReducer } from './Reducers/CoachReducer';
import { messageReducer } from './Reducers/MessageReducer';

const rootReducer = combineReducers({
  userReducer,
  programManagerReducer,
  coacheeReducer,
  coachReducer,
  messageReducer,
});

export const store = createStore(rootReducer);
