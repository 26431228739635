import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Checkbox, CheckboxProps, Grid, Icon, Transition } from 'semantic-ui-react';
import { LANGUGAGE_map, maxUploadSize } from '../../config/helperConstant';
import {
  LANGUAGES_Translation,
  CERTIFICATIONS,
  STATUS_CODE,
  LANGUAGE_Code,
  SemanticICONS,
  SemanticICONS_color,
  COACH_Actions,
  USER_ACTIONS,
  FormMessages,
} from '../../models/enum';
import { ICoachExtended } from '../../models/type';
import AlertModal from '../Common/AlertModal/AlertModal';
import styles from './editableCoachDetailComp.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import UploadComponent from '../UploadComponent/UploadComponent';
import PreviewFile from './PreviewFile';
import { useMutation } from 'urql';
import { COACH_update } from '../../services/apis/coachApis';
import { token_user } from '../../config/tokenConstant';

interface Props {
  editMode: boolean;
  coachDetail: ICoachExtended;
  fetchCoachDetail?: () => void;
}

const EditableCoachDetailComp: React.FC<Props> = (props: Props) => {
  const [formState, setFormState] = useState({
    first_name: null,
    last_name: null,
    email: null,
    hasEdited: false,
    presentation: '',
    additional_languages: [],
    quote: '',
    certifications: [],
    otherCertifications: '',
    personal_token: '',
    picture: '',
    file: null,
  });
  const [helperState, setHelperState] = useState({
    isEditing: !props.editMode,
    isModalShowed: false,
    showOtherCertificateInput: false,
    message: '',
    backup: {
      formstate: null,
    },
  });
  const uploadSelector = useSelector((state: any) => state.coachReducer.upload);
  const { t: translate } = useTranslation();
  const [, executeUpdateCoach] = useMutation(COACH_update);
  const dispatch = useDispatch();

  const handleOnChange = (e: React.FormEvent<HTMLInputElement> | React.FormEvent<HTMLTextAreaElement>) => {
    setFormState({
      ...formState,
      [e.currentTarget.name]: e.currentTarget.value,
      hasEdited: true,
    });
  };

  const handleOnChangeCheckboxLanguages = useCallback(
    (e: React.FormEvent<HTMLInputElement>, data: CheckboxProps) => {
      const tempArr = [...formState.additional_languages];
      const indexFound = tempArr.findIndex((item: string) => {
        return item === data.value;
      });
      if (indexFound === -1) tempArr.push(data.value);
      else tempArr.splice(indexFound, 1);
      setFormState({ ...formState, [data.name]: tempArr, hasEdited: true });
    },
    [formState],
  );

  const handleOnChangeCheckboxCertification = useCallback(
    (e: React.FormEvent<HTMLInputElement>, data: CheckboxProps) => {
      const tempArr = [...formState.certifications];
      if (data.value === CERTIFICATIONS.OTHER) {
        setFormState({
          ...formState,
          hasEdited: true,
          otherCertifications: '',
        });
        setHelperState({
          ...helperState,
          showOtherCertificateInput: !helperState.showOtherCertificateInput,
        });
        return;
      }
      const indexFound = tempArr.findIndex((item: string) => {
        return item === data.value;
      });
      if (indexFound === -1) tempArr.push(data.value);
      else tempArr.splice(indexFound, 1);
      setFormState({ ...formState, [data.name]: tempArr, hasEdited: true });
    },
    [formState, helperState],
  );

  const isAbleSubmit = () => {
    const isYes =
      formState.first_name &&
      formState.last_name &&
      formState.email &&
      formState.quote &&
      formState.presentation &&
      formState.hasEdited;

    if (!uploadSelector.files) return isYes;
    else return isYes && uploadSelector.files[0].size <= maxUploadSize;
  };

  const handleSwitchEditing = () => {
    setHelperState({
      ...helperState,
      isEditing: true,
      message: '',
      showOtherCertificateInput: !!formState.otherCertifications,
      backup: { ...helperState.backup, formstate: formState },
    });
    setFormState({ ...formState, hasEdited: false });
  };

  const handleAgreeCancelForm = () => {
    dispatch({
      type: COACH_Actions.clear_upload,
    });
    setFormState(helperState.backup.formstate);
    setHelperState({
      ...helperState,
      isModalShowed: false,
      isEditing: false,
      showOtherCertificateInput: false,
      message: '',
    });
  };

  const handleSubmit = () => {
    if (uploadSelector.files && uploadSelector.files[0].size > maxUploadSize) {
      setHelperState({
        ...helperState,
        message: 'Upload file cannot larger than 5MB',
      });
      return;
    }
    const tempCertifications = [...formState.certifications];
    if (formState.otherCertifications) {
      tempCertifications.push(formState.otherCertifications);
    }
    executeUpdateCoach({
      coach_id: props.coachDetail.id,
      firstName: formState.first_name,
      lastName: formState.last_name,
      email: formState.email,
      presentation: formState.presentation,
      quote: formState.quote,
      additional_languages: formState.additional_languages,
      certifications: tempCertifications,
      personal_token: formState.personal_token,
      file: uploadSelector.files ? uploadSelector.files[0] : null,
      picture: formState.picture,
    })
      .then((res) => {
        if (+res.data?.updateCoach.status_code === +STATUS_CODE.SUCCESS) {
          setHelperState({
            ...helperState,
            message: FormMessages.Success,
            isEditing: false,
            showOtherCertificateInput: false,
          });
          setFormState({
            ...formState,
            hasEdited: false,
            picture: res.data.updateCoach.coach.picture,
          });
          const tempUser = {
            ...JSON.parse(localStorage.getItem(token_user)),
            first_name: formState.first_name,
            last_name: formState.last_name,
            email: formState.email,
          };
          localStorage.setItem(token_user, JSON.stringify(tempUser));
          dispatch({
            type: USER_ACTIONS.set_user_name,
            payload: {
              first_name: formState.first_name,
              last_name: formState.last_name,
            },
          });
        } else {
          console.error(res.error);
          setHelperState({
            ...helperState,
            message: FormMessages.Failed,
          });
          setFormState(helperState.backup.formstate);
        }
      })
      .catch((err) => console.error(err));
    return;
  };

  /*EFFECT coach detail*/

  useEffect(() => {
    if (!props.editMode) return;
    const officialCertifications: Array<string> = [CERTIFICATIONS.AAC, CERTIFICATIONS.MCC, CERTIFICATIONS.PCC];

    const filteredOfficialCertificate = props.coachDetail.certifications.filter((item: string) => {
      return officialCertifications.includes(item);
    });

    const filteredOtherCertification = props.coachDetail.certifications.filter((item: string) => {
      return !officialCertifications.includes(item);
    });

    setFormState({
      ...formState,
      first_name: props.coachDetail.user_infos.first_name,
      last_name: props.coachDetail.user_infos.last_name,
      email: props.coachDetail.user_infos.email,
      hasEdited: false,
      picture: props.coachDetail.picture,
      quote: props.coachDetail.quote,
      presentation: props.coachDetail.presentation,
      personal_token: props.coachDetail.personal_token,
      additional_languages: props.coachDetail.additional_languages ? props.coachDetail.additional_languages : [],
      certifications: filteredOfficialCertificate, //need to separate other certifications
      otherCertifications: filteredOtherCertification.length ? filteredOtherCertification[0] : '', //need to separate other certifications
    });

    return () => {
      dispatch({
        type: COACH_Actions.clear_upload,
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.formWrapper}>
      {!helperState.isEditing && (
        <div className={`${styles.previewWrapper} ${styles.photoPositionLeft}`}>
          <PreviewFile photoLink={formState.picture} photoName={formState.first_name + ' ' + formState.last_name} />
        </div>
      )}
      {!helperState.isEditing && (
        <Button className={`${styles.editBtn}`} onClick={handleSwitchEditing}>
          <Icon name='pencil alternate' className={styles.pencilIcon} />
          <span className={`topLevelText buttonText hideUnderTablet`}>{translate('Edit')}</span>
        </Button>
      )}

      <div className={styles.formContainer}>
        <form className={styles.formCreateCoach}>
          <Transition.Group animation={'slide down'} duration={400}>
            {helperState.message && (
              <p
                className={
                  helperState.message === FormMessages.Success ? 'textInfoFormMessage' : 'textDangerFormMessage'
                }>
                {translate(helperState.message)}
              </p>
            )}
          </Transition.Group>

          <div className={styles.formRow}>
            <div className={styles.formField}>
              <div className={styles.labelWrapper}>
                <label className={styles.label}>
                  {translate('First name')}
                  {helperState.isEditing && <sup className={styles.sup}>*</sup>}
                </label>
              </div>
              <input
                className={`${styles.inputField} ${styles.w42}`}
                name='first_name'
                type='text'
                placeholder={translate('First name')}
                value={formState.first_name || ''}
                readOnly={!helperState.isEditing}
                onChange={handleOnChange}
              />
            </div>
            <div className={`${styles.formField} ${styles.positioningInput} `}>
              <div className={styles.labelWrapper}>
                <label className={styles.label}>
                  {translate('Last name')}
                  {helperState.isEditing && <sup className={styles.sup}>*</sup>}
                </label>
              </div>
              <input
                className={styles.inputField}
                name='last_name'
                type='text'
                placeholder='Nom de famille'
                value={formState.last_name || ''}
                readOnly={!helperState.isEditing}
                onChange={handleOnChange}
              />
            </div>
          </div>
          <div className={styles.formField}>
            <div className={styles.labelWrapper}>
              <label className={styles.label}>
                {translate('Email')}
                {helperState.isEditing && <sup className={styles.sup}>*</sup>}
              </label>
            </div>
            <input
              className={styles.inputField}
              name='email'
              type='email'
              placeholder='nom@email.com'
              value={formState.email ? formState.email : ''}
              readOnly={!helperState.isEditing}
              onChange={handleOnChange}
            />
          </div>

          <div className={`${styles.formField} `}>
            <div className={styles.labelWrapper}>
              <label className={styles.label}>{translate('Personel token')}</label>
            </div>
            <input
              className={`${styles.inputField} `}
              name='personal_token'
              type='text'
              placeholder={translate('Fill in your Calendly token')}
              value={formState.personal_token ? formState.personal_token : ''}
              readOnly={!helperState.isEditing}
              onChange={(e) => {
                handleOnChange(e);
              }}
            />
          </div>
          <Transition.Group animation='fade up' duration={500}>
            {!formState.personal_token && (
              <p className={styles.tokenWarning}>
                {translate('Warning coach account not active unless updated Calendly token')}
              </p>
            )}
          </Transition.Group>
          <div className={styles.formField}>
            <div className={styles.labelWrapper}>
              <label className={styles.label}>{translate('Language')}</label>
            </div>
            <div className={styles.checkboxWrapper}>
              {helperState.isEditing ? (
                <Grid>
                  <Grid.Row>
                    <Grid.Column stretched computer='4'>
                      <Checkbox
                        className={styles.checkbox}
                        label={<label>{translate(LANGUAGES_Translation.fr)}</label>}
                        name='additional_languages'
                        value={LANGUAGE_Code.fr}
                        checked={formState.additional_languages.includes(LANGUAGE_Code.fr)}
                        onChange={handleOnChangeCheckboxLanguages}
                      />
                    </Grid.Column>
                    <Grid.Column stretched computer='4'>
                      <Checkbox
                        className={styles.checkbox}
                        label={<label>{translate(LANGUAGES_Translation.en)}</label>}
                        name='additional_languages'
                        value={LANGUAGE_Code.en}
                        checked={formState.additional_languages.includes(LANGUAGE_Code.en)}
                        onChange={handleOnChangeCheckboxLanguages}
                      />
                    </Grid.Column>
                    <Grid.Column stretched computer='4'>
                      <Checkbox
                        className={styles.checkbox}
                        label={<label>{translate(LANGUAGES_Translation.es)}</label>}
                        name='additional_languages'
                        value={LANGUAGE_Code.es}
                        checked={formState.additional_languages.includes(LANGUAGE_Code.es)}
                        onChange={handleOnChangeCheckboxLanguages}
                      />
                    </Grid.Column>
                    <Grid.Column stretched computer='4'>
                      <Checkbox
                        className={styles.checkbox}
                        label={<label>{translate(LANGUAGES_Translation.de)}</label>}
                        name='additional_languages'
                        value={LANGUAGE_Code.de}
                        checked={formState.additional_languages.includes(LANGUAGE_Code.de)}
                        onChange={handleOnChangeCheckboxLanguages}
                      />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              ) : (
                <div>
                  {[...formState.additional_languages].map((item: string) => {
                    return (
                      <span className={styles.languagesViewItem} key={item}>
                        {translate(LANGUGAGE_map.get(item))}
                      </span>
                    );
                  })}
                </div>
              )}
            </div>
          </div>

          <div className={`${styles.formField} ${helperState.showOtherCertificateInput && styles.noBorderBottom}`}>
            <div className={styles.labelWrapper}>
              <label className={styles.label}>{translate('Certificate')}</label>
            </div>
            <div className={styles.checkboxWrapper}>
              {helperState.isEditing ? (
                <Grid>
                  <Grid.Row>
                    <Grid.Column computer='4'>
                      <Checkbox
                        className={styles.checkbox}
                        label={<label>{CERTIFICATIONS.AAC}</label>}
                        name='certifications'
                        value={CERTIFICATIONS.AAC}
                        checked={formState.certifications.includes(CERTIFICATIONS.AAC)}
                        onChange={handleOnChangeCheckboxCertification}
                      />
                    </Grid.Column>
                    <Grid.Column computer='4'>
                      <Checkbox
                        className={styles.checkbox}
                        label={<label>{CERTIFICATIONS.MCC}</label>}
                        name='certifications'
                        value={CERTIFICATIONS.MCC}
                        checked={formState.certifications.includes(CERTIFICATIONS.MCC)}
                        onChange={handleOnChangeCheckboxCertification}
                      />
                    </Grid.Column>
                    <Grid.Column computer='4'>
                      <Checkbox
                        className={styles.checkbox}
                        label={<label>{CERTIFICATIONS.PCC}</label>}
                        name='certifications'
                        value={CERTIFICATIONS.PCC}
                        checked={formState.certifications.includes(CERTIFICATIONS.PCC)}
                        onChange={handleOnChangeCheckboxCertification}
                      />
                    </Grid.Column>
                    <Grid.Column computer='4'>
                      <Checkbox
                        className={styles.checkbox}
                        label={<label>{translate('Other')}</label>}
                        name='certifications'
                        value={CERTIFICATIONS.OTHER}
                        checked={helperState.showOtherCertificateInput}
                        onChange={handleOnChangeCheckboxCertification}
                      />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              ) : (
                <div>
                  {[...formState.certifications, formState.otherCertifications].map((item) => (
                    <span className={styles.certificationViewItem} key={item}>
                      {item}
                    </span>
                  ))}
                </div>
              )}
            </div>
          </div>

          {helperState.showOtherCertificateInput && (
            <div className={`${styles.formField}`}>
              <div className={`${styles.labelWrapper}`}>
                <label className={styles.label}></label>
              </div>
              <input
                className={`${styles.inputField} `}
                name='otherCertifications'
                type='text'
                placeholder='ex: MBA, MS'
                onChange={handleOnChange}
                value={formState.otherCertifications ? formState.otherCertifications : ''}
              />
            </div>
          )}

          <div className={styles.formField}>
            <div className={styles.labelWrapper}>
              <label className={styles.label}>
                {translate('Coach introduction')}
                {helperState.isEditing && <sup className={styles.sup}>*</sup>}
              </label>
            </div>
            <input
              className={`${styles.inputField} `}
              name='quote'
              type='text'
              placeholder={translate('Please write short introdction')}
              value={formState.quote ? formState.quote : ''}
              readOnly={!helperState.isEditing}
              onChange={(e) => {
                handleOnChange(e);
              }}
            />
          </div>

          <div
            className={`${styles.formField} ${styles.introductionFormField} ${
              !helperState.isEditing && styles.noBorderBottom
            }`}>
            <div className={styles.labelWrapper}>
              <label className={styles.label}>
                {translate('Introduction')}
                {helperState.isEditing && <sup className={styles.sup}>*</sup>}
              </label>
            </div>
            <textarea
              className={styles.presentationTextArea}
              maxLength={450}
              name='presentation'
              value={formState.presentation ? formState.presentation : ''}
              readOnly={!helperState.isEditing}
              placeholder={translate('Presentation maximum 450 characters')}
              onChange={(e) => {
                handleOnChange(e);
              }}
            />
          </div>

          {helperState.isEditing && (
            <>
              <div className={`${styles.formField} ${styles.noBorderBottom} ${styles.alignTop}`}>
                <div className={`${styles.labelWrapper}`}>
                  <label className={styles.label}>{translate('Update my photo')}</label>
                </div>
                <div className={`${styles.inputField} `}>{translate('Photo format type jpeg 200x200')}</div>
              </div>
              <div className={styles.uploadBlock}>
                <UploadComponent
                  noBorderSegment={true}
                  title={''}
                  titleDesc={''}
                  titleDesc_bold={''}
                  iconName={uploadSelector.iconName}
                  iconColor={uploadSelector.iconColor}
                  file={uploadSelector.files ? uploadSelector.files[0] : null}
                  setFileActionName={COACH_Actions.set_upload_photo}
                  uploadFunction={() => {}}
                  cancelActionName={''}
                  hideButtonBlock={true}
                  optionalPayload={{
                    iconName: SemanticICONS['image outline'],
                    iconColor: SemanticICONS_color.blue,
                  }}
                  hasEditedFile={() => {
                    setFormState({
                      ...formState,
                      hasEdited: true,
                    });
                  }}
                />
              </div>
            </>
          )}
        </form>

        <div className={styles.rightGroup}>
          {helperState.isEditing ? (
            <>
              <Button
                className={`${styles.btnSave} ${isAbleSubmit() && styles.active} `}
                disabled={!isAbleSubmit()}
                onClick={handleSubmit}>
                <span className='buttonText topLevelText'>{translate('Save')}</span>
              </Button>
              <Button
                className={styles.btnCancel}
                onClick={() => {
                  if (formState.hasEdited)
                    setHelperState({
                      ...helperState,
                      isModalShowed: true,
                    });
                  else {
                    setHelperState({
                      ...helperState,
                      isEditing: false,
                      showOtherCertificateInput: false,
                      message: '',
                    });
                    dispatch({
                      type: COACH_Actions.clear_upload,
                    });
                  }
                }}>
                <span className='buttonText topLevelText'>{translate('Cancel')}</span>
              </Button>
            </>
          ) : (
            <div className={`${styles.previewWrapper} ${styles.photoPositionRight}`}>
              <PreviewFile photoLink={formState.picture} photoName={formState.first_name + ' ' + formState.last_name} />
            </div>
          )}
        </div>
      </div>

      {helperState.isModalShowed && (
        <AlertModal
          title={translate('Confirm cancel')}
          contentQuestion={translate('Do you want to undo?')}
          content={translate('You will be return to previous page with unsaved data')}
          cancel={() =>
            setHelperState({
              ...helperState,
              isModalShowed: false,
            })
          }
          agree={handleAgreeCancelForm}
        />
      )}
    </div>
  );
};

export default EditableCoachDetailComp;
