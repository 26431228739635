import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'semantic-ui-react';
import DiagnosisFinishedResult from '../../components/DiagnosisFinishedResult/DiagnosisFinishedResult';
import styles from './viewCoacheeDiagnosis.module.scss';

interface Props {
  cancel: () => void;
  courseID?: string;
}

const ViewCoacheeDiagnosis: React.FC<Props> = (props: Props) => {
  /*METHODS*/

  const { t: translate } = useTranslation();

  return (
    <div className={styles.modal}>
      <div className={styles.wrapper}>
        <h1 className={`${styles.title} topLevelText`}>{translate('Summary coachee self diagnosis')}</h1>
        <div className={styles.contentWrapper}>
          <DiagnosisFinishedResult courseID={props.courseID} showCheckIcon={false} showNextButton={false} />
          <div className={styles.buttonsWrapper}>
            <Button className={`${styles.closeBtn} `} onClick={props.cancel}>
              <span className='topLevelText buttonText'>{translate('Close')}</span>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewCoacheeDiagnosis;
