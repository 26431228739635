import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Icon } from 'semantic-ui-react';
import { IObjective, ISession } from '../../models/type';

import styles from './remindCoacheeSetObjectivesModal.module.scss';

interface Props {
  session: ISession;
}

const RemindCoacheeSetObjectives = ({ session }: Props) => {
  const { t: translate } = useTranslation();
  const [openModal, setOpenModal] = useState(false);

  /* Effect */
  useEffect(() => {
    if (!session?.course_context?.has_objectives) return;

    const objectives: IObjective[] | undefined = session?.course_context?.objectives;
    if (objectives && objectives?.length > 0) return;
    setOpenModal(true);
  }, [session]);

  if (openModal === false) return null;

  return (
    <div className={styles.modal}>
      <div className={styles.wrapper}>
        <h1 className={`${styles.title} topLevelText`}>{translate('COACH.REMIND_COACHEE_SET_OBJECTIVE.HEADER')}</h1>
        <div className={styles.contentWrapper}>
          <p className={styles.content}>
            {translate(
              session?.is_first
                ? 'COACH.REMIND_COACHEE_SET_OBJECTIVE.FIRST_SESSION'
                : 'COACH.REMIND_COACHEE_SET_OBJECTIVE.NOT_FIRST_SESSION',
            )}
          </p>
        </div>
        <div className={styles.buttonsWrapper}>
          <Button className={`${styles.btn} ${styles.cancelBtn} `} onClick={() => setOpenModal(false)}>
            <span className='topLevelText buttonText'>{translate('Close')}</span>
          </Button>
        </div>
        <Icon name='cancel' className={styles.closeIcon} onClick={() => setOpenModal(false)} />
      </div>
    </div>
  );
};

export default React.memo(RemindCoacheeSetObjectives);
