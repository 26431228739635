export const COACHEE_list_coachee_from_program = `
query ($program_id : ID!, $count : Int, $page : Int!, $search_name : String, $order_by : ListCoacheesFromProgramSortableColumn, $direction : SortDirection) {
    listCoacheesFromProgram(
        program_id: $program_id,
        count: $count,
        page: $page,
        order_by : $order_by,
        direction : $direction,
        search_name: $search_name
    ) {
        status_code
        coachees {
            id
            created_at
            courses {
                id
                assigned_at
                status
                number_of_sessions_done
                is_started
                offer_configuration {
                    name
                    offer_id
                    number_of_sessions
                    duration_of_session
                    has_objectives
                    has_self_diagnosis
                    number_of_courses
                }
                status
                objectives {
                    id
                    description
                    key_indicators
                    evaluations {
                        id
                        grade
                        session_id
                        session_current_index
                    }
                }
            }
            user_infos {
                id
                email
                first_name
                last_name
                department
                position
                gdpr_consent
                client {
                    id
                    name
                }
            }
        }
        paginatorInfo {
            currentPage
            lastPage
        }
    }
}
`;

export const COACHEE_fetch_info = `
query ($id : ID!) {
    detailCoachee(id: $id) {
        status_code
        id
        user_infos {
            id
            first_name
            last_name
            email
            department
            position
        }
        programs {
            id
            name
            courses {
                id
                created_at
                status
                evaluable
                offer_configuration {
                    id
                    name
                    offer_id
                    number_of_sessions
                    duration_of_session
                    has_objectives
                    has_self_diagnosis
                }
            }
            offerConfigurations {
                id
                name
                offer_id
                number_of_sessions
                duration_of_session
                has_objectives
                has_self_diagnosis
                number_of_courses
                number_of_program_managers
                number_of_coaches
                number_of_coachees
            }
        }
    }
}
`;

export const COACHEE_update_info = `
mutation ($id: ID!, $first_name: String!, $last_name: String!, $email: String!, $department: String!, $position : String! ) {
    updateCoachee (
        id: $id,
        first_name: $first_name,
        last_name: $last_name,
        email: $email,
        department: $department,
        position : $position
    ) {
        status_code
        id
        user_infos {
            id 
            first_name
            last_name
            email
            department
            position
        }
    }
}
`;

export const COACHEE_remove_from_program = `
mutation ($coachee_id : ID!, $program_id : ID!) {
    removeCoacheeFromProgram(
        id: $coachee_id,
        program_id: $program_id
    ) {
        status_code
    }
}
`;

export const COACHEE_upload = `
mutation ($program_id : ID!, $file: Upload!)  {
    importCoacheesForProgram(program_id : $program_id, file : $file) {
        status_code
        total
        number_of_imported
        number_of_duplicates
        number_of_invalids
        }
    }
`;
