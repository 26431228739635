import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Icon } from 'semantic-ui-react';
import styles from './alertModal.module.scss';

interface Props {
  title: string;
  contentQuestion: string;
  content: string;
  cancel: () => void;
  agree: () => void;
  hideCloseIcon?: boolean; //default false
  hideAgreeButton?: boolean; //default false
  hideCloseButton?: boolean; //default false
  cancelButtonTitle?: string; //default "Cancel"
  agreeButtonTitle?: string; //default "Yes"
}

const AlertModal: React.FC<Props> = (props: Props) => {
  const { t: translate } = useTranslation();

  return (
    <div className={styles.modal}>
      <div className={styles.wrapper}>
        <h1 className={`${styles.title} topLevelText`}>{props.title}</h1>
        <div className={styles.contentWrapper}>
          <p className={styles.contentQuestion}>{props.contentQuestion}</p>
          {props.content && (
            <p className={styles.content}>
              <Icon name='info' className={styles.infoIcon} />
              {props.content}
            </p>
          )}
        </div>
        <div className={styles.buttonsWrapper}>
          {!props.hideCloseButton && (
            <Button className={`${styles.btn} ${styles.cancelBtn} `} onClick={props.cancel}>
              <span className='topLevelText buttonText'>
                {translate(props.cancelButtonTitle ? props.cancelButtonTitle : 'Cancel')}
              </span>
            </Button>
          )}

          {!props.hideAgreeButton && (
            <Button className={`${styles.btn} ${styles.agreeBtn} `} onClick={props.agree}>
              <span className='topLevelText buttonText'>
                {translate(props.agreeButtonTitle ? props.agreeButtonTitle : 'Yes')}
              </span>
            </Button>
          )}
        </div>
        {!props.hideCloseIcon && <Icon name='cancel' className={styles.closeIcon} onClick={props.cancel} />}
      </div>
    </div>
  );
};

export default AlertModal;
