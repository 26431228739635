import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useMutation } from 'urql';

import { MESSAGE_Actions, STATUS_CODE } from '../../models/enum';
import { MESSAGE_get_conversation_content } from '../../services/apis/messageApis';

import Messages from './Messages';
import WelcomeMessage from './WelcomeMessage';
import styles from './messagingPage.module.scss';

interface Props {
  conversationID: string | null;
}

const MessageDisplayView = ({ conversationID }: Props) => {
  const dispatch = useDispatch();

  const [, fetchConversationContent] = useMutation(MESSAGE_get_conversation_content);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchMessages = async (conversationID: string | undefined) => {
    try {
      if (conversationID === null) return;
      const res = await fetchConversationContent({ conversation_id: conversationID });
      if (+res.data.getConversation.status_code === +STATUS_CODE.SUCCESS) {
        dispatch({
          type: MESSAGE_Actions.set_messages,
          payload: res.data.getConversation.conversation,
        });
      }
    } catch (err) {
      console.error('ERROR: Fetch messages');
    }
  };

  useEffect(() => {
    if (conversationID == null) return;
    fetchMessages(conversationID);
    const interval = setInterval(async () => {
      await fetchMessages(conversationID);
    }, 5000);

    return () => clearInterval(interval);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversationID]);

  return (
    <div id='messageViewPort' className={styles.messageDisplayedBlock}>
      <WelcomeMessage />
      <Messages />
    </div>
  );
};

export default MessageDisplayView;
