import React from 'react';
import { useTranslation } from 'react-i18next';
import MessagingPage from '../MessagingPage/MessagingPage';
import styles from './coachMessagePage.module.scss';

const CoachMessagePage: React.FC = (props) => {
  /*METHODS*/

  const { t: translate } = useTranslation();
  return (
    <div className={styles.wrapper}>
      <h3 className={styles.title}>{translate('Message')}</h3>
      <div className={styles.mainContentWrapper}>
        <MessagingPage />
      </div>
    </div>
  );
};

export default CoachMessagePage;
