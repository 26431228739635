import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Button, Icon } from 'semantic-ui-react';
import { useMutation } from 'urql';
import { token_user } from '../../config/tokenConstant';
import { FormMessages, STATUS_CODE, UserRoles, USER_ACTIONS } from '../../models/enum';
import { IProgramManager_user_info } from '../../models/type';
import { PROGRAMMANAGER_update_info } from '../../services/apis/programMangerApis';
import { getUserRoleID } from '../../utilities/helper';
import AlertModal from '../Common/AlertModal/AlertModal';
import styles from './editableProgramManagerComp.module.scss';

interface Props {
  user_info: IProgramManager_user_info;
}

const EditableProgramManagerComp: React.FC<Props> = ({ user_info }: Props) => {
  const [formState, setFormState] = useState({
    first_name: null,
    last_name: null,
    email: null,
    department: null,
    position: null,
    hasEdited: false,
  });
  const [helperState, setHelperState] = useState({
    isEditing: false,
    isModalShowed: false,
    message: '',
    backUpForm: null,
  });
  const { t: translate } = useTranslation();
  const dispatch = useDispatch();
  const [, executeUpdateProgramManagerInfo] = useMutation(PROGRAMMANAGER_update_info);

  const handleSubmit = useCallback(() => {
    executeUpdateProgramManagerInfo({
      id: getUserRoleID(UserRoles.PROGRAM_MANAGER),
      first_name: formState.first_name,
      last_name: formState.last_name,
      email: formState.email,
      department: formState.department,
      position: formState.position,
    })
      .then((res) => {
        if (res.data.updateProgramManager.status_code === STATUS_CODE.SUCCESS) {
          setHelperState({ ...helperState, isEditing: false, message: FormMessages.Success });
          setFormState({ ...formState, hasEdited: false });

          const tempUser = {
            ...JSON.parse(localStorage.getItem(token_user)),
            first_name: formState.first_name,
            last_name: formState.last_name,
            email: formState.email,
          };

          localStorage.setItem(token_user, JSON.stringify(tempUser));

          dispatch({
            type: USER_ACTIONS.set_user_name,
            payload: { first_name: formState.first_name, last_name: formState.last_name },
          });

          return;
        }

        setFormState({
          first_name: user_info.first_name,
          last_name: user_info.last_name,
          email: user_info.email,
          department: user_info.department,
          position: user_info.position,
          hasEdited: false,
        });
        setHelperState({ ...helperState, isEditing: false, message: FormMessages.Failed });
      })
      .catch((err) => console.error(err));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState]);

  const handleOnChange = (e: React.FormEvent<HTMLInputElement>) => {
    setFormState({ ...formState, [e.currentTarget.name]: e.currentTarget.value, hasEdited: true });
  };

  const handleAgreeCancelEditing = useCallback(() => {
    setFormState(helperState.backUpForm);
    setHelperState({ ...helperState, isModalShowed: false, isEditing: false });
  }, [helperState]);

  useEffect(() => {
    setFormState({
      ...formState,
      first_name: user_info.first_name,
      last_name: user_info.last_name,
      email: user_info.email,
      department: user_info.department,
      position: user_info.position,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.formWrapper}>
      <form>
        {!helperState.isEditing && (
          <Button
            className={`${styles.editBtn}`}
            onClick={() => setHelperState({ ...helperState, isEditing: true, message: '', backUpForm: formState })}>
            <Icon name='pencil alternate' className={styles.pencilIcon} />
            <span className={`topLevelText buttonText hideUnderTablet`}>{translate('Edit')}</span>
          </Button>
        )}
        <p className={helperState.message === FormMessages.Success ? 'textInfoFormMessage' : 'textDangerFormMessage'}>
          {translate(helperState.message)}
        </p>
        <div className={styles.formRow}>
          <div className={styles.formField}>
            <div className={styles.labelWrapper}>
              <label className={styles.label}>
                {translate('First name')}
                {helperState.isEditing && <sup className={styles.sup}>*</sup>}
              </label>
            </div>
            {helperState.isEditing ? (
              <input
                className={`${styles.inputField} ${styles.widthFirstName}`}
                name='first_name'
                type='text'
                placeholder='Prénom'
                readOnly={!helperState.isEditing}
                value={formState.first_name}
                onChange={handleOnChange}
              />
            ) : (
              <div className={`${styles.inputField} ${styles.widthFirstName}`}>{formState.first_name}</div>
            )}
          </div>
          <div className={`${styles.formField} ${styles.positioningInput} `}>
            <div className={`${styles.labelWrapper} `}>
              <label className={styles.label}>
                {translate('Last name')}
                {helperState.isEditing && <sup className={styles.sup}>*</sup>}
              </label>
            </div>
            {helperState.isEditing ? (
              <input
                className={`${styles.inputField}`}
                name='last_name'
                type='text'
                placeholder='Nom de famille'
                readOnly={!helperState.isEditing}
                value={formState.last_name}
                onChange={handleOnChange}
              />
            ) : (
              <div className={`${styles.inputField}`}>{formState.last_name}</div>
            )}
          </div>
        </div>
        <div className={styles.formField}>
          <div className={styles.labelWrapper}>
            <label className={styles.label}>
              {translate('Email')}
              {helperState.isEditing && <sup className={styles.sup}>*</sup>}
            </label>
          </div>
          {helperState.isEditing ? (
            <input
              className={`${styles.inputField} `}
              name='email'
              type='email'
              placeholder='email@email.com'
              readOnly={!helperState.isEditing}
              value={formState.email}
              onChange={handleOnChange}
            />
          ) : (
            <div className={`${styles.inputField}`}>{formState.email}</div>
          )}
        </div>
        <div className={styles.formField}>
          <div className={styles.labelWrapper}>
            <label className={styles.label}>{translate('Service')}</label>
          </div>
          {helperState.isEditing ? (
            <input
              className={`${styles.inputField} `}
              name='department'
              type='text'
              placeholder='HR'
              readOnly={!helperState.isEditing}
              value={formState.department}
              onChange={handleOnChange}
            />
          ) : (
            <div className={`${styles.inputField}`}>{formState.department}</div>
          )}
        </div>
        <div className={styles.formField}>
          <div className={styles.labelWrapper}>
            <label className={styles.label}>{translate('Position')}</label>
          </div>
          {helperState.isEditing ? (
            <input
              className={`${styles.inputField} `}
              name='position'
              type='text'
              placeholder='Vice-directeur'
              readOnly={!helperState.isEditing}
              value={formState.position}
              onChange={handleOnChange}
            />
          ) : (
            <div className={`${styles.inputField}`}>{formState.position}</div>
          )}
        </div>
      </form>

      <div className={styles.rightGroup}>
        {helperState.isEditing && (
          <>
            <Button
              className={`${styles.btnSave} ${
                formState.first_name && formState.last_name && formState.email && styles.active
              }`}
              disabled={!formState.first_name || !formState.last_name || !formState.email}
              onClick={handleSubmit}>
              <span className='topLevelText buttonText'>{translate('Save')}</span>
            </Button>
            <Button
              className={styles.btnCancel}
              onClick={() => {
                if (formState.hasEdited) setHelperState({ ...helperState, isModalShowed: true });
                else setHelperState({ ...helperState, isEditing: false });
              }}>
              <span className='topLevelText buttonText'>{translate('Cancel')}</span>
            </Button>
          </>
        )}
      </div>

      {helperState.isModalShowed && (
        <AlertModal
          title={"CONFIRMER L'ANNULATION"}
          contentQuestion='Voulez-vous vraiment annuler cette modification?'
          content='Vous reviendrez à la page précédente et toutes les données non enregistrées seront perdues.'
          cancel={() => setHelperState({ ...helperState, isModalShowed: false })}
          agree={() => handleAgreeCancelEditing()}
        />
      )}
    </div>
  );
};

export default EditableProgramManagerComp;
