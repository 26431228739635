export const PROGRAMMANAGER_get_program = `
query ($program_id : ID!) {
    getProgram (id: $program_id) {
        status_code
        program {
            id
            name
            status
            courses_summary {
                number_of_unassigned
                number_of_started
                number_of_done
                total
                evaluation
            }
            sessions_summary {
                number_of_sessions_unplanned
                number_of_sessions_planned
                number_of_sessions_done
                total
            }
            number_of_courses
            number_of_coachees
            offerConfigurations {
                id
                name
                offer_id
                number_of_sessions
                duration_of_session
                has_objectives
                has_self_diagnosis
                number_of_courses
                number_of_program_managers
                number_of_coaches
                number_of_coachees
                number_of_unassigned
                number_of_started
                number_of_closed
            }
            self_diagnosis {
                number_of_done
                total
            }
            courses {
                id
                objectives {
                    id
                    evaluations {
                        id
                        grade
                        session_id
                        session_current_index
                    }
                }
                coach {
                    first_name
                    last_name
                }
            }
            top_of_needs
        }
    }
}
`;

export const PROGRAMMANAGER_info = `
query ($id : ID!){
    detailProgramManager(id: $id) {
        status_code
        id
        programs {
            id
            name
        }
        user_infos {
            id
            first_name
            last_name
            email
            department
            position
        }
    }
}
`;

export const PROGRAMMANAGER_update_info = `
mutation ($id: ID!, $first_name: String!, $last_name: String!, $email: String!, $department: String!, $position: String!) {
    updateProgramManager (
        id: $id,
        first_name: $first_name,
        last_name: $last_name,
        email: $email,
        department: $department,
        position: $position
    ) {
        status_code
        id
        program_manager {
            id 
            first_name
            last_name
            email
            department
            position
        }
    }
}
`;

export const PROGRAMMANAGER_assign_coachee = `
mutation($conf_id: ID!, $coachee_ids: [ID!]!) {
    assignCoursesToCoachees(
        offer_configuration_id: $conf_id,
        coachee_ids: $coachee_ids
    ) {
        status_code
        coachees {
            id
            role_name
        }
        coacheesMissingCourse {
            id
            role_name
        }
    }
}
`;

export const PROGRAMMANAGER_unassign_coachee = `
mutation($courses_ids: [ID!]!) {
    unassignCoursesFromCoachee(
        courses_ids: $courses_ids
    ) {
        status_code
    }
}
`;

export const PROGRAMMANAGER_remove_coachee = `
mutation ($ids : [ID!]!, $program_id : ID!) {
    removeCoacheeFromProgram(
        ids: $ids,
        program_id: $program_id
    ) {
        status_code
    }
}
`;

export const PROGRAMMANAGER_program_info = `
query ($program_id : ID!) {
    getProgram (id: $program_id) {
        status_code
        program {
            id
            name
            status
        } 
    }
}
`;

export const PROGRAMMANAGER_assign_follow_up_course = `
mutation($courses_ids: [ID!]!) {
    assignFollowUpCourse(
        courses_ids: $courses_ids
    ) {
        status_code
    }
}
`;
