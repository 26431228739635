import React, { useContext, useEffect } from 'react';
import { Icon } from 'semantic-ui-react';
import styles from './listCoacheeModalOptions.module.scss';
import ModalOptionAssignCourse from './ModalOptionAssignCourse';
import CoacheeTableToolsBlockContext, {
  CoacheeListModalName,
  ICoacheeTableToolsBlockContext,
} from './Context/CoacheeTableToolsBlockContext';
import ModalOptionUnassignCourse from './ModalOptionUnassignCourse';
import ModalOptionRemoveCoachee from './ModalOptionRemoveCoachee';
import { useQuery } from 'urql';
import { useDispatch } from 'react-redux';
import { PROGRAMMANGER_ACTIONS } from '../../models/enum';
import { PROGRAMMANAGER_get_program } from '../../services/apis/programMangerApis';
import ModalOptionFollowUp from './ModalOptionFollowUp';

interface Props {
  onClose: () => void;
  handleRefetchCoacheeList: () => void;
}

const ListCoacheeModalOptions: React.FC<Props> = (props: Props) => {
  /*PROPS*/

  const modalContentContext: ICoacheeTableToolsBlockContext = useContext(CoacheeTableToolsBlockContext);
  /*METHODS*/

  const dispatch = useDispatch();

  const [programQueryResult, reFetchProgramDetail] = useQuery({
    query: PROGRAMMANAGER_get_program,
    variables: { program_id: window.location.pathname.split('/').pop() },
    requestPolicy: 'cache-and-network',
    pause: true,
  });

  useEffect(() => {
    if (programQueryResult.data) {
      dispatch({
        type: PROGRAMMANGER_ACTIONS.set_program_info_viewing,
        payload: programQueryResult.data.getProgram.program,
      });
    }
  }, [dispatch, programQueryResult.data]);

  return (
    <div className={styles.modal}>
      <div className={styles.wrapper}>
        <h1
          className={`${styles.title} ${
            modalContentContext.modalContent.titleDanger && styles.titleBgDanger
          } topLevelText`}>
          {modalContentContext.modalContent.title}
        </h1>
        <div className={styles.contentWrapper}>
          {modalContentContext.modalContent.openModal === CoacheeListModalName.removeCoachee && (
            <ModalOptionRemoveCoachee
              onClose={props.onClose}
              handleRefetchCoacheeList={props.handleRefetchCoacheeList}
              refetchProgramDetail={reFetchProgramDetail}
            />
          )}
          {modalContentContext.modalContent.openModal === CoacheeListModalName.assignCourse && (
            <ModalOptionAssignCourse
              onClose={props.onClose}
              handleRefetchCoacheeList={props.handleRefetchCoacheeList}
            />
          )}
          {modalContentContext.modalContent.openModal === CoacheeListModalName.unAssignCourse && (
            <ModalOptionUnassignCourse
              onClose={props.onClose}
              handleRefetchCoacheeList={props.handleRefetchCoacheeList}
              refetchProgramDetail={reFetchProgramDetail}
            />
          )}
          {modalContentContext.modalContent.openModal === CoacheeListModalName.assignFollowUpCourse && (
            <ModalOptionFollowUp
              onClose={props.onClose}
              handleRefetchCoacheeList={props.handleRefetchCoacheeList}
              refetchProgramDetail={reFetchProgramDetail}
            />
          )}
        </div>

        <Icon name='cancel' className={styles.closeIcon} onClick={props.onClose} />
      </div>
    </div>
  );
};

export default ListCoacheeModalOptions;
