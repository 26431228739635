import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './topListingComp.module.scss';

interface Props {
  top_of_besoins: Array<string>;
}
const TopListingComp: React.FC<Props> = (props: Props) => {
  /*METHODS*/
  const { t: translate } = useTranslation();

  const renderLiItem = useCallback((array: Array<string>) => {
    const size = 18;
    return array.map((item: string, index: number) => {
      return (
        <li key={index}>
          <span className={styles.numeric} style={{ fontSize: size - index }}>
            {index + 1 + '.'}
          </span>{' '}
          <span style={{ fontSize: size - index }}>{item} </span>
        </li>
      );
    });
  }, []);

  return (
    <div className={styles.wrapper}>
      <div className={styles.titleBlock}>
        <p className={styles.title}>
          <span className={`${styles.title} topLevelText`}>Top</span>
          {` ${props.top_of_besoins.length ? props.top_of_besoins.length : ''} `}
          {translate('of need')}
        </p>
      </div>
      <div className={styles.listingBlock}>
        <ul>{renderLiItem(props.top_of_besoins)}</ul>
      </div>
    </div>
  );
};

export default React.memo(TopListingComp);
