/**
 * When a session is offline true. There is no Join for coach. Only coachee can finish session
 * @param isOffline
 * @returns
 */
export const shouldDisplayJoinSessionBtn = (isOffline: boolean): boolean => {
  if (isOffline === true) return false;

  return true;
};
