import React, { useEffect, useState } from 'react';
import { useQuery } from 'urql';
import { useTranslation } from 'react-i18next';
import { Button, Icon } from 'semantic-ui-react';

import styles from './donwloadReportButton.module.scss';
import { PROGRAM_export_coachees } from '../../services/apis/programApis';

interface Props {
  programID: string;
}

const DownloadReportButton = ({ programID }: Props) => {
  const { t: translate } = useTranslation();
  const [loading, setLoading] = useState(false);

  const [reportResponse, triggerDownload] = useQuery({
    query: PROGRAM_export_coachees,
    variables: { program_id: programID },
    requestPolicy: 'network-only',
    pause: true,
  });

  const downloadFile = (uri: string) => {
    let timeOutId;
    const link = document.createElement('a');
    link.href = uri;
    link.setAttribute('download', '');
    document.body.appendChild(link);
    setLoading(true);
    // Prevent conflicts with React virtual DOM
    timeOutId = setTimeout(() => {
      link.click();
      link.parentNode.removeChild(link);
      setLoading(false);
    }, 2000);
    return timeOutId;
  };

  useEffect(() => {
    const fileURI: string | undefined = reportResponse.data?.exportCoacheesFromProgram?.file_url;
    if (fileURI == null || fileURI === '') return;

    const timeOutId = downloadFile(fileURI);
    return () => clearTimeout(timeOutId);
  }, [reportResponse.data?.exportCoacheesFromProgram?.file_url]);

  return (
    <Button className={styles.reportBtn} onClick={triggerDownload} disabled={loading} loading={loading}>
      <Icon name='download' />
      <span className={styles.desktopLabel}>{' ' + translate('BUTTON.DOWNLOAD_REPORT')}</span>
      <span className={styles.mobileLabel}>{' ' + translate('REPORT')}</span>
    </Button>
  );
};

export default React.memo(DownloadReportButton);
