import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Grid } from 'semantic-ui-react';
import { UseQueryArgs, useQuery } from 'urql';
import AwaitingComp from '../../components/Common/AwaitingComp/AwaitingComp';
import { token_user } from '../../config/tokenConstant';
import { MESSAGE_Actions, STATUS_CODE } from '../../models/enum';
import { IChatUser, IContactListItem, IConversationEssential } from '../../models/type';
import { MESSAGE_get_conversations } from '../../services/apis/messageApis';
import MessageDisplayView from './MessageDisplayView';
import MessageInput from './MessageInput';
import styles from './messagingPage.module.scss';
import SearchContact from './SearchContact';
import useComponentVisible from '../../components/Common/useComponentVisible/ComponentVisible';
import { useTranslation } from 'react-i18next';
import ContactList from './ContactList';

interface State {
  contactList: Array<IContactListItem>;
  searchContactResult: Array<IContactListItem>;
  selectedConversation: IContactListItem;
}

const MessagingPage = () => {
  const [state, setState] = useState<State>({
    contactList: [],
    searchContactResult: [],
    selectedConversation: null,
  });

  const [queryState, setQueryState] = useState<UseQueryArgs>({
    query: MESSAGE_get_conversations,
    requestPolicy: 'cache-and-network',
    pause: false,
  });

  const thisUserID: string = JSON.parse(localStorage.getItem(token_user)).id;

  const { ref, isComponentShowed, setComponentShowed } = useComponentVisible(false);

  /*METHODS*/

  const [myConversations] = useQuery(queryState);

  /*METHODS*/

  const { t: translate } = useTranslation();

  const dispatch = useDispatch();

  const handleSelectConversation = (receiver: IContactListItem) => {
    setState({ ...state, selectedConversation: receiver });
  };

  const handleSearchContact = (value: string) => {
    const resultArray: Array<IContactListItem> = state.contactList.filter((item: IContactListItem) => {
      const contactName = item.name.replace(/\s/g, '').toLowerCase();
      return contactName.includes(value);
    });
    if (!resultArray.length)
      resultArray.push({
        name: translate('No result'),
        conversationID: '',
        receiverID: '',
        hasUnreadMessage: false,
      });
    setState({ ...state, searchContactResult: resultArray });
    setComponentShowed(true);
  };

  const handleRenderSearchResult = () => {
    return state.searchContactResult.map((item) => {
      return (
        <div
          className={`${styles.searchResultItem} ${!item.receiverID && styles.disabledItem}`}
          onClick={() => {
            handleSelectConversation(item);
            setComponentShowed(false);
          }}>
          {item.name}
        </div>
      );
    });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const mapContactList = (conversations: Array<IConversationEssential>) => {
    let tempContactList: Array<IContactListItem> = [];
    conversations.forEach((conversation: IConversationEssential) => {
      conversation.conversers.forEach((converser: IChatUser) => {
        if (converser.id !== thisUserID) {
          tempContactList.push({
            name: converser.first_name + ' ' + converser.last_name,
            conversationID: conversation.id,
            receiverID: converser.id,
            hasUnreadMessage: conversation.has_unread_messages,
          });
        }
      });
    });

    return tempContactList;
  };

  /*EFFECT*/

  useEffect(() => {
    if (+myConversations.data?.getMyConversations?.status_code !== +STATUS_CODE.SUCCESS) return;

    const contactList = mapContactList(myConversations.data.getMyConversations.conversations);

    setState({
      ...state,
      contactList: contactList,
      selectedConversation: contactList.length ? contactList[0] : null,
    });

    setQueryState({ ...queryState, pause: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myConversations.data?.getMyConversations?.conversations?.length]);

  useEffect(() => {
    return () => {
      dispatch({ type: MESSAGE_Actions.clear_message_store });
    };
  }, [dispatch]);

  /*MAIN RETURN*/
  if (myConversations.fetching) return <AwaitingComp fluid={true} />;

  return (
    <Grid className={styles.gridWrapper}>
      <Grid.Row className={styles.gridRow}>
        <Grid.Column computer='13' widescreen='13' className={styles.leftBlock}>
          <div className={styles.leftContent}>
            <MessageInput receiver={state.selectedConversation} />
            <br />
            <div className={styles.fixedDiaglogBlock}>
              <MessageDisplayView conversationID={state.selectedConversation?.conversationID} />
            </div>
          </div>
        </Grid.Column>
        <Grid.Column computer='3' widescreen='3' className={styles.rightBlock}>
          <div className={styles.searchWrapper}>
            <SearchContact handleSearch={handleSearchContact} />
            {isComponentShowed && (
              <div ref={ref} className={styles.searchResultWrapper}>
                {handleRenderSearchResult()}
              </div>
            )}
          </div>
          <ContactList
            contactList={state.contactList}
            selectingConversationID={state.selectedConversation?.conversationID}
            handleSelectConversation={handleSelectConversation}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default MessagingPage;
