import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, Icon, Rating, RatingProps } from 'semantic-ui-react';
import { useMutation } from 'urql';
import { STATUS_CODE, SESSION_CONSTANT, Role_ID, SESSION_ACTIONS } from '../../models/enum';
import { ISession } from '../../models/type';
import { SESSION_finish_session } from '../../services/apis/sessionApis';
import styles from './rateSessionComp.module.scss';

interface Props {
  session: ISession;
  finishSession: boolean;
  isLastSession?: boolean;
  closeRateSession: () => void;
  queryGetCourse: () => void;
}

const RateSessionComp: React.FC<Props> = (props: Props) => {
  /*RPOPS*/
  const [state, setState] = useState({
    rating: 0,
    showAfterEvaluationContent: false,
    showCongrateFinishLastSession: false,
  });

  /*METHODS*/
  const { t: translate } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleRate = (e: React.MouseEvent<HTMLDivElement>, data: RatingProps) => {
    setState({ ...state, rating: +data.rating });
  };

  const [, executeFinishSession] = useMutation(SESSION_finish_session);

  const handleSubmit = (session_id: string, evaluation: number, finish_session: boolean) => {
    executeFinishSession({
      session_id: session_id,
      evaluation: evaluation,
      // evaluation_comment: "", dont have comment for now
      finish_session: finish_session,
    })
      .then((res) => {
        if (+res.data.saveSessionEvaluation.status_code === +STATUS_CODE.SUCCESS) {
          //Successful finish will display thank you content
          setState({ ...state, showAfterEvaluationContent: true });
          dispatch({
            type: SESSION_ACTIONS.coachee_add_new_done_session,
            payload: { newSessionDone: props.session },
          });
        } else {
          console.error(res.error);
        }
      })
      .catch((err) => console.error(err));
  };

  if (state.showCongrateFinishLastSession)
    return (
      <div className={styles.modal}>
        <div className={styles.wrapper}>
          <div className={styles.headingBlock}>
            <span className={`${styles.headerTitle} topLevelText`}>{translate('Congratulation')}</span>
            <Icon name='close' className={styles.closeIcon} onClick={props.closeRateSession} />
          </div>
          <div className={styles.bodyBlock}>
            <div className={styles.bodyContent}>
              <p className={styles.contentText}>
                {translate('Congratulation for finish a course with MyPositiveBooster')}
              </p>
            </div>
            <div className={`${styles.btnBlock} ${styles.btnBlock2}`}>
              <Button className={`${styles.sendBtn} ${styles.active}`} onClick={() => navigate(`/${Role_ID.COACHEE}`)}>
                <span className={'buttonText topLevelText'}>{translate('Close')}</span>
              </Button>
            </div>
          </div>
        </div>
      </div>
    );

  return (
    <div className={styles.modal}>
      <div className={styles.wrapper}>
        <div className={styles.headingBlock}>
          <span className={`${styles.headerTitle} topLevelText`}>
            {`${translate('Session')} ${props.session.current_index} - ${translate('Your opinion')}`}
          </span>
          <Icon name='close' className={styles.closeIcon} onClick={props.closeRateSession} />
        </div>
        <div className={styles.bodyBlock}>
          {!state.showAfterEvaluationContent ? (
            <>
              <div className={styles.bodyContent}>
                <p className={styles.contentText}>{translate('How was this session useful?')}</p>
                <div className={styles.ratingBlock}>
                  <span className={`${styles.ratingText} topLevelText`}>{translate('Not useful')}</span>
                  <Rating
                    minRating={0}
                    maxRating={SESSION_CONSTANT.max_star}
                    icon='star'
                    size='massive'
                    className={styles.ratingComp}
                    onRate={handleRate}
                  />
                  <span className={`${styles.ratingText} topLevelText`}>{translate('very useful')}</span>
                </div>
              </div>
              <div className={styles.btnBlock}>
                <Button
                  className={`${styles.sendBtn} ${state.rating && styles.active}`}
                  disabled={!state.rating}
                  onClick={() => handleSubmit(props.session.id, state.rating, props.finishSession)}>
                  <span className='topLevelText buttonText'>{translate('Send')}</span>
                </Button>
              </div>
            </>
          ) : (
            <>
              <div className={styles.bodyContent}>
                <div className={styles.finalEvaluateNumber}>
                  <span className={styles.ratingNumber}>{state.rating + '/'}</span>
                  <span className={styles.maxRatingNumber}>{SESSION_CONSTANT.max_star}</span>
                  <Icon name='star' className={styles.starIcon} size='large' />
                </div>
                <p className={styles.thanksText}>
                  {translate('Thank you for evaluation') + ' '}
                  <Icon name='smile outline' className={styles.smileIcon} size='large' />
                </p>
              </div>
              <div className={`${styles.btnBlock} ${styles.btnBlock2}`}>
                <Button
                  className={`${styles.sendBtn} ${styles.active}`}
                  onClick={() => {
                    //Click close button here will requery getCourse in Current session page and close rate component
                    if (props.isLastSession) {
                      setState({
                        ...state,
                        showCongrateFinishLastSession: true,
                      });
                      return;
                    }
                    props.closeRateSession();
                    props.queryGetCourse();
                  }}>
                  <span className={'buttonText topLevelText'}>{translate('Close')}</span>
                </Button>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default React.memo(RateSessionComp);
