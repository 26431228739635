export const PROJECTDIRECTOR_get_detail = `
query ($id : ID!) {
    detailProjectDirector (id : $id) {
        status_code
        id
        user_infos {
            id
            email
            first_name
            last_name
            department
            position
            gdpr_consent
        }
    }
}
`;

export const PROJECTDIRECTOR_update_detail = `
mutation ($id: ID!, $first_name: String!, $last_name: String!, $email: String!, $department: String!, $position: String!) {
    updateProjectDirector(
        id: $id,
        email: $email
        last_name: $last_name
        first_name: $first_name 
        department: $department
        position: $position
    ) {
        status_code
        project_director {
            id
            email
            last_name
            first_name
            department
            position
            client_id
        }
    }
}
`;

export const PROJECTDIRECTOR_dashboard = `
query {
    getProjectDirectorDashboard {
        status_code
        programs {
            in_progress {
                number_of_programs
                number_of_program_managers
                number_of_coachees
                courses {
                    total
                    number_of_done
                    number_of_remaining
                }
                sessions {
                    total
                    number_of_done
                    number_of_remaining
                }
            }
            done {
                year
                number_of_programs
                number_of_program_managers
                number_of_coachees
                courses {
                    total
                    number_of_done
                    number_of_remaining
                }
                sessions {
                    total
                    number_of_done
                    number_of_remaining
                }
            }
        }
    }
}
`;
