import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button, Grid, Segment } from 'semantic-ui-react';
import { ProgramStatus, Role_ID } from '../../models/enum';
import { IOfferConfiguration, IProgram_extended } from '../../models/type';
import styles from './programCardForManager.module.scss';

interface Props {
  program: IProgram_extended;
}

const ProgramCardForManager: React.FC<Props> = (props: Props) => {
  /*METHODS*/
  const { t: translate } = useTranslation();
  const navigate = useNavigate();

  const goToProgramMainPage = (programID: string) => {
    navigate(`/${Role_ID.PROGRAM_MANAGER}/main/dashboard/${programID}`);
  };

  const shouldDisableButton = (): boolean => {
    if (props.program.status === ProgramStatus.NOT_READY) return true;
    return false;
  };

  const renderButtonLabel = (): string => {
    if (props.program.status === ProgramStatus.DONE) return translate('Review program');
    return translate('Go to program');
  };

  return (
    <Segment
      className={`${styles.customedSegment} ${
        props.program.status === ProgramStatus.NOT_READY && styles.disabledSegment
      }`}
      key={props.program.id}>
      <div>
        <Grid>
          <Grid.Row divided centered>
            <Grid.Column mobile={16} computer='4' textAlign='center' verticalAlign='middle'>
              <p
                className={`${styles.programName} ${
                  props.program.status === ProgramStatus.NOT_READY && styles.programNameNotReady
                }`}>
                {props.program.name}
              </p>
              {props.program.status === ProgramStatus.NOT_READY && (
                <p className={styles.wordNotReady}>{translate('Program available soon')}</p>
              )}
            </Grid.Column>
            <Grid.Column mobile='16' computer='12'>
              <Grid>
                <Grid.Row>
                  <Grid.Column mobile='16' tablet='16' computer='12'>
                    <table className={styles.table}>
                      <thead>
                        <tr>
                          <th></th>
                          <th>{translate('Number of courses')}</th>
                          <th>{translate('Number of coachees')}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {props.program.offerConfigurations.map((offer: IOfferConfiguration, key: number) => {
                          return (
                            <tr key={key}>
                              <td>{offer.name}</td>
                              <td>{offer.number_of_courses}</td>
                              <td>{offer.number_of_coachees}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </Grid.Column>
                  <Grid.Column mobile='16' tablet={8} computer='4' verticalAlign='bottom'>
                    <Button
                      className={`${shouldDisableButton() ? styles.disabledBtn : styles.button}`}
                      disabled={shouldDisableButton()}
                      onClick={() => goToProgramMainPage(props.program.id)}>
                      <span className='topLevelText buttonText'>{renderButtonLabel()}</span>
                    </Button>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    </Segment>
  );
};

export default React.memo(ProgramCardForManager);
