import React from 'react';
import styles from './Chart.module.scss';
import 'chartjs-plugin-datalabels';
import { useTranslation } from 'react-i18next';
import { Line } from 'react-chartjs-2';

interface Props {
  renderData: any;
}

const Chart: React.FC<Props> = (props) => {
  //Props

  //METHODS
  const { t: translate } = useTranslation();

  // const renderLegends = () => {
  //   return props.renderData.labels.map((current: any, index: number) => {
  //     return (
  //       <div className={styles.legendItemWrapper} key={index}>
  //         <p className={styles.number}>{props.renderData.data[index]}</p>
  //         <p className={`${styles.legend}`}>
  //           {current.charAt(0) + current.slice(1)}
  //         </p>
  //         <div
  //           className={styles.legendColor}
  //           style={{ backgroundColor: props.renderData.backgroundColor[index] }}
  //         ></div>
  //       </div>
  //     );
  //   });
  // };

  const isSessionChart = () => {
    return props.renderData.title.split(' ')[0] === 'Sessions';
  };

  switch (props.renderData.chartType) {
    case 'horizontal_stacked_bar':
      const { number_of_started, number_of_done, number_of_unassigned, total } = props.renderData.data;
      //calculate % of Done and started
      const percentageOfStartedAndDone = (100 * (number_of_started + number_of_done)) / total + '%';
      //calculate % of Done
      const percentageOfDone = (100 * number_of_done) / (number_of_started + number_of_done) + '%';

      return (
        <div>
          <div className={styles.topBlock}>
            <div className={styles.totalBlock}>
              <span className={styles.totalNumber}>{total}</span>
              <span className={styles.totalText}>{translate('Course')}</span>
            </div>
            <div className={styles.legendBlock}>
              <div className={styles.legendBlock_topRow}>
                <div className={styles.legendItem}>
                  <div className={`${styles.bar_legendColor} ${styles.startedColor}`}></div>
                  <p>
                    <span className={styles.legendNumber}>{number_of_started}</span>
                    <span className={styles.legendText}>
                      {translate('Started', {
                        context: 'male',
                        count: number_of_started,
                      })}
                    </span>
                  </p>
                </div>
              </div>
              <div className={styles.legendBlock_botRow}>
                <div className={styles.legendItem}>
                  <div className={`${styles.bar_legendColor} ${styles.doneColor}`}></div>
                  <p>
                    <span className={styles.legendNumber}>{number_of_done}</span>
                    <span className={styles.legendText}>
                      {translate('Completed', {
                        context: 'male',
                        count: number_of_done,
                      })}
                    </span>
                  </p>
                </div>
                <div className={styles.legendItem}>
                  <div className={`${styles.bar_legendColor} ${styles.unassignedColor}`}></div>
                  <p>
                    <span className={styles.legendNumber}>{number_of_unassigned}</span>
                    <span className={styles.legendText}>
                      {translate('Unassigned', {
                        context: 'male',
                        count: number_of_unassigned,
                      })}
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.fullBar}>
            {!!total && (
              <div className={styles.startedAndDone} style={{ width: percentageOfStartedAndDone }}>
                <div className={styles.done} style={{ width: percentageOfDone }}></div>
              </div>
            )}
          </div>
        </div>
      );

    case 'simple_horizontal_stack_bar':
      const totalNumber = props.renderData.totalNumber || 1;
      const numberOfDone = props.renderData.numberOfDone;
      const donePercentage = (numberOfDone / totalNumber) * 100 + '%';
      return (
        <div>
          <div className={styles.topBlock}>
            <div className={styles.simpleHorizontalTotalBlock}>
              <span className={styles.totalNumber}>{totalNumber}</span>
              <span className={styles.totalText}>{props.renderData.title}</span>
            </div>
          </div>

          <div className={styles.simpleHorizontalLegendBlock}>
            <div className={`${styles.legendItem} ${styles.firstSimpleHorizontalLegendItem}`}>
              <div className={`${styles.bar_legendColor} ${styles.doneColor}`}></div>
              <p>
                <span className={styles.legendNumber}>{numberOfDone}</span>
                <span className={styles.legendText}>
                  {isSessionChart()
                    ? translate('SessionAchieved', { count: numberOfDone })
                    : translate('CourseAchived', { count: numberOfDone })}
                </span>
              </p>
            </div>
            <div className={styles.legendItem}>
              <div className={`${styles.bar_legendColor} ${styles.unassignedColor}`}></div>
              <p>
                <span className={styles.legendNumber}>{totalNumber - numberOfDone}</span>
                <span className={styles.legendText}>
                  {isSessionChart()
                    ? translate('SessionRemaning', {
                        count: totalNumber - numberOfDone,
                      })
                    : translate('CourseRemaning', {
                        count: totalNumber - numberOfDone,
                      })}
                </span>
              </p>
            </div>
          </div>

          <div className={styles.fullBar}>
            <div className={styles.done} style={{ width: donePercentage }}></div>
          </div>
        </div>
      );

    case 'line-chart':
      const lineChartoptions = {
        scaleShowGridLines: true,
        scaleGridLineColor: 'rgba(0,0,0,.05)',
        scaleGridLineWidth: 1,
        scaleShowHorizontalLines: true,
        scaleShowVerticalLines: true,
        pointDot: true,
        pointDotRadius: 4,
        pointDotStrokeWidth: 20,
        pointHitDetectionRadius: 20,
        datasetStroke: true,
        datasetStrokeWidth: 2,
        datasetFill: true,
        // responsive: true,
        maintainAspectRatio: false,
        title: {
          display: false,
        },
        legend: {
          display: false,
        },
        tooltips: {
          enabled: true,
        },
        scales: {
          xAxes: [
            {
              gridLines: {
                display: false,
              },
              scaleLabel: {
                display: true,
                labelString: props.renderData.xAxisLabel,
              },
            },
          ],
          yAxes: [
            {
              scaleLabel: {
                display: true,
                labelString: props.renderData.yAxisLabel,
              },
              ticks: {
                display: true,
                beginAtZero: true,
                max: 10,
              },
            },
          ],
        },
        plugins: {
          datalabels: {
            display: false,
          },
        },
      };
      return <Line data={props.renderData.data} options={lineChartoptions} height={350} />;

    default:
      return (
        <>
          <p>No chart type</p>
        </>
      );
  }
};

export default Chart;
