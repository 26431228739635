import React, { useState } from 'react';
import { Icon } from 'semantic-ui-react';
import styles from './searchSimple.module.scss';

interface Props {
  placeholder: string;
  handleSearch?: (searchInput: string) => void;
  noBorder?: boolean;
  freeWidth?: boolean;
}

const SearchSimple: React.FC<Props> = (props: Props) => {
  const [inputState, setInputState] = useState('');

  const handleSearch = (e: React.FormEvent, value: string) => {
    e.preventDefault();
    props.handleSearch(value);
  };

  return (
    <form
      autoComplete='off'
      onSubmit={(e) => handleSearch(e, inputState)}
      className={`${styles.searchWrapper} ${props.noBorder && styles.noBorder} ${props.freeWidth && styles.freeWidth}`}>
      <input
        type='text'
        className={styles.searchBar}
        placeholder={props.placeholder}
        value={inputState}
        onChange={(e: React.FormEvent<HTMLInputElement>) => setInputState(e.currentTarget.value)}
        autoComplete='off'
      />
      <button type={'submit'} className={styles.btnSubmit}>
        <Icon name='search' className={styles.searchIcon} />
      </button>
    </form>
  );
};

export default React.memo(SearchSimple);
