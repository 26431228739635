import React from 'react';
import Chart from '../Chart/Chart';
import styles from './ChartContainer.module.scss';

interface Props {
  chartTitle: string;
  chartTitleHighlight?: boolean;
  chartType: string;
  data: any;
}

const ChartContainer: React.FC<Props> = (props: Props) => {
  return (
    <div className={styles.chartWrapper}>
      <p className={`${styles.chartTitle} ${props.chartTitleHighlight && styles.chartTitleHighlight}`}>
        {props.chartTitle}
      </p>
      <div className={styles.chartContainer}>
        <Chart renderData={props.data} />
      </div>
    </div>
  );
};

export default ChartContainer;
