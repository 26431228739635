import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from 'semantic-ui-react';
import { useQuery } from 'urql';
import { COACH_Actions, STATUS_CODE, UserRoles } from '../../models/enum';
import { ISession } from '../../models/type';
import { COACH_list_sessions } from '../../services/apis/coachApis';
import { getUserRoleID } from '../../utilities/helper';
import CoachCardFinishedCourse from './CoachCardFinishedCourse';
import styles from './coachFinishedSessionsPage.module.scss';
import moment from 'moment';

const CoachFinishedSessionsPage: React.FC = () => {
  /*STATE*/
  const [queryVariables] = useState({
    user_role_id: getUserRoleID(UserRoles.COACH),
    number_of_days: 0,
    course_id: '',
    only_done: true,
  });

  const finishedSessionsSelector = useSelector((state: any) => state.coachReducer.myCourses.finishedCourses);

  /*METHODS*/
  const { t: translate } = useTranslation();

  const dispatch = useDispatch();

  const [listCoachSessions] = useQuery({
    query: COACH_list_sessions,
    variables: queryVariables,
    requestPolicy: 'cache-and-network',
  });

  const renderCoachCardFinish = (array: Array<ISession>) => {
    return array.map((item: ISession) => {
      return <CoachCardFinishedCourse finishedSessions={item} key={item.id} />;
    });
  };

  const renderMonthYearSessionBlock = () => {
    if (!finishedSessionsSelector) return <></>;
    const renderArray = [];
    for (let key in finishedSessionsSelector) {
      const shortMonthName = translate(moment(key, 'MM/YYYY').format('MMM'));
      const formatShortMonthYear = shortMonthName + ' ' + moment(key, 'MM/YYYY').format('YYYY');
      renderArray.push(
        <div className={styles.monthBlock}>
          <p className={`${styles.monthTitle} topLevelText`}>{formatShortMonthYear}</p>
          {renderCoachCardFinish(finishedSessionsSelector[key])}
        </div>,
      );
    }
    return renderArray;
  };

  /**EFFECT */

  useEffect(() => {
    if (+listCoachSessions.data?.listMySessions?.status_code === +STATUS_CODE.SUCCESS) {
      dispatch({
        type: COACH_Actions.set_my_finished_sessions,
        payload: listCoachSessions.data.listMySessions.sessions,
      });
    }
  }, [dispatch, listCoachSessions]);

  /*MAIN RETURN*/

  if (!finishedSessionsSelector) return <></>;

  return (
    <Grid className={styles.grid}>
      <Grid.Row>
        <Grid.Column mobile='16' tablet='16' computer='14' widescreen='14' largeScreen='11'>
          <div className={styles.wrapper}>
            <div className={styles.mainContentBlock}>{renderMonthYearSessionBlock()}</div>
          </div>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default CoachFinishedSessionsPage;
