import React from 'react';
import { useTranslation } from 'react-i18next';
import { Icon, Segment } from 'semantic-ui-react';
import styles from './closedSessionNote.module.scss';

interface Props {
  sessionIndex: number;
  sessionNote: string;
  closeSessionNote: () => void;
}

const ClosedSessionNote: React.FC<Props> = (props: Props) => {
  /*METHODS*/
  const { t: translate } = useTranslation();

  return (
    <div className={styles.wrapper}>
      <div className={styles.noteWrapper}>
        <Segment className={styles.noteBlock}>
          <div className={`${styles.topNoteBlock} `}>
            <span>
              <Icon name='file alternate outline' className={styles.icon1} />
              {translate('Note of session') + ' ' + props.sessionIndex}
            </span>
            <Icon name='close' className={styles.closeIcon} onClick={props.closeSessionNote} />
          </div>

          <div className={`${styles.bottomNoteBlock}`}>
            <p className={styles.noteText}>{props.sessionNote}</p>
          </div>
        </Segment>
      </div>
    </div>
  );
};

export default React.memo(ClosedSessionNote);
