import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Rating, RatingProps } from 'semantic-ui-react';
import { IEvaluateCourseRatingItem } from '../../models/type';
import styles from './coacheeEvaluateFinishCourse.module.scss';

interface Props {
  item: IEvaluateCourseRatingItem;
  onChange: (name: string, rating: number) => void;
}

const EvaluateCourseRatingItem: React.FC<Props> = (props: Props) => {
  /*METHODS*/
  const { t: translate } = useTranslation();

  const handleChange = (_e: React.MouseEvent<HTMLDivElement, MouseEvent>, data: RatingProps) => {
    props.onChange(props.item.name, +data.rating);
  };

  return (
    <Grid.Row stretched verticalAlign='middle'>
      <Grid.Column mobile='16' tablet='10' computer='10'>
        <p className={styles.text1}>{translate(props.item.question)}</p>
      </Grid.Column>
      <Grid.Column mobile='16' tablet='6' computer='6'>
        <Rating
          icon='star'
          size='massive'
          maxRating={props.item.maxRating}
          className={styles.ratingBlock}
          onRate={handleChange}
        />
      </Grid.Column>
    </Grid.Row>
  );
};

export default React.memo(EvaluateCourseRatingItem);
