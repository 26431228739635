

export const SESSION_plan = `
mutation($session_id: ID!, $planned_at: DateTimeTz!, $calendly_event_uri : String!) {
    planSession(
        id: $session_id,
        planned_at: $planned_at,
        calendly_event_uri : $calendly_event_uri
    ) {
        status_code
        session {
            id
            status
            planned_at
        }
    }
}
`;

export const SESSION_save_note = `
mutation($session_id: ID!, $note : String!) {
    saveSessionNote(
        id: $session_id,
        note: $note
    ) {
        status_code
        session {
            id
            status
            planned_at
            duration
            note
            evaluation
            evaluation_comment
        }
    }
}
`;

export const SESSION_start = `
mutation($session_id: ID!) {
    startSession(
        id: $session_id
    ) {
        status_code
        session {
            id
            status
            planned_at
            attendees
        }
    }
}
`;

export const SESSION_cancel = `
mutation($session_id: ID!) {
    cancelSession(
        id: $session_id
    ) {
        status_code
        session {
            id
            status
            planned_at
        }
    }
}
`;

export const SESSION_get_status = `
query($session_id: ID!) {
    getSessionStatus(
        id: $session_id
    ) {
        status_code
        session {
            id
            status
            planned_at
            duration
            note
            evaluation
            evaluation_comment
            is_first
            is_last
            current_index
            attendees
        }
    }
}
`;

export const SESSION_finish_session = `
mutation($session_id: ID!, $evaluation : Int!, $evaluation_comment : String,  $finish_session : Boolean) {
    saveSessionEvaluation(
        id: $session_id,
        evaluation: $evaluation,
        evaluation_comment: $evaluation_comment,
        finish_session: $finish_session
    ) {
        status_code
        session {
            id
            status
            planned_at
            duration
            evaluation
            evaluation_comment
        }
    }
}
`;

export const SESSION_declare_absent = `
mutation($session_id: ID!) {
    declareAnAbsence(
        id: $session_id
    ) {
        status_code
        session {
            id
            status
            planned_at
        }
    }
}
`;

export const SESSION_set_video_conference_link = `
mutation($session_id: ID!, $video_conference_link: String!) {
    setSessionConferenceLink(
        session_id: $session_id
        video_conference_link: $video_conference_link
    ) {
        status_code
    }
}
`;

export const SESSION_coachee_get_sessions_done = `
query ($user_role_id : ID!, $course_id : ID!) {
    listMySessions(
        user_role_id: $user_role_id,
        course_id: $course_id,
        only_done : true
    ) {
        status_code
        sessions {
            id
            status
            planned_at
            finished_at
            note
            current_index
            is_offline
            coach {
                first_name
                last_name
            }
            course_context {
                number_of_sessions
            }
        }
    }
}
`;

export const SESSION_to_offline = `
mutation($session_id: ID!) {
    sessionIsOffline(
        id: $session_id
    ) {
        status_code
        session {
            id
            status
            planned_at
            duration
            note
            evaluation
            evaluation_comment
            is_first
            is_last
            current_index
            is_offline
            coach {
                first_name
                last_name
            }
            coachee {
                first_name
                last_name
            }
        }
    }
}
`
export const SESSION_to_online = `
mutation($session_id: ID!) {
    sessionIsOnline(
        id: $session_id
    ) {
        status_code
        session {
            id
            status
            planned_at
            duration
            note
            evaluation
            evaluation_comment
            is_first
            is_last
            current_index
            is_offline
            coach {
                first_name
                last_name
            }
            coachee {
                first_name
                last_name
            }
        }
    }
}
`