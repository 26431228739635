import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Button } from 'semantic-ui-react';
import { useMutation } from 'urql';
import { token_user } from '../../config/tokenConstant';
import { MaterialIconName, MESSAGE_Actions, STATUS_CODE } from '../../models/enum';
import { IContactListItem } from '../../models/type';
import { MESSAGE_send } from '../../services/apis/messageApis';
import { calcHeight } from '../../utilities/helper';
import styles from './messageInput.module.scss';

interface Props {
  receiver: IContactListItem;
}

const MessageInput: React.FC<Props> = (props: Props) => {
  /*STATE*/
  const [state, setState] = useState({
    title: '',
    content: '',
  });

  /*METHODS*/
  const { t: translate } = useTranslation();
  const dispatch = useDispatch();
  const [, executeSendMessage] = useMutation(MESSAGE_send);

  const handleOnChange = (e: React.FormEvent<HTMLInputElement> | React.FormEvent<HTMLTextAreaElement>) => {
    setState({ ...state, [e.currentTarget.name]: e.currentTarget.value });
  };

  const handleSubmit = () => {
    const requestBody = {
      conversation_id: props.receiver.conversationID,
      receiver_id: props.receiver.receiverID,
      title: state.title,
      content: state.content,
    };

    if (state.title === '' || state.title === null) {
      delete requestBody.title;
    }

    executeSendMessage(requestBody)
      .then((res) => {
        if (+res.data?.sendMessage?.status_code === +STATUS_CODE.SUCCESS) {
          dispatch({
            type: MESSAGE_Actions.add_new_message,
            payload: {
              id: 'temp' + Date.now(),
              sender: JSON.parse(localStorage.getItem(token_user)).id,
              title: state.title,
              content: state.content,
              created_at: Date.now(),
              sent_at: Date.now(),
              received_at: Date.now(),
              read_at: null,
              is_private: false,
            },
          });
          setState({ title: '', content: '' });
        }
      })
      .catch((err) => console.error(err));
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.inputBlock}>
        <div className={styles.messageTitleBlock}>
          <input
            type='text'
            name='title'
            placeholder={translate('Insert message title')}
            className={styles.messageTitleInput}
            value={state.title}
            onChange={handleOnChange}
          />
        </div>
        <div className={styles.messageContentBlock}>
          <textarea
            className={styles.textArea}
            name='content'
            placeholder={translate('Message content')}
            value={state.content}
            onChange={handleOnChange}
            style={{ height: calcHeight(state.content) }}
          />
        </div>
      </div>
      <div className={styles.buttonBlock}>
        <Button className={styles.sendBtn} disabled={!state.content} onClick={handleSubmit}>
          <span className={`${styles.sendIcon} material-icons-outlined`}>{MaterialIconName.send}</span>
          <span className='buttonText topLevelText'>{translate('Send')}</span>
        </Button>
      </div>
    </div>
  );
};

export default React.memo(MessageInput);
