import { RouteObject } from 'react-router-dom';

import ProgramMangerListCoacheePage from '../../pages/ProgramMangerListCoacheePage/ProgramMangerListCoacheePage';
import PMDashboard from '../PMDashboard/PMDashboard';

const prograManagerRouter: RouteObject[] = [
  { path: 'dashboard/:id', element: <PMDashboard /> },
  { path: 'list_coachee/:id', element: <ProgramMangerListCoacheePage /> },
];

export default prograManagerRouter;
