import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Grid, Button, Icon, Segment } from 'semantic-ui-react';
import { useMutation, useQuery } from 'urql';

import CoachCardComp from '../../components/CoachCardComp/CoachCardComp';
import PrimaryLayout from '../../layouts/PrimaryLayout/PrimaryLayout';
import { COURSE_STATUS, MaterialIconName, Role_ID, STATUS_CODE } from '../../models/enum';
import { ICoachSummary } from '../../models/type';
import { COURSE_course_on_boarded, COURSE_get_coachs, COURSE_select_coach } from '../../services/apis/courseApis';
import styles from './coacheeCoachSelectionPage.module.scss';

const CoacheeCoachSelectionPage: React.FC = () => {
  /*PROPS*/
  const [state, setState] = useState({
    selectedCoach: null,
    indexOfSelectedCoach: null,
    showSuccesfulSelectedCoach: false,
  });
  const location = useLocation();
  const [coachsListResult, getCoachList] = useQuery({
    query: COURSE_get_coachs,
    variables: { course_id: location.state.selectedCourse.id },
    requestPolicy: 'cache-and-network',
    pause: true,
  });

  /*METHODS*/
  const { t: translate } = useTranslation();
  const navigate = useNavigate();
  const [, executeCourOnboarded] = useMutation(COURSE_course_on_boarded);
  const [, executeSelectCoach] = useMutation(COURSE_select_coach);

  const gotToProfile = () => navigate(`/${Role_ID.COACHEE}/profile`);

  const renderCoach = () => {
    return coachsListResult.data.getCoachSelection.coaches.map((coachItem: ICoachSummary, index: number) => {
      return (
        <CoachCardComp
          key={coachItem.id}
          coach={coachItem}
          isSelected={state.indexOfSelectedCoach === index}
          selectCoach={() => setState({ ...state, selectedCoach: coachItem, indexOfSelectedCoach: index })}
        />
      );
    });
  };

  const handleSubmit = (courseID: string, coachID: string) => {
    executeSelectCoach({ course_id: courseID, coach_id: coachID })
      .then((res) => {
        if (res.data?.selectCourseCoach.status_code === +STATUS_CODE.SUCCESS) {
          setState({ ...state, showSuccesfulSelectedCoach: true });
        } else console.error(res.error);
      })
      .catch((err) => console.error(err));
  };

  /*EFFECT*/

  useEffect(() => {
    if (!location?.state.selectedCourse) {
      navigate(`/${Role_ID.COACHEE}`);
      return;
    }
    if (location?.state.selectedCourse.status === COURSE_STATUS.started_coach_selection) {
      getCoachList();
      return;
    }
    //OTHER CASE CALL API
    executeCourOnboarded({
      course_id: location.state.selectedCourse.id,
    })
      .then((res) => {
        if (res.data?.courseOnboarded.status_code === +STATUS_CODE.SUCCESS) {
          getCoachList();
          return;
        }
        /*FAILED*/
        console.error('Cannot onboard this course', res.error);
        navigate(`/${Role_ID.COACHEE}`);
      })
      .catch((err) => console.error(err));
  }, [executeCourOnboarded, getCoachList, location.state.selectedCourse, navigate]);

  /*MAIN RETURN */

  return (
    <PrimaryLayout
      primaryTitle='Mes parcours'
      primaryTitleIcon={MaterialIconName.assignment}
      role_name='Coaché'
      toProfile={gotToProfile}
      hideUserIcon={true}
      onClickPrimaryTitle={() => {
        navigate(`/${Role_ID.COACHEE}`);
      }}
      goToMessage={() => navigate(`/${Role_ID.COACHEE}/course-management/message`)}>
      {!state.showSuccesfulSelectedCoach ? (
        <div className={styles.wrapper}>
          <div>
            <h1 className={styles.title}>{translate('Choose coach')}</h1>
            <p className={styles.titleDesc}>{translate('List of coachs for you to choose')}</p>
          </div>
          {coachsListResult.data && (
            <div className={styles.cardsWrapper}>
              <Grid stretched>{renderCoach()}</Grid>
              <p className={styles.supportText}>
                <Icon name='info' className={styles.infoIcon} />
                {translate('Contact MPB to choose more coachs')}
                <a href={`mailto:${translate('mpb email support')}`}>
                  <span className={styles.emailSupport}>{translate('mpb email support')}</span>
                </a>
              </p>
              <div className={styles.bottomButtonWrapper}>
                <Button
                  className={`${styles.submitButton} ${state.selectedCoach && styles.active}`}
                  disabled={!state.selectedCoach}
                  onClick={() => handleSubmit(location.state.selectedCourse.id, state.selectedCoach.id)}>
                  <span className={`topLevelText buttonText`}>{translate('Finish')}</span>
                </Button>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className={styles.wrapper2}>
          <Grid>
            <Grid.Row>
              <Grid.Column mobile='16' tablet='16' computer='14'>
                <Segment className={styles.customedSegment}>
                  <div className={styles.success_contentWrapper}>
                    <Icon name='check' className={styles.checkIcon} size='huge' />
                    <span className={styles.successText}>{translate('Congratulation')}</span>
                    <p className={styles.descText}>
                      {translate('Good selection')}, {translate('You have selected')}{' '}
                      <span className={styles.coachName}>
                        {' '}
                        {state.selectedCoach.user_infos.first_name + ' ' + state.selectedCoach.user_infos.last_name}
                      </span>{' '}
                      {translate('Like a coach for your course')}
                    </p>
                    <Button
                      className={styles.closeBtn}
                      onClick={() => {
                        navigate(`/${Role_ID.COACHEE}/course-management/current/?${location.state.selectedCourse.id}`);
                      }}>
                      <span className={`buttonText topLevelText`}>{translate('Close')}</span>
                    </Button>
                  </div>
                </Segment>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      )}
    </PrimaryLayout>
  );
};

export default CoacheeCoachSelectionPage;
