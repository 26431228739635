import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, Form } from 'semantic-ui-react';
import { useMutation } from 'urql';
import { COACHEE_ACTIONS, Role_ID, STATUS_CODE } from '../../models/enum';
import { ICoacheeAnswerInput, IDiagnosisQuestion } from '../../models/type';
import { COURSE_answer_self_diagnostic, COURSE_finish_diagnosis } from '../../services/apis/courseApis';
import QuestionStep from '../QuestionStep/QuestionStep';
import styles from './multiFormWrapper.module.scss';

const MultiFormWrapper: React.FC = () => {
  const [state, setState] = useState({
    errorRetried: 0,
  });
  /*PROPS*/
  const coacheeSelector = useSelector((state: any) => state.coacheeReducer.selfDiagnostic);

  /*METHODS*/
  const { t: translate } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [answerDiagnosisResult, executeAnswerQuestions] = useMutation(COURSE_answer_self_diagnostic);
  const [finishDiagnosisResult, executeFinishDiagnosis] = useMutation(COURSE_finish_diagnosis);

  /**
   * Everytime submit a question: Check question ID coacheeAnswer also has in stepQuestionArray IF no remove that coacheeAnswer item
   * @returns {ICoacheeAnswerInput[]}
   */
  const filterUnusedAnswer = (): Array<ICoacheeAnswerInput> => {
    const tempCoacheeAnswersDiasnosis: Array<ICoacheeAnswerInput> = coacheeSelector.coacheeAnswerDiagnosisArr;
    const allStepQuestionID: Array<string> = coacheeSelector.stepsQuestionArray.map(
      (item: IDiagnosisQuestion) => item.id,
    );
    const indexOfAnswerShouldNotBeThere: Array<number> = [];
    tempCoacheeAnswersDiasnosis.forEach((item, index) => {
      if (!allStepQuestionID.includes(item.question_id)) {
        indexOfAnswerShouldNotBeThere.push(index);
      }
    });
    if (indexOfAnswerShouldNotBeThere.length) {
      indexOfAnswerShouldNotBeThere.forEach((item) => {
        tempCoacheeAnswersDiasnosis.splice(item, 1);
      });
    }
    return tempCoacheeAnswersDiasnosis;
  };

  const handleSubmit = (
    answersArray: Array<ICoacheeAnswerInput>,
    stepsQuestionArray: Array<IDiagnosisQuestion>,
    diagnosisID: string,
    currentStepIndex: number,
  ) => {
    //Is this the last question?

    if (currentStepIndex !== stepsQuestionArray.length - 1) {
      //IF not last question: call api answerselfdiagnosis AND dispatch an action to get next question
      dispatch({
        type: COACHEE_ACTIONS.answer_diagnosis,
        payload: {
          filteredCoacheeAnswerArrays: filterUnusedAnswer(),
        },
      });
      executeAnswerQuestions({
        diagnosis_id: diagnosisID,
        coacheeAnswers: answersArray,
      })
        .then((res) => {
          console.log('res: ', res);
          if (res.data.answerSelfDiagnosis.status_code === +STATUS_CODE.SUCCESS) return;
          if (res.error?.graphQLErrors[0].extensions.error_code === STATUS_CODE.FORBIDDEN) {
            alert(translate('Cannot answer questions had answered'));
            if (state.errorRetried < 1) {
              window.location.reload();
              setState({
                errorRetried: 1,
              });
            } else {
              alert(translate('Server Error, please try again'));
              navigate(`/${Role_ID.COACHEE}`);
              console.error(res.error);
            }
          } else {
            alert(translate('Server Error, please try again'));
            navigate(`/${Role_ID.COACHEE}`);
            console.error(res.error);
          }
        })
        .catch((err) => console.error(err));
    }
    //IF last question: sent api finish answer
    else {
      executeFinishDiagnosis({
        diagnosis_id: diagnosisID,
        coacheeAnswers: answersArray,
      })
        .then((res) => {
          console.log('Res: ', res);
          if (res.data.finishSelfDiagnosis.status_code === +STATUS_CODE.SUCCESS) {
            dispatch({
              type: COACHEE_ACTIONS.finish_self_diagnostic,
            });
          } else {
            alert(translate('Server Error, please try again'));
            navigate(`/${Role_ID.COACHEE}`);
            console.error(res.error);
          }
        })
        .catch((err) => console.error(err));
    }
  };

  const handleBack = useCallback(() => {
    dispatch({
      type: COACHEE_ACTIONS.back_to_previous_question,
    });
  }, [dispatch]);

  return (
    <Form>
      <QuestionStep />
      <div className={styles.btnBlock}>
        <Button
          className={`${styles.btn} ${styles.btnBack} ${!coacheeSelector.currentStepIndex && styles.invisible}`}
          disabled={answerDiagnosisResult.fetching || finishDiagnosisResult.fetching}
          onClick={handleBack}>
          <span className='topLevelText buttonText'>{translate('Go back')}</span>
        </Button>

        <Button
          className={`${styles.btn} ${!coacheeSelector.showSubmitBtn && styles.nextBtnInvisible} ${
            answerDiagnosisResult.fetching && styles.nextBtnInvisible
          }`}
          onSubmit={handleSubmit}
          onClick={() =>
            handleSubmit(
              filterUnusedAnswer(),
              coacheeSelector.stepsQuestionArray,
              coacheeSelector.diagnosisID,
              coacheeSelector.currentStepIndex,
            )
          }>
          <span className='topLevelText buttonText'>{translate('Next')}</span>
        </Button>
      </div>
    </Form>
  );
};

export default MultiFormWrapper;
