import { RouterProvider } from 'react-router-dom';
import Router from './components/Router/Router';
import './App.scss';
import './theme/mpbConsistence.scss';

const App = () => {
  return (
    <div className='App'>
      <RouterProvider router={Router} />
    </div>
  );
};

export default App;
