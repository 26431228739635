import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { SESSION_ACTIONS, SESSION_CONSTANT } from '../../models/enum';
import { convertMinuteToHour } from '../../utilities/helper';
import styles from './clockTimer.module.scss';
import Countdown, { zeroPad } from 'react-countdown';
import { ISession } from '../../models/type';

interface Props {
  usingByCoach?: boolean;
}

const ClockTimer: React.FC<Props> = ({ usingByCoach }: Props) => {
  /*STATE*/

  const [showNoThing, setShowNoThing] = useState(false);
  const [showClockTimer, setShowClockTimer] = useState(false);

  /*PROPS*/

  const currentSession: ISession = useSelector((state: any) => {
    if (usingByCoach) {
      return state.coachReducer.myCourses.selectedSession;
    }

    return state.coacheeReducer.myCourseManagement.myCurrentSession;
  });

  const offerConfigurationDurationSelector: number = useSelector((state: any) => {
    return state.coacheeReducer.myCourseManagement?.myCourse?.offer_configuration?.duration_of_session;
  });
  /*METHODS*/

  const { t: translate } = useTranslation();

  const dispatch = useDispatch();

  const onStart = () => {
    if (usingByCoach) {
      return; //Because coach will set show finish session button inside it's component
    }

    const miliSecondsLeft = Math.floor(Date.now().valueOf() - new Date(currentSession.planned_at).valueOf());
    const timeOutDuration = currentSession.duration * 60000 - miliSecondsLeft - SESSION_CONSTANT.buffer_time * 60000;
    setTimeout(() => {
      dispatch({
        type: SESSION_ACTIONS.update_show_end_button,
        payload: true, //This case is TRUE
      });
    }, timeOutDuration);
  };

  const onTick = () => {
    //Miliseconds
    const gapBetweenFinishTimeAndNow =
      new Date(currentSession?.planned_at).valueOf() +
      currentSession?.duration * SESSION_CONSTANT.one_minute_in_miliseconds -
      Date.now().valueOf();

    if (gapBetweenFinishTimeAndNow <= SESSION_CONSTANT.one_minute_in_miliseconds) {
      setShowNoThing(true);
      setShowClockTimer(false);
    }
  };

  /*EFFECT*/

  useEffect(() => {
    if (!currentSession) return;
    //Check if status is started_wating or started
    const isStartedOrWaiting: boolean = ['started_waiting', 'started'].includes(currentSession.status);

    if (!isStartedOrWaiting) {
      setShowClockTimer(false);
      return;
    }

    const diffNowAndPlannedTime = Date.now().valueOf() - new Date(currentSession.planned_at).valueOf();

    //Now > planned at time
    if (diffNowAndPlannedTime >= 0) {
      setShowClockTimer(true);
      return;
    }

    const timeOutVar = setTimeout(() => {
      setShowClockTimer(true);
    }, Math.abs(diffNowAndPlannedTime));

    return () => {
      clearTimeout(timeOutVar);
    };
  }, [currentSession]);

  /*MAIN RETURN*/

  if (showNoThing || !currentSession) {
    return <></>;
  }

  return (
    <div className={styles.timeBlock}>
      <p>{translate('This session duration')}</p>
      {showClockTimer ? (
        <Countdown
          zeroPadTime={2}
          date={currentSession.duration * 60000 + new Date(currentSession.planned_at).valueOf()}
          renderer={({ hours, minutes }) => (
            <p className={styles.timerNumber}>
              {hours}h{zeroPad(minutes)}
            </p>
          )}
          onStart={onStart}
          onTick={onTick}
        />
      ) : (
        <p className={styles.timerNumber}>{convertMinuteToHour(offerConfigurationDurationSelector, 'h:mm')}</p>
      )}
    </div>
  );
};

export default React.memo(ClockTimer);
