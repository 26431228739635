import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'semantic-ui-react';
import { ICoacheeAnswerInput, IDiagnosisAnswer, IDiagnosisQuestion } from '../../models/type';
import styles from './questionStep.module.scss';
import { COACHEE_ACTIONS } from '../../models/enum';
import { checkAnswerIDHasInAnswerArray } from '../../utilities/helper';
import DateQuestionStep from './DateQuestionStep';

const QuestionStep: React.FC = () => {
  /*PROPS*/
  const questionOnStore: IDiagnosisQuestion = useSelector((state: any) => {
    return state.coacheeReducer.selfDiagnostic.stepsQuestionArray[state.coacheeReducer.selfDiagnostic.currentStepIndex];
  });

  const coacheeAnswerOnStore: Array<ICoacheeAnswerInput> = useSelector(
    (state: any) => state.coacheeReducer.selfDiagnostic.coacheeAnswerDiagnosisArr,
  );
  /*METHODS*/

  const dispatch = useDispatch();

  const handleChangeAnswer = useCallback(
    (
      e: React.FormEvent<HTMLInputElement>,
      questionID: string,
      maxAnswers: number,
      minAnswers: number,
      questionType: string,
    ) => {
      //Push answer to redux
      dispatch({
        type: COACHEE_ACTIONS.choose_self_diagnosis_answer,
        payload: {
          max_answers: maxAnswers,
          min_answers: minAnswers,
          question_type: questionType,
          answerID: e.currentTarget.value,
          questionID: questionID,
        },
      });
    },
    [dispatch],
  );

  const renderAnswers = useCallback(
    (answers: Array<IDiagnosisAnswer>, questionID: string, maxAnswer: number, minAnswer: number, type: string) => {
      return answers.map((item: IDiagnosisAnswer) => {
        return (
          <div className={styles.questionAnswer} key={item.id}>
            <input
              type={type}
              id={item.id}
              name={item.id}
              value={item.id}
              checked={checkAnswerIDHasInAnswerArray(questionID, item.id, coacheeAnswerOnStore)}
              className={styles.input}
              onChange={(e) => {
                handleChangeAnswer(e, questionID, maxAnswer, minAnswer, type);
              }}
            />
            <label htmlFor={item.id} className={styles.label}>
              {item.text}
            </label>
          </div>
        );
      });
    },
    [coacheeAnswerOnStore, handleChangeAnswer],
  );

  // const renderMinMaxPhrase = useCallback((min: number, max: number) => {
  //   if (min === max) {
  //     return translate('answers must be', { numberAnswer: max });
  //   }
  //   return translate('Several choices possible', { maxAnswer: max });
  // }, []);

  if (!questionOnStore) return <></>;

  if (questionOnStore.question_type === 'date') {
    return <DateQuestionStep dateQuestion={questionOnStore} />;
  }

  return (
    <>
      <div className={styles.questionWrapper}>
        <Form.Field className={styles.customedField}>
          <span className={styles.questionTitle}>
            {questionOnStore.title}{' '}
            {/* {questionOnStore.question_type === 'checkbox' &&
              `(${renderMinMaxPhrase(
                questionOnStore.min_answers,
                questionOnStore.max_answers
              )})`} */}
          </span>

          <div className={styles.inputField}>
            {renderAnswers(
              questionOnStore.answers,
              questionOnStore.id,
              questionOnStore.max_answers,
              questionOnStore.min_answers,
              questionOnStore.question_type,
            )}
          </div>
        </Form.Field>
      </div>
    </>
  );
};

export default QuestionStep;
