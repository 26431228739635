import { IEvaluateCourseRatingItem } from '../models/type';
import Theme1 from '../assets/images/theme1.webp';
import Theme2 from '../assets/images/theme2.webp';
import Theme3 from '../assets/images/theme3.webp';
import Theme4 from '../assets/images/theme4.webp';
import Theme5 from '../assets/images/theme5.webp';
import Theme6 from '../assets/images/theme6.webp';

export const CoachMediaResourcesTitle = new Map([
  ['pdf', 'Documents PDF'],
  ['web_link', 'Liens web'],
  ['video_link', 'Liens vidéo'],
  ['image', 'Images'],
]);

export const Coach_classify_decode = new Map([
  ['EXEC', 'Executive Coach'],
  ['MNGR', 'Coach Senior'],
  ['CDP', 'Coach'],
  ['CLB', 'Coach en devenir'],
]);

export const LANGUGAGE_map = new Map([
  ['fr', 'French'],
  ['en', 'English'],
  ['es', 'Spanish'],
  ['de', 'German'],
]);

export const coacheeSessionStatusTranslation_map = new Map([
  ['done', 'done'],
  ['coachee_is_absent', 'You were absent'],
  ['cancelled_by_coach', 'cancelled_by_coach'],
  ['cancelled_by_coachee', 'Cancelled by you'],
  ['interrupted', 'interrupted'],
]);

export const domainName = window.location.origin;

export const mediaResourcesFormat = [
  {
    key: 'all',
    text: 'All',
    value: '',
  },
  {
    key: 'web_link',
    text: 'Web link',
    value: 'web_link',
  },
  {
    key: 'video_link',
    text: 'Video link',
    value: 'video_link',
  },
  {
    key: 'pdf',
    text: 'Pdf',
    value: 'pdf',
  },
  {
    key: 'image',
    text: 'Image',
    value: 'image',
  },
];

export const mediaResourcesInputFormat = [
  {
    key: 'image',
    text: 'Image',
    value: 'image',
  },
  {
    key: 'pdf',
    text: 'PDF',
    value: 'pdf',
  },
  {
    key: 'video_link',
    text: 'Video link',
    value: 'video_link',
  },
  {
    key: 'web_link',
    text: 'Web link',
    value: 'web_link',
  },
];

export const calendlyAPIURL = 'https://api.calendly.com/users/me';

export const maxUploadSize = 5000000; //5MB

export const courseEvaluationQuestionsCollection: Array<IEvaluateCourseRatingItem> = [
  {
    question: 'Because of your performance',
    maxRating: 5,
    name: 'operational_performance',
  },
  {
    question: 'Because of work life quality',
    maxRating: 5,
    name: 'work_life_quality',
  },
  {
    question: 'Because of professional relationship',
    maxRating: 5,
    name: 'professional_relationships',
  },
  {
    question: 'Because of your leadership',
    maxRating: 5,
    name: 'leadership',
  },
  {
    question: 'Because of personal organization',
    maxRating: 5,
    name: 'personal_organization',
  },
];

export const monthLastAnswerIndex = 12;

export const MONTH_LABEL = 'month';

export const YEAR_LABEL = 'year';

export const SESSION_PAGE_HAVE_OVERLAY = 'media';

export const multimediaResourceThemes = [
  { theme: 'theme1', passcodes: ['DB11', 'MB3', 'DS2', 'CB8', 'PB4'], image: Theme1 },
  { theme: 'theme2', passcodes: ['DB3', 'MB3', 'MS9', 'DS8', 'MB8'], image: Theme2 },
  { theme: 'theme3', passcodes: ['DB6', 'PB2', 'DS9', 'MB13', 'PB3'], image: Theme3 },
  { theme: 'theme4', passcodes: ['PB5', 'PS1', 'DB7', 'MB18', 'MB9'], image: Theme4 },
  { theme: 'theme5', passcodes: ['DB11', 'PB6', 'MS9', 'DB17', 'DS10'], image: Theme5 },
  { theme: 'theme6', passcodes: ['DS2', 'MS2', 'CB4', 'DB9', 'PS1'], image: Theme6 },
];
