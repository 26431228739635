import { token_user } from '../../../config/tokenConstant';
import { MESSAGE_Actions } from '../../../models/enum';
import { IChatUser, IMessage, IMessageAction } from '../../../models/type';
import { findReceiver } from '../../../utilities/helper';

export interface IMessageReducer {
  receiver: IChatUser;
  messages: Array<IMessage>;
}

const initinalState: IMessageReducer = {
  receiver: null,
  messages: null,
};

export const messageReducer = (state = initinalState, action: IMessageAction) => {
  switch (action.type) {
    case MESSAGE_Actions.set_messages:
      const receiver = findReceiver(action.payload.conversers, JSON.parse(localStorage.getItem(token_user)).id);

      const tempMessages: Array<IMessage> = action.payload.messages;
      tempMessages.sort((a, b) => new Date(b.sent_at).getTime() - new Date(a.sent_at).getTime());

      return {
        ...state,
        receiver: receiver,
        messages: tempMessages,
      };

    case MESSAGE_Actions.add_new_message:
      const copiedMessages = [action.payload, ...state.messages];
      return { ...state, messages: copiedMessages };

    case MESSAGE_Actions.clear_message_store:
      return { ...state, receiver: null, messages: null };
    default:
      return state;
  }
};
