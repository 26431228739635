import { ISession } from '../models/type';
import { Store } from '../services/redux/Reducers/CoacheeReducer';

export const switchSessionType = (state: Store, sessionID: string, newIsOfflineValue: boolean): Store => {
  const currentSession: ISession = state.myCourseManagement.myCurrentSession;
  const upComingSessions: ISession[] = state.myCourseManagement.myUpComingSession;

  if (currentSession.id === sessionID) {
    currentSession.is_offline = newIsOfflineValue;
  } else {
    const index = upComingSessions.findIndex((session: ISession) => session.id === sessionID);
    const newSessionItem: ISession = { ...upComingSessions[index], is_offline: newIsOfflineValue };
    upComingSessions[index] = newSessionItem;
  }

  const newCourseManagement = { ...state.myCourseManagement };
  newCourseManagement.myCurrentSession = { ...currentSession };
  newCourseManagement.myUpComingSession = [...upComingSessions];

  const newState: Store = { ...state, myCourseManagement: newCourseManagement };
  return newState;
};
